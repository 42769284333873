const newPassword = {
  heading: 'Mật khẩu mới',
  changePassword: 'Đổi mật khẩu',
  passwordVerify: 'Mật khẩu phải có ít nhất 8 ký tự',
  passwordRequired: 'Mật khẩu là bắt buộc',
  confirmPasswordVerify: 'Mật khẩu phải trùng khớp',
  confirmPasswordRequired: 'Xác nhận mật khẩu là bắt buộc',
  changePasswordSuccess: 'Đổi mật khẩu thành công!',
  changePasswordError: 'Đổi mật khẩu thất bại! Vui lòng thử lại',
  confirmPassword: 'Xác nhận mật khẩu',
  password: 'Mật khẩu',
  logo: 'Logo',
}

export default newPassword
