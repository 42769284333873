import blog from './blog'
import board from './board'
import calculator from './calculator'
import candidates from './candidates'
import clients from './clients'
import cvForm from './cvForm'
import dashboard from './dashboard'
import forgotPassword from './forgotPassword'
import header from './header'
import installApp from './installApp'
import interview from './interview'
import jobs from './jobs'
import kpiBonus from './kpiBonus'
import login from './login'
import manageCV from './manageCV'
import manageRedeem from './manageRedeem'
import newPassword from './newPassword'
import fetchNews from './news'
import notification from './notification'
import profile from './profile'
import quiz from './quiz'
import recruiter from './recruiter'
import report from './report'
import setting from './setting'
import signup from './signup'
import summaryHiring from './summaryHiring'
import task from './task'
import transferData from './transferData'
import users from './users'

const en = {
  login: 'Log in',
  home: 'Home',
  setting: 'Settings',
  logout: 'Log out',
  add_assignee: 'Add assignee',
  contacts: 'Contacts',
  nav: {
    dashboard: 'Dashboard',
    notification: 'Notification',
    task: 'Task',
    jobs: 'Jobs',
    jobDetail: 'Job detail',
    clients: 'Clients',
    candidates: 'Candidates',
    users: 'Users',
    kpiBonus: 'KPI & Bonus',
    interview: 'Interview',
    board: 'Board',
    calculator: 'Calculator',
    recruiter: 'External recruiter',
    blogs: 'Blogs',
    setting: 'Setting',
    recruiterProfile: 'Recruiter profile',
    installApp: 'Install app',
    manageCV: 'Manage CV',
    manageRedeem: 'Manage Redeem',
    blog: {
      index: 'Blog',
      list: 'List blogs',
      create: 'Create blog',
    },
    marketplace: {
      all: 'All',
      list: 'My Listing',
      my_purchase: 'My Purchase',
    },
    transactions: {
      withdraw: 'Withdraw',
    },
    transferData: 'Transfer Data',
    summaryHiring: 'Summary Hiring',
    yourJobs: 'Your Jobs',
    salaryCalculator: 'Salary Calculator',
    report: 'Report A Problem',
    news: {
      index: 'Fetch News',
      list: 'List News',
      create: 'Create New',
    },
  },
  pages: {
    dashboard,
    calculator,
    board,
    candidates,
    clients,
    interview,
    notification,
    recruiter,
    task,
    users,
    login,
    jobs,
    setting,
    signup,
    profile,
    installApp,
    forgotPassword,
    newPassword,
    manageRedeem,
    manageCV,
    blog,
    transferData,
    summaryHiring,
    kpiBonus,
    report,
    fetchNews,
  },
  common: {
    action: 'Action',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
    update: 'Update',
    close: 'Close',
    back: 'Back',
    next: 'Next',
    search: 'Search',
    advanceSearch: 'Advanced Search',
    new: 'New',
    clear: 'Clear',
    create: 'Create',
    saveChanges: 'Save changes',
    comingSoon: 'Coming soon',
    goToHome: 'Go to home',
    totalPoint: 'Total point',
    availablePoint: 'Available point',
    processingPoint: 'Processing point',
    type: 'Type',
    status: 'Status',
    timeStart: 'Time start',
    timeEnd: 'Time end',
    approve: 'Approve',
    reject: 'Reject',
    confirm: 'Confirm',
    noData: 'No Data',
    emptyContent: 'Empty content',
    somethingWentWrong: 'Somthing went wrong',
    invalidDate: 'Please select a valid date',
    minDateError: 'Date must not be less than 01/01/1970',
    datetimeFormatError:
      'Date is invalid. Please enter date in the correct format ex: 16/12-2022 14:17',
    timeFormatError:
      'Time is invalid. Please enter time in the correct format ex: 14:17',
    months: 'Months',
    years: 'Years',
    month: 'Month',
    year: 'Year',
    hide: 'Hide',
    show: 'Show',
    browse: 'Browse',
    candidate: 'Candidate',
    upload: 'Upload',
    error: 'Error',
    success: 'Success',
    tryAgain: 'Try Again',
    continue: 'Continue',
    history: 'History',
    pointToVnd: `1 point equal {{pointValue}} VND`,
    submit: 'Submit',
    points: 'points',
    referralNoity: 'Refer Friends and get <b>{{ points }}</b> points',
    accept: 'Accept',
    warningCancelEditForm:
      'You will have to input data again if you cancel this edition',
    apply: 'Apply',
    send: 'Send',
    view: 'View',
    fetchunt: 'Fetchunt',
    button: 'button',
    finish: 'Finish',
    pin: 'Pin',
    shareFetchNews: "Toggle share Fetch News"
  },
  cvUpload: {
    title: 'Click or drag file to this area to upload',
    pdfOnly: 'PDF file only ({{fileSize}})',
    singleFile: 'You can only upload 1 CV',
    multipleFile: 'You can upload maximum {{totalFile}} CV',
    uploadFileSuccess: 'Upload file {{fileName}} success!',
    error: {
      pdfOnly: 'File type must be pdf format',
      tooLargeSizeSingleFile: 'File must smaller than {{fileSize}}',
      singleFile: 'You can only upload 1 CV',
      multiFile: 'You can only upload {{totalFile}} CV',
    },
  },
  singleImageUpload: {
    title: 'Click or drag file to this area to upload',
    imageOnly: 'Image file only ({{fileSize}})',
    singleFile: 'You can only upload 1 image',
    multipleFile: 'You can upload maximum {{totalFile}} images',
    error: {
      imageOnly: 'File type must be image format',
      tooLargeSizeSingleFile: 'File must smaller than {{fileSize}}',
      singleFile: 'You can only upload 1 image',
      multiFile: 'You can only upload {{totalFile}} images',
    },
  },
  cvForm,
  header,
  quiz,
}

export default en
