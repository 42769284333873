import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_ADMIN_DOWNLOAD_CV_PDF,
  API_ADMIN_SEARCH_CANDIDATE,
  API_ADMIN_SEARCH_LIST_JOB,
  API_CANDIDATES,
} from 'routes/api'

import { _getApi, _postApi } from 'utils/axios'

export const getAdminSearchCandidate = createAsyncThunk(
  'candidates/getSearchCandidates',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_ADMIN_SEARCH_CANDIDATE}?${qs.stringify(queries)}`
    )

    if (!response?.data?.success)
      throw new Error(response?.message || response?.data?.message)

    return response?.data || {}
  }
)

export const getJobs = createAsyncThunk('candidates/getJobs', async () => {
  const response = await _getApi(API_ADMIN_SEARCH_LIST_JOB)

  return response?.data?.listJob || []
})

export const getAdminCandidateDetail = createAsyncThunk(
  'candidates/getDetail',
  async ({ candidateId }) => {
    const response = await _getApi(`${API_CANDIDATES}/${candidateId}`)
    return response?.data || {}
  }
)

export const convertDriverToBase64 = createAsyncThunk(
  'convertBase64/download',
  async ({ linkDrive, candidateId }) => {
    if (!candidateId) return {}
    const response = await _postApi(API_ADMIN_DOWNLOAD_CV_PDF, {
      linkDrive,
    })
    return {
      base64: response.data.base64,
      candidateId,
    }
  }
)

export const candidateSlice = createSlice({
  name: 'candidates',
  initialState: {
    listCandidates: {
      isLoading: false,
      data: {},
    },
    listJobs: [],
    candidateDetail: {},
    base64: '',
    isLoadingPDF: false,
  },
  reducers: {
    resetBase64(state) {
      state.base64 = ''
    },
    resetCandidateDetail(state) {
      state.candidateDetail = {}
    },
    setCandidateDetail(state, action) {
      state.candidateDetail = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminSearchCandidate.pending, (state) => {
        state.listCandidates.isLoading = true
      })
      .addCase(getAdminSearchCandidate.fulfilled, (state, action) => {
        state.listCandidates.data = action.payload
        state.listCandidates.isLoading = false
      })
      .addCase(getAdminSearchCandidate.rejected, (state) => {
        state.listCandidates.isLoading = false
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.listJobs = (action.payload || []).map(
          ({ title = '', id = '' }) => ({
            label: title,
            value: id,
          })
        )
      })
      .addCase(getAdminCandidateDetail.pending, (state) => {
        state.isLoadingPDF = false
      })
      .addCase(getAdminCandidateDetail.fulfilled, (state, action) => {
        state.candidateDetail = action.payload.data
        state.base64 = ''
        state.isLoadingPDF = true
      })
      .addCase(getAdminCandidateDetail.rejected, (state) => {
        state.isLoadingPDF = false
      })
      .addCase(convertDriverToBase64.pending, (state) => {
        state.isLoadingPDF = true
      })
      .addCase(convertDriverToBase64.fulfilled, (state, action) => {
        const { base64 = '', candidateId: originalCandidateId = '' } =
          action.payload || {}
        const { id: candidateId = '' } = state.candidateDetail || {}
        if (originalCandidateId !== candidateId) return

        state.base64 = base64
        state.isLoadingPDF = false
      })
      .addCase(convertDriverToBase64.rejected, (state) => {
        state.isLoadingPDF = false
      })
  },
})

export const { resetCandidateDetail, setCandidateDetail, resetBase64 } =
  candidateSlice.actions

export const selectPositionOptions = createSelector(
  [(state) => state.candidates.positionOptions],
  (positionOptions) => positionOptions
)

export const selectListCandidates = createSelector(
  [(state) => state.candidates.listCandidates],
  (listCandidates) => listCandidates
)

export const selectListJobs = createSelector(
  [(state) => state.candidates.listJobs],
  (listJobs) => listJobs
)

export default candidateSlice.reducer
