import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_ADMIN_INTERVIEW,
  API_CANDIDATE_INTERVIEW,
  API_LIST_ACTIVE_JOB,
} from 'routes/api'

import { _deleteApi, _getApi, _patchApi, _postApi } from 'utils/axios'

export const getCandidateByJob = createAsyncThunk(
  'interview/getCandidateByJob',
  async ({ jobId }) => {
    if (!jobId) return []
    const url = `${API_CANDIDATE_INTERVIEW}/${jobId}`
    const response = await _getApi(url)
    return response?.data?.candidate || []
  }
)

export const getInterview = createAsyncThunk(
  'interview/getInterview',
  async ({ queries = {} }) => {
    const url = `${API_ADMIN_INTERVIEW}?${qs.stringify(queries)}`
    const response = await _getApi(url)
    return response?.data || []
  }
)

export const getInterviewDetail = createAsyncThunk(
  'interview/getInterviewDetail',
  async ({ interviewId }) => {
    if (!interviewId) return {}
    const url = `${API_ADMIN_INTERVIEW}/${interviewId}`
    const response = await _getApi(url)
    return response?.data?.interview || {}
  }
)

export const updateInterview = createAsyncThunk(
  'interview/updateInterview',
  async ({ interviewId, data }) => {
    if (!interviewId) return
    const url = `${API_ADMIN_INTERVIEW}/${interviewId}`
    await _patchApi(url, data)
  }
)

export const addInterview = createAsyncThunk(
  'interview/addInterview',
  async ({ data }) => {
    const url = API_ADMIN_INTERVIEW
    try {
      await _postApi(url, data)
    } catch (error) {
      throw new Error(error?.message || error?.error)
    }
  }
)

export const deleteInterview = createAsyncThunk(
  'interview/deleteInterview',
  async ({ interviewId }) => {
    if (!interviewId) return
    const url = `${API_ADMIN_INTERVIEW}/${interviewId}`
    await _deleteApi(url)
  }
)

export const getJob = createAsyncThunk('interview/getJob', async () => {
  const response = await _getApi(API_LIST_ACTIVE_JOB)
  return response?.data?.arrJob || []
})

const initialState = {
  candidates: {
    isLoading: false,
    data: [],
  },
  interview: {
    isLoading: false,
    data: [],
  },
  interviewDetail: {
    isLoading: false,
    data: {},
  },
  job: [],
}

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    updateCandidate(state, action) {
      state.candidates.data = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCandidateByJob.pending, (state) => {
        state.candidates.isLoading = true
        state.candidates.data = []
      })
      .addCase(getCandidateByJob.fulfilled, (state, action) => {
        state.candidates.isLoading = false
        state.candidates.data = action.payload || []
      })
      .addCase(getCandidateByJob.rejected, (state) => {
        state.candidates.isLoading = false
      })
      .addCase(getInterview.pending, (state) => {
        state.interview.isLoading = true
        state.interview.data = []
      })
      .addCase(getInterview.fulfilled, (state, action) => {
        state.interview.isLoading = false
        state.interview.data = action.payload || []
      })
      .addCase(getInterview.rejected, (state) => {
        state.interview.isLoading = false
      })
      .addCase(getInterviewDetail.pending, (state) => {
        state.interviewDetail.isLoading = true
      })
      .addCase(getInterviewDetail.fulfilled, (state, action) => {
        state.interviewDetail.isLoading = false
        state.interviewDetail.data = action.payload || {}
      })
      .addCase(getInterviewDetail.rejected, (state) => {
        state.interviewDetail.isLoading = false
      })
      .addCase(getJob.fulfilled, (state, action) => {
        state.job = action.payload || []
      })
  },
})

export const { updateCandidate } = interviewSlice.actions

export const selectInterview = createSelector(
  [(state) => state.interview.interview],
  (interview) => interview
)

export const selectInterviewDetail = createSelector(
  [(state) => state.interview.interviewDetail],
  (interviewDetail) => interviewDetail
)

export const selectJob = createSelector(
  [(state) => state.interview.job],
  (job) => job
)

export default interviewSlice.reducer
