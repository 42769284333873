const kpiBonus = {
  kpi: 'KPI',
  bonus: 'Bonus',
  name: 'Name',
  team: 'Team',
  role: 'Role',
  salaryGross: 'Salary (Gross)',
  individualKPI: 'Individual KPI',
  process: 'Process',
  achievement: 'Achievement',
  kpiPeriod: 'KPI Period',
  year: 'Year',
  teamKPI: 'Team KPI',
  teamProcess: 'Team Process',
  historyKPI: 'History KPI',
  show: 'Show',
  dateTime: 'Date Time',
  details: 'Details',
  individualKPIAchievement: 'Individual KPI Achievement',
  bonusRate: 'Bonus Rate',
  teamBonus: 'Team Bonus',
  personalBonus: 'Personal Bonus',
  bonusFromTeamFund: 'Bonus From Team Fund',
  totalBonus: 'Total Bonus',
  status: 'Status',
  getListKpiTeamsFailed: 'Get list KPI team failed!',
  getListBonusTeamsFailed: 'Get list Bonus team failed!',
  changeSalaryContent: `<strong>Salary of {{name}}</strong> has been changed from <strong>{{salaryOld}} VND</strong> to <strong>{{salaryNew}} VND</strong>`,
  historyIndividualKPIContent: `<strong>Individual KPI of {{name}}</strong> has been changed from <strong>{{individualKpiOld}} VND</strong> to <strong>{{individualKpiNew}} VND</strong>`,
  historyTeamKPIContent: `<strong>Team KPI</strong> has been changed from <strong>{{teamKpiOld}} VND</strong> to <strong>{{teamKpiNew}} VND</strong>`,
  statusWaiting: 'Waiting',
  activateUserContent: `<strong>{{name}}</strong> has been activated`,
  deactivateUserContent: `<strong>{{name}}</strong> has been deactivated`,
  addUserToTeamContent: `<strong>{{name}}</strong> has been added to the team`,
  individualKPIAchievementPositive:
    'Individual KPI Achievement must be a positive number',
  individualKPIAchievementRequired: 'Individual KPI Achievement is required',
  individualKPIAchievementTypeError:
    'Individual KPI Achievement must be a number',
  bonusRateRequired: 'Bonus rate is required',
  bonusRateTypeError: 'Bonus rate must be a number',
  bonusRateRange: 'Bonus rate must be between {{min}} and {{max}}',
  updateTeamBonusSuccess: 'Update team bonus success',
  updateTeamBonusFail: 'Update team bonus failed',
}

export default kpiBonus
