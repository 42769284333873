import { createContext, useCallback, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import useAuth from 'hooks/useAuth'

import { setIsFirstLogin as resetFirstLogin } from 'utils/jwt'

const ReferralContext = createContext()

const ReferralProvider = ({ children }) => {
  const [isFirstLogin, setIsFirstLogin] = useState(false)
  const { user } = useAuth()
  const { userRefer = {} } = user || {}

  const firstLoginValue = localStorage.getItem('isFirstLogin')

  const isReferralSuccess = useMemo(
    () => isFirstLogin && !!userRefer?.name,
    [isFirstLogin, userRefer?.name]
  )

  const isRedirectReferralTab = useMemo(
    () => isFirstLogin && !userRefer?.name,
    [isFirstLogin, userRefer?.name]
  )

  const handleResetReferralData = useCallback((isFirstLogin = false) => {
    setIsFirstLogin(isFirstLogin)
    resetFirstLogin(isFirstLogin)
  }, [])

  useEffect(() => {
    try {
      setIsFirstLogin(JSON.parse(firstLoginValue))
    } catch (error) {
      setIsFirstLogin(false)
    }
  }, [firstLoginValue])

  return (
    <ReferralContext.Provider
      value={{
        isReferralSuccess,
        isRedirectReferralTab,
        handleResetReferralData,
      }}
    >
      {children}
    </ReferralContext.Provider>
  )
}

ReferralProvider.propTypes = {
  children: PropTypes.node,
}

export { ReferralContext, ReferralProvider }
