import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  API_CHECK_QUIZ_DAILY,
  API_LIST_QUIZ,
  API_SKIP_QUIZ_DAILY,
  API_SUBMIT_QUIZ,
} from 'routes/api'

import { _getApi, _postApi } from 'utils/axios'

export const getListQuiz = createAsyncThunk('quiz/getListQuiz', async () => {
  const response = await _getApi(API_LIST_QUIZ)
  return response?.data || {}
})

export const submitQuiz = createAsyncThunk('quiz/submitQuiz', async (data) => {
  const response = await _postApi(API_SUBMIT_QUIZ, data)

  return response?.data || {}
})

export const checkQuizDaily = createAsyncThunk(
  'quiz/checkQuizDaily',
  async () => {
    const response = await _getApi(API_CHECK_QUIZ_DAILY)

    return response?.data || {}
  }
)

export const skipQuizDaily = createAsyncThunk(
  'quiz/skipQuizDaily',
  async () => {
    const response = await _postApi(API_SKIP_QUIZ_DAILY)

    return response?.data || {}
  }
)

const initialState = {
  quizDaily: false,
  listQuiz: {
    isLoading: false,
    data: [],
    quizId: '',
  },
  results: {
    data: {},
    listQuizResults: [],
    isSubmitting: false,
  },
}

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuizDaily: (state, action) => {
      state.quizDaily = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListQuiz.pending, (state) => {
        state.listQuiz.isLoading = true
        state.listQuiz.quizId = ''
        state.listQuiz.data = []
      })
      .addCase(getListQuiz.fulfilled, (state, action) => {
        state.listQuiz.isLoading = false
        state.listQuiz.data = action.payload?.quiz?.listQuestion?.map(
          ({ title, options, type, id, TagGames: tags }) => {
            const tagsFormat = tags
              .concat(
                type === 'checkbox'
                  ? {
                      id: `multiple-choices-${title}`,
                      name: 'Multiple Choices',
                    }
                  : {}
              )
              .filter((item) => !!item?.name)

            return {
              name: id,
              type,
              title,
              choices: options?.filter((item) => !!item) || [],
              isRequired: false,
              tags: tagsFormat,
            }
          }
        )
        state.listQuiz.quizId = action.payload?.quiz?.quizId
      })
      .addCase(getListQuiz.rejected, (state) => {
        state.listQuiz.isLoading = false
        state.listQuiz.quizId = ''
        state.listQuiz.data = []
      })
      .addCase(submitQuiz.pending, (state) => {
        state.results.data = {}
        state.results.listQuizResults = []
        state.results.isSubmitting = true
      })
      .addCase(submitQuiz.fulfilled, (state, action) => {
        state.results.data = action.payload?.quiz
        state.results.listQuizResults =
          action.payload?.quiz?.QuizGameQuestions?.map((item) => {
            const { QuestionGame, isCorrect, answerUser } = item || {}

            const {
              title,
              options,
              type,
              id,
              TagGames: tags,
            } = QuestionGame || {}

            return {
              title,
              choices: options,
              type,
              name: id,
              tags,
              isCorrect,
              answerUser,
              isRequired: false,
            }
          })
        state.results.isSubmitting = false
      })
      .addCase(submitQuiz.rejected, (state) => {
        state.results.data = {}
        state.results.listQuizResults = []
        state.results.isSubmitting = false
      })
  },
})

export default quizSlice.reducer

export const { setQuizDaily } = quizSlice.actions

export const selectListQuiz = createSelector(
  [(state) => state.quiz.listQuiz],
  (listQuiz) => listQuiz
)

export const selectListQuizResults = createSelector(
  [(state) => state.quiz.results],
  (results) => results
)
