const newPassword = {
  heading: 'New Password',
  changePassword: 'Change Password',
  passwordVerify: 'Password must be at least 8 characters',
  passwordRequired: 'Password is required',
  confirmPasswordVerify: 'Passwords must match',
  confirmPasswordRequired: 'Confirm password is required',
  changePasswordSuccess: 'Change password success!',
  changePasswordError: 'Change password error! Please try again',
  confirmPassword: 'Confirm password',
  password: 'Password',
  logo: 'Logo',
}

export default newPassword
