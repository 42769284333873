const cvForm = {
  name: 'Name',
  dateOfBirth: 'Date of birth',
  phone: 'Phone number',
  position: 'Position',
  gender: 'Gender',
  address: 'Address',
  email: 'Email',
  nameJob: 'Name job',
  location: 'Location',
  clientName: 'Client name',
  columnName: 'Column name',
  approachDate: 'Approach date',
  expectedDate: 'Expected date',
  onboardDate: 'Onboarding date',
  expectedTestingDate: 'Expected testing date',
  yearOfExperience: 'Year of experience',
  latestCompany: 'Latest company',
  availability: 'Notice Period',
  salaryExpectation: 'Salary expectation (Gross)',
  negotiable: 'Negotiable',
  minSalary: 'Min',
  maxSalary: 'Max',
  workExperience: 'Work experience',
  addWorkExperience: 'Add work experience',
  positionRequired: 'Position is required',
  companyRequired: 'Company is required',
  descriptionRequired: 'Description is required',
  timeStartRequired: 'Time start is required',
  timeEndRequired: 'Time end is required',
  timeEndGreaterThanTimeStart: 'Time end must be greater than time start',
  company: 'Company',
  from: 'From',
  to: 'To',
  currentJob: 'Current job',
  description: 'Description',
  skill: 'Skill',
  education: 'Education',
  languages: 'Languages',
  checkedEnglish: 'Checked English',
  englishTakeNote: 'English take note',
  exEmployee: 'Ex-employee',
  certificate: 'Certificate',
  addCertificate: 'Add certificate',
  certificateName: 'Certificate name',
  certificateNameRequired: 'Certificate name is required',
  organization: 'Organization',
  year: 'Year',
  linkCertificate: 'Link certificate',
  linkCVUpload: 'Link CV upload',
  linkCVRefine: 'Link CV refine',
  createLinkCVRefine: 'Create link CV refine',
  approachPoint: 'Short description to Client',
  takeNote: 'Take note',
  recruiterUpload: 'Recruiter upload',
  rawCV: 'Raw CV',
  allowShare: 'Allow to share candidate now',
  amountUnlock: 'Amount unlock',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  skype: 'Skype',
  cvRequired: 'CV is required',
  nameRequired: 'Name is required',
  dateOfBirthRequired: 'Date of birth is required',
  phoneRequired: 'Phone is required',
  addressRequired: 'Address is required',
  emailRequired: 'Email is required',
  emailInvalid: 'Email is invalid',
  nameJobRequired: 'Name job is required',
  locationRequired: 'Location is required',
  columnNameRequired: 'Column name is required',
  expectedDateRequired: 'Expected date is required',
  onboardDateRequired: 'Onboarding date is required',
  workExperienceRequired: 'Work experience is required',
  experienceMissingData: 'Experience is missing data',
  skillRequired: 'Skill is required',
  languageRequired: 'Language is required',
  englishTakeNoteRequired: 'English take note is required',
  exEmployeeRequired: 'Ex-employee is required',
  approachPointRequired: 'Short description to Client is required',
  sellPointRequired: 'Sell point is required',
  minSalaryRequired: 'Min salary is required',
  maxSalaryRequired: 'Max salary is required',
  maxSalaryValidate: 'Max salary must be greater than min salary',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  canGetJobNow: 'Can get job now',
  afterOneMonth: 'After 1 month',
  afterTwoMonth: 'After 2 months',
  phoneInvalid: 'Not a valid phone number',
  minDateError: 'Date of birth must be greater than 1970 years',
  lastUpdate: 'Last update',
  thankYouMessage: `Thanks for listing the candidate profile! Your listing will be reviewed and approved by Fetch within 7 working days.
  It will be approved and published on the marketplace if it is valid and complete. If it is rejected, please provide more information required for Fetch to review.
  `,
  historyCandidate: 'History candidate',
  createLinkRefineSuccess: 'Create link refine successfully.',
  createLinkRefineFail: 'Create link refine failed!',
  present: 'Present',
  uploadSameYourCVMessage: `You are uploading a CV with information that matches
  your CV that is being sold on the market. If you want
  to continue uploading, the CV that is being sold will
  have to wait for the manager to re-check.`,
  uploadSameOtherCVMessage:
    'You cannot upload the CV with information that matches the CV that is being sold on the market.',
  isSameYourCVMessage:
    'You are sharing a CV with information that matches your CV being sold on the market, if you continue to share then your CV will have to wait for review.',
  isSameOtherCVMessage:
    'Candidates with this information already existed in the marketplace',
  salaryOffer: 'Salary Offer (Gross)',
  salaryOfferRequired: 'Salary offer is required',
  pointDesc: '1 point equal 1.000 VND',
  quickGenerateFromLinkedIn: 'Quick generate from LinkedIn',
  messageDontHaveCV: "Or if you don't have CV",
  instructionMessageUpload:
    'Save your candidate LinkedIn profile as a PDF and upload it to',
  instructionMessageClick: 'Click the <strong>"More"</strong> button.',
  openLinked: 'Open Linked',
  or: 'Or',
  ifDontHaveCV: `If you don't have CV`,
  downloadRefineCVFailed: 'Download link refine failed!',
  copyClipboardSuccess: 'Copied to clipboard!',
  copyClipboardFailed: 'Copied to clipboard failed!',
  clickToCopy: 'Click to copy',
  policyPrivacy: 'privacy policy',
  termsOfService: 'terms of service',
  agreeText: 'I have read and agree to the',
  policyPrivacyRequired: 'You must accept our policy privacy',
  termsOfServiceRequired: 'You must accept our terms of service',
}

export default cvForm
