import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_CANDIDATES,
  API_LIST_RECRUITERS,
  API_RECRUITER_CANDIDATE_LIST,
} from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'

export const getListCV = createAsyncThunk(
  'manageCV/getListCV',
  async (queries = {}) => {
    const response = await _getApi(API_RECRUITER_CANDIDATE_LIST, {
      params: queries,
    })

    return response?.data || {}
  }
)

export const updateStatus = createAsyncThunk(
  'manageCV/updateStatus',
  async ({ cvId, ...rest }) => {
    const response = await _patchApi(`${API_CANDIDATES}/${cvId}/verify`, rest)

    return response?.data || {}
  }
)

export const getListRecruiter = createAsyncThunk(
  'manageCV/getListRecruiter',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_LIST_RECRUITERS}?${qs.stringify(queries)}`
    )

    return response.data?.list || []
  }
)

const initialState = {
  listCV: {
    isLoading: false,
    data: {},
  },
  rawCV: {
    isLoading: false,
    base64: '',
  },
  listRecruiter: {
    isLoading: false,
    data: [],
  },
}

const manageCVSlice = createSlice({
  name: 'manageCV',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getListCV.pending, (state) => {
        state.listCV.isLoading = true
      })
      .addCase(getListCV.fulfilled, (state, action) => {
        state.listCV.isLoading = false
        state.listCV.data = action.payload
      })
      .addCase(getListCV.rejected, (state) => {
        state.listCV.isLoading = false
      })
      .addCase(getListRecruiter.pending, (state) => {
        state.listRecruiter.isLoading = true
        state.listRecruiter.data = []
      })
      .addCase(getListRecruiter.fulfilled, (state, action) => {
        state.listRecruiter.isLoading = false
        state.listRecruiter.data = action.payload
      })
      .addCase(getListRecruiter.rejected, (state) => {
        state.listRecruiter.isLoading = false
        state.listRecruiter.data = []
      })
  },
})

export const selectListCV = createSelector(
  [(state) => state.manageCV.listCV],
  (listCV) => listCV
)

export const selectListRecruiter = createSelector(
  [(state) => state.manageCV.listRecruiter],
  (listRecruiter) => listRecruiter
)

export default manageCVSlice.reducer
