const cvForm = {
  name: 'Tên',
  dateOfBirth: 'Ngày sinh',
  phone: 'Số điện thoại',
  position: 'Vị trí',
  gender: 'Giới tính',
  address: 'Địa chỉ',
  email: 'Email',
  nameJob: 'Tên công việc',
  location: 'Địa điểm',
  clientName: 'Tên khách hàng',
  columnName: 'Tên cột',
  approachDate: 'Ngày tiếp cận',
  expectedDate: 'Ngày dự kiến',
  onboardDate: 'Ngày bắt đầu',
  expectedTestingDate: 'Ngày kết thúc kiểm tra',
  yearOfExperience: 'Năm kinh nghiệm',
  latestCompany: 'Công ty gần đây',
  availability: 'Thời hạn thông báo',
  salaryExpectation: 'Mức lương mong muốn (Gross)',
  negotiable: 'Thỏa thuận',
  minSalary: 'Min',
  maxSalary: 'Max',
  workExperience: 'Kinh nghiệm làm việc',
  addWorkExperience: 'Thêm kinh nghiệm làm việc',
  positionRequired: 'Vị trí là bắt buộc',
  companyRequired: 'Công ty là bắt buộc',
  descriptionRequired: 'Mô tả là bắt buộc',
  timeStartRequired: 'Thời gian bắt đầu là bắt buộc',
  timeEndRequired: 'Thới gian kết thúc là bắt buộc',
  timeEndGreaterThanTimeStart:
    'Thới gian kết thúc phải lớn hơn thời gian bắt đầu',
  company: 'Công ty',
  from: 'Từ',
  to: 'Đến',
  currentJob: 'Công việc hiện tại',
  description: 'Mô tả',
  skill: 'Kỹ năng',
  education: 'Học vấn',
  languages: 'Ngôn ngữ',
  checkedEnglish: 'Đã kiểm tra tiếng Anh',
  englishTakeNote: 'Đánh giá tiếng Anh',
  exEmployee: 'Nhân viên cũ',
  certificate: 'Chứng chỉ',
  addCertificate: 'Thêm chứng chỉ',
  certificateName: 'Tên chứng chỉ',
  certificateNameRequired: 'Tên chứng chỉ là bắt buộc',
  organization: 'Tổ chức',
  year: 'Năm',
  linkCertificate: 'Link chứng chỉ',
  linkCVUpload: 'Link CV tải lên',
  linkCVRefine: 'Link CV tinh chỉnh',
  createLinkCVRefine: 'Tạo link CV tinh chỉnh',
  approachPoint: 'Mô tả ngắn gọn cho khách hàng',
  takeNote: 'Lưu ý',
  recruiterUpload: 'Người tải lên',
  rawCV: 'Xem CV',
  allowShare: 'Cho phép chia sẻ ứng viên',
  amountUnlock: 'Số điểm mở khóa',
  facebook: 'Facebook',
  linkedin: 'Linkedin',
  skype: 'Skype',
  cvRequired: 'CV là bắt buộc',
  nameRequired: 'Tên là bắt buộc',
  dateOfBirthRequired: 'Ngày sinh là bắt buộc',
  phoneRequired: 'Số điện thoại là bắt buộc',
  addressRequired: 'Địa chỉ là bắt buộc',
  emailRequired: 'Email là bắt buộc',
  emailInvalid: 'Email không hợp lệ',
  nameJobRequired: 'Tên công việc là bắt buộc',
  locationRequired: 'Địa điểm là bắt buộc',
  columnNameRequired: 'Tên cột là bắt buộc',
  expectedDateRequired: 'Ngày dự kiến là bắt buộc',
  onboardDateRequired: 'Ngày bắt đầu là bắt buộc',
  workExperienceRequired: 'Kinh nghiệm làm việc là bắt buộc',
  experienceMissingData: 'Kinh nghiệm làm việc thiếu dữ liệu',
  skillRequired: 'Kỹ năng là bắt buộc',
  languageRequired: 'Ngôn ngữ là bắt buộc',
  englishTakeNoteRequired: 'Lưu ý tiếng Anh là bắt buộc',
  exEmployeeRequired: 'Nhân viên cũ là bắt buộc',
  approachPointRequired: 'Mô tả ngắn gọn cho khách hàng là bắt buộc',
  sellPointRequired: 'Số điểm mở khóa là bắt buộc',
  minSalaryRequired: 'Mức lương tối thiểu là bắt buộc',
  maxSalaryRequired: 'Mức lương tối đa là bắt buộc',
  maxSalaryValidate: 'Mức lương tối đa phải lớn hơn mức lương tối thiểu',
  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
  canGetJobNow: 'Có thể nhận việc ngay',
  afterOneMonth: 'Sau 1 tháng',
  afterTwoMonth: 'Sau 2 tháng',
  phoneInvalid: 'Không phải là 1 số điện thoại hợp lệ',
  minDateError: 'Năm sinh phải lơn hơn 1970',
  lastUpdate: 'Lần cập nhật gần nhất',
  thankYouMessage: `Cảm ơn bạn đã thêm hồ sơ ứng viên! Ứng viên của bạn sẽ được Fetch kiểm tra và phê duyệt trong vòng 7 ngày.
  Nếu được chấp thuận thì ứng viên của bạn sẽ được đưa lên thị trường. Nếu bị từ chối, vui lòng cung cấp thêm thông tin để Fetch kiểm tra lại.
  `,
  historyCandidate: 'Lịch sử ứng viên',
  createLinkRefineSuccess: 'Tạo file pdf CV thành công.',
  createLinkRefineFail: 'Tạo file pdf CV thất bại!',
  present: 'Hiện tại',
  uploadSameYourCVMessage: `Bạn đang tải lên 1 cv có thông tin trùng với cv của bạn đang được bán trên chợ. Nếu bạn muốn tiếp tục tải lên thì cv đang được bán sẽ phải chờ quản lý kiểm tra lại.`,
  uploadSameOtherCVMessage:
    'Bạn không thể tải lên 1 cv có thông tin trùng với cv đang được bán trên chợ.',
  isSameYourCVMessage:
    'Bạn đang chia sẻ một ứng viên có thông tin trùng với ứng viên đang được bán trên chợ của bạn, nếu bạn vẫn muốn chia sẻ thì ứng viên này sẽ phải chờ để kiểm tra lại',
  isSameOtherCVMessage: 'Thông tin ứng viên này đã tồn tại trên chợ',
  salaryOffer: 'Lương đề nghị',
  salaryOfferRequired: 'Lương đề nghị là bắt buộc',
  pointDesc: '1 điểm bằng 1.000 VND',
  quickGenerateFromLinkedIn: 'Tạo nhanh từ LinkedIn',
  messageDontHaveCV: 'Hoặc nếu bạn không có CV',
  instructionMessageUpload:
    'Lưu hồ sơ LinkedIn ứng viên của bạn dưới dạng PDF và tải nó lên',
  instructionMessageClick: 'Bấm nút <strong>"More"</strong>.',
  openLinked: 'Mở Linked',
  or: 'Hoặc',
  ifDontHaveCV: `Nếu bạn không có CV`,
  downloadRefineCVFailed: 'Tải xuống CV thất bại!',
  copyClipboardSuccess: 'Sao chép thành công!',
  copyClipboardFailed: 'Sao chép thất bại!',
  clickToCopy: 'Nhấn để sao chép',
  policyPrivacy: 'chính sách bảo mật',
  termsOfService: 'điều khoản dịch vụ',
  agreeText: 'Tôi đã đọc và đồng ý với',
  policyPrivacyRequired: 'Bạn phải chấp nhận chính sách bảo mật của chúng tôi',
  termsOfServiceRequired: 'Bạn phải chấp nhận điều khoản dịch vụ của chúng tôi',
}

export default cvForm
