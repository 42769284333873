const recruiter = {
  heading: 'Dánh sách nhà tuyển dụng',
  name: 'Tên',
  email: 'Email',
  active: 'Hoạt động',
  action: 'Hành động',
  viewDetail: 'Xem chi tiết',
  profile: 'Hồ sơ',
  candidate: 'Ứng viên',
  detail: 'Chi tiết nhà tuyển dụng',
  profileInfo: 'Thông tin hồ sơ',
  displayName: 'Tên hiển thị',
  bankAccount: 'Tài khoản ngân hàng',
  fullName: 'Họ và tên',
  bankNumber: 'Số tài khoản',
  bankName: 'Tên ngân hàng',
  peopleId: 'Số CMND',
  status: 'Trạng thái',
  bonus: 'Tiền thưởng',
  referTime: 'Ngày giới thiệu',
  job: 'Việc làm',
  historyBonus: 'Lịch sử tiền thưởng',
  total: 'Tổng',
  level: 'Cấp độ',
  candidateHasMetTheFirst: 'Ứng viên đã đạt mục tiêu 1',
  candidateHasMetTheSecond: 'Ứng viên đã đạt mục tiêu 2',
  candidateHasMetTheLast: 'Ứng viên đã đạt mục tiêu cuối',
  toggleActiveUserSuccess:
    'Cập nhật trạng thái hoạt động của người dùng thành công!',
  toggleActiveUserFail:
    'Cập nhật trạng thái hoạt động của người dùng thất bại!',
  frontID: 'Mặt trước CMND',
  behindID: 'Mặt sau CMND',
  point: 'Điểm thưởng',
  message: 'Lời nhắn',
  editPointTitle: 'Chỉnh sửa điểm thưởng',
  historyPointTitle: 'Lịch sử điểm thưởng',
  time: 'Thời gian',
  amount: 'Số lượng',
  pointMustNumber: 'Điểm phải là dạng số',
  pointRequired: 'Điểm là bắt buộc',
  pointNotNegative: 'Điểm phải lớn hơn hoặc bằng 0',
  pointMustInteger: 'Điểm phải là số nguyên',
  editPointSuccess: 'Cập nhật điểm thành công!',
  editPointFail: 'Cập nhật điểm thất bại!',
  report: 'Báo cáo',
  recruiter: 'Nhà tuyển dụng',
  subject: 'Chủ đề',
}

export default recruiter
