import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  API_ADMIN_DOWNLOAD_CV_PDF,
  API_REVIEW_TRANSFER_CANDIDATES,
  API_SUBMIT_TRANSFER_CANDIDATES,
} from 'routes/api'

import { _getApi, _postApi } from 'utils/axios'
import { formatCVData } from 'utils/formatCVData'

export const getListCV = createAsyncThunk('transfer/getListCV', async () => {
  const response = await _getApi(API_REVIEW_TRANSFER_CANDIDATES)

  return response?.data?.list || []
})

export const getListSubmittedCV = createAsyncThunk(
  'transfer/getListSubmittedCV',
  async () => {
    const response = await _getApi(API_SUBMIT_TRANSFER_CANDIDATES)

    return response?.data?.list || []
  }
)

export const createCandidateFromCV = createAsyncThunk(
  'transfer/createCandidateFromCV',
  async (data) => {
    const response = await _postApi(API_REVIEW_TRANSFER_CANDIDATES, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const submitCandidateForm = createAsyncThunk(
  'transfer/submitCandidateForm',
  async (data) => {
    const response = await _postApi(API_SUBMIT_TRANSFER_CANDIDATES, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const convertDriverToBase64 = createAsyncThunk(
  'convertBase64/download',
  async (linkDrive) => {
    const response = await _postApi(API_ADMIN_DOWNLOAD_CV_PDF, {
      linkDrive,
    })
    return response?.data?.base64 || ''
  }
)

export const getDetailCandidateByReviewer = createAsyncThunk(
  'transfer/getDetailCandidateByReviewer',
  async (candidateId = '') => {
    const response = await _getApi(
      `${API_REVIEW_TRANSFER_CANDIDATES}/${candidateId}`
    )

    return response?.data?.data || []
  }
)

export const getDetailCandidateBySubmitter = createAsyncThunk(
  'transfer/getDetailCandidateBySubmitter',
  async (candidateInfo = {}) => candidateInfo
)

const initialState = {
  listCV: {
    isLoading: false,
    data: [],
  },
  base64: {
    data: '',
    isLoading: false,
  },
  candidateDetail: {
    data: null,
    isLoading: false,
  },
}

const transferDataSlice = createSlice({
  name: 'transferData',
  initialState,
  reducers: {
    resetBase64: (state) => {
      state.base64 = {
        data: '',
        isLoading: false,
      }
    },
    resetCandidateDetail: (state) => {
      state.candidateDetail = {
        data: null,
        isLoading: false,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListCV.pending, (state) => {
        state.listCV.isLoading = true
      })
      .addCase(getListCV.fulfilled, (state, action) => {
        state.listCV.isLoading = false
        state.listCV.data = (action.payload || []).filter(
          (it) => !it?.isTransfer && !it?.isCVEmpty
        )
      })
      .addCase(getListCV.rejected, (state) => {
        state.listCV.isLoading = false
      })
      .addCase(getListSubmittedCV.pending, (state) => {
        state.listCV.isLoading = true
      })
      .addCase(getListSubmittedCV.fulfilled, (state, action) => {
        state.listCV.isLoading = false
        state.listCV.data = (action.payload || []).filter((it) => !it?.isSubmit)
      })
      .addCase(getListSubmittedCV.rejected, (state) => {
        state.listCV.isLoading = false
      })
      .addCase(convertDriverToBase64.pending, (state) => {
        state.base64.isLoading = true
      })
      .addCase(convertDriverToBase64.fulfilled, (state, action) => {
        state.base64.isLoading = false
        state.base64.data = action.payload
      })
      .addCase(convertDriverToBase64.rejected, (state) => {
        state.base64.isLoading = false
      })
      .addCase(getDetailCandidateBySubmitter.fulfilled, (state, action) => {
        state.candidateDetail.isLoading = false
        state.candidateDetail.data = action.payload
      })
      .addCase(getDetailCandidateByReviewer.pending, (state) => {
        state.candidateDetail.isLoading = true
      })
      .addCase(getDetailCandidateByReviewer.fulfilled, (state, action) => {
        const {
          cv = '',
          id = '',
          name = '',
          payloadEx = {},
        } = action.payload || {}

        state.candidateDetail.isLoading = false
        state.candidateDetail.data = {
          cv,
          id,
          name,
          ...formatCVData(payloadEx || {}),
        }
      })
      .addCase(getDetailCandidateByReviewer.rejected, (state) => {
        state.candidateDetail.isLoading = false
      })
  },
})

export default transferDataSlice.reducer

export const { resetBase64, resetCandidateDetail } = transferDataSlice.actions

export const selectListCV = createSelector(
  [(state) => state.transferData.listCV],
  (listCV) => listCV
)

export const selectBase64 = createSelector(
  [(state) => state.transferData.base64],
  (base64) => base64
)

export const selectCandidateDetail = createSelector(
  [(state) => state.transferData.candidateDetail],
  (candidateDetail) => candidateDetail
)
