import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_BLOG, API_EXTERNAL_RANKING } from 'routes/api'

import { _getApi } from 'utils/axios'

export const getListExternalRanking = createAsyncThunk(
  'news/getListExternalRanking',
  async (queries = {}) => {
    const url = `${API_EXTERNAL_RANKING}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const getListPosts = createAsyncThunk(
  'news/getListPosts',
  async (queries = {}) => {
    const url = `${API_BLOG}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const getPostDetails = createAsyncThunk(
  'news/getPostDetails',
  async (slug) => {
    const response = await _getApi(`${API_BLOG}/${slug}`)

    return response?.data || {}
  }
)

export const getListRelatedPost = createAsyncThunk(
  'news/getListRelatedPost',
  async ({ postId = '', queries = {} }) => {
    const url = `${API_BLOG}/${postId}/related?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data?.list || []
  }
)

const initialState = {
  listExternalRanking: {
    isLoading: false,
    data: {},
  },
  listPosts: {
    isLoading: false,
    data: {},
  },
  postDetails: {
    data: {},
    isLoading: false,
  },
  listRelatedPost: {
    data: [],
    isLoading: false,
  },
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListExternalRanking.pending, (state) => {
        state.listExternalRanking.isLoading = true
        state.listExternalRanking.data = {}
      })
      .addCase(getListExternalRanking.fulfilled, (state, action) => {
        state.listExternalRanking.isLoading = false
        state.listExternalRanking.data = action.payload
      })
      .addCase(getListExternalRanking.rejected, (state) => {
        state.listExternalRanking.isLoading = false
      })
      .addCase(getListPosts.pending, (state) => {
        state.listPosts.isLoading = true
      })
      .addCase(getListPosts.fulfilled, (state, action) => {
        state.listPosts.data = action.payload
        state.listPosts.isLoading = false
      })
      .addCase(getListPosts.rejected, (state) => {
        state.listPosts.isLoading = false
      })
      .addCase(getPostDetails.pending, (state) => {
        state.postDetails.isLoading = true
      })
      .addCase(getPostDetails.fulfilled, (state, action) => {
        state.postDetails.isLoading = false
        state.postDetails.data = action.payload
      })
      .addCase(getPostDetails.rejected, (state) => {
        state.postDetails.isLoading = false
      })
      .addCase(getListRelatedPost.pending, (state) => {
        state.listRelatedPost.isLoading = true
      })
      .addCase(getListRelatedPost.fulfilled, (state, action) => {
        state.listRelatedPost.isLoading = false
        state.listRelatedPost.data = action.payload
      })
      .addCase(getListRelatedPost.rejected, (state) => {
        state.listRelatedPost.isLoading = false
      })
  },
})

export const selectListExternalRanking = createSelector(
  [(state) => state.new.listExternalRanking],
  (listExternalRanking) => listExternalRanking
)

export const selectListPosts = createSelector(
  [(state) => state.new.listPosts],
  (listPosts) => listPosts
)

export const selectPostDetails = createSelector(
  [(state) => state.new.postDetails],
  (postDetails) => postDetails
)

export const selectListRelatedPost = createSelector(
  [(state) => state.new.listRelatedPost],
  (listRelatedPost) => listRelatedPost
)

export default newsSlice.reducer
