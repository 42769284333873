import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

import { DATE_YEAR_MONTH_DAY_FORMAT } from 'config'

import {
  API_ADD_POSITION,
  API_ADMIN_CARD_INTERVIEW,
  API_ADMIN_SEARCH_LIST_JOB,
  API_ASSIGNMENT,
  API_CARD,
  API_CARD_LABEL,
  API_CARD_MEMBER_IN_CHARGE,
  API_LANES,
  API_LIST_ACTIVE_JOB,
  API_LIST_CLIENT,
  API_LIST_LABEL,
  API_LIST_MEMBER,
  API_LIST_UPDATE_HISTORY,
  API_LIST_USER,
  API_REMOVE_ASSIGNMENT,
  API_SEARCH_CARD,
  API_SEARCH_EMAIL,
  API_SEARCH_PHONE,
} from 'routes/api'

import { _deleteApi, _getApi, _patchApi, _postApi } from 'utils/axios'

import { LIST_COLUMN_RESET_CHECK_ENG } from './config'

export const getBoard = createAsyncThunk('kanban/getBoard', async (data) => {
  let queries
  if (data) {
    queries = Object.keys(data)
      .filter((key) => key !== 'search' && data[key])
      .reduce((obj, key) => {
        const getValue = (key) => {
          if (['startDate', 'endDate'].includes(key))
            return format(data[key], DATE_YEAR_MONTH_DAY_FORMAT)

          return data[key]
        }

        return {
          ...obj,
          [key]: getValue(key),
        }
      }, {})
  }

  const response = await _getApi(API_CARD, {
    params: queries,
  })

  return response.data.list
})

export const getListColumn = createAsyncThunk(
  'candidates/getListColumn',
  async () => {
    const response = await _getApi(API_LANES)
    return response.data?.lane || []
  }
)

export const getMoreCardByColumn = createAsyncThunk(
  'kanban/getMoreCardByColumn',
  async ({ columnId, offset = 0, dataSearch }) => {
    let search
    if (dataSearch) {
      search = Object.keys(dataSearch)
        .filter((key) => !!dataSearch[key])
        .reduce((acc, key) => {
          acc[key] = dataSearch[key]
          return acc
        }, {})
    }

    const response = await _getApi(`${API_CARD}/${columnId}/lane`, {
      params: {
        offset,
        ...search,
      },
    })

    return {
      data: response.data.list,
      columnId,
    }
  }
)

export const updateCardByDestColumn = createAsyncThunk(
  'kanban/updateCardByDestColumn',
  async (
    {
      columnId,
      cardId,
      originalColumns = {},
      newColumns = {},
      enqueueSnackbar,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(updateBoardColumns(newColumns))
      const response = await _patchApi(`${API_CARD}/${cardId}`, {
        laneId: columnId,
      })

      if (response?.data?.success) return

      enqueueSnackbar(response?.message || response?.data?.message, {
        variant: 'error',
      })

      // eslint-disable-next-line consistent-return
      return rejectWithValue({
        columns: originalColumns,
      })
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      })

      // eslint-disable-next-line consistent-return
      return rejectWithValue({
        columns: originalColumns,
      })
    }
  }
)

export const createLabel = createAsyncThunk(
  'kanban/createLabel',
  async (data) => {
    const { laneId, ...rest } = data || {}
    const response = await _postApi(API_CARD_LABEL, rest)

    if (response.data.success) {
      return { ...data, laneId, id: response?.data?.label?.id || '' }
    }

    return response || {}
  }
)

export const deleteLabel = createAsyncThunk(
  'kanban/deleteLabel',
  async (data) => {
    const { id } = data || {}
    const response = await _deleteApi(`${API_CARD}/${id}/label`)

    if (response.data.success) {
      return { ...data }
    }

    return response
  }
)

export const moveCard = createAsyncThunk('kanban/moveCard', async (data) => {
  try {
    const { laneId, cardId } = data || {}
    const response = await _patchApi(`${API_CARD}/${cardId}`, { laneId })

    if (response.data.success) {
      return { ...data }
    }

    throw new Error(response?.message || response?.data?.message)
  } catch (error) {
    const { message } = error || {}

    if (Array.isArray(message)) {
      throw new Error(message.join(', '))
    }

    throw new Error(message)
  }
})

export const updateCheckEng = createAsyncThunk(
  'kanban/updateCheckEng',
  async (data = {}) => {
    try {
      const { cardId, reqData } = data || {}

      const response = await _patchApi(`${API_CARD}/${cardId}`, reqData)

      if (!response?.data?.success) {
        throw new Error(response?.message || response?.data?.message)
      }

      return data
    } catch (error) {
      const { message } = error || {}

      if (Array.isArray(message)) {
        throw new Error(message.join(', '))
      }

      throw new Error(message)
    }
  }
)

export const toggleStorageCard = createAsyncThunk(
  'kanban/toggleStorageCard',
  async (data) => {
    const { cardId, storage } = data || {}
    const response = await _patchApi(`${API_CARD}/${cardId}`, {
      storage: !storage,
    })

    if (response.data.success) {
      return { ...data }
    }

    return response
  }
)

export const removeAssignee = createAsyncThunk(
  'kanban/removeAssignee',
  async ({ cardId, userId, ...user }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(removeCardAssignee({ cardId, ...user }))
      const response = await _patchApi(`${API_REMOVE_ASSIGNMENT}/${cardId}`, {
        userId,
      })

      if (response.data.success) {
        return { cardId, ...user }
      }

      return response
    } catch (error) {
      return rejectWithValue({ cardId, ...user })
    }
  }
)

export const addAssignee = createAsyncThunk(
  'kanban/addAssignee',
  async ({ cardId, ...user }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(addCardAssignee({ cardId, ...user }))
      const response = await _patchApi(`${API_ASSIGNMENT}/${cardId}`, {
        userId: user.id,
      })

      if (response.data.success) {
        return { cardId, ...user }
      }

      return response
    } catch (error) {
      return rejectWithValue({ cardId, ...user })
    }
  }
)

export const searchEmail = createAsyncThunk(
  'kanban/searchEmail',
  async (queries = {}) => {
    const response = await _getApi(API_SEARCH_EMAIL, { params: queries })
    return response?.data?.candidate || []
  }
)

export const searchPhone = createAsyncThunk(
  'kanban/searchPhone',
  async (queries = {}) => {
    const response = await _getApi(API_SEARCH_PHONE, { params: queries })
    return response?.data?.candidate || []
  }
)

export const searchCards = createAsyncThunk(
  'kanban/searchCard',
  async (queries = {}) => {
    const response = await _getApi(API_SEARCH_CARD, { params: queries })

    return (response?.data?.list || []).map((card) => {
      const { Lane = {}, Job = {} } = card || {}
      const { nameColumn = '', order = 0, background = '' } = Lane || ''
      const { title = '' } = Job || {}

      return {
        ...card,
        value: card.Candidate.name,
        label: card.Candidate.name,
        id: card.id,
        cardColumn: nameColumn,
        cardOrder: order,
        cardBackground: background,
        jobTitle: title,
      }
    })
  }
)

export const getLabels = createAsyncThunk('kanban/getLabels', async () => {
  const response = await _getApi(API_LIST_LABEL)

  return (response?.data?.list || []).map(
    ({ title = '', background = '', id = '' }) => ({
      value: title,
      label: title,
      background,
      id,
    })
  )
})

export const getClients = createAsyncThunk('kanban/getClients', async () => {
  const response = await _getApi(API_LIST_CLIENT)

  return (response?.data?.clients || []).map(({ id = '', name = '' }) => ({
    value: id,
    label: name,
  }))
})

export const getJobs = createAsyncThunk(
  'kanban/getJobs',
  async (queries = {}) => {
    const response = await _getApi(API_ADMIN_SEARCH_LIST_JOB, {
      params: queries,
    })

    return (response?.data?.listJob || []).map(
      ({ id = '', title = '', jobStatus = '', Client = {} }) => ({
        value: id,
        label: title,
        status: jobStatus,
        clientName: Client?.name,
      })
    )
  }
)

export const getMembers = createAsyncThunk('kanban/getMembers', async () => {
  const response = await _getApi(API_LIST_MEMBER)

  return (response?.data?.list || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }))
})

export const getUsers = createAsyncThunk('kanban/getUsers', async () => {
  const response = await _getApi(API_LIST_USER)

  return response?.data?.list || []
})

export const getAllPositions = createAsyncThunk(
  'kanban/getAllPositions',
  async () => {
    const response = await _getApi(API_ADD_POSITION)
    return response?.data?.list || []
  }
)

export const getListActiveJobs = createAsyncThunk(
  'kanban/getListActiveJobs',
  async () => {
    const response = await _getApi(API_LIST_ACTIVE_JOB)

    return response?.data?.arrJob || []
  }
)

export const addCard = createAsyncThunk('kanban/addCard', async (data) => {
  try {
    const response = await _postApi(API_CARD, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  } catch (error) {
    const { message } = error || {}

    if (Array.isArray(message)) {
      throw new Error(message.join(', '))
    }

    throw new Error(message)
  }
})

export const updateCard = createAsyncThunk(
  'kanban/updateCard',
  async (data) => {
    try {
      const response = await _patchApi(
        `${API_CARD}/${data.cardId}`,
        data.reqData
      )

      if (!response?.data?.success) {
        throw new Error(response?.message || response?.data?.message)
      }
    } catch (error) {
      const { message } = error || {}

      if (Array.isArray(message)) {
        throw new Error(message.join(', '))
      }

      throw new Error(message)
    }
  }
)

export const updateLane = createAsyncThunk(
  'kanban/updateLane',
  async (data) => {
    const response = await _patchApi(`${API_CARD}/${data.cardId}`, {
      laneId: data.laneId,
    })

    return response || {}
  }
)

export const getCardDetail = createAsyncThunk(
  'kanban/getCardDetail',
  async (cardId) => {
    const response = await _getApi(`${API_CARD}/${cardId}`)

    return response || {}
  }
)

export const getListUpdateHistory = createAsyncThunk(
  'kanban/getListUpdateHistory',
  async (data) => {
    const response = await _postApi(API_LIST_UPDATE_HISTORY, data)

    return response?.data?.historyCard || []
  }
)

export const createInterview = createAsyncThunk(
  'kanban/createInterview',
  async (data) => {
    try {
      await _postApi(API_ADMIN_CARD_INTERVIEW, data)
    } catch (error) {
      throw new Error(error?.data?.message || error?.message)
    }
  }
)

export const completeProcess = createAsyncThunk(
  'kanban/completeProcess',
  async ({ cardId, laneId }) => {
    try {
      const response = await _patchApi(`${API_CARD}/${cardId}/mark-done`)

      if (response.data.success) {
        return { cardId, laneId }
      }
      return response?.data
    } catch (error) {
      throw new Error(error?.data?.message || error?.data?.message)
    }
  }
)

export const updateMemberInCharge = createAsyncThunk(
  'kanban/updateMemberInCharge',
  async ({ cardId = '', laneId = '', data = {} }, { dispatch, getState }) => {
    const { kanban = {} } = getState() || {}
    const { isDataInForm = false } = kanban?.listCardUsers || {}

    const response = await _patchApi(
      `${API_CARD_MEMBER_IN_CHARGE}/${cardId}`,
      data
    )

    if (response?.data?.success) {
      dispatch(
        isDataInForm
          ? updateMemberInChargeToForm({
              isMemberInCharge: true,
              ...data,
            })
          : updateMemberInChargeToCard({
              cardId,
              laneId,
              isMemberInCharge: true,
              ...data,
            })
      )
    }

    return response || {}
  }
)

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current
    return accumulator
  }, {})
}

const initialState = {
  isLoading: false,
  isUpdatingListCard: false,
  error: null,
  board: {
    allColumns: {},
    columns: {},
    columnOrder: [],
  },
  listColumnName: [],
  searchEmail: {
    isLoading: false,
    emailOptions: [],
  },
  searchPhone: {
    isLoading: false,
    phoneOptions: [],
  },
  searchCards: {
    isLoading: false,
    cardOptions: [],
  },
  listUpdateHistory: {
    isLoading: false,
    data: [],
  },
  labelOptions: [],
  clientOptions: [],
  jobOptions: [],
  memberOptions: [],
  activeJobOptions: [],
  positionOptions: [],
  listUsers: [],
  dataSearch: {},
  listCardUsers: {
    data: [],
    isDataInForm: false,
  },
}

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,

  reducers: {
    updateBoardColumns(state, action) {
      state.board.columns = action.payload
    },
    addCardAssignee(state, action) {
      const { laneId, cardId, ...user } = action.payload
      const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
        (item) => item.id === cardId
      )

      if (cardIndex !== -1) {
        state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers.push({
          User: { ...user },
          userId: user?.id,
          cardId,
          laneId,
        })
      }
    },
    removeCardAssignee(state, action) {
      const { laneId, cardId, ...user } = action.payload
      const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
        (item) => item.id === cardId
      )
      if (cardIndex !== -1) {
        state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers =
          state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers.filter(
            (item) => item.userId !== user.id
          )
      }
    },
    getListCardUsers(state, action) {
      const { listCardUsers = [], isDataInForm = false } = action.payload || {}
      state.listCardUsers.data = listCardUsers || []
      state.listCardUsers.isDataInForm = isDataInForm || false
    },
    resetListCardUsers(state) {
      state.listCardUsers = {}
    },
    updateMemberInChargeToForm(state, action) {
      const { userId = '', isMemberInCharge = false } = action.payload

      const memberInChargeIndex = state.listCardUsers.data.findIndex(
        (item) => item?.isMemberInCharge
      )

      const newMemberInChargeIndex = state.listCardUsers.data.findIndex(
        (item) => item?.userId === userId
      )

      if ([memberInChargeIndex, newMemberInChargeIndex].includes(-1)) return

      state.listCardUsers.data[memberInChargeIndex].isMemberInCharge = false
      state.listCardUsers.data[newMemberInChargeIndex].isMemberInCharge =
        isMemberInCharge
    },
    updateMemberInChargeToCard(state, action) {
      const {
        laneId = '',
        cardId = '',
        userId = '',
        isMemberInCharge = false,
        isCloseForm = false,
      } = action.payload

      if (!laneId) return

      const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
        (item) => item.id === cardId
      )

      if (cardIndex === -1) return

      if (isCloseForm) {
        state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers =
          state.listCardUsers.data
        return
      }

      const memberInChargeIndex = state.board.columns[laneId].CandidateJobs[
        cardIndex
      ].CardUsers.findIndex((item) => item?.isMemberInCharge)

      const newMemberInCharIndex = state.board.columns[laneId].CandidateJobs[
        cardIndex
      ].CardUsers.findIndex((item) => item?.userId === userId)

      if (memberInChargeIndex !== -1) {
        state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers[
          memberInChargeIndex
        ].isMemberInCharge = false
      }

      if (newMemberInCharIndex !== -1) {
        state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers[
          newMemberInCharIndex
        ].isMemberInCharge = isMemberInCharge
      }
    },
    addCommentToCard(state, action) {
      state.listComments = state.listComments.unshift(action.payload)
    },
    updateCommentToCard(state, action) {
      const commentIndex = state.listComments.findIndex(
        (comment) => comment.id === action.payload.id
      )
      state.listComments[commentIndex].content = action.payload.content
      state.listComments[commentIndex].updatedAt = action.payload.updatedAt
    },
    updateDataSearch(state, action) {
      state.dataSearch = action.payload
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getBoard.pending, (state) => {
        state.isLoading = true
        state.isUpdatingListCard = true
      })
      .addCase(getBoard.fulfilled, (state, action) => {
        state.isLoading = false
        state.isUpdatingListCard = false
        state.board.columnOrder = action.payload.map((value) => value.id)
        state.board.columns = objFromArray(action.payload)
      })
      .addCase(getBoard.rejected, (state, action) => {
        state.isLoading = false
        state.isUpdatingListCard = false
        state.error = action.error.message
      })
      .addCase(getListColumn.fulfilled, (state, action) => {
        const listName = action.payload.map((item) => ({
          label: item.nameColumn,
          value: item.id,
        }))
        state.listColumnName = listName
        state.board.allColumns = objFromArray(action.payload)
      })
      .addCase(getMoreCardByColumn.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMoreCardByColumn.fulfilled, (state, action) => {
        const { columnId, data } = action.payload || {}

        state.isLoading = false
        state.board.columns[columnId].isEndPage = data.length === 0
        state.board.columns[columnId].CandidateJobs.push(...data)
      })
      .addCase(getMoreCardByColumn.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message
      })
      .addCase(updateCardByDestColumn.rejected, (state, action) => {
        state.board.columns = action.payload.columns
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        const { laneId, cardId, id } = action.payload
        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === cardId
        )
        state.board.columns[laneId].CandidateJobs[cardIndex].Labels =
          state.board.columns[laneId].CandidateJobs[cardIndex].Labels.filter(
            (item) => item.id !== id
          )
      })
      .addCase(createLabel.fulfilled, (state, action) => {
        const { laneId, candidateJobId, ...rest } = action.payload
        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === candidateJobId
        )
        state.board.columns[laneId].CandidateJobs[cardIndex].Labels.push(rest)
      })
      .addCase(completeProcess.fulfilled, (state, action) => {
        const { cardId, laneId } = action.payload
        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === cardId
        )
        state.board.columns[laneId].CandidateJobs[cardIndex].done = true
      })
      .addCase(moveCard.fulfilled, (state, action) => {
        const { laneId, cardId, sourceId } = action.payload
        const card = state.board.columns[sourceId].CandidateJobs.find(
          (item) => item.id === cardId
        )

        const sourceIndex = state.board.columns[
          sourceId
        ].CandidateJobs.findIndex((item) => item.id === cardId)

        state.board.columns[sourceId].CandidateJobs.splice(sourceIndex, 1)

        const destColumn = state.board.columns[laneId]

        if (!destColumn) return

        const { CandidateJobs, ...rest } = destColumn

        const newLane = { ...rest }

        const updatedCard = {
          ...card,
          Lane: newLane,
          laneId,
          ...(LIST_COLUMN_RESET_CHECK_ENG.includes(newLane?.nameColumn) && {
            isCheckEng: false,
            noteEnglish: '',
          }),
        }

        state.board.columns[laneId].CandidateJobs.splice(0, 0, updatedCard)
      })
      .addCase(toggleStorageCard.fulfilled, (state, action) => {
        // remove card after storage
        const { laneId, cardId } = action.payload
        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === cardId
        )
        state.board.columns[laneId].CandidateJobs.splice(cardIndex, 1)
      })
      .addCase(addAssignee.rejected, (state, action) => {
        const { laneId, cardId, ...user } = action.payload
        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === cardId
        )
        if (cardIndex !== -1) {
          state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers =
            state.board.columns[laneId].CandidateJobs[
              cardIndex
            ].CardUsers.filter((item) => item.userId !== user.id)
        }
      })
      .addCase(removeAssignee.rejected, (state, action) => {
        const { laneId, cardId, ...user } = action.payload
        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === cardId
        )
        if (cardIndex !== -1) {
          state.board.columns[laneId].CandidateJobs[cardIndex].CardUsers.push(
            user
          )
        }
      })
      .addCase(searchEmail.pending, (state) => {
        state.searchEmail.isLoading = true
      })
      .addCase(searchEmail.fulfilled, (state, action) => {
        state.searchEmail.isLoading = false
        state.searchEmail.emailOptions = action.payload
      })
      .addCase(searchPhone.pending, (state) => {
        state.searchPhone.isLoading = true
      })
      .addCase(searchPhone.fulfilled, (state, action) => {
        state.searchPhone.isLoading = false
        state.searchPhone.phoneOptions = action.payload
      })
      .addCase(searchCards.pending, (state) => {
        state.searchCards.isLoading = true
      })
      .addCase(searchCards.fulfilled, (state, action) => {
        state.searchCards.isLoading = false
        state.searchCards.cardOptions = action.payload
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.labelOptions = action.payload
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.clientOptions = action.payload
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        state.jobOptions = action.payload
      })
      .addCase(getMembers.fulfilled, (state, action) => {
        state.memberOptions = action.payload
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.listUsers = action.payload
      })
      .addCase(getAllPositions.fulfilled, (state, action) => {
        state.positionOptions = (action.payload || []).map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      })
      .addCase(getListActiveJobs.fulfilled, (state, action) => {
        state.activeJobOptions = action.payload
      })
      .addCase(getListUpdateHistory.pending, (state) => {
        state.listUpdateHistory.isLoading = true
      })
      .addCase(getListUpdateHistory.fulfilled, (state, action) => {
        state.listUpdateHistory.isLoading = false
        state.listUpdateHistory.data = action.payload
      })
      .addCase(getListUpdateHistory.rejected, (state) => {
        state.listUpdateHistory.isLoading = false
      })
      .addCase(addCard.fulfilled, (state) => {
        state.isUpdatingListCard = true
      })
      .addCase(updateCheckEng.fulfilled, (state, action) => {
        const { cardId, laneId, reqData } = action.payload || {}
        const { isCheckEng = false, noteEnglish = '' } = reqData || {}

        const cardIndex = state.board.columns[laneId].CandidateJobs.findIndex(
          (item) => item.id === cardId
        )

        if (cardIndex !== -1) {
          state.board.columns[laneId].CandidateJobs[cardIndex].isCheckEng =
            isCheckEng
          if (isCheckEng && noteEnglish)
            state.board.columns[laneId].CandidateJobs[cardIndex].noteEnglish =
              noteEnglish
        }
      })
  },
})

export const {
  updateBoardColumns,
  addCardAssignee,
  removeCardAssignee,
  updateDataSearch,
  updateMemberInChargeToCard,
  updateMemberInChargeToForm,
  getListCardUsers,
  resetListCardUsers,
} = kanbanSlice.actions

export const selectBoard = createSelector(
  [(state) => state.kanban.board],
  (board) => board
)

export const selectEmailOptions = createSelector(
  [(state) => state.kanban.searchEmail],
  (searchEmail) => searchEmail
)

export const selectPhoneOptions = createSelector(
  [(state) => state.kanban.searchPhone],
  (searchPhone) => searchPhone
)

export const selectCardOptions = createSelector(
  [(state) => state.kanban.searchCards],
  (searchCards) => searchCards
)

export const selectLabelOptions = createSelector(
  [(state) => state.kanban.labelOptions],
  (labelOptions) => labelOptions
)

export const selectClientOptions = createSelector(
  [(state) => state.kanban.clientOptions],
  (clientOptions) => clientOptions
)

export const selectJobOptions = createSelector(
  [(state) => state.kanban.jobOptions],
  (jobOptions) => jobOptions
)

export const selectMemberOptions = createSelector(
  [(state) => state.kanban.memberOptions],
  (memberOptions) => memberOptions
)

export const selectListUsers = createSelector(
  [(state) => state.kanban.listUsers],
  (listUsers) => listUsers
)

export const selectPositionOptions = createSelector(
  [(state) => state.kanban.positionOptions],
  (positionOptions) => positionOptions
)

export const selectActiveJobOptions = createSelector(
  [(state) => state.kanban.activeJobOptions],
  (activeJobOptions) => activeJobOptions
)

export const selectListUpdateHistory = createSelector(
  [(state) => state.kanban.listUpdateHistory],
  (listUpdateHistory) => listUpdateHistory
)

export const selectDataSearch = createSelector(
  [(state) => state.kanban.dataSearch],
  (dataSearch) => dataSearch
)

export const selectListCardUsers = createSelector(
  [(state) => state.kanban.listCardUsers],
  (listCardUsers) => listCardUsers
)

export default kanbanSlice.reducer
