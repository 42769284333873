const recruiter = {
  heading: 'List recruiter',
  name: 'Name',
  email: 'Email',
  active: 'Active',
  action: 'Action',
  viewDetail: 'View detail',
  profile: 'Profile',
  candidate: 'Candidate',
  detail: 'Detail recruiter',
  profileInfo: 'Profile Info',
  displayName: 'Display name',
  bankAccount: 'Bank Account',
  fullName: 'Full name',
  bankNumber: 'Bank Number',
  bankName: 'Bank Name',
  peopleId: 'People ID',
  status: 'Status',
  bonus: 'Bonus',
  referTime: 'Refer Time',
  job: 'Job',
  historyBonus: 'History Bonus',
  total: 'Total',
  level: 'Level',
  firstGoal: 'Candidate has met the first goal',
  secondGoal: 'Candidate has met the second goal',
  lastGoal: 'Candidate has met the last goal',
  toggleActiveUserSuccess: 'Toggle active user success!',
  toggleActiveUserFail: 'Toggle active user failed!',
  frontID: 'Front ID',
  behindID: 'Behind ID',
  point: 'Point',
  message: 'Message',
  editPointTitle: 'Edit point',
  historyPointTitle: 'History point',
  time: 'Time',
  amount: 'Amount',
  pointMustNumber: 'Point must be a number',
  pointRequired: 'Point is required',
  pointNotNegative: 'Point must be greater than or equal to 0',
  pointMustInteger: 'Point must be an integer',
  editPointSuccess: 'Edit point success!',
  editPointFail: 'Edit point fail!',
  report: 'Report',
  recruiter: 'Recruiter',
  subject: 'Subject',
}

export default recruiter
