import blog from './blog'
import board from './board'
import calculator from './calculator'
import candidates from './candidates'
import clients from './clients'
import cvForm from './cvForm'
import dashboard from './dashboard'
import forgotPassword from './forgotPassword'
import header from './header'
import installApp from './installApp'
import interview from './interview'
import jobs from './jobs'
import kpiBonus from './kpiBonus'
import login from './login'
import manageCV from './manageCV'
import manageRedeem from './manageRedeem'
import newPassword from './newPassword'
import fetchNews from './news'
import notification from './notification'
import profile from './profile'
import quiz from './quiz'
import recruiter from './recruiter'
import report from './report'
import setting from './setting'
import signup from './signup'
import task from './task'
import transferData from './transferData'
import users from './users'

const vn = {
  login: 'Đăng nhập',
  home: 'Trang chủ',
  setting: 'Cài đặt',
  logout: 'Đăng xuất',
  add_assignee: 'Thêm người',
  contacts: 'Liên hệ',
  nav: {
    dashboard: 'Bảng điều khiển',
    notification: 'Thông báo',
    task: 'Nhiệm vụ',
    jobs: 'Công việc',
    jobDetail: 'Chi tiết công việc',
    clients: 'Khách hàng',
    candidates: 'Ứng viên',
    users: 'Người dùng',
    kpiBonus: 'KPI & Bonus',
    interview: 'Phỏng vấn',
    board: 'Bảng',
    calculator: 'Máy tính',
    recruiter: 'Nhà tuyển dụng bên ngoài',
    blogs: 'Blogs',
    setting: 'Cài đặt',
    recruiterProfile: 'Hồ sơ nhà tuyển dụng',
    installApp: 'Cài đặt ứng dụng',
    manageCV: 'Quản lý CV',
    manageRedeem: 'Quản lý điểm thưởng',
    blog: {
      index: 'Bài viết',
      list: 'Danh sách bài viết',
      create: 'Tạo bài viết',
    },
    marketplace: {
      all: 'Chợ CV',
      list: 'Danh sách CV của tôi',
      my_purchase: 'Danh sách CV mua của tôi',
    },
    transactions: {
      withdraw: 'Rút tiền',
    },
    transferData: 'Chuyển đổi dữ liệu',
    summaryHiring: 'Tổng quan tuyển dụng',
    yourJobs: 'Công việc của bạn',
    salaryCalculator: 'Máy tính lương',
    report: 'Báo cáo lỗi',
    news: {
      index: 'Bản tin Fetch',
      list: 'Danh sách bản tin',
      create: 'Tạo bản tin',
    },
  },
  pages: {
    dashboard,
    calculator,
    board,
    candidates,
    clients,
    interview,
    notification,
    recruiter,
    task,
    users,
    login,
    jobs,
    setting,
    signup,
    profile,
    installApp,
    forgotPassword,
    newPassword,
    manageRedeem,
    manageCV,
    blog,
    transferData,
    kpiBonus,
    report,
    fetchNews,
  },
  common: {
    action: 'Hành động',
    add: 'Thêm',
    edit: 'Sửa',
    delete: 'Xóa',
    save: 'Lưu',
    cancel: 'Hủy',
    update: 'Cập nhật',
    close: 'Đóng',
    back: 'Quay lại',
    next: 'Tiếp theo',
    search: 'Tìm kiếm',
    advanceSearch: 'Tìm kiếm nâng cao',
    new: 'Mới',
    clear: 'Xóa',
    create: 'Tạo',
    saveChanges: 'Lưu thay đổi',
    comingSoon: 'Sắp ra mắt',
    goToHome: 'Trở về trang chủ',
    totalPoint: 'Tổng điểm thưởng',
    availablePoint: 'Điểm thưởng có sẵn',
    processingPoint: 'Điểm thưởng đang xử lý',
    type: 'Loại',
    status: 'Trạng thái',
    timeStart: 'Thời gian bắt đầu',
    timeEnd: 'Thời gian kết thúc',
    approve: 'Phê duyệt',
    reject: 'Từ chối',
    confirm: 'Xác nhận',
    noData: 'Không có dữ liệu',
    emptyContent: 'Nội dung trống',
    somethingWentWrong: 'Có lỗi xảy ra',
    invalidDate: 'Vui lòng chọn ngày hợp lệ',
    minDateError: 'Ngày không được nhỏ hơn 01/01/1970',
    datetimeFormatError:
      'Thời gian không hợp lệ. Nhập thời gian có định dạng như ex: 16/12-2022 14:17',
    timeFormatError:
      'Thời gian không hợp lệ. Nhập thời gian có định dạng như ex: 14:17',
    months: 'Tháng',
    years: 'Năm',
    month: 'Tháng',
    year: 'Năm',
    hide: 'Ẩn',
    show: 'Hiển thị',
    browse: 'Tìm kiếm',
    candidate: 'Ứng viên',
    upload: 'Tải lên',
    error: 'Lỗi',
    success: 'Thành công',
    tryAgain: 'Thử lại',
    continue: 'Tiếp tục',
    history: 'Lịch sử',
    pointToVnd: `1 điểm tương đương {{pointValue}} VND`,
    submit: 'Gửi đi',
    points: 'điểm',
    referralNoity: 'Giới thiệu bạn bè và nhận <b>{{ points }}</b> điểm',
    accept: 'Chấp nhận',
    warningCancelEditForm:
      'Bạn phải nhập lại dữ liệu nếu huỷ phiên bản chỉnh sửa này',
    apply: 'Áp dụng',
    send: 'Gửi',
    view: 'Xem',
    fetchunt: 'Fetchunt',
    button: 'nút',
    pin: 'Ghim',
    shareFetchNews: "Chia sẻ lên bản tin Fetch"
  },
  cvUpload: {
    title: 'Kéo thả file vào khu vực này để tải CV lên',
    pdfOnly: 'Chỉ nhận file định dạng PDF, tối đa ({{fileSize}})',
    singleFile: 'Bạn chỉ có thể tải lên 1 CV',
    multipleFile: 'Bạn có thể tải tối đa {{totalFile}} CV',
    uploadFileSuccess: 'Tải lên file {{fileName}} thành công!',
    error: {
      pdfOnly: 'Định dạng file phải là pdf',
      tooLargeSize: 'Kích thước file phải nhỏ hơn {{fileSize}}',
      singleFile: 'Bạn chỉ có thể tải tối đa 1 CV',
      multiFile: 'Bạn chỉ có thể tải lên tối đa {{totalFile}} CV',
    },
  },
  singleImageUpload: {
    title: 'Kéo thả file vào khu vực này để tải ảnh lên',
    imageOnly: 'Chỉ nhận file định dạng image, tối đa ({{fileSize}})',
    singleFile: 'Bạn chỉ có thể tải lên 1 ảnh',
    multipleFile: 'Bạn có thể tải tối đa {{totalFile}} ảnh',
    error: {
      imageOnly: 'Định dạng file phải là ảnh',
      tooLargeSizeSingleFile: 'Kích thước file phải nhỏ hơn {{fileSize}}',
      singleFile: 'Bạn chỉ có thể tải tối đa 1 ảnh',
      multiFile: 'Bạn chỉ có thể tải lên tối đa {{totalFile}} ảnh',
    },
  },
  cvForm,
  header,
  quiz,
}

export default vn
