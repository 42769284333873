const manageCV = {
  process: 'Process',
  done: 'Done',
  rejected: 'Rejected',
  reject: 'Reject',
  nameCandidate: 'Name Candidate',
  detailCandidate: 'Detail Candidate',
  uploadCV: 'Upload CV',
  public_cv: 'List CV',
  your_cv: 'Your CV',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  time: 'Time Upload',
  point: 'Point',
  recruiterUpload: 'Recruiter Upload',
  amountPoint: 'Amount point',
  source: 'Source',
  status: 'Status',
  actions: 'Actions',
  takeNote: 'Take Note',
  share: 'Share',
  cannotShare: `You can't sell this CV when the point equals 0`,
  action: 'Action',
  skill: 'Skill',
  location: 'Location',
  linkCV: 'Link CV',
  rawCV: 'Raw CV',
  position: 'Position',
  approve: 'Approve',
  active: 'Approve',
  approveCV: 'Do you want to approve ',
  rejectCV: 'Do you want to reject ',
  approveSuccess: 'Approve CV successfully',
  approveFail: 'Approve CV fail',
  rejectSuccess: 'Reject CV successfully',
  rejectFail: 'Reject CV fail',
  confirmReject: 'Please enter the reason for rejecting',
  timeStart: 'Time Start',
  timeEnd: 'Time End',
  price: 'Price',
  see: 'See',
  buyCV: 'Buy CV',
  buySuccess: 'Buy CV success!',
  buyCVFail: 'Buy CV fail!',
  transactionMessage:
    'Your transaction is being processed, please wait a second',
  confirmBuyMessage: 'Are you sure you want to buy this CV',
  uploadCVTitle: 'Please upload your CV',
  uploadCVHelperText: 'Allowed file *.pdf max size of 5 MB',
  dateOfBirth: 'Date of birth',
  gender: 'Gender',
  address: 'Address',
  experience: 'Work experience',
  certificate: 'Certificate',
  note: 'Take note',
  allowShare: 'Allow to share candidate now',
  amountUnlock: 'Amount unlock',
  deleteCVSuccess: 'Delete CV success!',
  deleteCVFail: 'Delete CV fail!',
  deleteCVMessage: 'Are you sure you want to delete this CV?',
  shareCVSuccess: 'Share CV success!',
  shareCVFail: 'Share CV fail!',
  shareCVMessage: 'Are you sure you want to share this CV?',
  unShare: 'Unshare',
  unShareCVSuccess: 'Unshare CV success!',
  unShareCVFail: 'Unshare CV fail!',
  unShareCVMessage: 'Are you sure you want to unshare this CV?',
  uploadCVSuccess: 'Upload CV success!',
  uploadCVFail: 'Upload CV fail!',
  updateCVSuccess: 'Update CV success!',
  updateCVFail: 'Update CV fail!',
  updateCVMessage:
    'You must wait for the administrator to review your CV again when updating',
  nameRequired: 'Name is required',
  birthdayRequired: 'Birthday is required',
  phoneRequired: 'Phone is required',
  emailInvalid: 'Email is invalid',
  emailRequired: 'Email is required',
  skillRequired: 'Skill is required',
  addressRequired: 'Address is required',
  sellPointRequired: 'Amount unlock is required',
  experienceRequired: 'Experience is required',
  languageRequired: 'Language is required',
  locationRequired: 'Location is required',
  sellPointCheck: 'Amount unlock must be greater than 0',
  positionRequired: 'Position is required',
  companyRequired: 'Company is required',
  descriptionRequired: 'Description is required',
  yearOfExp: 'Year of experience',
  latestCompany: 'Latest company',
  education: 'Education',
  languages: 'Languages',
  company: 'Company',
  from: 'From',
  to: 'To',
  currentJob: 'Current Job',
  description: 'Description',
  addWorkExp: 'Add work experience',
  timeStartRequired: 'Time start is required',
  timeEndRequired: 'Time end is required',
  addCertificate: 'Add certificate',
  certificateName: 'Certificate name',
  organization: 'Organization',
  year: 'Year',
  linkCertificate: 'Link certificate',
  certificateNameRequired: 'Certificate name is required',
  cancelUploadConfirm:
    'You will have to upload your CV again if you cancel this edition',
  cvIsRequired: 'CV is required',
  cvMustBePdf: 'This file is invalid. Only allowed to upload file pdf',
  sortBy: 'Sort By',
  keyword: 'Keyword',
  salaryExpectation: 'Salary Expectation',
  availability: 'Availability',
  thankYouMessage: `Thanks for listing the candidate profile! Your listing will be reviewed and approved by Fetch within 7 working days.
  It will be approved and published on the marketplace if it is valid and complete. If it is rejected, please provide more information required for Fetch to review.
  `,
  getJobNow: 'Can get job now',
  afterOneMonth: 'After 1 month',
  afterTwoMonth: 'After 2 month',
  negotiable: 'Negotiable',
  lastUpdate: 'Last update',
  hasUpdateThisCandidate: 'Has update this candidate',
  candidateNewActive: 'Candidate New Active',
  candidateOpenJob: 'Candidate Open To Work',
  checkShareCVFail:
    'Candidates with this information already existed in the marketplace',
  feedbackCV: 'Feedback CV',
}

export default manageCV
