const kpiBonus = {
  kpi: 'KPI',
  bonus: 'Bonus',
  name: 'Tên',
  team: 'Team',
  role: 'Vai trò',
  salaryGross: 'Lương (Gross)',
  individualKPI: 'KPI cá nhân',
  process: 'Tiến độ',
  achievement: 'KPI đạt được',
  kpiPeriod: 'Kỳ KPI',
  year: 'Năm',
  teamKPI: 'KPI Nhóm',
  teamProcess: 'Tiến độ nhóm',
  historyKPI: 'Lịch sử KPI',
  show: 'Hiển thị',
  dateTime: 'Thời gian',
  details: 'Chi tiết',
  individualKPIAchievement: 'Thành tích KPI cá nhân',
  bonusRate: 'Tỷ lệ Bonus',
  teamBonus: 'Team Bonus',
  personalBonus: 'Bonus cá nhân',
  bonusFromTeamFund: 'Bonus Từ Quỹ Team',
  totalBonus: 'Tổng Bonus',
  status: 'Trạng thái',
  getListKpiTeamsFailed: 'Lấy danh sách KPI không thành công!',
  getListBonusTeamsFailed: 'Lấy danh sách Bonus không thành công!',
  changeSalaryContent: `<strong>Lương của {{name}}</strong> đã thay đổi từ <strong>{{salaryOld}} VND</strong> thành <strong>{{salaryNew}} VND</strong>`,
  historyIndividualKPIContent: `<strong>KPI cá nhân của {{name}}</strong> đã thay đổi từ <strong>{{individualKpiOld}} VND</strong> thành <strong>{{individualKpiNew}} VND</strong>`,
  historyTeamKPIContent: `<strong>KPI nhóm</strong> đã thay đổi từ <strong>{{teamKpiOld}} VND</strong> thành <strong>{{teamKpiNew}} VND</strong>`,
  statusWaiting: 'Đang chờ',
  activateUserContent: `<strong>{{name}}</strong> đã được kích hoạt`,
  deactivateUserContent: `<strong>{{name}}</strong> đã được vô hiệu hoá`,
  addUserToTeamContent: `<strong>{{name}}</strong> đã được thêm vào đội`,
  individualKPIAchievementPositive: 'Thành tích KPI cá nhân phải là số dương',
  individualKPIAchievementRequired: 'Thành tích KPI cá nhân là bắt buộc',
  individualKPIAchievementTypeError: 'Thành tích KPI cá nhân phải là một số',
  bonusRateRequired: 'Tỷ lệ bonus là bắt buộc',
  bonusRateTypeError: 'Tỷ lệ bonus phải là một số',
  bonusRateRange: 'Tỷ lệ bonus phải trong khoảng {{min}} và {{max}}',
  updateTeamBonusSuccess: 'Cập nhật team bonus thành công',
  updateTeamBonusFail: 'Cập nhật team bonus thất bại',
}

export default kpiBonus
