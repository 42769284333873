import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { DATETIME_FORMAT } from 'config'

import {
  API_ADMIN_CALENDAR_INTERVIEW,
  API_CREATE_JOB,
  API_DASHBOARD_APPLICANT,
  API_DASHBOARD_EX_RECRUITER,
  API_DASHBOARD_JOBS_BY_STATUS,
  API_DASHBOARD_PERFORMANCE_DIRECTOR,
  API_DASHBOARD_PERFORMANCE_MEMBER,
  API_KPI_PROFILE_USER,
  API_KPI_TEAM,
  API_LIST_RANKING,
  API_LIST_TEAM,
  API_TASK,
  API_USER,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'
import {
  fDate,
  fDateEachDayOfInterval,
  fDateEachMonthOfInterval,
  fDateEachWeekOfInterval,
  fUtcToDateTime,
} from 'utils/formatTime'

import {
  APPLICANT_TAB_LABELS,
  TEAM_COLORS_PERFORMANCE,
  TIME_TAB_LABELS,
} from './config'
import { TEXT_COLORS } from './interview-schedule/config'

export const getListActiveJobs = createAsyncThunk(
  'dashboard/getListActiveJobs',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_DASHBOARD_JOBS_BY_STATUS}?${qs.stringify(queries)}`
    )

    if (!response?.data?.success)
      throw new Error(response?.message || response?.data?.message)

    return response?.data || {}
  }
)

export const getCalendarInterviews = createAsyncThunk(
  'dashboard/getCalendarInterviews',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_ADMIN_CALENDAR_INTERVIEW}?${qs.stringify(queries)}`
    )

    if (!response?.data?.success)
      throw new Error(response?.message || response?.data?.message)

    const { calendar = {} } = response?.data || {}

    return Object.keys(calendar).reduce((prev, curr, currIndex) => {
      const { interviews = [] } = calendar[curr] || {}
      const listInterviews = interviews.map((value) => {
        const {
          timeInterview,
          timeInterviewEnd,
          CandidateJob = {},
        } = value || {}
        const { Candidate = {}, Job = {} } = CandidateJob || {}
        const { name: candidateName = '' } = Candidate || {}
        const { name: locationName = '' } = Job?.Location || {}

        return {
          ...value,
          start: timeInterview,
          end: timeInterviewEnd,
          timeInterviewStr: fDate(
            fUtcToDateTime(timeInterview),
            DATETIME_FORMAT
          ),
          timeInterviewEndStr: fDate(
            fUtcToDateTime(timeInterviewEnd),
            DATETIME_FORMAT
          ),
          title: candidateName,
          textColor: TEXT_COLORS[currIndex % 5],
          candidateName,
          locationName,
        }
      })
      return {
        ...prev,
        [curr]: {
          interviews: listInterviews,
        },
      }
    }, {})
  }
)

export const getDashboardKpiMember = createAsyncThunk(
  'dashboard/getDashboardKpiMember',
  async ({ userId = '', queries = {} }) => {
    const response = await _getApi(
      `${API_KPI_PROFILE_USER}/${userId}?${qs.stringify(queries)}`
    )

    return response?.data?.kpiUser || {}
  }
)

export const getDashboardKpiDirector = createAsyncThunk(
  'dashboard/getDashboardKpiDirector',
  async (queries) => {
    const response = await _getApi(`${API_KPI_TEAM}?${qs.stringify(queries)}`)

    return response?.data?.list || []
  }
)

export const getDashboardPerformanceDirector = createAsyncThunk(
  'dashboard/getDashboardPerformanceDirector',
  async (queries) => {
    const response = await _getApi(
      `${API_DASHBOARD_PERFORMANCE_DIRECTOR}?${qs.stringify(queries)}`
    )

    return response?.data?.list || []
  }
)

export const getDashboardKpiLeader = createAsyncThunk(
  'dashboard/getDashboardKpiLeader',
  async (queries) => {
    const response = await _getApi(`${API_KPI_TEAM}?${qs.stringify(queries)}`)

    return response?.data?.list?.[0] || {}
  }
)

export const getDashboardPerformanceMember = createAsyncThunk(
  'dashboard/getDashboardPerformanceMember',
  async (queries) => {
    const response = await _getApi(
      `${API_DASHBOARD_PERFORMANCE_MEMBER}?${qs.stringify(queries)}`
    )

    return response?.data?.list || []
  }
)

export const getDashboardApplicants = createAsyncThunk(
  'dashboard/getDashboardApplicants',
  async (queries) => {
    const { tab = '', ...rest } = queries || {}

    if (APPLICANT_TAB_LABELS.EX_RECRUITER === tab) {
      const response = await _getApi(
        `${API_DASHBOARD_EX_RECRUITER}?${qs.stringify(rest)}`
      )

      return response?.data || {}
    }

    const response = await _getApi(
      `${API_DASHBOARD_APPLICANT}?${qs.stringify(rest)}`
    )

    return response?.data || {}
  }
)

export const getListTeam = createAsyncThunk(
  'dashboard/getListTeam',
  async () => {
    const response = await _getApi(`${API_LIST_TEAM}/all`)

    return response?.data?.list || []
  }
)

export const getWeeklyTaskTeamMember = createAsyncThunk(
  'dashboard/getWeeklyTaskTeamMember',
  async () => {
    const response = await _getApi(`${API_USER}/task`)

    return response?.data?.list || []
  }
)

export const getListKpiRanking = createAsyncThunk(
  'dashboard/getListKpiRanking',
  async (queries) => {
    const response = await _getApi(
      `${API_LIST_RANKING}?${qs.stringify(queries)}`
    )

    return response?.data || {}
  }
)
export const getListMemberJobs = createAsyncThunk(
  'dashboard/getListMemberJobs',
  async (userId) => {
    const response = await _getApi(`${API_CREATE_JOB}/${userId}/assignment`)

    return response?.data?.list || []
  }
)

export const createTask = createAsyncThunk(
  'dashboard/createTask',
  async (data) => {
    const response = await _postApi(API_TASK, data)

    return response?.data || {}
  }
)

export const updateTask = createAsyncThunk(
  'dashboard/createTask',
  async ({ id = '', data = '' }) => {
    const response = await _patchApi(`${API_TASK}/${id}`, data)

    return response?.data || {}
  }
)

export const getListWeeklyTask = createAsyncThunk(
  'dashboard/getListWeeklyTask',
  async (queries) => {
    const response = await _getApi(`${API_TASK}?${qs.stringify(queries)}`)

    return response?.data || {}
  }
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    listActiveJobs: {
      isLoading: false,
      data: {},
    },
    listCalendarInterviews: {
      isLoading: false,
      data: {},
    },
    listWeeklyTask: {
      data: {},
      isLoading: false,
    },
    listUserTask: [],
    listMemberJobs: [],
    dashboardKpiMember: {
      isLoading: false,
      data: {},
    },
    dashboardPerformanceDirector: {
      isLoading: false,
      data: [],
    },
    dashboardPerformanceMember: {
      isLoading: false,
      chartLabels: [],
      data: [],
    },
    dashboardKpiDirector: {
      data: [],
      teamKpi: [],
      teamColors: [],
      labelColor: [],
      isLoading: false,
    },
    dashboardKpiLeader: {
      data: [],
      teamKpi: 0,
      isLoading: false,
    },
    dashboardApplicants: {
      data: [],
      chartLabels: [],
      total: 0,
      rateChange: 0,
      isLoading: false,
    },
    dashboardListTeam: {
      data: [],
    },
    dashboardListKpiRanking: {
      data: {},
      isLoading: false,
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListActiveJobs.pending, (state) => {
        state.listActiveJobs.isLoading = true
      })
      .addCase(getListActiveJobs.fulfilled, (state, action) => {
        state.listActiveJobs.isLoading = false
        state.listActiveJobs.data = action.payload
      })
      .addCase(getListActiveJobs.rejected, (state) => {
        state.listActiveJobs.isLoading = false
      })
      .addCase(getCalendarInterviews.pending, (state) => {
        state.listCalendarInterviews.isLoading = true
      })
      .addCase(getCalendarInterviews.fulfilled, (state, action) => {
        state.listCalendarInterviews.isLoading = false
        state.listCalendarInterviews.data = action.payload
      })
      .addCase(getCalendarInterviews.rejected, (state) => {
        state.listCalendarInterviews.isLoading = false
      })
      .addCase(getDashboardKpiMember.pending, (state) => {
        state.dashboardKpiMember = {
          data: {},
          isLoading: true,
        }
      })
      .addCase(getDashboardKpiMember.fulfilled, (state, action) => {
        state.dashboardKpiMember.isLoading = false

        try {
          const { kpiMemberProcess = 0, kpiMember = 0 } = action.payload || {}

          if (!kpiMember) {
            state.dashboardKpiMember.data = {}
            return
          }

          const individualKpiAchivement = parseInt(
            (
              (parseInt(kpiMemberProcess || 0, 10) / parseInt(kpiMember, 10)) *
              100
            ).toFixed(0),
            10
          )

          state.dashboardKpiMember.data = {
            individualKpi:
              individualKpiAchivement > 99 ? 0 : 100 - individualKpiAchivement,
            individualKpiAchivement,
          }
        } catch (error) {
          state.dashboardKpiMember.data = {}
        }
      })
      .addCase(getDashboardKpiMember.rejected, (state) => {
        state.dashboardKpiMember = {
          data: {},
          isLoading: false,
        }
      })
      .addCase(getDashboardKpiDirector.pending, (state) => {
        state.dashboardKpiDirector = {
          isLoading: true,
          data: [],
          teamColors: [],
          teamKpi: [],
          labelColor: [],
        }
      })
      .addCase(getDashboardKpiDirector.fulfilled, (state, action) => {
        state.dashboardKpiDirector.isLoading = false

        state.dashboardKpiDirector.data =
          action.payload?.reduce((prev, cur) => {
            const { KpiBonusUsers = [] } = cur || {}

            if (!Array.isArray(KpiBonusUsers) || !KpiBonusUsers.length)
              return prev

            return [
              ...prev,
              ...KpiBonusUsers.map(
                ({ User = {}, process = 0, individualKpi = 0 }) => {
                  const { name: username = '' } = User || {}

                  if (!process || !individualKpi)
                    return {
                      x: username,
                      y: 0,
                    }

                  return {
                    x: username,
                    y: parseInt(
                      (
                        (parseInt(process, 10) / parseInt(individualKpi, 10)) *
                        100
                      ).toFixed(0),
                      10
                    ),
                  }
                }
              ),
            ]
          }, []) || []

        state.dashboardKpiDirector.teamColors =
          action.payload?.map(
            (_, index) =>
              TEAM_COLORS_PERFORMANCE[index % TEAM_COLORS_PERFORMANCE.length]
          ) || []

        state.dashboardKpiDirector.labelColor = action.payload?.reduce(
          (prev, cur, index) => {
            const { KpiBonusUsers = [] } = cur || {}

            if (!Array.isArray(KpiBonusUsers) || !KpiBonusUsers.length)
              return prev

            const color =
              TEAM_COLORS_PERFORMANCE[index % TEAM_COLORS_PERFORMANCE.length]

            return [...prev, ...KpiBonusUsers.map(() => color)]
          },
          []
        )

        state.dashboardKpiDirector.teamKpi =
          action.payload?.map(
            ({
              teamProcess = 0,
              teamKpi = 0,
              KpiBonusUsers = [],
              name: teamName = '',
            }) => {
              const cols = KpiBonusUsers?.length || 0
              if (!teamProcess || !teamKpi)
                return {
                  title: [teamName, `(${0}%)`],
                  cols,
                }

              const percent = parseInt(
                (
                  (parseInt(teamProcess, 10) / parseInt(teamKpi, 10)) *
                  100
                ).toFixed(0),
                10
              )
              return {
                title: [teamName, `(${percent}%)`],
                cols,
              }
            }
          ) || []
      })
      .addCase(getDashboardKpiDirector.rejected, (state) => {
        state.dashboardKpiDirector = {
          isLoading: false,
          data: [],
          teamColors: [],
          teamKpi: [],
          labelColor: [],
        }
      })
      .addCase(getDashboardPerformanceDirector.pending, (state) => {
        state.dashboardPerformanceDirector = {
          isLoading: true,
          data: [],
        }
      })
      .addCase(getDashboardPerformanceDirector.fulfilled, (state, action) => {
        let totalBeforeMembers = 0
        let totalIndex = 0

        const teamInfo =
          action.payload?.reduce((prev, curr) => {
            const {
              memberTeam = [],
              idTeam: teamId = '',
              name: teamName = '',
            } = curr || {}
            if (!Array.isArray(memberTeam) || !memberTeam.length) return prev

            const originalTotalBeforeMembers = totalBeforeMembers
            totalBeforeMembers += memberTeam.length

            return {
              ...prev,
              [teamId]: {
                teamName,
                totalBeforeMembers: originalTotalBeforeMembers,
              },
            }
          }, {}) || {}

        const listDividers = Object.keys(teamInfo)
          .map((teamId) => {
            const { totalBeforeMembers = 0 } = teamInfo[teamId] || {}

            return totalBeforeMembers
          })
          .filter((value) => value > 0)

        state.dashboardPerformanceDirector.data =
          action.payload?.reduce((prev, curr, index) => {
            const {
              memberTeam = [],
              name: teamName = '',
              idTeam: teamId = '',
              approachTotal: approach = 0,
              inProgressTotal: inProgress = 0,
              doneTotal: done = 0,
              failedTotal: failed = 0,
              total = 0,
            } = curr || {}

            const teamData = {
              approach,
              inProgress,
              done,
              failed,
              total,
              teamId,
            }
            const { totalBeforeMembers = 0 } = teamInfo[teamId] || {}

            if (!Array.isArray(memberTeam) || !memberTeam.length) return prev

            return [
              ...prev,
              ...memberTeam.map(
                ({
                  approach = 0,
                  done = 0,
                  failed = 0,
                  inProgress = 0,
                  name = '',
                }) => {
                  const originalTotalIndex = totalIndex
                  totalIndex += 1

                  return {
                    name,
                    Approach: approach,
                    'In Progress': inProgress,
                    Done: done,
                    Failed: failed,
                    teamName,
                    color:
                      TEAM_COLORS_PERFORMANCE[
                        index % TEAM_COLORS_PERFORMANCE.length
                      ],
                    isMiddle:
                      originalTotalIndex ===
                      Math.round((memberTeam.length - 1) / 2) +
                        totalBeforeMembers,
                    teamData,
                    hasDivider: []
                      .concat(listDividers)
                      .includes(originalTotalIndex),
                  }
                }
              ),
            ]
          }, []) || []
        state.dashboardPerformanceDirector.isLoading = false
      })
      .addCase(getDashboardPerformanceDirector.rejected, (state) => {
        state.dashboardPerformanceDirector = {
          isLoading: false,
          data: [],
        }
      })
      .addCase(getDashboardKpiLeader.pending, (state) => {
        state.dashboardKpiLeader = {
          isLoading: true,
          data: [],
          teamKpi: 0,
        }
      })
      .addCase(getDashboardKpiLeader.fulfilled, (state, action) => {
        const { percent = 0, KpiBonusUsers: teamKpiData = [] } =
          action.payload || {}

        state.dashboardKpiLeader.isLoading = false
        state.dashboardKpiLeader.data =
          teamKpiData?.map(
            ({
              User = {},
              process: individualKpiAchivement = 0,
              individualKpi = 0,
            }) => {
              const { name = '' } = User || {}

              if (!individualKpiAchivement || !individualKpi)
                return {
                  name,
                  performance: 0,
                }

              return {
                name,
                performance: parseInt(
                  (
                    (parseInt(individualKpiAchivement, 10) /
                      parseInt(individualKpi, 10)) *
                    100
                  ).toFixed(0),
                  10
                ),
              }
            }
          ) || []

        state.dashboardKpiLeader.teamKpi = parseInt(percent.toFixed(0), 10)
      })
      .addCase(getDashboardKpiLeader.rejected, (state) => {
        state.dashboardKpiLeader = {
          isLoading: false,
          data: [],
          teamKpi: 0,
        }
      })
      .addCase(getDashboardPerformanceMember.pending, (state) => {
        state.dashboardPerformanceMember = {
          isLoading: true,
          data: [],
          chartLabels: [],
        }
      })
      .addCase(getDashboardPerformanceMember.fulfilled, (state, action) => {
        const { startDate, endDate, type } = action.meta.arg

        state.dashboardPerformanceMember.isLoading = false
        state.dashboardPerformanceMember.data = [
          {
            name: 'Approach',
            data:
              action.payload?.map(({ approach = 0 }) => approach || 0) || [],
          },
          {
            name: 'In Progress',
            data:
              action.payload?.map(({ inProgress = 0 }) => inProgress || 0) ||
              [],
          },
          {
            name: 'Done',
            data: action.payload?.map(({ done = 0 }) => done || 0) || [],
          },
          {
            name: 'Failed',
            data: action.payload?.map(({ failed = 0 }) => failed || 0) || [],
          },
        ]

        if (type === TIME_TAB_LABELS.WEEK) {
          state.dashboardPerformanceMember.chartLabels = fDateEachDayOfInterval(
            { startDate: new Date(startDate), endDate: new Date(endDate) }
          )

          return
        }

        if (type === TIME_TAB_LABELS.MONTH) {
          state.dashboardPerformanceMember.chartLabels =
            fDateEachWeekOfInterval({
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            }).map((_, index) => `Week ${index + 1}`)

          return
        }

        state.dashboardPerformanceMember.chartLabels = fDateEachMonthOfInterval(
          {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }
        )
      })
      .addCase(getDashboardPerformanceMember.rejected, (state) => {
        state.dashboardPerformanceMember = {
          isLoading: false,
          data: [],
          chartLabels: [],
        }
      })
      .addCase(getDashboardApplicants.pending, (state) => {
        state.dashboardApplicants = {
          data: [],
          chartLabels: [],
          total: 0,
          rateChange: 0,
          isLoading: true,
        }
      })
      .addCase(getDashboardApplicants.fulfilled, (state, action) => {
        const {
          total = 0,
          percent: rateChange = 0,
          list: listData = [],
        } = action.payload || {}

        const { startDate = '', endDate = '', type = '' } = action.meta.arg

        state.dashboardApplicants = {
          ...state.dashboardApplicants,
          total,
          rateChange,
          isLoading: false,
          data: [
            {
              name: 'Total applicants',
              data: listData?.map(({ total = 0 }) => total || 0) || [],
            },
          ],
        }

        if (type === TIME_TAB_LABELS.WEEK) {
          state.dashboardApplicants.chartLabels = fDateEachDayOfInterval({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          })

          return
        }

        if (type === TIME_TAB_LABELS.MONTH) {
          state.dashboardApplicants.chartLabels = fDateEachWeekOfInterval({
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            options: {
              weekStartsOn: 1,
            },
          }).map((_, index) => `Week ${index + 1}`)

          return
        }

        state.dashboardApplicants.chartLabels = fDateEachMonthOfInterval({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
        })
      })
      .addCase(getDashboardApplicants.rejected, (state) => {
        state.dashboardApplicants = {
          data: [],
          chartLabels: [],
          total: 0,
          rateChange: 0,
          isLoading: false,
        }
      })
      .addCase(getListTeam.pending, (state) => {
        state.dashboardListTeam.data = []
      })
      .addCase(getListTeam.fulfilled, (state, action) => {
        state.dashboardListTeam.data = action.payload
      })
      .addCase(getListTeam.rejected, (state) => {
        state.dashboardListTeam.data = []
      })
      .addCase(getListKpiRanking.pending, (state) => {
        state.dashboardListKpiRanking.data = {}
        state.dashboardListKpiRanking.isLoading = true
      })
      .addCase(getListKpiRanking.fulfilled, (state, action) => {
        state.dashboardListKpiRanking.data = action.payload
        state.dashboardListKpiRanking.isLoading = false
      })
      .addCase(getListKpiRanking.rejected, (state) => {
        state.dashboardListKpiRanking.isLoading = false
      })
      .addCase(getWeeklyTaskTeamMember.pending, (state) => {
        state.listUserTask = []
      })
      .addCase(getWeeklyTaskTeamMember.fulfilled, (state, action) => {
        state.listUserTask = action.payload?.map(({ name = '', id = '' }) => ({
          label: name,
          value: id,
        }))
      })
      .addCase(getWeeklyTaskTeamMember.rejected, (state) => {
        state.listUserTask = []
      })
      .addCase(getListMemberJobs.pending, (state) => {
        state.listMemberJobs = []
      })
      .addCase(getListMemberJobs.fulfilled, (state, action) => {
        state.listMemberJobs = action.payload?.map(
          ({ name = '', id = '' }) => ({
            label: name,
            value: id,
          })
        )
      })
      .addCase(getListMemberJobs.rejected, (state) => {
        state.listMemberJobs = []
      })
      .addCase(getListWeeklyTask.pending, (state) => {
        state.listWeeklyTask.data = {}
        state.listWeeklyTask.isLoading = true
      })
      .addCase(getListWeeklyTask.fulfilled, (state, action) => {
        state.listWeeklyTask.data = action.payload
        state.listWeeklyTask.isLoading = false
      })
      .addCase(getListWeeklyTask.rejected, (state) => {
        state.listWeeklyTask.data = {}
        state.listWeeklyTask.isLoading = false
      })
  },
})

export const selectListCandidates = createSelector(
  [(state) => state.dashboard.listActiveJobs],
  (listActiveJobs) => listActiveJobs
)

export const selectListCanlendarInteviews = createSelector(
  [(state) => state.dashboard.listCalendarInterviews],
  (listCalendarInterviews) => listCalendarInterviews
)

export const selectDashboardKpiMember = createSelector(
  [(state) => state.dashboard.dashboardKpiMember],
  (dashboardKpiMember) => dashboardKpiMember
)

export const selectDashboardKpiDirector = createSelector(
  [(state) => state.dashboard.dashboardKpiDirector],
  (dashboardKpiDirector) => dashboardKpiDirector
)

export const selectDashboardPerformanceDirector = createSelector(
  [(state) => state.dashboard.dashboardPerformanceDirector],
  (dashboardPerformanceDirector) => dashboardPerformanceDirector
)

export const selectDashboardKpiLeader = createSelector(
  [(state) => state.dashboard.dashboardKpiLeader],
  (dashboardKpiLeader) => dashboardKpiLeader
)

export const selectDashboardPerformanceMember = createSelector(
  [(state) => state.dashboard.dashboardPerformanceMember],
  (dashboardPerformanceMember) => dashboardPerformanceMember
)

export const selectDashboardApplicants = createSelector(
  [(state) => state.dashboard.dashboardApplicants],
  (dashboardApplicants) => dashboardApplicants
)

export const selectListTeams = createSelector(
  [(state) => state.dashboard.dashboardListTeam],
  (dashboardListTeam) => dashboardListTeam
)

export const selectListKpiRanking = createSelector(
  [(state) => state.dashboard.dashboardListKpiRanking],
  (dashboardListKpiRanking) => dashboardListKpiRanking
)
export const selectListTeamMembers = createSelector(
  [(state) => state.dashboard.listUserTask],
  (listUserTask) => listUserTask
)

export const selectListMemberJobs = createSelector(
  [(state) => state.dashboard.listMemberJobs],
  (listMemberJobs) => listMemberJobs
)

export const selectListWeeklyTask = createSelector(
  [(state) => state.dashboard.listWeeklyTask],
  (listWeeklyTask) => listWeeklyTask
)

export default dashboardSlice.reducer
