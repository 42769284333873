import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_ADMIN_ALL_NOTIFY, API_ADMIN_READ_ALL_NOTIFY } from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'

export const getAdminAllNotifyPage = createAsyncThunk(
  'notification/getAdminAllNotifyPage',
  async (queries = {}) => {
    const url = `${API_ADMIN_ALL_NOTIFY}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const getAdminAllNotifyHeader = createAsyncThunk(
  'notification/getAdminAllNotifyHeader',
  async (queries = {}) => {
    const url = `${API_ADMIN_ALL_NOTIFY}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const updateAdminReadAllNotify = createAsyncThunk(
  'notification/updateAdminReadAllNotify',
  async () => {
    const response = await _patchApi(`${API_ADMIN_READ_ALL_NOTIFY}`)
    return response?.data || {}
  }
)

const initialState = {
  listNotificationsPage: {
    isLoading: false,
    data: {},
  },
  listNotificationsHeader: {
    isLoading: false,
    data: {},
  },
  markAsRead: {
    isLoading: false,
    data: {},
  },
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    markAsRead: (state) => {
      state.listNotificationsHeader.data.list.map((item) => ({
        ...item,
        status: true,
      }))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAllNotifyPage.pending, (state) => {
        state.listNotificationsPage.isLoading = true
        state.listNotificationsPage.data = {}
      })
      .addCase(getAdminAllNotifyPage.fulfilled, (state, action) => {
        state.listNotificationsPage.isLoading = false
        state.listNotificationsPage.data = action.payload
      })
      .addCase(getAdminAllNotifyPage.rejected, (state) => {
        state.listNotificationsPage.isLoading = false
      })
      .addCase(getAdminAllNotifyHeader.pending, (state) => {
        state.listNotificationsHeader.isLoading = true
        state.listNotificationsHeader.data = {}
      })
      .addCase(getAdminAllNotifyHeader.fulfilled, (state, action) => {
        state.listNotificationsHeader.isLoading = false
        state.listNotificationsHeader.data = action.payload
      })
      .addCase(getAdminAllNotifyHeader.rejected, (state) => {
        state.listNotificationsHeader.isLoading = false
      })
      .addCase(updateAdminReadAllNotify.fulfilled, (state, action) => {
        state.markAsRead.data = action.payload
      })
  },
})

export const { markAsRead } = notificationSlice.actions

export const selectAllNotificationPage = createSelector(
  [(state) => state.notification.listNotificationsPage],
  (listNotificationsPage) => listNotificationsPage
)

export const selectAllNotificationHeader = createSelector(
  [(state) => state.notification.listNotificationsHeader],
  (listNotificationsHeader) => listNotificationsHeader
)

export default notificationSlice.reducer
