const dashboard = {
  interviewSchedule: {
    title: 'Interview Schedule',
    name: 'Name',
    nameJob: 'Name Job',
    phone: 'Phone',
    location: 'Location',
    type: 'Type',
    timeStart: 'Time start',
    timeEnd: 'Time end',
    listInterviews: 'List Interviews',
    interviewDetail: 'Interview Detail',
    linkZoom: 'Link Zoom',
    cancel: 'Cancel',
    today: 'Today',
    viewDetail: 'View detail',
    getListInterviewsFail: 'Failed to get list interviews!',
  },
  activeJobs: {
    title: 'Active Jobs',
    activeJobs: 'Active Jobs',
    pendingJobs: 'Pending Jobs',
    closeJobs: 'Close Jobs',
    active: 'Active',
    pending: 'Pending',
    close: 'Close',
    job: 'Job',
    client: 'Client',
    team: 'Team',
    candidate: 'Candidate',
    status: 'Status',
    salary: 'Salary',
    type: 'Type',
    follower: 'Follower',
    viewDetail: 'View Detail',
    getListJobsFail: 'Failed to get list jobs!',
  },
  weeklyTask: {
    task: 'Task',
    title: 'Weekly Task',
    newTask: 'New Task',
    createNewTask: 'Create New Task',
    editTask: 'Edit Task',
    apply: 'Apply',
    noData: 'No Data',
    achievement: 'Achievement',
    target: 'Target',
    deadline: 'Deadline',
    from: 'From',
    to: 'To',
    name: 'Name',
    taskContent: 'Task Content',
    totalTarget: 'Total Target',
    totalAchievement: 'Total Achievement',
    team: 'Team',
    time: 'Time',
    content: 'Content',
    viewDetail: 'View detail',
    updateTaskFailed: 'Update task failed',
    createTaskFailed: 'Create task failed',
    updateTaskSuccess: 'Update task success!',
    createTaskSuccess: 'Create task success!',
    mustBePositiveNumber: 'Must be a positive number',
    nameIsRequired: 'Name is required',
    jobIsRequired: 'Job is required',
    getListWeeklyTaskFail: 'Failed to get list weekly tasks!',
    startDate: 'Start Date',
    endDate: 'End Date',
    week: 'Week',
    job: 'Job',
    card: 'Card',
    cardProcess: 'Card Process',
    member: 'Member',
    other: 'Other',
    total: 'Total',
    totalCard: 'Total Card',
    totalTargetEqual100: 'Total target must be equal to 100',
    myTask: 'My Task',
    createTask: 'Member Task',
    greaterThan0: 'Must be greater than 0',
  },
  applicants: {
    title: 'New Applicants',
    externalRecruiter: 'External Recruiters',
    exRecruiter: 'Ex-Recruiter',
    applicants: 'Applicants',
    totalExRecruiters: 'Total External Recruiters',
    totalApplicants: 'Total Applicants',
  },
  performance: {
    title: 'Performance',
    today: 'Today',
    all: 'All',
    hn: 'Hanoi',
    dn: 'Da Nang',
    hcm: 'Ho Chi Minh',
    startDateRequired: 'Start Date is required',
    approach: 'Approach',
    inProgress: 'In Progress',
    done: 'Done',
    failed: 'Failed',
    getTeamPerformanceSuccess: 'Get team performance success',
    getTeamPerformanceFailed: 'Get team performance failed!',
    startDateAfterEndDateMessage: 'Start Date must be before or equal End Date',
    startDate: 'Start Date',
    endDate: 'End Date',
  },
  recruitmentProgress: {
    title: 'Recruitment Progress',
    getListRecruitmentProgressFail: 'Failed to get list recruiment progress!',
  },
  memberActivities: {
    title: 'Member Activities',
    noData: 'No Data',
    lastLogin: 'Last login',
    getListMemberActiveFail: 'Failed to get list members last login!',
  },
  jobList: {
    jobs: 'Jobs',
    job: 'Job',
    client: 'Client',
    approach: 'Approach',
    inProgress: 'In Progress',
    done: 'Done',
    failed: 'Failed',
    total: 'Total',
  },
  kpi: {
    title: 'KPI',
    kpiPeriod: 'KPI Period',
    year: 'Year',
    individualKpi: 'Individual KPI ({{individualKpi}}%)',
    individualKpiAchivement:
      'Individual KPI Achievement ({{individualKpiAchivement}}%)',
    teamKpi: 'Team KPI: {{teamKpi}}%',
  },
  kpiRanking: {
    title: 'KPI Ranking',
    type: 'Type',
    year: 'Year',
    kpiPeriod: 'KPI Period',
  },
}

export default dashboard
