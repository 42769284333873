const forgotPassword = {
  forgotPasswordSuccess: 'Vui lòng kiểm tra hộp thư để lấy lại mật khẩu',
  resetPassword: 'Đặt lại mật khẩu',
  emailVerify: 'Email phải là một địa chỉ email hợp lệ',
  emailRequired: 'Email là bắt buộc',
  changePassword: 'Đổi mật khẩu',
  emailNotFound: 'Email không tồn tại',
  serverError: 'Lỗi máy chủ nội bộ',
  enterEmailToResetPassword:
    'Vui lòng nhập địa chỉ email liên kết với tài khoản của bạn và chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn.',
  forgotYourPassword: 'Quên mật khẩu?',
}

export default forgotPassword
