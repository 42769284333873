/* eslint-disable no-restricted-syntax */
import { isValidPhoneNumber } from 'react-phone-number-input'

// import { ValidationError } from 'yup'
import { CALANDER_DATE_FORMAT, MIN_DATE_VALUE } from 'config'

import { fDate } from 'utils/formatTime'
import { replaceAll } from 'utils/replace'

export const GENDER_TYPES = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
}

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: GENDER_TYPES.MALE,
  },
  {
    label: 'Female',
    value: GENDER_TYPES.FEMALE,
  },
  { label: 'Other', value: GENDER_TYPES.OTHER },
]

export const SOCIAL_TYPES = {
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  SKYPE: 'skype',
}

export const SOCIAL_OPTIONS = [
  { label: 'Facebook', value: SOCIAL_TYPES.FACEBOOK },
  { label: 'Linkedin', value: SOCIAL_TYPES.LINKEDIN },
  { label: 'Skype', value: SOCIAL_TYPES.SKYPE },
]

export const SOCIAL_LIST = [
  SOCIAL_TYPES.FACEBOOK,
  SOCIAL_TYPES.LINKEDIN,
  SOCIAL_TYPES.SKYPE,
]

export const AVAILABILITY_OPTIONS = [
  'Can get job now',
  'After 15 days',
  'After 30 days',
  'After 45 days',
  'After 60 days',
]

export const EX_EMPLOYEE_NOTE_OPTIONS = [
  'Before 2 months',
  '2 months -> 6 months',
]

export const EXPERIENCE_FORM_FIELDS = {
  POSITION: 'position',
  COMPANY: 'company',
  FROM: 'from',
  TO: 'to',
  CURRENT_JOB: 'currentJob',
  DESCRIPTION: 'description',
}

export const CERTIFICATE_FORM_FIELDS = {
  NAME: 'name',
  ORGANIZATION: 'organization',
  YEAR: 'year',
  LINK: 'link',
}

export const SALARY_EXPECTATION_FORM_FIELDS = {
  NEGOTIABLE: 'negotiable',
  MIN: 'minSalary',
  MAX: 'maxSalary',
}

export const FORM_FIELDS = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  ADDRESS: 'address',
  SKILL: 'skillCV',
  RAW_CV: 'rawCv',
  LINK_AVATAR: 'linkAvatar',
  GENDER: 'gender',
  CERTIFICATE: 'certificate',
  EXPERIENCE: 'experience',
  BIRTHDAY: 'birthday',
  POSITION: 'position',
  NOTE: 'note',
  IS_PUBLIC: 'isPublic',
  SELL_POINT: 'sellPoint',
  LOCATION: 'location',
  YEAR_OF_EXPERIENCE: 'yearOfExperience',
  LATEST_COMPANY: 'latestCompany',
  EDUCATION: 'education',
  LANGUAGES: 'languages',
  USER_UPLOADED: 'userUploaded',
  CAPTCHA: 'captcha',
  EXPERIENCE_FORM: {
    ...Object.keys(EXPERIENCE_FORM_FIELDS).reduce((acc, cur) => {
      const field = EXPERIENCE_FORM_FIELDS[cur] || ''

      return { ...acc, [cur]: `experienceForm.${field}` }
    }, {}),
    INDEX: 'experienceForm',
  },
  CERTIFICATE_FORM: {
    ...Object.keys(CERTIFICATE_FORM_FIELDS).reduce((acc, cur) => {
      const field = CERTIFICATE_FORM_FIELDS[cur] || ''

      return { ...acc, [cur]: `certificateForm.${field}` }
    }, {}),
    INDEX: 'certificateForm',
  },
  UPLOAD_CV: 'uploadCV',
  JOB_ID: 'jobId',
  CLIENT_NAME: 'clientName',
  LANE_ID: 'laneId',
  APPROACH_DATE: 'approachDate',
  EXPECTED_DATE: 'expectedDate',
  ONBOARD_DATE: 'onboardDate',
  AVAILABILITY: 'availableWork',
  SALARY_EXPECTATION: {
    ...Object.keys(SALARY_EXPECTATION_FORM_FIELDS).reduce((acc, cur) => {
      const field = SALARY_EXPECTATION_FORM_FIELDS[cur] || ''

      return { ...acc, [cur]: `salaryExpect.${field}` }
    }, {}),
    INDEX: 'salaryExpect',
  },
  SOCIAL: 'social',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  SKYPE: 'skype',
  LINK_CV: 'linkCv',
  REFINE_CV: 'refineCv',
  NOTE_APPROACH: 'noteApproach',
  LASTEST_UPDATE: 'lastestUpdate',
  SHOW_HISTORY_ACTION: 'showHistoryAction',
  NOTIFY_MESSAGE: 'notifyMessage',
  SHORT_LINK_REFINE_CV: 'shortLinkRefineCV',
  IS_CHECK_ENG: 'isCheckEng',
  ENGLISH_TAKE_NOTE: 'noteEnglish',
  SALARY_OFFER: 'salaryOffer',
  IS_EX_EMPLOYEE: 'isExEmployee',
  EX_EMPLOYEE_NOTE: 'exEmployee',
  EXPECTED_TESTING_DATE: 'expectedTestingDate',
  POLICY_PRIVACY: 'policyPrivacy',
  TERMS_OF_SERVICE: 'termsOfService',
}

export const EXPERIENCE_FORM_VALIDATE_FIELDS = [
  FORM_FIELDS.EXPERIENCE_FORM.POSITION,
  FORM_FIELDS.EXPERIENCE_FORM.COMPANY,
  FORM_FIELDS.EXPERIENCE_FORM.DESCRIPTION,
  FORM_FIELDS.EXPERIENCE_FORM.FROM,
  FORM_FIELDS.EXPERIENCE_FORM.TO,
]

export const CERTIFICATE_FORM_VALIDATE_FIELDS = [
  FORM_FIELDS.CERTIFICATE_FORM.NAME,
]

export const DEFAULT_EXPERIENCE_FORM_VALUES = {
  [EXPERIENCE_FORM_FIELDS.POSITION]: '',
  [EXPERIENCE_FORM_FIELDS.COMPANY]: '',
  [EXPERIENCE_FORM_FIELDS.FROM]: null,
  [EXPERIENCE_FORM_FIELDS.TO]: null,
  [EXPERIENCE_FORM_FIELDS.CURRENT_JOB]: false,
  [EXPERIENCE_FORM_FIELDS.DESCRIPTION]: '',
}

export const DEFAULT_CERTIFICATE_FORM_VALUES = {
  [CERTIFICATE_FORM_FIELDS.NAME]: '',
  [CERTIFICATE_FORM_FIELDS.ORGANIZATION]: '',
  [CERTIFICATE_FORM_FIELDS.YEAR]: '',
  [CERTIFICATE_FORM_FIELDS.LINK]: '',
}

export const DEFAULT_SALARY_EXPECTATION_FORM_VALUES = {
  [SALARY_EXPECTATION_FORM_FIELDS.NEGOTIABLE]: true,
  [SALARY_EXPECTATION_FORM_FIELDS.MIN]: '',
  [SALARY_EXPECTATION_FORM_FIELDS.MAX]: '',
}

export const DEFAUTLT_SALARY_EXPECTATION_FORM_VALUES = {}

export const DEFAULT_FORM_VALUES = {
  [FORM_FIELDS.NAME]: '',
  [FORM_FIELDS.PHONE]: '',
  [FORM_FIELDS.EMAIL]: '',
  [FORM_FIELDS.ADDRESS]: '',
  [FORM_FIELDS.SKILL]: [],
  [FORM_FIELDS.RAW_CV]: '',
  [FORM_FIELDS.LINK_AVATAR]: '',
  [FORM_FIELDS.GENDER]: '',
  [FORM_FIELDS.CERTIFICATE]: [],
  [FORM_FIELDS.EXPERIENCE]: [],
  [FORM_FIELDS.BIRTHDAY]: '',
  [FORM_FIELDS.POSITION]: [],
  [FORM_FIELDS.NOTE]: '',
  [FORM_FIELDS.IS_PUBLIC]: true,
  [FORM_FIELDS.SELL_POINT]: 0,
  [FORM_FIELDS.LOCATION]: [],
  [FORM_FIELDS.YEAR_OF_EXPERIENCE]: 0,
  [FORM_FIELDS.LATEST_COMPANY]: '',
  [FORM_FIELDS.EDUCATION]: '',
  [FORM_FIELDS.LANGUAGES]: [],
  [FORM_FIELDS.USER_UPLOADED]: '',
  [FORM_FIELDS.EXPERIENCE_FORM.INDEX]: DEFAULT_EXPERIENCE_FORM_VALUES,
  [FORM_FIELDS.CERTIFICATE_FORM.INDEX]: DEFAULT_CERTIFICATE_FORM_VALUES,
  [FORM_FIELDS.UPLOAD_CV]: null,
  [FORM_FIELDS.JOB_ID]: '',
  [FORM_FIELDS.CLIENT_NAME]: '',
  [FORM_FIELDS.LANE_ID]: '',
  [FORM_FIELDS.APPROACH_DATE]: fDate(new Date(), CALANDER_DATE_FORMAT),
  [FORM_FIELDS.EXPECTED_DATE]: null,
  [FORM_FIELDS.ONBOARD_DATE]: null,
  [FORM_FIELDS.AVAILABILITY]: AVAILABILITY_OPTIONS[0],
  [FORM_FIELDS.SALARY_EXPECTATION.INDEX]:
    DEFAULT_SALARY_EXPECTATION_FORM_VALUES,
  [FORM_FIELDS.SOCIAL]: [],
  [FORM_FIELDS.FACEBOOK]: '',
  [FORM_FIELDS.LINKEDIN]: '',
  [FORM_FIELDS.SKYPE]: '',
  [FORM_FIELDS.NOTE_APPROACH]: '',
  [FORM_FIELDS.LINK_CV]: '',
  [FORM_FIELDS.REFINE_CV]: '',
  [FORM_FIELDS.SHORT_LINK_REFINE_CV]: '',
  [FORM_FIELDS.IS_CHECK_ENG]: false,
  [FORM_FIELDS.ENGLISH_TAKE_NOTE]: '',
  [FORM_FIELDS.SALARY_OFFER]: '',
  [FORM_FIELDS.IS_EX_EMPLOYEE]: false,
  [FORM_FIELDS.EX_EMPLOYEE_NOTE]: '',
  [FORM_FIELDS.EXPECTED_TESTING_DATE]: null,
  [FORM_FIELDS.POLICY_PRIVACY]: false,
  [FORM_FIELDS.TERMS_OF_SERVICE]: false,
}

function customValidateExperience(formData, index, Yup, translate) {
  const schema = Yup.object().shape({
    [EXPERIENCE_FORM_FIELDS.POSITION]: Yup.string()
      .nullable()
      .required(translate('cvForm.positionRequired')),
    [EXPERIENCE_FORM_FIELDS.COMPANY]: Yup.string()
      .nullable()
      .required(translate('cvForm.companyRequired')),
    [EXPERIENCE_FORM_FIELDS.DESCRIPTION]: Yup.string()
      .nullable()
      .required(translate('cvForm.descriptionRequired')),
    [EXPERIENCE_FORM_FIELDS.FROM]: Yup.date()
      .required(translate('cvForm.timeStartRequired'))
      .min(MIN_DATE_VALUE, translate('common.minDateError'))
      .typeError(translate('common.invalidDate'))
      .nullable(),
    ...(!formData?.currentJob && {
      [EXPERIENCE_FORM_FIELDS.TO]: Yup.date()
        .required(translate('cvForm.timeEndRequired'))
        .min(MIN_DATE_VALUE, translate('common.minDateError'))
        .typeError(translate('common.invalidDate'))
        .nullable(),
    }),
  })

  try {
    schema.validateSync(formData, { abortEarly: false })
    return null // Trả về null nếu không có lỗi
  } catch (err) {
    // Xử lý các lỗi trả về từ Yup và trả về một object chứa các thông báo lỗi tương ứng
    const errors = {
      path: `${FORM_FIELDS.EXPERIENCE}.${index}`,
      message: err.inner.map((error) => error.message),
    }

    return errors
  }
}

export const CVSchema = ({ Yup, translate, specialFields = [], ...rest }) =>
  Yup.object().shape({
    [FORM_FIELDS.NAME]: Yup.string().required(translate('cvForm.nameRequired')),
    [FORM_FIELDS.PHONE]: Yup.string()
      .required(translate('cvForm.phoneRequired'))
      .test('validator-phone', (value, { createError }) => {
        const isValid = isValidPhoneNumber(value, 'VN')

        if (!isValid)
          return createError({
            path: FORM_FIELDS.PHONE,
            message: translate('cvForm.phoneInvalid'),
          })

        return true
      }),
    [FORM_FIELDS.EMAIL]: Yup.string()
      .email(translate('cvForm.emailInvalid'))
      .required(translate('cvForm.emailRequired')),
    [FORM_FIELDS.ADDRESS]: Yup.string().required(
      translate('cvForm.addressRequired')
    ),
    [FORM_FIELDS.LOCATION]: Yup.array()
      .nullable()
      .min(1, translate('cvForm.locationRequired'))
      .required(translate('cvForm.locationRequired')),
    [FORM_FIELDS.YEAR_OF_EXPERIENCE]: Yup.string().max(100),
    [FORM_FIELDS.SELL_POINT]: Yup.string()
      .max(100)
      .required(translate('cvForm.sellPointRequired')),
    [FORM_FIELDS.POSITION]: Yup.array()
      .nullable()
      .min(1, translate('cvForm.positionRequired'))
      .required(translate('cvForm.positionRequired')),
    [FORM_FIELDS.EXPERIENCE]: Yup.array()
      .of(Yup.object())
      .min(1, translate('cvForm.workExperienceRequired'))
      .test('validator-experience-item', (value, { createError }) => {
        const listErrors = value
          .map((item, index) =>
            customValidateExperience(item, index, Yup, translate)
          )
          .filter(Boolean)

        if (!listErrors.length) return true

        return createError({
          path: FORM_FIELDS.EXPERIENCE,
          message: () => ({
            main: translate('cvForm.experienceMissingData'),
            other: listErrors.reduce((acc, cur) => {
              const { path, message } = cur

              return {
                ...acc,
                [path]: message,
              }
            }, {}),
          }),
        })
      })
      .required(translate('cvForm.workExperienceRequired')),
    [FORM_FIELDS.SKILL]: Yup.array()
      .of(Yup.string())
      .min(1, translate('cvForm.skillRequired'))
      .required(translate('cvForm.skillRequired')),
    [FORM_FIELDS.LANGUAGES]: Yup.array()
      .of(Yup.string())
      .min(1, translate('cvForm.languageRequired'))
      .required(translate('cvForm.languageRequired')),
    [FORM_FIELDS.SALARY_EXPECTATION.INDEX]: Yup.object()
      .test('validator-minSalary-required', (value, { createError }) => {
        const { negotiable, minSalary } = value || {}

        if (!negotiable && !minSalary)
          return createError({
            path: FORM_FIELDS.SALARY_EXPECTATION.MIN,
            message: translate('cvForm.minSalaryRequired'),
          })

        return true
      })
      .test('validator-maxSalary-required', (value, { createError }) => {
        const { negotiable, maxSalary } = value || {}

        if (!negotiable && !maxSalary)
          return createError({
            path: FORM_FIELDS.SALARY_EXPECTATION.MAX,
            message: translate('cvForm.maxSalaryRequired'),
          })

        return true
      })
      .test('validator-greater-than-min', (value, { createError }) => {
        const { negotiable, minSalary, maxSalary } = value || {}
        const fmtMinSalary = Number.parseInt(
          replaceAll(String(minSalary), '.', ''),
          10
        )
        const fmtMaxSalary = Number.parseInt(
          replaceAll(String(maxSalary), '.', ''),
          10
        )

        if (!negotiable && fmtMinSalary >= fmtMaxSalary)
          return createError({
            path: FORM_FIELDS.SALARY_EXPECTATION.MAX,
            message: translate('cvForm.maxSalaryValidate'),
          })

        return true
      }),
    ...(specialFields.includes(FORM_FIELDS.CAPTCHA) && {
      [FORM_FIELDS.CAPTCHA]: Yup.string().required(
        translate('pages.jobs.captchaVerify')
      ),
    }),
    ...rest,
  })

export const WARNING_SAME_CV_TYPE = {
  SAME_YOUR_CV: 'sameYourCV',
  SAME_OTHER_CV: 'sameOtherCV',
}

export const CREATE_REFINE_CV_TYPE = {
  CREATE: 'create',
  DOWNLOAD: 'download',
  COPY: 'copy',
}

export const REVIEW_CV_DEADLINE = '2023-10-30'
