const dashboard = {
  interviewSchedule: {
    title: 'Lịch Phỏng Vấn',
    name: 'Tên ứng viên',
    nameJob: 'Tiêu đề công việc',
    phone: 'Điện thoại',
    location: 'Địa điểm',
    type: 'Hình thức',
    timeStart: 'Thời gian bắt đầu',
    timeEnd: 'Thời gian kết thúc',
    listInterviews: 'Danh sách các buổi phỏng vấn',
    interviewDetail: 'Chi tiết phỏng vấn',
    linkZoom: 'Link Zoom',
    cancel: 'Hủy',
    today: 'Hôm nay',
    viewDetail: 'Xem chi tiết',
    getListInterviewsFail: 'Không thể tải danh sách phỏng vấn',
  },
  activeJobs: {
    title: 'Active Jobs',
    activeJobs: 'Công Việc Đang Tuyển',
    pendingJobs: 'Công Việc Đang Chờ Xử Lý',
    closeJobs: 'Công Việc Đã Đóng',
    active: 'Đang tuyển',
    pending: 'Đang chờ',
    close: 'Đã đóng',
    job: 'Công việc',
    client: 'Khách hàng',
    team: 'Đội',
    candidate: 'Ứng viên',
    status: 'Trạng thái',
    salary: 'Lương',
    type: 'Hình thức',
    follower: 'Người theo dõi',
    viewDetail: 'Xem chi tiết',
    getListJobsFail: 'Không thể tải danh sách công việc',
  },
  weeklyTask: {
    task: 'Nhiệm vụ',
    title: 'Nhiệm Vụ Hàng Tuần',
    newTask: 'Nhiệm Vụ Mới',
    createNewTask: 'Tạo Nhiệm Vụ Mới',
    editTask: 'Sửa Nhiệm Vụ',
    apply: 'Lọc',
    noData: 'Không có dữ liệu',
    achievement: 'Hoàn thành',
    target: 'Mục tiêu',
    deadline: 'Thời hạn',
    from: 'Từ',
    to: 'Tới',
    name: 'Tên',
    taskContent: 'Nội dung nhiệm vụ',
    totalTarget: 'Tổng mục tiêu',
    totalAchievement: 'Tổng hoàn thành',
    team: 'Đội',
    time: 'Thời gian',
    content: 'Nội dung',
    viewDetail: 'Xem chi tiết',
    updateTaskFailed: 'Cập nhật nhiệm vụ thất bại',
    createTaskFailed: 'Tạo mới nhiệm vụ thất bại',
    updateTaskSuccess: 'Cập nhật nhiệm vụ thành công!',
    createTaskSuccess: 'Tạo mới nhiệm vụ thành công!',
    mustBePositiveNumber: 'Phải là số nguyên dương',
    nameIsRequired: 'Chưa chọn tên',
    jobIsRequired: 'Công việc là bắt buộc',
    getListWeeklyTaskFail: 'Không thể tải danh sách nhiệm vụ hàng tuần!',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',
    week: 'Tuần',
    job: 'Công việc',
    card: 'Thẻ',
    cardProcess: 'Thẻ đạt được',
    member: 'Thành viên',
    other: 'Nhiệm vụ khác',
    total: 'Tổng',
    totalCard: 'Tổng số thẻ',
    totalTargetEqual100: 'Tổng số mục tiêu phải bằng 100',
    myTask: 'Nhiệm vụ của tôi',
    createTask: 'Nhiệm vụ của thành viên',
    greaterThan0: 'Phải lớn hơn 0',
  },
  applicants: {
    title: 'Ứng Viên Mới',
    externalRecruiter: 'Người tuyển dụng ngoài công ty',
    exRecruiter: 'Người tuyển dụng ngoài công ty',
    applicants: 'Ứng viên',
    totalExRecruiters: 'Tổng số người tuyển dụng ngoài công ty',
    totalApplicants: 'Tổng số ứng viên',
  },
  performance: {
    title: 'Hiệu Suất',
    today: 'Hôm nay',
    all: 'Tất cả',
    hn: 'Hà Nội',
    dn: 'Đà Nẵng',
    hcm: 'Hồ Chí Minh',
    approach: 'Approach',
    inProgress: 'In Progress',
    done: 'Done',
    failed: 'Failed',
    getTeamPerformanceSuccess: 'Lấy dữ liệu hiệu suất thành công',
    getTeamPerformanceFailed: 'Lấy dữ liệu hiệu suất thất bại!',
    startDateAfterEndDateMessage:
      'Ngày bắt đầu phải ở trước hoặc cùng với ngày kết thúc',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày kết thúc',
  },
  recruitmentProgress: {
    title: 'Tiến Độ Tuyển Dụng',
    getListRecruitmentProgressFail:
      'Không thể tải danh sách tiến trình tuyển dụng danh sách!',
  },
  memberActivities: {
    title: 'Hoạt Động Của Các Thành Viên',
    noData: 'Không có dữ liệu',
    lastLogin: 'Đăng nhập lần cuối',
    getListMemberActiveFail:
      'Không thể tải danh sách thành viên đăng nhập lần cuối!',
  },
  jobList: {
    jobs: 'Danh sách công việc',
    job: 'Công việc',
    client: 'Khách hàng',
    approach: 'Tiếp cận',
    inProgress: 'Đang tiến hành',
    done: 'Đã hoàn thành',
    failed: 'Thất bại',
    total: 'Tổng',
  },
  kpi: {
    title: 'KPI',
    kpiPeriod: 'Kì KPI',
    year: 'Năm',
    individualKpi: 'Kpi cá nhân ({{individualKpi}}%)',
    individualKpiAchivement:
      'Kpi cá nhân đạt được ({{individualKpiAchivement}}%)',
    teamKpi: 'Kpi nhóm: {{teamKpi}}%',
  },
  kpiRanking: {
    title: 'Bảng xếp hạng KPI',
    type: 'Kiểu',
    year: 'Năm',
    kpiPeriod: 'Kì KPI',
  },
}

export default dashboard
