const calculator = {
  heading: 'Calculator Salary',
  salaryCalculator: 'Salary Calculator',
  dashboard: 'Dashboard',
  title: 'Salary calculator tool Gross to Net / Net to Gross standard 2021',
  salary: 'Salary',
  rate: 'Exchange rate',
  insurance: 'Insurance',
  full_wage: 'Full wage',
  other: 'Other',
  dependent: 'Dependent (people)',
  description: 'Description',
  paid_gross: 'Paid by the employer gross ',
  copy: 'Copy to clipboard',
  social_insurance: 'Social insurance',
  health_insurance: 'Health Insurance ',
  pvi_care: 'Pvi care',
  union_tax: 'Union tax',
  unemployment_insurance: 'UnEmployment Insurance',
  taxable_income: 'Taxable Income',
  personal_income_tax: 'Personal income tax',
  total_expense: 'Total expense',
  detail_income_tax: 'Personal income tax details ',
  taxable_rate: 'Taxable rate',
  gross_salary: 'GROSS Salary',
  net_salary: 'Net salary',
  tax: 'Tax',
  money: 'Money',
  detail_taxable_rate1: 'Up to 5 million VND',
  detail_taxable_rate2: 'Over 5 million VND to 10 million VND',
  detail_taxable_rate3: 'From over 10 million VND to 18 million VND',
  detail_taxable_rate4: 'From over 18 million VND to 32 million VND',
  detail_taxable_rate5: 'From over 32 million VND to 52 million VND',
  detail_taxable_rate6: 'From over 52 million VND to 80 million VND',
  detail_taxable_rate7: 'Over 80 million VND',
  gross_to_net: 'GROSS → NET',
  net_to_gross: 'NET → GROSS',
  VND: 'VND',
  SGD: 'SGD',
  PVI: 'PVI',
  salaryRequired: 'Salary is required',
  insuranceAmountCheck:
    'Insurance amount must be around base salary insurance ({{baseSalaryInsurance}}) and salary amount',
  currency: 'Currency',
}

export default calculator
