import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_ADMIN_USER_LIST, API_LIST_TEAM } from 'routes/api'

import { _deleteApi, _getApi, _patchApi, _postApi } from 'utils/axios'

export const getListTeamByConditional = createAsyncThunk(
  'team/getListTeamByConditional',
  async (queries = {}) => {
    const url = `${API_LIST_TEAM}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const createTeam = createAsyncThunk('team/createTeam', async (data) => {
  const response = await _postApi(API_LIST_TEAM, data)

  return response?.data || {}
})

export const updateTeam = createAsyncThunk(
  'team/updateTeam',
  async ({ teamId, data = {} }) => {
    const response = await _patchApi(`${API_LIST_TEAM}/${teamId}`, data)

    if (!response?.data?.success || response?.error)
      throw new Error(
        response?.message || response?.data?.error || response?.data?.message
      )

    return response?.data || {}
  }
)

export const deleteTeam = createAsyncThunk(
  'team/deleteTeam',
  async (teamId) => {
    const response = await _deleteApi(`${API_LIST_TEAM}/${teamId}`)

    return response?.data || {}
  }
)

export const getAdminUserList = createAsyncThunk(
  'team/getAdminUserList',
  async () => {
    const response = await _getApi(API_ADMIN_USER_LIST)

    return response?.data || {}
  }
)

const initialState = {
  listTeams: {
    isLoading: false,
    data: {},
  },
  listUsers: {
    isLoading: false,
    data: {},
  },
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getListTeamByConditional.pending, (state) => {
        state.listTeams.isLoading = true
      })
      .addCase(getListTeamByConditional.fulfilled, (state, action) => {
        state.listTeams.isLoading = false
        state.listTeams.data = action.payload
      })
      .addCase(getListTeamByConditional.rejected, (state) => {
        state.listTeams.isLoading = false
      })
      .addCase(getAdminUserList.pending, (state) => {
        state.listUsers.isLoading = true
      })
      .addCase(getAdminUserList.fulfilled, (state, action) => {
        state.listUsers.isLoading = false
        state.listUsers.data = action.payload
      })
      .addCase(getAdminUserList.rejected, (state) => {
        state.listUsers.isLoading = false
      })
  },
})

export const selectListTeams = createSelector(
  [(state) => state.team.listTeams],
  (listTeams) => listTeams
)

export const selectListUsers = createSelector(
  [(state) => state.team.listUsers],
  (listUsers) => listUsers
)

export default teamSlice.reducer
