const quiz = {
  youEarn: 'You earn',
  point: 'point',
  points: 'points',
  totalScore: 'Total score',
  detailResult: 'Detail Result',
  home: 'Home',
  playNow: 'Play Now',
  completation: 'Completation',
  totalQuestion: 'Total Question',
  correct: 'Correct',
  wrong: 'Wrong',
  quizGameTitle: `Let's Quiz with`,
  dino: 'Dino',
  confirmSkipGameMessage: `If you close the quiz game now, you cannot play again today. Are you sure to close now?`,
  confirmFinishGameMessage: `You've just answered {{numberOfAnswer}} of {{numberOfQuestion}} questions. Are you sure to finish quiz game now?`,
  confirmCloseGameMessage: 'Are you sure to close game now?',
  skipGameMessage: `Don't show again`,
  runOutOfPlayGame: 'You have run out of play Quiz Game today',
  submitQuizFailed: 'Submit quiz failed. Please try again!',
  gameRules: 'Game Rules',
  totalQuestionRule: 'Each daily quiz consists of 5 questions.',
  typeOfAnswerRule: 'You can choose one or multiple correct answers.',
  pointEarnRule:
    'For each correctly answered question, you will earn a certain number of points.',
  typeOfGift:
    'These points can be converted into money or gifts when you reach a specific milestone.',
}

export default quiz
