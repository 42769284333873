import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_BONUS_PROFILE_USER, API_KPI_PROFILE_USER } from 'routes/api'

import { _getApi } from 'utils/axios'

export const getListKpiInfo = createAsyncThunk(
  'kpiBonusProfile/getListKpiInfo',
  async ({ userId = '', queries = {} }) => {
    const url = `${API_KPI_PROFILE_USER}/${userId}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data?.kpiUser || {}
  }
)

export const getListBonusInfo = createAsyncThunk(
  'kpiBonusProfile/getListBonusInfo',
  async ({ userId = '', queries = {} }) => {
    const url = `${API_BONUS_PROFILE_USER}/${userId}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data?.list || {}
  }
)

const initialState = {
  kpiInfo: {
    isLoading: false,
    data: {},
  },
  bonusInfo: {
    isLoading: false,
    data: {},
  },
}

const kpiBonusProfileSlice = createSlice({
  name: 'kpiBonusProfile',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getListKpiInfo.pending, (state) => {
        state.kpiInfo.isLoading = true
        state.kpiInfo.data = {}
      })
      .addCase(getListKpiInfo.fulfilled, (state, action) => {
        state.kpiInfo.isLoading = false
        state.kpiInfo.data = action.payload
      })
      .addCase(getListKpiInfo.rejected, (state) => {
        state.kpiInfo.isLoading = false
        state.kpiInfo.data = {}
      })
      .addCase(getListBonusInfo.pending, (state) => {
        state.bonusInfo.isLoading = true
        state.bonusInfo.data = {}
      })
      .addCase(getListBonusInfo.fulfilled, (state, action) => {
        state.bonusInfo.isLoading = false
        state.bonusInfo.data = action.payload
      })
      .addCase(getListBonusInfo.rejected, (state) => {
        state.bonusInfo.isLoading = false
        state.bonusInfo.data = {}
      })
  },
})

export const selectListKpiInfo = createSelector(
  [(state) => state.kpiBonusProfile.kpiInfo],
  (kpiInfo) => kpiInfo
)

export const selectListBonusInfo = createSelector(
  [(state) => state.kpiBonusProfile.bonusInfo],
  (bonusInfo) => bonusInfo
)

export default kpiBonusProfileSlice.reducer
