export const removeEmptyKeysFromObject = (optional = {}) => {
  if (!Object.keys(optional).length) return {}

  return Object.keys(optional).reduce((acc, cur) => {
    if (!optional[cur]) return acc

    return {
      ...acc,
      [cur]: optional[cur],
    }
  }, {})
}
