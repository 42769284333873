import { FORM_FIELDS } from 'components/cv-form/config'

export const MIN_LENGTH_PHONE_NUMBER = 7
export const MAX_LENGTH_PHONE_NUMBER = 12

export const HIDDEN_FIELDS = [
  FORM_FIELDS.UPLOAD_CV,
  FORM_FIELDS.JOB_ID,
  FORM_FIELDS.CLIENT_NAME,
  FORM_FIELDS.LANE_ID,
  FORM_FIELDS.APPROACH_DATE,
  FORM_FIELDS.LINK_CV,
  FORM_FIELDS.REFINE_CV,
  FORM_FIELDS.NOTE_APPROACH,
  FORM_FIELDS.USER_UPLOADED,
  FORM_FIELDS.IS_PUBLIC,
  FORM_FIELDS.SELL_POINT,
]

export const FULL_SIZE_FIELDS = [
  {
    field: FORM_FIELDS.LATEST_COMPANY,
    sx: { sm: 12 },
  },
]

export const DEFAULT_FORM_VALUES = {
  [FORM_FIELDS.IS_PUBLIC]: false,
  [FORM_FIELDS.CAPTCHA]: '',
}

export const EXPECTED_SUBMIT_FIELDS = [
  FORM_FIELDS.NAME,
  FORM_FIELDS.PHONE,
  FORM_FIELDS.EMAIL,
  FORM_FIELDS.ADDRESS,
  FORM_FIELDS.SKILL,
  FORM_FIELDS.RAW_CV,
  FORM_FIELDS.LINK_AVATAR,
  FORM_FIELDS.GENDER,
  FORM_FIELDS.CERTIFICATE,
  FORM_FIELDS.EXPERIENCE,
  FORM_FIELDS.BIRTHDAY,
  FORM_FIELDS.POSITION,
  FORM_FIELDS.NOTE,
  FORM_FIELDS.IS_PUBLIC,
  FORM_FIELDS.SELL_POINT,
  FORM_FIELDS.LOCATION,
  FORM_FIELDS.YEAR_OF_EXPERIENCE,
  FORM_FIELDS.LATEST_COMPANY,
  FORM_FIELDS.EDUCATION,
  FORM_FIELDS.LANGUAGES,
  FORM_FIELDS.JOB_ID,
  FORM_FIELDS.AVAILABILITY,
  FORM_FIELDS.SALARY_EXPECTATION.INDEX,
  FORM_FIELDS.FACEBOOK,
  FORM_FIELDS.LINKEDIN,
  FORM_FIELDS.SKYPE,
  FORM_FIELDS.CAPTCHA,
]

export const handleRedundantFields = (
  expectedFields = [],
  currentReqData = {}
) => {
  const expectedData = Object.entries(currentReqData).reduce((acc, cur) => {
    if (expectedFields.includes(cur?.[0]))
      return { ...acc, [cur?.[0]]: cur?.[1] }

    return acc
  }, {})

  return expectedData || {}
}
