import { useEffect } from 'react'

// routes
import Router from 'routes'
// theme
import ThemeProvider from 'theme'

import NotistackProvider from 'components/NotistackProvider'
import { ProgressBarStyle } from 'components/ProgressBar'
import ScrollToTop from 'components/ScrollToTop'
import MotionLazyContainer from 'components/animate/MotionLazyContainer'
import ChartStyle from 'components/chart/ChartStyle'
// components
import ThemeSettings from 'components/settings'

import { ReferralProvider } from 'contexts/ReferralContext'

import { analytics } from 'utils/firebase'

export default function App() {
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== 'production') return

    // only run firebase in production enviroment
    analytics()
  }, [])

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ReferralProvider>
              <ChartStyle />
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </ReferralProvider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  )
}
