const signup = {
  fullNameRequired: 'Full name required',
  emailRequired: 'Email is required',
  emailValid: 'Email must be a valid email address',
  passwordRequired: 'Password is required',
  confirmPasswordRequired: 'Confirm password is required',
  fullName: 'Full name',
  email: 'Email address',
  password: 'Password',
  confirmPassword: 'Confirm password',
  captchaVerify: 'Please verify that you are not a robot',
  passwordNotMatch: 'Confirm password is not match!',
  passwordLength: 'Password must be at least 8 characters',
  confirmPasswordLength: 'Confirm password must be at least 8 characters',
  welcome: 'Welcome to FETCH!',
  signup: 'Sign Up',
  confirmEmail:
    'We have just sent a verify link to your email, please confirm that link below before you can sign in.',
  signUpSuccess: 'Sign up success!',
  somethingWentWrong: 'Something went wrong!',
  referralCode: 'Referral code',
}

export default signup
