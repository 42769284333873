const users = {
  heading: 'Danh sách người dùng',
  name: 'Tên',
  user: 'Người dùng',
  team: 'Đội',
  client: 'Khách hàng',
  leader: 'Người điều hành',
  role: 'Vai trò',
  salaryGross: 'Lương (Gross)',
  position: 'Vị trí',
  actions: 'Hành động',
  email: 'Email',
  password: 'Mật khẩu',
  addNewUser: 'Tạo Người Dùng Mới',
  editUser: 'Sửa Người Dùng',
  addNewTeam: 'Tạo Đội Mới',
  editTeam: 'Sửa Đội',
  addNewClient: 'Thêm Khách Hàng Mới',
  editClient: 'Sửa Khách Hàng',
  addMember: 'Thêm thành viên',
  listMembers: 'Danh sách thành viên',
  saveChange: 'Lưu thay đổi',
  clientRequired: 'Khách hàng là bắt buộc',
  nameRequired: 'Tên là bắt buộc',
  leaderRequired: 'Người điều hành là bắt buộc',
  emailValid: 'Email phải là địa chỉ email hợp lệ',
  emailRequired: 'Email là bắt buộc',
  passwordRequired: 'Mật khẩu là bắt buộc',
  passwordMin: 'Mật khẩu phải có ít nhất 8 ký tự',
  passwordMax: 'Mật khẩu bị giới hạn 16 ký tự',
  salaryMin: 'Lương không được nhỏ hơn 1',
  roleRequired: 'Vai trò là bắt buộc',
  createSuccess: 'Tạo người dùng thành công!',
  updateSuccess: 'Cập nhật người dùng thành công!',
  toggleActiveUserSuccess:
    'Cập nhật trạng thái hoạt động của người dùng thành công!',
  toggleActiveUserFail:
    'Cập nhật trạng thái hoạt động của người dùng thất bại!',
  confirmDeleteUserMessage: 'Bạn có chắc chắn muốn xóa người dùng',
  createTeamSuccess: 'Tạo đội thành công!',
  updateTeamSuccess: 'Cập nhật đội thành công!',
  deleteTeamSuccess: 'Xóa đội thành công!',
  deleteTeamFail: 'Xóa đội thất bại!',
  confirmDeleteTeamMessage: 'Bạn có chắc chắn muốn xóa đội',
  listMemberOfTeam: 'Danh sách thành viên trong team',
  active: 'Hoạt động',
  confirmDeactiveUser:
    'Bạn có chắc chắn muốn deactive người dùng <strong>{{name}}</strong> không? Bạn chỉ có thể active leader này trong <strong>7 ngày đầu</strong> của kỳ KPI',
}

export default users
