import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  API_ADD_POSITION,
  API_ALL_SKILL,
  API_LANGUAGES,
  API_LOCATIONS,
} from 'routes/api'

import { _getApi } from 'utils/axios'

export const getAllPositions = createAsyncThunk(
  'master/getAllPositions',
  async () => {
    const response = await _getApi(API_ADD_POSITION)
    return response?.data?.list || []
  }
)

export const getAllSkills = createAsyncThunk(
  'master/getAllSkills',
  async () => {
    const response = await _getApi(API_ALL_SKILL)
    return response?.data?.skills || []
  }
)

export const getListLocations = createAsyncThunk(
  'master/getListLocations',
  async () => {
    const response = await _getApi(API_LOCATIONS)
    return response?.data?.list || []
  }
)

export const getListLanguages = createAsyncThunk(
  'master/getListLanguages',
  async () => {
    const response = await _getApi(API_LANGUAGES)
    return response?.data?.list || []
  }
)

const initialState = {
  positionOptions: [],
  skillOptions: [],
  locationOptions: [],
  languageOptions: [],
}

const masterDataSlice = createSlice({
  name: 'master',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllPositions.fulfilled, (state, action) => {
        state.positionOptions = (action.payload || []).map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      })
      .addCase(getAllSkills.fulfilled, (state, action) => {
        state.skillOptions = (action.payload || []).map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      })
      .addCase(getListLocations.fulfilled, (state, action) => {
        state.locationOptions = (action.payload || []).map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      })
      .addCase(getListLanguages.fulfilled, (state, action) => {
        state.languageOptions = action.payload
      })
  },
})

export const selectPositionOptions = createSelector(
  [(state) => state.master.positionOptions],
  (positionOptions) => positionOptions
)

export const selectSkillOptions = createSelector(
  [(state) => state.master.skillOptions],
  (skillOptions) => skillOptions
)

export const selectLocationOptions = createSelector(
  [(state) => state.master.locationOptions],
  (locationOptions) => locationOptions
)

export const selectLanguageOptions = createSelector(
  [(state) => state.master.languageOptions],
  (languageOptions) => languageOptions
)

export default masterDataSlice.reducer
