import { JOB_ACTIVE_STATUS } from 'sections/job/config'

import { replaceAll } from 'utils/replace'

export const FORM_FIELDS_COLOR_DARK_MODE = '#cccccc'

export const SEO_MAX_CHARACTERS = 100

export const EDITOR_DEFAULT_TEXT = (primaryColor = 'rgb(252, 204, 75)') => ({
  aboutFetch: `<h3 style="margin: 0.75em 0"><strong style="color: ${primaryColor};">1. INTRODUCTION</strong></h3><h4 style="margin: 0.75em 0"><strong>a. About Fetch:</strong></h4><p style="margin: 0.5em 0">Our mission is to offer Vietnam’s most talented technologists a platform to connect with some of the world’s leading tech companies and build their expertise on a global scale. Over 4 years, Fetch has built a good reputation and is trusted by many Vietnamese and foreign companies; And Fetch will continue its good work to bridge the divide between the World and the Vietnam Tech sector.</p>`,
  responsibilities: `<h3 style="margin: 0.75em 0"><strong style="color: ${primaryColor};">2. RESPONSIBILITIES</strong></h3>`,
  requirement: `<h3 style="margin: 0.75em 0"><strong style="color: ${primaryColor};">3. REQUIREMENT</strong></h3>`,
  niceToHave: ``,
  benefit: `<h3 style="margin: 0.75em 0"><strong style="color: ${primaryColor};">4. WHY YOU‘LL LOVE WORKING HERE</strong></h3>`,
  locationAndHours: ({ location = '' }) =>
    `<h3 style="margin: 0.75em 0"><strong style="color: ${primaryColor};">5. WORKING HOURS &amp; LOCATION</strong></h3><ul style="margin-top: 0.75em; margin-bottom: 0.75em; margin-left: 2em;"><li style="margin-top: 0.5em; margin-bottom: 0.5em">Working hours: From 9 AM - 6 PM, Monday to Friday.</li><li style="margin-top: 0.5em; margin-bottom: 0.5em">Location:&nbsp;</li></ul>${
      location && `<p style="margin: 0.5em 0">${location}`
    }`,
})

export const STICKY_BAR_TEXT = (title) =>
  `<h1 style="font-size: 16px;"><span style="color: #FCCC4B;">${title}</span></h1>`

export const ONSITE_LABEL = 'Onsite'
export const REMOTE_LABEL = 'Remote'

export const SALARY_FORM_FIELDS = {
  CURRENCY: 'currency',
  MIN: 'min',
  MAX: 'max',
}

export const FORM_FIELDS = {
  IS_SHOW_CAREER: 'isShowCareer',
  EXTERNAL_RECRUITER: 'externalRecruiter',
  NAME: 'name',
  LABEL: 'label',
  SALARY: {
    ...Object.keys(SALARY_FORM_FIELDS).reduce((acc, cur) => {
      const field = SALARY_FORM_FIELDS[cur] || ''

      return { ...acc, [cur]: `salaryJob.${field}` }
    }, {}),
    INDEX: 'salaryJob',
  },
  LOCATIONS: 'locations',
  TIME: 'time',
  TYPE: 'type',
  TAGS: 'tags',
  TITLE_PAGE: 'titlePage',
  META_JOB: 'metaJob',
  KEYWORD: 'keyword',
  NOTE: 'note',
  DESC_JOB: 'descJob',
  DESCRIPTION: 'description',
  INTERVIEW_PROCESS: 'interviewProcess',
  EXTRA_BENEFIT: 'extraBenefit',
  JOB_STATUS: 'jobStatus',
  CLIENT_ID: 'clientId',
  SKILL_NOT_REQUIRED: 'skillNotRequired',
  SKILL_REQUIRED: 'skillRequired',
  ABOUT_FETCH: 'aboutFetch',
  RESPONSIBILITIES: 'responsibilities',
  REQUIREMENT: 'requirement',
  NICE_TO_HAVE: 'niceToHave',
  BENEFIT: 'benefit',
  POSITION: 'positions',
  LOCATION_AND_HOURS: 'timeLocation',
  LOCATION_CHECKBOX: 'locationCheckbox',
  BONUS_SETTING_POINT: {
    LANES: 'lanes',
    STATUS: 'status',
    POINT: 'point',
    TITLE: 'title',
  },
}

export const CLIENT_IGNORE_FIELDS = [
  FORM_FIELDS.META_JOB,
  FORM_FIELDS.TITLE_PAGE,
  FORM_FIELDS.KEYWORD,
  FORM_FIELDS.NOTE,
  FORM_FIELDS.EXTRA_BENEFIT,
  FORM_FIELDS.ABOUT_FETCH,
  FORM_FIELDS.CLIENT_ID,
  FORM_FIELDS.EXTERNAL_RECRUITER,
  FORM_FIELDS.TAGS,
]

export const JobSchema = ({ Yup, translate, isClientRole = false }) =>
  Yup.object().shape({
    [FORM_FIELDS.NAME]: Yup.string().required(
      translate('pages.jobs.titleMessage')
    ),
    [FORM_FIELDS.SALARY.INDEX]: Yup.object()
      .test('validator-currency-required', (value, { createError }) => {
        const { currency } = value || {}

        if (!currency)
          return createError({
            path: FORM_FIELDS.SALARY.CURRENCY,
            message: translate('pages.jobs.currencyMessage'),
          })

        return true
      })
      .test('validator-minSalary-required', (value, { createError }) => {
        const { min: minSalary } = value || {}
        const fmtMinSalary = Number.parseInt(
          replaceAll(String(minSalary), '.', ''),
          10
        )

        if (Number.isNaN(fmtMinSalary))
          return createError({
            path: FORM_FIELDS.SALARY.MIN,
            message: translate('pages.jobs.minSalaryMessage'),
          })

        return true
      })
      .test('validator-maxSalary-required', (value, { createError }) => {
        const { max: maxSalary } = value || {}
        const fmtMaxSalary = Number.parseInt(
          replaceAll(String(maxSalary), '.', ''),
          10
        )

        if (Number.isNaN(fmtMaxSalary))
          return createError({
            path: FORM_FIELDS.SALARY.MAX,
            message: translate('pages.jobs.maxSalaryMessage'),
          })

        return true
      })
      .test('validator-greater-than-min', (value, { createError }) => {
        const { min: minSalary, max: maxSalary } = value || {}
        const fmtMinSalary = Number.parseInt(
          replaceAll(String(minSalary), '.', ''),
          10
        )
        const fmtMaxSalary = Number.parseInt(
          replaceAll(String(maxSalary), '.', ''),
          10
        )

        if (fmtMinSalary >= fmtMaxSalary)
          return createError({
            path: FORM_FIELDS.SALARY.MAX,
            message: translate('cvForm.maxSalaryValidate'),
          })

        return true
      }),
    [FORM_FIELDS.LOCATIONS]: Yup.array()
      .nullable()
      .min(1)
      .required(translate('pages.jobs.locationMessage')),
    [FORM_FIELDS.TIME]: Yup.string().required(
      translate('pages.jobs.timeMessage')
    ),
    [FORM_FIELDS.TYPE]: Yup.string().required(
      translate('pages.jobs.typeMessage')
    ),
    [FORM_FIELDS.TITLE_PAGE]: Yup.string()
      .max(SEO_MAX_CHARACTERS)
      .required(translate('pages.jobs.titlePageMessage')),
    [FORM_FIELDS.META_JOB]: Yup.string()
      .max(SEO_MAX_CHARACTERS)
      .required(translate('pages.jobs.meteJobMessage')),
    [FORM_FIELDS.CLIENT_ID]: Yup.object()
      .nullable()
      .required(translate('pages.jobs.clientMessage')),
    [FORM_FIELDS.POSITION]: Yup.array()
      .of(Yup.object())
      .min(1, translate('pages.jobs.positionMessage'))
      .required(translate('pages.jobs.positionMessage')),
    [FORM_FIELDS.BONUS_SETTING_POINT.LANES]: Yup.array().of(
      Yup.object().shape({
        [FORM_FIELDS.BONUS_SETTING_POINT.TITLE]: Yup.string().required(
          translate('pages.setting.titleRequired')
        ),
        [FORM_FIELDS.BONUS_SETTING_POINT.STATUS]: Yup.array()
          .of(Yup.object())
          .min(1, translate('pages.setting.statusRequired'))
          .required(translate('pages.setting.statusRequired')),
        [FORM_FIELDS.BONUS_SETTING_POINT.POINT]: Yup.string()
          .max(100, translate('pages.setting.pointRequired'))
          .required(translate('pages.setting.pointRequired')),
      })
    ),
    ...(isClientRole && {
      ...OVERRIDE_VALIDATE_FIELDS({ Yup }),
    }),
  })

export const generateField = (index, field) =>
  `${FORM_FIELDS.BONUS_SETTING_POINT.LANES}.${index}.${field}`

export const EDITOR_FIELDS = [
  FORM_FIELDS.ABOUT_FETCH,
  FORM_FIELDS.RESPONSIBILITIES,
  FORM_FIELDS.REQUIREMENT,
  FORM_FIELDS.NICE_TO_HAVE,
  FORM_FIELDS.BENEFIT,
]

export const SALARY_FORM_DEFAULT_VALUES = {
  [SALARY_FORM_FIELDS.MIN]: 0,
  [SALARY_FORM_FIELDS.MAX]: 0,
  [SALARY_FORM_FIELDS.CURRENCY]: '',
}

export const FORM_DEFAULT_VALUES = ({ color = '#FCCC4B' }) => ({
  [FORM_FIELDS.IS_SHOW_CAREER]: true,
  [FORM_FIELDS.EXTERNAL_RECRUITER]: false,
  [FORM_FIELDS.NAME]: '',
  [FORM_FIELDS.LABEL]: '',
  [FORM_FIELDS.SALARY.INDEX]: SALARY_FORM_DEFAULT_VALUES,
  [FORM_FIELDS.LOCATIONS]: [],
  [FORM_FIELDS.TIME]: '',
  [FORM_FIELDS.TYPE]: '',
  [FORM_FIELDS.TAGS]: [],
  [FORM_FIELDS.TITLE_PAGE]: '',
  [FORM_FIELDS.META_JOB]: '',
  [FORM_FIELDS.KEYWORD]: '',
  [FORM_FIELDS.NOTE]: '',
  [FORM_FIELDS.DESC_JOB]: '',
  [FORM_FIELDS.DESCRIPTION]: '',
  [FORM_FIELDS.INTERVIEW_PROCESS]: '',
  [FORM_FIELDS.EXTRA_BENEFIT]: '',
  [FORM_FIELDS.JOB_STATUS]: JOB_ACTIVE_STATUS,
  [FORM_FIELDS.CLIENT_ID]: '',
  [FORM_FIELDS.SKILL_NOT_REQUIRED]: [],
  [FORM_FIELDS.SKILL_REQUIRED]: [],
  [FORM_FIELDS.POSITION]: [],
  [FORM_FIELDS.ABOUT_FETCH]: EDITOR_DEFAULT_TEXT(color)?.aboutFetch,
  [FORM_FIELDS.RESPONSIBILITIES]: EDITOR_DEFAULT_TEXT(color)?.responsibilities,
  [FORM_FIELDS.REQUIREMENT]: EDITOR_DEFAULT_TEXT(color)?.requirement,
  [FORM_FIELDS.NICE_TO_HAVE]: EDITOR_DEFAULT_TEXT(color)?.niceToHave,
  [FORM_FIELDS.BENEFIT]: EDITOR_DEFAULT_TEXT(color)?.benefit,
  [FORM_FIELDS.LOCATION_AND_HOURS]: EDITOR_DEFAULT_TEXT(
    color
  )?.locationAndHours({ location: '' }),
  [FORM_FIELDS.LOCATION_CHECKBOX]: [],
})

export const LABEL_AUTO_CHECKBOX_LOCATION = {
  FETCH_HN: 'Fetch HN',
  FETCH_HCM: 'Fetch HCM',
  FETCH_DN: 'Fetch ĐN',
}

export const LOCATION_CHECKBOX_VALUES = {
  HN: 'hn',
  HCM: 'hcm',
  DN: 'dn',
}

export const LIST_AUTO_CHECKBOX_LOCATION = {
  [LABEL_AUTO_CHECKBOX_LOCATION.FETCH_HN]: LOCATION_CHECKBOX_VALUES.HN,
  [LABEL_AUTO_CHECKBOX_LOCATION.FETCH_HCM]: LOCATION_CHECKBOX_VALUES.HCM,
  [LABEL_AUTO_CHECKBOX_LOCATION.FETCH_DN]: LOCATION_CHECKBOX_VALUES.DN,
}

export const LOCATION_CHECKBOX_OPTIONS = [
  {
    label: (
      <p>
        <span style={{ fontWeight: 'bold' }}>HN</span>: 13th Floor, TTC Building
        - No.19, Duy Tan Street, Dich Vong Hau Ward, Cau Giay District, Hanoi.
      </p>
    ),
    value: 'hn',
    content:
      '+ HN: 13th Floor, TTC Building - No.19, Duy Tan Street, Dich Vong Hau Ward, Cau Giay District, Hanoi.',
  },
  {
    label: (
      <p>
        <span style={{ fontWeight: 'bold' }}>HCM</span>: 3rd Floor, BCONS TOWER
        2 - No.42/1, Ung Van Khiem Street, Ward 25, Binh Thanh District, HCMC.
      </p>
    ),
    value: 'hcm',
    content:
      '+ HCM: 3rd Floor, BCONS TOWER 2 - No.42/1, Ung Van Khiem Street, Ward 25, Binh Thanh District, HCMC.',
  },
  {
    label: (
      <p>
        <span style={{ fontWeight: 'bold' }}>DN</span>: 35 Thai Phien, Phuoc
        Ninh Ward, Hai Chau District, Da Nang City
      </p>
    ),
    value: 'dn',
    content:
      '+ DN: 35 Thai Phien, Phuoc Ninh Ward, Hai Chau District, Da Nang City.',
  },
]

export const formatTextEditor = (content = '') =>
  `<p style="margin: 0.5em 0">${content}</p>`

export const JOB_STATUS = {
  CLOSE: 'Close',
}

export const OVERRIDE_VALIDATE_FIELDS = ({ Yup }) => ({
  [FORM_FIELDS.CLIENT_ID]: Yup.object().nullable(),
  [FORM_FIELDS.META_JOB]: Yup.string().nullable(),
  [FORM_FIELDS.TITLE_PAGE]: Yup.string().nullable(),
})

export const CURRENCY_OPTIONS = ['USD', 'VND', 'SGD']
