const manageRedeem = {
  system: {
    name: 'Name',
    time: 'Time',
    point: 'Point redeem',
    status: 'Status',
    action: 'Action',
    reason: 'Reason',
    approveAction: 'Approve',
    rejectAction: 'Reject',
    statusConfig: {
      process: 'Process',
      done: 'Done',
      reject: 'Reject',
    },
    approveConfirmMessage: 'Are you sure you want to approve request from',
    addMoneyTransferBill: 'Add money transfer bill',
    rejectReason: 'Reason for reject request from',
    reasonRequired: 'Reason is required',
    approveSuccess: 'Approve request success!',
    approveFail: 'Approve request fail!',
    rejectSuccess: 'Reject request success!',
    rejectFail: 'Reject request fail!',
  },
  user: {
    message: 'Message',
    amount: 'Amount',
    redeemBonus: 'Redeem bonus',
    redeemMessage: 'Enter the amount point you want to redeem',
    bankMessage:
      'You need to update your bank information before sending redeem request',
    redeemSuccess: 'Redeem request success!',
    redeemFail: 'Redeem request failed!',
    cancelRedeemMessage:
      'Are your sure you want to cancel this redeem request?',
    cancelRedeemSuccess: 'Cancel redeem request success!',
    cancelRedeemFail: 'Cancel redeem request failed!',
    pointCheck: 'Redeem point must be greater than 0',
  },
}

export default manageRedeem
