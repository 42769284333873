const report = {
  heading: 'Báo cáo vấn đề',
  reportDetail: 'Chi tiết báo cáo',
  reportProblem: 'Báo cáo một vấn đề',
  subject: 'Chủ đề',
  content: 'Nội dung',
  image: 'Ảnh',
  timeSent: 'Thời gian gửi',
  action: 'Hành động',
  subjectRequired: 'Chủ đề là bắt buộc',
  contentRequired: 'Nội dung là bắt buộc',
  createReportSuccess: 'Tạo báo cáo thành công',
}

export default report
