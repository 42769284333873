import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_ADD_POSITION,
  API_ADD_SKILL,
  API_ALL_SKILL,
  API_CARDS_LANE,
  API_CLOSE_JOB,
  API_CREATE_JOB,
  API_LIST_BONUS_POINTS,
  API_LIST_CLIENT,
  API_LIST_JOBS,
  API_LIST_TAGS,
  API_LOCATIONS,
  API_SEARCH_POSITION,
  API_SEARCH_SKILL,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const getListLocations = createAsyncThunk(
  'jobs/getListLocations',
  async () => {
    const response = await _getApi(API_LOCATIONS)
    const locations = response?.data?.list || []

    const locationOptions = locations.map(({ id = '', name = '' }) => ({
      value: id,
      label: name,
    }))

    return {
      data: locations,
      locationOptions,
    }
  }
)

export const getListClients = createAsyncThunk(
  'jobs/getListClients',
  async () => {
    const response = await _getApi(API_LIST_CLIENT)
    const { clients = [] } = response?.data || {}

    const clientOptions = (clients || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }))

    return {
      data: clients,
      clientOptions,
    }
  }
)

export const getListTags = createAsyncThunk('jobs/getListTags', async () => {
  const response = await _getApi(API_LIST_TAGS)
  const { tags = [] } = response?.data || {}

  const tagOptions = (tags || []).map(({ id, title }) => ({
    value: id,
    label: title,
  }))

  return {
    data: tags,
    tagOptions,
  }
})

export const getListJobs = createAsyncThunk(
  'jobs/getListJobs',
  async (queries = {}) => {
    const response = await _getApi(API_LIST_JOBS, { params: queries })
    return response.data || {}
  }
)

export const createJob = createAsyncThunk('jobs/createJob', async (data) => {
  try {
    const response = await _postApi(API_CREATE_JOB, data)

    if (!response?.data?.success) {
      throw new Error(
        response?.error?.message ||
          response?.data?.message ||
          response?.data?.error ||
          response?.message
      )
    }
  } catch (error) {
    throw new Error(
      error?.validation?.body?.message ||
        error?.error?.message ||
        error?.data?.message ||
        error?.data?.error ||
        error?.message
    )
  }
})

export const getAllSkill = createAsyncThunk('jobs/getAllSkill', async () => {
  const response = await _getApi(API_ALL_SKILL)
  return (response?.data?.skills || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }))
})

export const createSkill = createAsyncThunk(
  'jobs/createSkill',
  async (data) => {
    try {
      await _postApi(API_ADD_SKILL, data)
    } catch (error) {
      throw new Error(
        error?.validation?.body?.message ||
          error?.error?.message ||
          error?.data?.message ||
          error?.data?.error ||
          error?.message
      )
    }
  }
)

export const searchSkill = createAsyncThunk(
  'jobs/searchSkill',
  async (data) => {
    const response = await _postApi(API_SEARCH_SKILL, data)
    return (response?.data?.data?.skills || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }
)

export const createPosition = createAsyncThunk(
  'jobs/createPosition',
  async (data) => {
    const response = await _postApi(API_ADD_POSITION, data)

    if (!response?.data?.success) throw new Error(response?.data?.message)
  }
)

export const getAllPosition = createAsyncThunk(
  'jobs/getAllPosition',
  async () => {
    const response = await _getApi(API_ADD_POSITION)
    return (response?.data?.list || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }
)

export const searchPosition = createAsyncThunk(
  'jobs/searchPosition',
  async (data) => {
    const response = await _postApi(API_SEARCH_POSITION, data)
    return (response?.data?.list || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }
)

export const toggleShareJob = createAsyncThunk(
  'jobs/toggleShareJob',
  async ({ id, externalRecruiter }) => {
    const response = await _patchApi(
      `${API_CREATE_JOB}/${id}/toggle-share-recruiter`,
      { externalRecruiter }
    )

    if (!response?.data?.succes) throw new Error(response?.data?.message)
  }
)

export const getListBonusPoints = createAsyncThunk(
  'setting/getListBonusPoints',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_LIST_BONUS_POINTS}?${qs.stringify(queries)}`
    )
    return response?.data?.list || []
  }
)

export const closeJobAction = createAsyncThunk(
  'jobDetail/closeJobAction',
  async ({ jobId, data }, { rejectWithValue }) => {
    try {
      const response = await _patchApi(`${API_CLOSE_JOB}/${jobId}`, data)

      return response?.data || {}
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getCardsHasScreenCVStatus = createAsyncThunk(
  'jobDetail/getCardsHasScreenCVStatus',
  async ({ columnId = '', jobId = '' }) => {
    const response = await _getApi(
      `${API_CARDS_LANE}/${columnId}?jobId=${jobId}`
    )

    return response?.data?.list || []
  }
)

const initialState = {
  listJobs: {
    isLoading: false,
    data: {},
  },
  listLocations: {},
  listClients: {},
  listTags: {},
  skillOptions: [],
  positionOptions: [],
  skillSearch: [],
  positionSearch: [],
  bonusSettingPoint: {
    data: [],
    isLoading: false,
    isEmpty: false,
  },
  closeJobInfo: {
    isLoading: false,
    listScreenCV: [],
  },
}

const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    resetSkillSearch: (state) => {
      state.skillSearch = []
    },
    resetPositionSearch: (state) => {
      state.positionSearch = []
    },
    handleToggleShareJob: (state, action) => {
      const jobIndex = state.listJobs.data.list.findIndex(
        (job) => job.id === action.payload.id
      )
      state.listJobs.data.list[jobIndex].externalRecruiter =
        action.payload.externalRecruiter
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListJobs.pending, (state) => {
        state.listJobs.isLoading = true
      })
      .addCase(getListJobs.fulfilled, (state, action) => {
        state.listJobs.isLoading = false
        state.listJobs.data = action.payload
      })
      .addCase(getListJobs.rejected, (state) => {
        state.listJobs.isLoading = false
      })
      .addCase(getAllSkill.fulfilled, (state, action) => {
        state.skillOptions = action.payload
      })
      .addCase(searchSkill.fulfilled, (state, action) => {
        state.skillSearch = action.payload
      })
      .addCase(searchPosition.fulfilled, (state, action) => {
        state.positionSearch = action.payload
      })
      .addCase(getAllPosition.fulfilled, (state, action) => {
        state.positionOptions = action.payload
      })
      .addCase(getListLocations.fulfilled, (state, action) => {
        state.listLocations = action.payload
      })
      .addCase(getListClients.fulfilled, (state, action) => {
        state.listClients = action.payload
      })
      .addCase(getListTags.fulfilled, (state, action) => {
        state.listTags = action.payload
      })
      .addCase(getListBonusPoints.pending, (state) => {
        state.bonusSettingPoint.isLoading = true
      })
      .addCase(getListBonusPoints.fulfilled, (state, action) => {
        state.bonusSettingPoint.isLoading = false
        state.bonusSettingPoint.data = action.payload
        state.bonusSettingPoint.isEmpty = !action.payload.length
      })
      .addCase(getListBonusPoints.rejected, (state) => {
        state.bonusSettingPoint.isLoading = false
        state.bonusSettingPoint.data = []
      })
      .addCase(closeJobAction.pending, (state) => {
        state.closeJobInfo.isLoading = true
      })
      .addCase(closeJobAction.fulfilled, (state) => {
        state.closeJobInfo.isLoading = false
      })
      .addCase(closeJobAction.rejected, (state) => {
        state.closeJobInfo.isLoading = false
      })
      .addCase(getCardsHasScreenCVStatus.pending, (state) => {
        state.closeJobInfo.isLoading = true
        state.closeJobInfo.listScreenCV = []
      })
      .addCase(getCardsHasScreenCVStatus.fulfilled, (state, action) => {
        state.closeJobInfo.isLoading = false
        state.closeJobInfo.listScreenCV = action.payload
      })
      .addCase(getCardsHasScreenCVStatus.rejected, (state) => {
        state.closeJobInfo.isLoading = false
        state.closeJobInfo.listScreenCV = []
      })
  },
})

export default jobSlice.reducer

export const { resetSkillSearch, resetPositionSearch, handleToggleShareJob } =
  jobSlice.actions

export const selectListJobs = createSelector(
  [(state) => state.job.listJobs],
  (listJobs) => listJobs
)

export const selectListLocations = createSelector(
  [(state) => state.job.listLocations],
  (listLocations) => listLocations
)

export const selectListClients = createSelector(
  [(state) => state.job.listClients],
  (listClients) => listClients
)

export const selectListTags = createSelector(
  [(state) => state.job.listTags],
  (listTags) => listTags
)

export const selectSkillOptions = createSelector(
  [(state) => state.job.skillOptions],
  (skillOptions) => skillOptions
)

export const selectPositionOptions = createSelector(
  [(state) => state.job.positionOptions],
  (positionOptions) => positionOptions
)

export const selectListBonusPoints = createSelector(
  [(state) => state.job.bonusSettingPoint],
  (bonusSettingPoint) => bonusSettingPoint
)

export const selectCloseJob = createSelector(
  [(state) => state.job.closeJobInfo],
  (closeJobInfo) => closeJobInfo
)
