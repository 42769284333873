const interview = {
  heading: 'Danh sách phỏng vấn',
  name: 'Tên',
  email: 'Email',
  phone: 'Điện thoại',
  timeInterview: 'Thời gian phỏng vấn',
  timeEnd: 'Thời gian kết thúc',
  action: 'Hành động',
  newInterview: 'Tạo lịch phỏng vấn',
  editDetails: 'Sửa chi tiết',
  reviewCandidate: 'Đánh giá ứng viên',
  enterYourEmailSearch: 'Nhập email cần tìm kiếm',
  password: 'Mật khẩu zoom',
  confirmMessage: 'Bạn có chắc chắn xóa cuộc phỏng vấn',
  deleteSuccess: 'Xóa phỏng vấn thành công!',
  deleteFail: 'Xóa phỏng vấn thất bại!',
  updateSuccess: 'Cập nhật phỏng vấn thành công!',
  updateFail: 'Cập nhật phỏng vấn thất bại!',
  interviewer: 'Người phỏng vấn',
  nameJob: 'Tên công việc',
  candidate: 'Ứng viên',
  location: 'Vị trí',
  linkZoom: 'Link zoom',
  timeInterviewEnd: 'Thời gian kết thúc phỏng vấn',
  type: 'Hình thức',
  editInterview: 'Sửa phỏng vấn',
  addInterview: 'Thêm phỏng vấn',
  detailInterview: 'Chi tiết phỏng vấn',
  typeIsRequired: 'Hình thức là bắt buộc',
  jobIsRequired: 'Công việc là bắt buộc',
  candidateIsRequired: 'Ứng viên là bắt buộc',
  interviewAssessmentForm: 'Phiếu đánh giá',
  interviewFeedbackForm: 'Nhận xét/ghi chú',
  recommentedForNextRound: 'Gợi ý cho vòng tiếp theo',
  otherComment: 'Đánh giá khác',
  teamWork: 'Làm việc nhóm',
  motivation: 'Động lực làm việc',
  personalAttitude: 'Thái độ',
  languageAbility: 'Khả năng ngôn ngữ',
  workExperience: 'Kinh nghiệm làm việc',
  professionalKnowledge: 'Kiến thức chuyên môn',
  abilityFactor: 'Yếu Tố Kỹ Năng',
  positionApplied: 'Vị trí ứng tuyển',
  suggestionPosition: 'Gợi ý vị trí',
  interviewDate: 'Ngày phỏng vấn',
  candidateName: 'Tên ứng viên',
  placeholderFrom: 'Nhập vào đây',
  addSuccess: 'Thêm phỏng vấn thành công!',
  addFail: 'Thêm phỏng vấn thất bại!',
  linkZoomIsRequired: 'Link zoom là bắt buộc',
  maxLengthPassword: 'Độ dài của mật khẩu không được quá 10 ký tự',
  timeInterviewStartRequired: 'Thời gian bắt đầu phỏng vấn là bắt buộc',
  timeInterviewEndRequired: 'Thời gian kết thúc phỏng vấn là bắt buộc',
}

export default interview
