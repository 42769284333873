import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_ADMIN_USER_LIST,
  API_LIST_CLIENT,
  API_LIST_ROLES,
  API_REGISTER,
  API_USER,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const getAdminUserByConditional = createAsyncThunk(
  'user/getAdminUserByConditional',
  async (queries = {}) => {
    const url = `${API_USER}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const getListRoles = createAsyncThunk('user/getListRoles', async () => {
  const url = API_LIST_ROLES
  const response = await _getApi(url)

  return response?.data?.roles || []
})

export const createUser = createAsyncThunk('user/createUser', async (data) => {
  try {
    const response = await _postApi(API_REGISTER, data)

    if (!response?.data?.success)
      throw new Error(
        response?.message || response?.data?.message || response?.data?.error
      )
  } catch (error) {
    throw new Error(error?.message)
  }
})

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ userId, data = {} }) => {
    try {
      const response = await _patchApi(`${API_USER}/${userId}`, data)

      if (!response?.data?.success)
        throw new Error(
          response?.message || response?.data?.message || response?.data?.error
        )
    } catch (error) {
      throw new Error(error?.message)
    }
  }
)

export const toggleActiveUser = createAsyncThunk(
  'user/toggleActiveUser',
  async ({ userId, data = {} }) => {
    const response = await _patchApi(`${API_USER}/${userId}/active`, data)

    return response?.data || {}
  }
)

export const getListAdminUsers = createAsyncThunk(
  'user/getListAdminUsers',
  async () => {
    const response = await _getApi(API_ADMIN_USER_LIST)

    return response?.data?.user || []
  }
)

export const getAllClients = createAsyncThunk(
  'user/getAllClients',
  async () => {
    const response = await _getApi(API_LIST_CLIENT)

    return response?.data?.clients || []
  }
)

const initialState = {
  listAdminUsers: {
    isLoading: false,
    error: null,
    data: [],
  },
  listRoles: {
    isLoading: false,
    data: [],
  },
  listUsers: {
    isLoading: false,
    data: {},
  },
  listClients: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getListAdminUsers.pending, (state) => {
        state.listAdminUsers.isLoading = true
      })
      .addCase(getListAdminUsers.fulfilled, (state, action) => {
        state.listAdminUsers.isLoading = false
        state.listAdminUsers.data = action.payload
      })
      .addCase(getListAdminUsers.rejected, (state, action) => {
        state.listAdminUsers.isLoading = false
        state.listAdminUsers.error = action.error.message
      })
      .addCase(getListRoles.pending, (state) => {
        state.listRoles.isLoading = true
      })
      .addCase(getListRoles.fulfilled, (state, action) => {
        state.listRoles.isLoading = false
        state.listRoles.data = action.payload
      })
      .addCase(getListRoles.rejected, (state) => {
        state.listRoles.isLoading = false
      })
      .addCase(getAdminUserByConditional.pending, (state) => {
        state.listUsers.isLoading = true
      })
      .addCase(getAdminUserByConditional.fulfilled, (state, action) => {
        state.listUsers.isLoading = false
        state.listUsers.data = action.payload
      })
      .addCase(getAdminUserByConditional.rejected, (state) => {
        state.listUsers.isLoading = false
      })
      .addCase(toggleActiveUser.fulfilled, (state, action) => {
        const { userId = '', success = false } = action.payload || {}
        const currentUser = state.listUsers?.data?.list?.find(
          (item) => item.id === userId
        )

        if (!success || !currentUser) return

        currentUser.isActive = action.meta.arg.data.isActive
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.listClients = action.payload
      })
  },
})

export const selectListAdminUsers = createSelector(
  [(state) => state.user.listAdminUsers],
  (listAdminUsers) => listAdminUsers
)

export const selectListRoles = createSelector(
  [(state) => state.user.listRoles],
  (listRoles) => listRoles
)

export const selectListUsers = createSelector(
  [(state) => state.user.listUsers],
  (listUsers) => listUsers
)

export const selectListClients = createSelector(
  [(state) => state.user.listClients],
  (listClients) => listClients
)

export default userSlice.reducer
