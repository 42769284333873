const clients = {
  heading: 'List client',
  newClient: 'New Client',
  name: 'Name',
  website: 'Website',
  time: 'Time',
  email: 'Email',
  phone: 'Phone',
  action: 'Action',
  color: 'Color',
  about: 'About',
  active: 'Active',
  nameError: 'Name is required',
  aboutError: 'About is required',
  deleteSuccess: 'Delete client success!',
  deleteFail: 'Delete client failed!',
  updateClient: 'Update Client',
  updateSuccess: 'Update client success!',
  createSuccess: 'Create client success!',
  updateFail: 'Update client fail!',
  createFail: 'Create client fail!',
  confirmMessage: 'Are you sure you want to delete client',
  emailInvalid: 'Email is invalid',
  phoneInvalid: 'Phone is invalid',
  toggleClientStatusSuccess: 'Change client status success!',
  toggleClientStatusFail: 'Change client status fail!',
}

export default clients
