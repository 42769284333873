import {
  MAX_LENGTH_PHONE_NUMBER,
  MIN_LENGTH_PHONE_NUMBER,
} from 'sections/job-detail/refer-now/config'
import { SEO_MAX_CHARACTERS } from 'sections/job/form/config'

const jobs = {
  heading: 'Danh sách công việc',
  yourJobs: 'Danh sách công việc của bạn',
  newJob: 'Công việc mới',
  title: 'Tiêu đề',
  client: 'Khách hàng',
  time: 'Thời gian',
  salary: 'Lương (Gross)',
  minSalary: 'Min',
  maxSalary: 'Max',
  currency: 'Đơn vị tiền tệ',
  type: 'Loại',
  location: 'Địa điểm',
  status: 'Trạng thái',
  actions: 'Hành động',
  editJob: 'Sửa công việc',
  addJob: 'Thêm Công Việc Mới',
  jobInfo: 'Thông tin công việc',
  jobTitle: 'Tiêu đề công việc',
  tags: 'Thẻ',
  jobTitleHelper: `Dùng để SEO trang web (Tối đa ${SEO_MAX_CHARACTERS} ký tự)`,
  allowShare: 'Cho phép chia sẻ với nhà tuyển dụng ngoài',
  pageTitle: 'Tiêu đề trang',
  chooseType: 'Hình thức làm việc',
  noteFromLeader: 'Ghi chú từ lãnh đạo',
  keyword: 'Từ khóa',
  note: 'Ghi chú',
  descJob: 'Mô tả công việc',
  descMeta: 'Desc meta',
  interviewProcess: 'Quá trình phỏng vấn',
  extraBenefit: 'Lợi ích đi kèm',
  description: 'Mô tả',
  aboutFetch: 'Về Fetch',
  responsibilities: 'Trách nhiệm',
  requirement: 'Yêu cầu',
  niceToHave: 'Rất vui khi có bạn',
  benefit: 'Bạn sẽ thích làm việc tại đây',
  locationAndHours: 'Địa điểm và thời gian làm việc',
  skillRequired: 'Kỹ năng bắt buộc',
  skill: 'Kỹ năng',
  addSkillText: 'Bạn có thể thêm kỹ năng mới',
  addNewSkill: 'Thêm kỹ năng mới',
  titleMessage: 'Tiêu đề công việc là bắt buộc',
  salaryMessage: 'Lương là bắt buộc',
  minSalaryMessage: 'Mức lương tối thiểu là bắt buộc',
  maxSalaryMessage: 'Mức lương tối đa là bắt buộc',
  currencyMessage: 'Đơn vị tiền tệ là bắt buộc',
  locationMessage: 'Địa điểm là bắt buộc',
  timeMessage: 'Thời gian là bắt buộc',
  typeMessage: 'Hình thức làm việc là bắt buộc',
  titlePagerMessage: 'Tiêu đề trang là bắt buộc',
  metaJobMessage: 'Mô tả meta là bắt buộc',
  clientMessage: 'Khách hàng là bắt buộc',
  downloadAsPdf: 'Tải file dạng PDF',
  followers: 'Người theo dõi',
  addToBoard: 'Add to board',
  name: 'Tên',
  jobName: 'Tên công việc',
  clientName: 'Tên khách hàng',
  email: 'Email',
  lane: 'Cột',
  phone: 'Điện thoại',
  approachDate: 'Ngày tiếp cận',
  linkCv: 'Đường dẫn CV',
  position: 'Chức vụ',
  noteApproach: 'Lưu ý',
  rawCv: 'Xem CV',
  closeCV: 'Đóng CV',
  changeTo: 'đổi thành',
  activity: 'Hoạt động',
  click: 'Click',
  candidate: 'Ứng viên',
  listCandidate: 'Danh sách ứng viên',
  viewDetail: 'Xem chi tiết',
  updateCandidateFail: 'Cập nhật ứng viên thất bại!',
  updateCandidateSuccess: 'Cập nhật ứng viên thành công!',
  selectStatus: 'Lựa chọn trạng thái',
  noteApproachTitle: 'Note approach title is required',
  approachDateMessage: 'Ngày tiếp cận là bắt buộc',
  laneMessage: 'Địa điểm là bắt buộc',
  positionMessage: 'Chức vụ là bắt buộc',
  updateJobSuccess: 'Cập nhật công việc thành công!',
  updateJobFailed: 'Cập nhật công việc thất bại!',
  createJobFailed: 'Tạo công việc thất bại!',
  createJobSuccess: 'Tạo công việc thành công!',
  failedToGetSkill: 'Tải kỹ năng thất bại!',
  failedToAddSkill: 'Thêm kỹ năng thất bại!',
  skillExist: 'Kỹ năng đã tồn tại!',
  addSkillSuccess: 'Thêm kỹ năng thành công!',
  enterLinkOrImportCv: 'Điền link hoặc tải lên CV',
  hasUpdateThisJob: 'Đã cập nhật công việc này',
  active: 'Đang tuyển',
  close: 'Đã đóng',
  pending: 'Đang chờ',
  archive: 'Lưu trữ',
  process: 'Đang xem xét',
  descriptionMessage: 'Mô tả là bắt buộc',
  removeFromJob: 'Xóa khỏi công việc',
  removeFromCandidate: 'Xóa khỏi ứng viên',
  removeYourself: 'Không thể tự xóa chính mình',
  referNow: 'Gửi ngay',
  referCandidate: 'Giới thiệu ứng viên',
  fullName: 'Họ và tên',
  yourMessage: 'Tin nhắn của bạn',
  upload: 'Gửi',
  uploadPortfolio: 'Bạn có thể upload thêm Portfolio ở',
  here: 'đây',
  curriculumVitae: 'Hồ sơ ứng viên',
  nameRequired: 'Tên là bắt buộc',
  emailRequired: 'Email là bắt buộc',
  emailInvalid: 'Email không hợp lệ',
  phoneRequired: 'Số điện thoại là bắt buộc',
  phoneInvalid: 'Số điện thoại không hợp lệ',
  messageRequired: 'Tin nhắn là bắt buộc',
  uploadCvRequired: 'Bạn phải upload CV',
  captchaVerify: 'Hãy xác nhận bạn không phải là robot',
  uploadSuccess: 'Gửi hồ sơ thành công',
  uploadFailed: 'Gửi hồ sơ thất bại',
  share: 'Chia sẻ',
  unShare: 'Dừng chia sẻ',
  confirmShareMessage: 'Bạn có chắc chắn muốn chia sẻ công việc',
  confirmUnshareMessage: 'Bạn có chắc chắn muốn dừng chia sẻ công việc',
  addNewPosition: 'Tạo chức vụ mới',
  addPositionText: 'Bạn có thể thêm chức vụ mới',
  positionExist: 'Chức vụ đã tồn tại!',
  addPositionSuccess: 'Tạo chức vụ thành công!',
  failedToAddPosition: 'Tạo chức vụ thất bại',
  shareJobSuccess: 'Chia sẻ công việc thành công!',
  shareJobFail: 'Chia sẻ công việc thất bại!',
  unShareJobSuccess: 'Dừng chia sẻ công việc thành công!',
  unShareJobFail: 'Dừng chia sẻ công việc thất bại!',
  removeAssigneeFailed: 'Xóa người tham gia thất bại! Hãy thử lại.',
  addAssigneeFailed: 'Thêm người tham gia thất bại! Hãy thử lại.',
  removeAssigneeSuccess: 'Xóa người tham gia thành công!',
  addAssigneeSuccess: 'Thêm người tham gia thành công!',
  bonusTitleLabel: 'Ứng viên đạt',
  bonusPointLabel: 'Nhận điểm thưởng',
  pointOfJob: 'Mức điểm thưởng của công việc',
  points: 'điểm',
  minPhoneNumberLengthMessage: `Số điện thoại phải có ít nhất ${MIN_LENGTH_PHONE_NUMBER} ký tự`,
  maxPhoneNumberLengthMessage: `Số điện thoại phải ngắn hơn hoặc bằng ${MAX_LENGTH_PHONE_NUMBER} ký tự`,
  cancelReferCV: 'Bạn sẽ phải tải lên CV lại nếu hủy bỏ phiên bản này',
  submitReferral: 'Lưu ứng viên',
  salaryExpectation: 'Lương mong muốn',
  yearOfExperience: 'Số năm kinh nghiệm',
  failMessageReferCV: `Tải lên CV không thành công. Có thể bạn gặp một vài vấn đề gì đó. Thử lại hoặc liên hệ chúng tôi để trợ giúp.`,
  successMessageReferCV: 'Cảm ơn bạn đã giới thiệu ứng viên.',
  inReferring: 'In-Referring',
  referralNotifySuccess: `Cảm ơn bạn đã đăng ký tài khoản trên portal FETCH. Chúng tôi rất vinh dự khi nhận được sự tin tưởng của bạn. Chúc mừng bạn đã nhận được điểm thưởng giới thiệu.`,
  closeJob: 'Đóng job',
  temporaryClosure:
    'Đóng tạm thời <br /> (Tất cả cards sẽ được đẩy vào trạng thái pending)',
  permanentClosure: 'Đóng vĩnh viễn',
  closeJobFailed: 'Đóng job thất bại!',
  confirmEditJobMessage:
    'Bạn đang thay đổi thông tin công việc. Công việc này sẽ phải chờ chúng tôi kiểm tra lại thông tin bạn vừa thay đổi. Công việc sẽ được chuyển về trạng thái <b style="color: {{color}}">{{statusReview}}</b>.',
  confirmActiveMessage:
    'Bạn đang thay đổi một công việc sang trạng thái <b style="color: {{color}}">{{statusActive}}</b>. Công việc này sẽ phải chờ chúng tôi kiểm tra lại thông tin bạn vừa cập nhật. Công việc sẽ được chuyển về trạng thái <b style="color: {{color}}">{{statusReview}}</b>.',
  deactiveMessage: 'Bạn có chắc muốn đóng công việc',
  activeMessage: 'Bạn có chắc muốn mở công việc',
  deactive: 'Đóng',
  cancelJobConfirmMessage: 'Bạn có chắc muốn huỷ việc chờ kiểm tra công việc',
  cannotOpenCandidateCv: 'Không thể xem cv của ứng viên này, vui lòng thử lại',
  notification: 'Thông báo',
  availability: 'Trạng thái trước đó',
  getBonus: 'Nhận điểm thưởng',
  confirmArchiveMessage:
    'Tất cả thẻ sẽ được đẩy vào <strong>bộ nhớ</strong> nếu công việc này <strong>được lưu trữ</strong>',
  shareJDViaCopyLink: 'Chia sẻ JD bằng đường dẫn',
  emailVerify: 'Email phải là địa chỉ email hợp lệ',
  linkedinRequired: 'Đường dẫn linkedin là bắt buộc',
  sendInvitationLetterSuccess: 'Gửi thư mời tới ứng viên thành công!',
  sendInvitationLetterFailed: 'Gửi thư mời tới ứng viên thất bại!',
  sendInvitationLetter: 'Gửi thư mời tới ứng viên của bạn',
  importLinkedin: 'Nhập đường dẫn Linkedin',
  enterEmail: 'Nhập email',
  enterLinkedin: 'Nhập đường dẫn Linkedin',
}
export default jobs
