import { Suspense, lazy } from 'react'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { Navigate, useRoutes } from 'react-router-dom'

import loadable from '@loadable/component'

// components
import LoadingScreen from 'components/LoadingScreen'

import { PAGES, ROLE_BY_PAGE } from 'config'

// guards
import AuthGuard from 'guards/AuthGuard'
import GuestGuard from 'guards/GuestGuard'
import RoleBasedGuard from 'guards/RoleBasedGuard'

// layouts
const DashboardLayout = loadable(() => import('layouts/dashboard'))

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'linkedin',
          element: (
            <GuestGuard>
              <LinkedInCallback />
            </GuestGuard>
          ),
        },

        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'verify-email',
      element: (
        <GuestGuard>
          <VerifyEmail />
        </GuestGuard>
      ),
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Dashboard]} hasContent>
              <DashboardPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Notification Routes
    {
      path: 'notification',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Notification]} hasContent>
              <NotificationPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // KPI & bonus Routes
    {
      path: 'kpi-bonus',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.KPIBonus]} hasContent>
              <KPIBonusPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Client Routes
    {
      path: 'clients',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Clients]} hasContent>
              <ClientPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Calculator Routes
    {
      path: 'calculator',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Calculator]} hasContent>
              <CalculatorPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Setting Routes
    {
      path: 'setting',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Setting]} hasContent>
              <SettingPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Users Routes
    {
      path: 'users',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Users]} hasContent>
              <UsersPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // User Profile Routes
    {
      path: 'profile/:userId',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Profile]} hasContent>
              <UserProfilePage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Jobs Routes
    {
      path: 'jobs',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Jobs]} hasContent>
              <JobsPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Interview Routes
    {
      path: 'interview',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Interview]} hasContent>
              <InterviewPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Kanban Routes
    {
      path: 'board',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/board/opening' replace />, index: true },
        {
          path: 'opening',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Board]} hasContent>
              <KanbanPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'process',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Board]} hasContent>
              <KanbanPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'done',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Board]} hasContent>
              <KanbanPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'close',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Board]} hasContent>
              <KanbanPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Candidate Routes
    {
      path: 'candidates',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Candidates]} hasContent>
              <CandidatesPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Job Detail Routes
    {
      path: 'job-detail/:jobId',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Jobs]} hasContent>
              <JobDetailPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Recruiter Routes
    {
      path: 'recruiter',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Recruiter]} hasContent>
              <RecruiterPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':recruiterId',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Recruiter]} hasContent>
              <RecruiterProfilePage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // INSTALL APP
    {
      path: 'install',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: <InstallAppPage />,
        },
      ],
    },
    // Manage Routes
    {
      path: 'manage',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'cv',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.ManageCV]} hasContent>
              <ManageCVPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'redeem',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.ManageRedeem]} hasContent>
              <ManageRedeemPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Blog Routes
    {
      path: 'blog',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/blog/list' replace />, index: true },
        {
          path: 'list',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <ListBlogPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'create',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <BlogPostCreatePage />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':slug/edit',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <BlogPostEditPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'post/:slug',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <BlogPostDetailsPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Marketplace router
    {
      path: 'marketplace',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/marketplace/all' replace />, index: true },
        {
          path: 'all',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.MarketCV]} hasContent>
              <MarketCVPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'my-list-cv',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.MyListCV]} hasContent>
              <MyListCVPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'my-purchase-cv',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.MyPurchaseCV]} hasContent>
              <MyPurchaseCVPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Withdraw router
    {
      path: 'transactions',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to='/transactions/withdraw' replace />,
          index: true,
        },
        {
          path: 'withdraw',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Withdraw]} hasContent>
              <WithdrawPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Transfer Data Routes
    {
      path: 'transfer-data',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.TransferData]} hasContent>
              <TransferDataPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    // Summary Hiring Routes
    {
      path: 'summary-hiring',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard
              roles={ROLE_BY_PAGE[PAGES.SummaryHiring]}
              hasContent
            >
              <SummaryHiringPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: 'report',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Report]} hasContent>
              <ReportPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: 'news',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.FetchNews]} hasContent>
              <FetchNewsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'list',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <ListBlogPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'create',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <BlogPostCreatePage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'preview',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <FetchNewsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':slug/edit',
          element: (
            <RoleBasedGuard roles={ROLE_BY_PAGE[PAGES.Blog]} hasContent>
              <BlogPostEditPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':slug',
          element: (
            <RoleBasedGuard
              roles={[
                ...ROLE_BY_PAGE[PAGES.FetchNews],
                ...ROLE_BY_PAGE[PAGES.Blog],
              ]}
              hasContent
            >
              <PostDetailsPage />
            </RoleBasedGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <HomePage />
        </AuthGuard>
      ),
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ])
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/auth/Login')))
// const Register = Loadable(lazy(() => import('pages/auth/Register')))
const VerifyEmail = Loadable(lazy(() => import('pages/auth/VerifyEmail')))
// const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')))
const NewPassword = Loadable(lazy(() => import('pages/auth/NewPassword')))
// const VerifyCode = Loadable(lazy(() => import('pages/auth/VerifyCode')))

// MAIN
const HomePage = Loadable(lazy(() => import('pages/Home')))
const NotFound = Loadable(lazy(() => import('pages/Page404')))

// PAGE
const DashboardPage = Loadable(lazy(() => import('pages/Dashboard')))
const NotificationPage = Loadable(lazy(() => import('pages/Notification')))
const ClientPage = Loadable(lazy(() => import('pages/Client')))
const CalculatorPage = Loadable(lazy(() => import('pages/Calculator')))
const SettingPage = Loadable(lazy(() => import('pages/Setting')))
const UsersPage = Loadable(lazy(() => import('pages/users')))
const UserProfilePage = Loadable(lazy(() => import('pages/users/UserProfile')))
const JobsPage = Loadable(lazy(() => import('pages/job')))
const JobDetailPage = Loadable(lazy(() => import('pages/job/JobDetail')))
const InterviewPage = Loadable(lazy(() => import('pages/Interview')))
const KanbanPage = Loadable(lazy(() => import('pages/Kanban')))
const CandidatesPage = Loadable(lazy(() => import('pages/Candidates')))
const InstallAppPage = Loadable(lazy(() => import('pages/InstallApp')))
const RecruiterPage = Loadable(lazy(() => import('pages/recruiter')))
const RecruiterProfilePage = Loadable(
  lazy(() => import('pages/recruiter/Profile'))
)
const ManageCVPage = Loadable(lazy(() => import('pages/ManageCV')))
const ManageRedeemPage = Loadable(lazy(() => import('pages/ManageRedeem')))
const ListBlogPage = Loadable(lazy(() => import('pages/blog')))
const BlogPostCreatePage = Loadable(
  lazy(() => import('pages/blog/BlogPostCreate'))
)
const BlogPostEditPage = Loadable(lazy(() => import('pages/blog/BlogPostEdit')))
const BlogPostDetailsPage = Loadable(
  lazy(() => import('pages/blog/BlogPostDetails'))
)
const MarketCVPage = Loadable(lazy(() => import('pages/MarketCV')))
const MyListCVPage = Loadable(lazy(() => import('pages/MyListCV')))
const MyPurchaseCVPage = Loadable(lazy(() => import('pages/MyPurchaseCV')))
const WithdrawPage = Loadable(lazy(() => import('pages/Withdraw')))
const TransferDataPage = Loadable(lazy(() => import('pages/TransferData')))
const SummaryHiringPage = Loadable(lazy(() => import('pages/SummaryHiring')))
const KPIBonusPage = Loadable(lazy(() => import('pages/KpiBonus')))
const ReportPage = Loadable(lazy(() => import('pages/Report')))
const FetchNewsPage = Loadable(lazy(() => import('pages/fetch-news')))
const PostDetailsPage = Loadable(
  lazy(() => import('pages/fetch-news/PostDetails'))
)
