const calculator = {
  heading: 'Tính lương',
  dashboard: 'Bảng điều khiển',
  title: 'Công cụ tính lương Gross sang Net / Net sang Gross tiêu chuẩn 2021',
  salary: 'Lương',
  rate: 'Tỷ giá',
  insurance: 'Bảo hiểm',
  full_wage: 'Lương đầy đủ',
  other: 'Khác',
  dependent: 'Người phụ thuộc',
  description: 'Mô tả',
  paid_gross: 'Tổng chi trả của người sử dụng lao động',
  copy: 'Sao chép',
  gross: 'Lương tổng',
  social_insurance: 'Bảo hiểm xã hội',
  health_insurance: 'Bảo hiểm sức khỏe',
  pvi_care: 'Bảo hiểm PVI',
  union_tax: 'Thuế liên hiệp',
  unemployment_insurance: 'Bảo hiểm thất nghiệp',
  taxable_income: 'Thu nhập chịu thuế',
  personal_income_tax: 'Thuế thu nhập cá nhân',
  total_expense: 'Tổng chi phí',
  detail_income_tax: 'Chi tiết thuế thu nhập các nhân',
  taxable_rate: 'Mục chịu thuế',
  gross_salary: 'Tổng tiền lương',
  net_salary: 'Lương Net',
  tax: 'Thuế',
  money: 'Tiền',
  detail_taxable_rate1: 'Lên đến 5 triệu VND',
  detail_taxable_rate2: 'Trên 5 triệu VND đến 10 triệu VND',
  detail_taxable_rate3: 'Trên 10 triệu VND đến 18 triệu VND',
  detail_taxable_rate4: 'Trên 18 triệu VND đến 32 triệu VND',
  detail_taxable_rate5: 'Trên 32 triệu VND đến 52 triệu VND',
  detail_taxable_rate6: 'Trên 52 triệu VND đến 80 triệu VND',
  detail_taxable_rate7: 'Trên 80 triệu VND',
  gross_to_net: 'GROSS → NET',
  net_to_gross: 'NET → GROSS',
  VND: 'VND',
  SGD: 'SGD',
  PVI: 'PVI',
  salaryRequired: 'Lương là bắt buộc',
  insuranceAmountCheck:
    'Số tiền bảo hiểm phải trong khoảng mức bảo hiểm cơ bản ({{baseSalaryInsurance}}) và số tiền lương',
}

export default calculator
