const report = {
  heading: 'Report problem',
  reportDetail: 'Report Detail',
  reportProblem: 'Report A Problem',
  subject: 'Subject',
  content: 'Content',
  image: 'Image',
  timeSent: 'Time sent',
  action: 'Action',
  subjectRequired: 'Subject is required',
  contentRequired: 'Content is required',
  createReportSuccess: 'Create report success',
}

export default report
