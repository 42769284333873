const notification = {
  heading: 'List notification',
  message: 'Message',
  time: 'Time',
  viewDetail: 'View Detail',
  detail: 'Detail',
  name: 'Name',
  user: 'User',
  type: 'Type',
  timeStartCard: 'Time start Card',
  timeEndCard: 'Time end Card',
  assignCard: 'Assign Card',
  assignJob: 'Assign Job',
  assignTask: 'Assign Task',
  jobOverTime: 'Job Over Time',
  updatePoint: 'Update Point',
  updateStatusCandidate: 'Update Status Candidate',
  redeemPoint: 'Redeem Point',
  buyCV: 'Buy CV',
  sellCV: 'Sell CV',
  verifyCV: 'Verify CV',
  uploadCV: 'Upload CV',
  markDoneCard: 'Mark done card',
  markAsReadSuccess: 'Mark as read success',
  markAsReadFail: 'Mark as read fail',
  referUser: 'Refer User',
  commentCard: 'Comment Card',
  clientRequestJob: 'Client Job Request',
  reportProblem: 'Report Problem',
  quizGame: 'Quiz Game',
}

export default notification
