import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_LANES,
  API_LIST_BONUS_POINTS,
  API_REDEEM_CONFIG_POINTS,
  API_REFERRAL_SETTING,
  API_SYSTEM,
} from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'
import { fNumberWithDot } from 'utils/formatNumber'
import uuidv4 from 'utils/uuidv4'

export const getAllSystemSettings = createAsyncThunk(
  'setting/getAllSystemSettings',
  async () => {
    const response = await _getApi(`${API_SYSTEM}`)
    return response.data?.result || {}
  }
)

export const updateSystemSettings = createAsyncThunk(
  'setting/updateSystemSettings',
  async (data) => {
    const url = `${API_SYSTEM}`
    const response = await _patchApi(url, data)
    return response?.data?.success ? response.data : []
  }
)

export const getRedeemPointSetting = createAsyncThunk(
  'setting/getRedeemPointSetting',
  async (queries) => {
    const response = await _getApi(
      `${API_REDEEM_CONFIG_POINTS}?${qs.stringify(queries)}`
    )

    return response?.data?.configPoint || {}
  }
)

export const getReferralSettingPoint = createAsyncThunk(
  'setting/getReferralSettingPoint',
  async () => {
    const response = await _getApi(API_REFERRAL_SETTING)

    return response?.data?.referSetting || {}
  }
)

export const updateReferralSettingPoint = createAsyncThunk(
  'setting/updateReferralSettingPoint',
  async ({ data }) => {
    try {
      await _patchApi(API_REFERRAL_SETTING, data)
    } catch (error) {
      throw new Error(error?.message || error?.data?.message)
    }
  }
)

export const updateRedeemPointSetting = createAsyncThunk(
  'setting/updateRedeemPointSetting',
  async ({ data }) => {
    const response = await _patchApi(API_REDEEM_CONFIG_POINTS, data)

    return response?.data?.configPoint || {}
  }
)

export const getAllLanes = createAsyncThunk('setting/getAllLanes', async () => {
  const response = await _getApi(API_LANES)
  return response?.data?.lane || []
})

export const getListBonusPoints = createAsyncThunk(
  'setting/getListBonusPoints',
  async (queries) => {
    const response = await _getApi(
      `${API_LIST_BONUS_POINTS}?${qs.stringify(queries)}`
    )
    return response?.data?.list || []
  }
)

export const updateBonusPointSetting = createAsyncThunk(
  'setting/updateBonusPointSetting',
  async (data) => {
    const response = await _patchApi(API_LIST_BONUS_POINTS, data)
    return response?.data?.config || []
  }
)

export const getUploadCVPoint = createAsyncThunk(
  'setting/getUploadCVPoint',
  async (queries) => {
    const response = await _getApi(
      `${API_REDEEM_CONFIG_POINTS}?${qs.stringify(queries)}`
    )
    return response?.data?.configPoint || {}
  }
)

export const updateUploadCVPoint = createAsyncThunk(
  'setting/updateUploadCVPoint',
  async ({ data }) => {
    const response = await _patchApi(API_REDEEM_CONFIG_POINTS, data)
    return response?.data?.configPoint || {}
  }
)

const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    data: {},
    isLoading: false,
    redeemConfigPoint: {
      data: {},
      isLoading: false,
    },
    referralSettingPoint: {
      data: [],
      isLoading: false,
    },
    listLaneOptions: [],
    bonusConfigPoint: {
      data: [],
      isLoading: false,
    },
    uploadCVPoint: {
      data: {},
      isLoading: false,
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllSystemSettings.pending, (state) => {
        state.isLoading = true
        state.data = {}
      })
      .addCase(getAllSystemSettings.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload
      })
      .addCase(getAllSystemSettings.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getReferralSettingPoint.pending, (state) => {
        state.referralSettingPoint.isLoading = true
        state.referralSettingPoint.data = []
      })
      .addCase(getReferralSettingPoint.fulfilled, (state, action) => {
        const { receiverRefer = 0, payload = [] } = action.payload || {}

        state.referralSettingPoint.isLoading = false
        state.referralSettingPoint.data =
          payload?.map(({ userRefer = 0 }, index) => ({
            userRefer: fNumberWithDot(userRefer),
            receiverRefer: index === 0 ? fNumberWithDot(receiverRefer) : '0',
            uuid: uuidv4(),
          })) || []
      })
      .addCase(getReferralSettingPoint.rejected, (state) => {
        state.referralSettingPoint.isLoading = false
      })
      .addCase(getRedeemPointSetting.pending, (state) => {
        state.redeemConfigPoint.isLoading = true
        state.redeemConfigPoint.data = {}
      })
      .addCase(getRedeemPointSetting.fulfilled, (state, action) => {
        state.redeemConfigPoint.isLoading = false
        state.redeemConfigPoint.data = action.payload
      })
      .addCase(getRedeemPointSetting.rejected, (state) => {
        state.redeemConfigPoint.isLoading = false
      })
      .addCase(updateRedeemPointSetting.fulfilled, (state, action) => {
        state.redeemConfigPoint.data = action.payload
      })
      .addCase(getAllLanes.fulfilled, (state, action) => {
        state.listLaneOptions = (action.payload || [])
          .filter((option) => option?.disabled === false)
          .map(({ id, nameColumn }) => ({
            value: id,
            label: nameColumn,
          }))
      })
      .addCase(getListBonusPoints.pending, (state) => {
        state.bonusConfigPoint.isLoading = true
      })
      .addCase(getListBonusPoints.fulfilled, (state, action) => {
        state.bonusConfigPoint.isLoading = false
        state.bonusConfigPoint.data = action.payload
      })
      .addCase(getListBonusPoints.rejected, (state) => {
        state.bonusConfigPoint.isLoading = false
        state.bonusConfigPoint.data = []
      })
      .addCase(getUploadCVPoint.pending, (state) => {
        state.uploadCVPoint.isLoading = true
        state.uploadCVPoint.data = {}
      })
      .addCase(getUploadCVPoint.fulfilled, (state, action) => {
        state.uploadCVPoint.isLoading = false
        state.uploadCVPoint.data = action.payload
      })
      .addCase(getUploadCVPoint.rejected, (state) => {
        state.uploadCVPoint.isLoading = false
      })
      .addCase(updateUploadCVPoint.fulfilled, (state, action) => {
        state.uploadCVPoint.data = action.payload
      })
  },
})

export const selectRedeemConfigPoint = createSelector(
  [(state) => state.setting.redeemConfigPoint],
  (redeemConfigPoint) => redeemConfigPoint
)

export const selectReferralSettingPoint = createSelector(
  [(state) => state.setting.referralSettingPoint],
  (referralSettingPoint) => referralSettingPoint
)

export const selectAllLaneOptions = createSelector(
  [(state) => state.setting.listLaneOptions],
  (listLaneOptions) => listLaneOptions
)

export const selectListBonusPoints = createSelector(
  [(state) => state.setting.bonusConfigPoint],
  (bonusConfigPoint) => bonusConfigPoint
)

export const selectUploadCVPoint = createSelector(
  [(state) => state.setting.uploadCVPoint],
  (uploadCVPoint) => uploadCVPoint
)

export default settingSlice.reducer
