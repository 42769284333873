import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  API_LIST_REDEEM_BONUS,
  API_LIST_REDEEM_RECRUITER,
  API_REDEEM_POINT,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const getInfoRedeem = createAsyncThunk(
  'withdraw/getInfoPoint',
  async (listRedeemQueries) => {
    const response = await _getApi(API_LIST_REDEEM_RECRUITER, {
      params: listRedeemQueries,
    })

    return response?.data || {}
  }
)

export const redeemPoint = createAsyncThunk(
  'withdraw/redeemPoint',
  async (data) => {
    const response = await _postApi(API_REDEEM_POINT, data)

    if (!response?.data?.success) {
      throw new Error(response?.error || response?.data?.error)
    }
  }
)

export const cancelRedeemPoint = createAsyncThunk(
  'withdraw/cancelRedeemPoint',
  async (redeemId) => {
    const response = await _patchApi(
      `${API_LIST_REDEEM_BONUS}/${redeemId}/cancel-redeem-point`
    )

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

const initialState = {
  infoRedeem: {
    isLoading: false,
    data: {},
  },
}

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInfoRedeem.pending, (state) => {
        state.infoRedeem.isLoading = true
      })
      .addCase(getInfoRedeem.fulfilled, (state, action) => {
        state.infoRedeem.isLoading = false
        state.infoRedeem.data = action.payload
      })
      .addCase(getInfoRedeem.rejected, (state) => {
        state.infoRedeem.isLoading = false
      })
  },
})

export default withdrawSlice.reducer

export const selectInfoPoint = createSelector(
  [(state) => state.withdraw.infoRedeem],
  (infoRedeem) => infoRedeem
)
