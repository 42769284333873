const quiz = {
  youEarn: 'Bạn nhận được',
  point: 'điểm',
  points: 'điểm',
  totalScore: 'Tổng số điểm',
  detailResult: 'Kết quả chi tiết',
  home: 'Home',
  playNow: 'Chơi ngay',
  completation: 'Tỷ lệ đúng',
  totalQuestion: 'Tổng câu hỏi',
  correct: 'Câu đúng',
  wrong: 'Câu sai',
  quizGameTitle: `Trả lời câu hỏi với`,
  dino: 'Dino',
  confirmSkipGameMessage: `Nếu bạn bỏ qua trò chơi thì bạn sẽ không thể chơi lại trong ngày hôm nay. Bạn có chắc muốn tắt ngay bây giờ?`,
  confirmFinishGameMessage: `Bạn mới chỉ trả lời {{numberOfAnswer}} trên {{numberOfQuestion}} câu hỏi. Bạn có chắc muốn kết thúc ngay bây giờ?`,
  confirmCloseGameMessage: 'Bạn có chắc muốn đóng trò chơi không?',
  skipGameMessage: `Không hiển thị lại`,
  runOutOfPlayGame: 'Bạn đã hết lượt chơi trong ngày hôm nay',
  submitQuizFailed: 'Gửi câu trả lời thất bại. Vui lòng thử lại!',
  gameRules: 'Luật chơi',
  totalQuestionRule: 'Mỗi ngày bạn sẽ được chơi tối đa 5 câu hỏi.',
  typeOfAnswerRule: 'Ở mỗi câu hỏi bạn có thể chọn một hoặc nhiều câu trả lời.',
  pointEarnRule:
    'Với mỗi câu hỏi được trả lời đúng, bạn sẽ nhận số điểm tương ứng.',
  typeOfGift: 'Số điểm này bạn có thể đổi sang tiền mặt hoặc quà.',
}

export default quiz
