import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_REPORT } from 'routes/api'

import { _getApi, _uploadApi } from 'utils/axios'

export const getListReports = createAsyncThunk(
  'report/getListReports',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_REPORT}/my-report?${qs.stringify(queries)}`
    )

    return response?.data || {}
  }
)

export const createReport = createAsyncThunk(
  'report/createReport',
  async (data) => {
    const response = await _uploadApi(API_REPORT, data)

    if (!response?.data?.success)
      throw new Error(response?.data?.message || response?.data?.error)
  }
)

const initialState = {
  listReports: {
    isLoading: false,
    data: {},
  },
}

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListReports.pending, (state) => {
        state.listReports.isLoading = true
        state.listReports.data = {}
      })
      .addCase(getListReports.fulfilled, (state, action) => {
        state.listReports.isLoading = false
        state.listReports.data = action.payload
      })
      .addCase(getListReports.rejected, (state) => {
        state.listReports.isLoading = false
      })
  },
})

export const selectListReports = createSelector(
  [(state) => state.report.listReports],
  (listReports) => listReports
)

export default reportSlice.reducer
