import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { API_ADMIN_INTERVIEW } from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'

export const getInterviewDetail = createAsyncThunk(
  'feedbackForm/getInterviewDetail',
  async (interviewId) => {
    try {
      const response = await _getApi(`${API_ADMIN_INTERVIEW}/${interviewId}`)

      if (!response?.data?.success)
        throw new Error(response?.message || response?.data?.message)

      return response?.data?.interview
    } catch (error) {
      throw new Error(error?.message)
    }
  }
)

export const updateInterviewFeedback = createAsyncThunk(
  'feedbackForm/updateInterviewFeedback',
  async ({ interviewId = '', data }) => {
    try {
      const response = await _patchApi(
        `${API_ADMIN_INTERVIEW}/${interviewId}`,
        data
      )

      if (!response?.data?.success)
        throw new Error(response?.message || response?.data?.message)
    } catch (error) {
      throw new Error(error?.message)
    }
  }
)

const initialState = {
  interviewDetail: {
    isLoading: false,
    data: {},
  },
}

const feedbackFormSlice = createSlice({
  name: 'feedbackForm',
  initialState,
  reducers: {
    resetInterviewDetail(state) {
      state.interviewDetail = {
        isLoading: false,
        data: {},
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getInterviewDetail.pending, (state) => {
        state.interviewDetail.isLoading = true
      })
      .addCase(getInterviewDetail.fulfilled, (state, action) => {
        state.interviewDetail.isLoading = false
        state.interviewDetail.data = action.payload || {}
      })
      .addCase(getInterviewDetail.rejected, (state) => {
        state.interviewDetail.isLoading = false
      })
  },
})

export const { resetInterviewDetail } = feedbackFormSlice.actions

export const selectInterviewDetail = createSelector(
  [(state) => state.feedbackForm.interviewDetail],
  (interviewDetail) => interviewDetail
)

export default feedbackFormSlice.reducer
