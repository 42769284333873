import {
  META_DESCRIPTION_MAX_CHARACTER,
  META_TITLE_MAX_CHARACTER,
} from 'components/post-form/config'

const blog = {
  addNewPost: 'Add new post',
  editPost: 'Edit post',
  deleteSuccess: 'Delete post success!',
  deleteFail: 'Delete post fail!',
  confirmDeleteMessage: 'Are you sure you want to delete',
  confirmShareMessage: 'Are you sure you want to toggle share',
  toggleShareSuccess: "Toggle share Fetch News success",
  toggleShareFail: "Toggle share Fetch News fail",
  changePinStatusSuccess: "Change pin status success",
  changePinStatusFail: "Change pin status fail",
  from: 'from',
  post: 'Post',
  preview: 'Preview',
  coverImage: 'Cover image',
  postTitle: 'Post title',
  description: 'Description',
  content: 'Content',
  publish: 'Publish',
  enableComments: 'Enable comments',
  tag: 'Tag',
  tags: 'Tags',
  metaTitle: 'Meta title',
  metaDescription: 'Meta description',
  metaKeywords: 'Meta keywords',
  characterRemainMessage: 'characters remaining.',
  postSuccess: 'Post success!',
  postFail: 'Post failed!',
  updateBlogSuccess: 'Update blog success!',
  updateBlogFail: 'Update blog failed!',
  titleRequired: 'Title is required',
  descriptionRequired: 'Description is required',
  contentRequired: 'Content is required',
  coverImageRequired: 'Cover image is required',
  tagsRequired: 'Tags is required',
  metaTitleRequired: 'Meta title is required',
  metaTitleMaxCharacters: `Meta title must be at most ${META_TITLE_MAX_CHARACTER} characters`,
  metaDescriptionRequired: 'Meta description is required',
  metaDescriptionMaxCharacters: `Meta description must be at most ${META_DESCRIPTION_MAX_CHARACTER} characters`,
  metaKeywordsRequired: 'Meta keywords is required',
  createTag: 'Create new tag',
  newTag: 'New tag',
  tagExist: 'Tag exists!',
  createTagSuccess: 'Create tag success!',
  createTagFailed: 'Create tag failed!',
  createKeyword: 'Create new keyword',
  newKeyword: 'New keyword',
  keywordExist: 'Keyword exists!',
  createKeywordSuccess: 'Create keyword success!',
  createKeywordFailed: 'Create keyword failed!',
  uploadImageFailed: 'Upload image failed!',
  previewPost: 'Preview Post',
  dropSelectImageMessage: 'Drop or Select cover image',
  dropOrClickMessage: 'Drop image here or click',
  thoroughMachineMessage: 'thorough your machine',
  metaTitleMessage:
    'This is the page title that Google and most other search engines show in search results.',
  metaDescriptionMessage:
    'The meta description summarizes the page content. Search engines often use it for the snippet in search results.',
  metaKeywordsMessage:
    'Meta keywords are meta tags that you can use to give search engines more information about a page content.',
  createdTime: 'Created time',
  lastestUpdate: 'Lastest update',
  imageCoverMessage:
    'Recommended that you use an image size with a ratio of 1200 x 628px for the best display to SEO',
  relatedPosts: 'Related posts',
}

export default blog
