import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import 'react-lazy-load-image-component/src/effects/black-and-white.css'
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import loadable from '@loadable/component'
// scroll bar
import 'simplebar/src/simplebar.css'

// contexts
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext'
import { AuthProvider } from 'contexts/JWTContext'
import { NotificationProvider } from 'contexts/NotificationContext'
import { RoleProvider } from 'contexts/RoleContext'
import { SettingsProvider } from 'contexts/SettingsContext'
import { SocketProvider } from 'contexts/SocketContext'

// i18n
import 'locales/i18n'

// redux
import { store } from 'redux/store'

//
import App from './App'

// https://stackoverflow.com/questions/66575667/promise-allsettled-is-not-a-function
if (typeof window.Promise.allSettled === 'undefined') {
  Promise.allSettled =
    Promise.allSettled ||
    ((promises) =>
      Promise.all(
        promises.map((p) =>
          p
            .then((value) => ({
              status: 'fulfilled',
              value,
            }))
            .catch((reason) => ({
              status: 'rejected',
              reason,
            }))
        )
      ))
}

// highlight
const Highlight = loadable.lib(() => import('utils/highlight'))

ReactDOM.render(
  <AuthProvider>
    <RoleProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <SocketProvider>
                  <NotificationProvider>
                    <BrowserRouter>
                      <Highlight />
                      <App />
                    </BrowserRouter>
                  </NotificationProvider>
                </SocketProvider>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </ReduxProvider>
      </HelmetProvider>
    </RoleProvider>
  </AuthProvider>,
  document.getElementById('root')
)
