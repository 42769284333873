import * as Yup from 'yup'

import palette from 'theme/palette'

export const META_BLOG_FIELDS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  KEYWORD: 'keyword',
}

export const PUBLIC_STATUS = {
  ENABLE: 'enabled',
  DISABLE: 'disable',
}

export const FORM_FIELDS = {
  TITLE: 'title',
  IMAGE_COVER: 'imageCover',
  DESCRIPTION: 'description',
  CONTENT: 'content',
  PUBLISH: 'publish',
  // ENABLE_COMMENTS: 'comments',
  TAGS: 'tags',
  META_BLOG: {
    ...Object.keys(META_BLOG_FIELDS).reduce((acc, cur) => {
      const field = META_BLOG_FIELDS[cur] || ''

      return { ...acc, [cur]: `metaBlog.${field}` }
    }, {}),
    INDEX: 'metaBlog',
  },
  PIN: 'pin',
}

export const DEFAULT_PIN_STATUS = 'un_pin'
export const MAIN_PIN_STATUS = 'main_pin'
export const SECONDARY_PIN_STATUS = 'secondary_pin'

export const PIN_OPTIONS = [
  {
    label: 'Main Pin',
    value: MAIN_PIN_STATUS,
  },
  {
    label: 'Secondary Pin',
    value: SECONDARY_PIN_STATUS,
  },
  {
    label: 'Un Pin',
    value: DEFAULT_PIN_STATUS,
  },
]

const PIN_OPTIONS_VALUES = PIN_OPTIONS.map((item) => item.value)

export const PIN_COLORS = {
  main_pin: {
    backgroundColor: palette.light.error.main,
    color: 'common.white',
    border: 'none',
  },
  secondary_pin: {
    backgroundColor: palette.light.warning.main,
    color: 'common.white',
    border: 'none',
  },
  un_pin: {
    backgroundColor: palette.light.grey[200],
    color: 'grey.900',
    border: '1px solid text.primary',
  },
}

export const META_BLOG_DEFAULT_VALUES = {
  [META_BLOG_FIELDS.TITLE]: '',
  [META_BLOG_FIELDS.DESCRIPTION]: '',
  [META_BLOG_FIELDS.KEYWORD]: [],
}

export const FORM_FIELDS_DEFAULT_VALUES = {
  [FORM_FIELDS.TITLE]: '',
  [FORM_FIELDS.IMAGE_COVER]: null,
  [FORM_FIELDS.DESCRIPTION]: '',
  [FORM_FIELDS.CONTENT]: '',
  [FORM_FIELDS.PUBLISH]: true,
  [FORM_FIELDS.TAGS]: [],
  [FORM_FIELDS.PIN]: DEFAULT_PIN_STATUS,
  [FORM_FIELDS.META_BLOG.INDEX]: META_BLOG_DEFAULT_VALUES,
}

export const NewSchema = ({ translate }) =>
  Yup.object().shape({
    [FORM_FIELDS.TITLE]: Yup.string().required(
      translate('pages.blog.titleRequired')
    ),
    [FORM_FIELDS.IMAGE_COVER]: Yup.mixed().required(
      translate('pages.blog.coverImageRequired')
    ),
    [FORM_FIELDS.DESCRIPTION]: Yup.string().required(
      translate('pages.blog.descriptionRequired')
    ),
    [FORM_FIELDS.CONTENT]: Yup.string()
      .min(1000)
      .required(translate('pages.blog.contentRequired')),
    [FORM_FIELDS.PIN]: Yup.string().oneOf(PIN_OPTIONS_VALUES),
  })

export const BlogSchema = ({ translate }) =>
  Yup.object().shape({
    [FORM_FIELDS.TITLE]: Yup.string().required(
      translate('pages.blog.titleRequired')
    ),
    [FORM_FIELDS.IMAGE_COVER]: Yup.mixed().required(
      translate('pages.blog.coverImageRequired')
    ),
    [FORM_FIELDS.DESCRIPTION]: Yup.string().required(
      translate('pages.blog.descriptionRequired')
    ),
    [FORM_FIELDS.CONTENT]: Yup.string()
      .min(1000)
      .required(translate('pages.blog.contentRequired')),
    [FORM_FIELDS.TAGS]: Yup.array()
      .of(Yup.object())
      .min(1, translate('pages.blog.tagsRequired'))
      .required(translate('pages.blog.tagsRequired')),
    [FORM_FIELDS.META_BLOG.INDEX]: Yup.object().shape({
      [META_BLOG_FIELDS.TITLE]: Yup.string()
        .max(
          META_TITLE_MAX_CHARACTER,
          translate('pages.blog.metaTitleMaxCharacters')
        )
        .required(translate('pages.blog.metaTitleRequired')),
      [META_BLOG_FIELDS.DESCRIPTION]: Yup.string()
        .max(
          META_DESCRIPTION_MAX_CHARACTER,
          translate('pages.blog.metaDescriptionMaxCharacters')
        )
        .required(translate('pages.blog.metaDescriptionRequired')),
      [META_BLOG_FIELDS.KEYWORD]: Yup.array()
        .of(Yup.string())
        .min(1, translate('pages.blog.metaKeywordsRequired'))
        .required(translate('pages.blog.metaKeywordsRequired')),
    }),
  })

export const META_TITLE_MAX_CHARACTER = 70

export const META_DESCRIPTION_MAX_CHARACTER = 150

export const MIN_CHARACTER_WARNING = 11
