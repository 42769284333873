const manageCV = {
  process: 'Chờ xử lý',
  done: 'Hoàn thành',
  rejected: 'Từ chối',
  reject: 'Từ chối',
  nameCandidate: 'Tên ứng viên',
  detailCandidate: 'Thông tin ứng viên',
  uploadCV: 'Tải lên CV',
  public_cv: 'Danh sách CV',
  your_cv: 'CV của bạn',
  name: 'Tên',
  phone: 'Điện thoại',
  email: 'Email',
  time: 'Thời gian',
  point: 'Điểm',
  recruiterUpload: 'Người tải lên',
  amountPoint: 'Số điểm',
  source: 'Nguồn',
  status: 'Trạng thái',
  actions: 'Hành động',
  takeNote: 'Ghi chú',
  share: 'Chia sẻ',
  cannotShare: 'Bạn không thể bán CV này khi điểm bằng 0',
  action: 'Hành động',
  skill: 'Kỹ năng',
  location: 'Địa điểm',
  linkCV: 'Liên kết CV',
  rawCV: 'Xem CV',
  position: 'Vị trí',
  approve: 'Chấp nhận',
  active: 'Đã xử lý',
  approveCV: 'Bạn có muốn chấp nhận CV ',
  rejectCV: 'Bạn có muốn từ chối CV ',
  approveSuccess: 'Chấp nhận CV thành công',
  approveFail: 'Chấp nhận CV thất bại',
  rejectSuccess: 'Từ chối CV thành công',
  rejectFail: 'Từ chối CV thất bại',
  confirmReject: 'Vui lòng nhập lý do từ chối',
  timeStart: 'Thời gian bắt đầu',
  timeEnd: 'Thời gian kết thúc',
  price: 'Giá',
  see: 'Xem',
  buyCV: 'Mua CV',
  buySuccess: 'Mua CV thành công!',
  buyCVFail: 'Mua CV thất bại!',
  transactionMessage:
    'Giao dịch của bạn đang được xử lý, vui lòng đợi giây lát',
  confirmBuyMessage: 'Bạn có chắc chắn muốn mua CV',
  uploadCVTitle: 'Tải lên CV của bạn',
  uploadCVHelperText: 'Cho phép file *.pdf kích thước tối đa 5 MB',
  dateOfBirth: 'Ngày sinh',
  gender: 'Giới tính',
  address: 'Địa chỉ',
  experience: 'Kinh nghiệm làm việc',
  deleteCVSuccess: 'Xóa CV thành công!',
  deleteCVFail: 'Xóa CV thất bại!',
  deleteCVMessage: 'Bạn có chắc chắn muốn xóa CV này?',
  shareCVSuccess: 'Chia sẻ CV thành công!',
  shareCVFail: 'Chia sẻ CV thất bại!',
  shareCVMessage: 'Bạn có chắc chắn muốn chia sẻ CV này?',
  unShare: 'Dừng chia sẻ',
  unShareCVSuccess: 'Dừng chia sẻ CV thành công!',
  unShareCVFail: 'Dừng chia sẻ CV thất bại!',
  unShareCVMessage: 'Are you sure you want to dừng chia sẻ CV này?',
  uploadCVSuccess: 'Tải lên CV thành công!',
  uploadCVFail: 'Tải lên CV thất bại!',
  updateCVMessage:
    'Bạn phải đợi quản trị viên kiểm tra lại CV khi cập nhật mới',
  updateCVSuccess: 'Cập nhật CV thành công!',
  updateCVFail: 'Cập nhật CV thất bại!',
  nameRequired: 'Tên là bắt buộc',
  birthdayRequired: 'Ngày sinh là bắt buộc',
  phoneRequired: 'Điện thoại là bắt buộc',
  emailInvalid: 'Email không hợp lệ',
  emailRequired: 'Email là bắt buộc',
  skillRequired: 'Kỹ năng là bắt buộc',
  addressRequired: 'Địa chỉ là bắt buộc',
  locationRequired: 'Địa điểm là bắt buộc',
  sellPointRequired: 'Điểm mở khóa là bắt buộc',
  experienceRequired: 'Kinh nghiệm làm việc là bắt buộc',
  languageRequired: 'Ngôn ngữ là bắt buộc',
  sellPointCheck: 'Điểm mở khóa phải lớn hơn 0',
  positionRequired: 'Vị trí là bắt buộc',
  companyRequired: 'Công ty là bắt buộc',
  descriptionRequired: 'Mô tả là bắt buộc',
  yearOfExp: 'Năm kinh nghiệm',
  latestCompany: 'Công ty mới nhất',
  education: 'Học vấn',
  languages: 'Ngôn ngữ',
  company: 'Công ty',
  from: 'Từ',
  to: 'Đến',
  currentJob: 'Công việc hiện tại',
  description: 'Mô tả',
  addWorkExp: 'Thêm kinh nghiệm làm việc',
  timeStartRequired: 'Thời gian bắt đầu là bắt buộc',
  timeEndRequired: 'Thời gian kết thúc là bắt buộc',
  addCertificate: 'Thêm chứng chỉ',
  certificateName: 'Tên chứng chỉ',
  organization: 'Tổ chức',
  year: 'Năm',
  linkCertificate: 'Đường dẫn chứng chỉ',
  certificateNameRequired: 'Tên chứng chỉ là bắt buộc',
  cancelUploadConfirm: 'Bạn sẽ phải tải lại CV nếu bạn hủy bản chỉnh sửa này',
  cvIsRequired: 'CV là bắt buộc',
  cvMustBePdf: 'File không hợp lệ. Chỉ cho phép tải lên file pdf',
  sortBy: 'Sắp xếp theo',
  keyword: 'Từ khoá',
  salaryExpectation: 'Mức lương mong muốn',
  availability: 'Thời gian làm việc',
  thankYouMessage: `Cảm ơn bạn đã thêm hồ sơ ứng viên! Ứng viên của bạn sẽ được Fetch kiểm tra và phê duyệt trong vòng 7 ngày.
  Nếu được chấp thuận thì ứng viên của bạn sẽ được đưa lên thị trường. Nếu bị từ chối, vui lòng cung cấp thêm thông tin để Fetch kiểm tra lại.
  `,
  getJobNow: 'Có thể làm việc ngay',
  afterOneMonth: 'Làm việc sau 1 tháng',
  afterTwoMonth: 'Làm việc sau 2 tháng',
  negotiable: 'Thoả thuận',
  lastUpdate: 'Cập nhật gần nhất',
  hasUpdateThisCandidate: 'Đã cập nhật ứng viên',
  candidateNewActive: 'Ứng viên mới',
  candidateOpenJob: 'Ứng viên đang tìm kiếm công việc',
  checkShareCVFail: 'Thông tin của ứng viên đã tồn tại trên hệ thống',
  feedbackCV: 'Nhận xét CV',
}

export default manageCV
