import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_BANK_RECRUITER,
  API_RECRUITER,
  API_REPORT,
  API_USER,
} from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'

export const getRecruiter = createAsyncThunk(
  'recruiter/getRecruiter',
  async (queries = {}) => {
    const url = `${API_RECRUITER}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || []
  }
)

export const toggleActiveUser = createAsyncThunk(
  'recruiter/toggleActiveUser',
  async ({ userId, data = {} }) => {
    const response = await _patchApi(`${API_USER}/${userId}/active`, data)

    return response?.data || {}
  }
)

export const getRecruiterDetail = createAsyncThunk(
  'recruiter/getRecruiterDetail',
  async ({ recruiterId }) => {
    const response = await _getApi(`${API_BANK_RECRUITER}/${recruiterId}`)

    return response?.data || {}
  }
)

export const getCandidateRecruiter = createAsyncThunk(
  'recruiter/getCandidateRecruiter',
  async ({ recruiterId }) => {
    const response = await _getApi(`${API_RECRUITER}/${recruiterId}/candidate`)

    return response?.data || []
  }
)

export const editRecruiterPoint = createAsyncThunk(
  'recruiter/editRecruiterPoint',
  async ({ recruiterId, data }) => {
    const url = `${API_RECRUITER}/${recruiterId}/point`
    const response = await _patchApi(url, data)

    return response?.data || {}
  }
)

export const getListHistoryPoint = createAsyncThunk(
  'recruiter/getListHistoryPoint',
  async ({ recruiterId, queries = {} }) => {
    const url = `${API_RECRUITER}/${recruiterId}/point`
    const response = await _getApi(url, { params: queries })

    return response?.data || {}
  }
)

export const getListReportsDirector = createAsyncThunk(
  'recruiter/getListReportsDirector',
  async (queries) => {
    const response = await _getApi(`${API_REPORT}?${qs.stringify(queries)}`)

    if (!response?.data?.success)
      throw new Error(response?.data?.message || response?.data?.error)

    return response?.data || {}
  }
)

const initialState = {
  listRecruiter: {
    isLoading: false,
    data: [],
  },
  recruiterDetail: {
    isLoading: false,
    data: {},
  },
  listCandidateRecruiter: {
    isLoading: false,
    data: {},
  },
  listHistoryBonus: {
    isLoading: false,
    data: {},
  },
  listReports: {
    isLoading: false,
    data: {},
  },
}

export const recruiterSlice = createSlice({
  name: 'recruiter',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRecruiter.pending, (state) => {
        state.listRecruiter.isLoading = true
      })
      .addCase(getRecruiter.fulfilled, (state, action) => {
        state.listRecruiter.isLoading = false
        state.listRecruiter.data = action.payload || []
      })
      .addCase(getRecruiter.rejected, (state) => {
        state.listRecruiter.isLoading = false
      })
      .addCase(getRecruiterDetail.pending, (state) => {
        state.recruiterDetail.isLoading = true
      })
      .addCase(getRecruiterDetail.fulfilled, (state, action) => {
        state.recruiterDetail.isLoading = false
        state.recruiterDetail.data = action.payload || {}
      })
      .addCase(getRecruiterDetail.rejected, (state) => {
        state.recruiterDetail.isLoading = false
      })
      .addCase(getCandidateRecruiter.pending, (state) => {
        state.listCandidateRecruiter.isLoading = true
      })
      .addCase(getCandidateRecruiter.fulfilled, (state, action) => {
        state.listCandidateRecruiter.isLoading = false
        state.listCandidateRecruiter.data = action.payload || {}
      })
      .addCase(getCandidateRecruiter.rejected, (state) => {
        state.listCandidateRecruiter.isLoading = false
      })
      .addCase(getListHistoryPoint.pending, (state) => {
        state.listHistoryBonus.isLoading = true
      })
      .addCase(getListHistoryPoint.fulfilled, (state, action) => {
        state.listHistoryBonus.isLoading = false
        state.listHistoryBonus.data = action.payload
      })
      .addCase(getListHistoryPoint.rejected, (state) => {
        state.listHistoryBonus.isLoading = false
      })
      .addCase(toggleActiveUser.fulfilled, (state, action) => {
        const { userId = '', success = false } = action.payload || {}
        const recruiter = state.listRecruiter?.data?.list?.find(
          (item) => item.id === userId
        )

        if (!success || !recruiter) return

        recruiter.isActive = action.meta.arg.data.isActive
      })
      .addCase(getListReportsDirector.pending, (state) => {
        state.listReports.isLoading = true
        state.listReports.data = {}
      })
      .addCase(getListReportsDirector.fulfilled, (state, action) => {
        state.listReports.isLoading = false
        state.listReports.data = action.payload
      })
  },
})

export const { updateActive } = recruiterSlice.actions

export const selectListRecruiter = createSelector(
  [(state) => state.recruiter.listRecruiter],
  (listRecruiter) => listRecruiter
)

export const selectRecruiterDetail = createSelector(
  [(state) => state.recruiter.recruiterDetail],
  (recruiterDetail) => recruiterDetail
)

export const selectListCandidateRecruiter = createSelector(
  [(state) => state.recruiter.listCandidateRecruiter],
  (listCandidateRecruiter) => listCandidateRecruiter
)

export const selectListHistoryBonus = createSelector(
  [(state) => state.recruiter.listHistoryBonus],
  (listHistoryBonus) => listHistoryBonus
)

export const selectListReportsDirectior = createSelector(
  [(state) => state.recruiter.listReports],
  (listReports) => listReports
)

export default recruiterSlice.reducer
