const setting = {
  bitlyConfig: 'Cấu Hình Bitly',
  driverConfig: 'Cấu Hình Driver',
  zoomConfig: 'Cấu Hình Zoom',
  secretKey: 'Khóa Bí Mật',
  refineCV: 'Sàng lọc CV',
  account: 'Tài Khoản',
  folderCv: 'Thư Mục CV',
  token: 'Token',
  id: 'ID',
  configSettings: 'Cài Đặt Cấu Hình',
  configMessage: 'Thiết Lập Cấu Hình Admin',
  updateSuccess: 'Cập nhật cấu hình thành công!',
  updateFail: 'Cập nhật cấu hình thất bại!',
  required: 'Required',
  redeem: 'Cài đặt đổi điểm',
  bonus: 'Cài đặt điểm thưởng',
  system: 'Cài đặt cấu hình',
  minPointRequired: 'Điểm tối thiểu là bắt buộc',
  maxPointRequired: 'Điểm tối đa là bắt buộc',
  minPointMustNumber: 'Điểm tối thiểu phải là dạng số',
  maxPointMustNumber: 'Điểm tối đa phải là dạng số',
  minPointMustInteger: 'Điểm tối thiểu phải là số nguyên',
  maxPointMustInteger: 'Điểm tối đa phải là số nguyên',
  minPointNotNegative: 'Điểm tối thiểu phải lớn hơn hoặc bằng 0',
  maxPointNotNegative: 'Điểm tối đa phải lớn hơn hoặc bằng 0',
  minimumPointRedeem: 'Điểm tối thiểu có thể rút',
  maximumPointRedeem: 'Điểm tối đa có thể rút',
  updatePointSuccess: 'Cập nhật điểm thành công!',
  updatePointFail: 'Cập nhật điểm thất bại!',
  statusRequired: 'Trạng thái là bắt buộc',
  pointMustNumber: 'Điểm phải là dạng số ',
  pointNotNegative: 'Điểm phải lớn hơn hoặc bằng 0',
  titleRequired: 'Tiêu đề là bắt buộc',
  pointRequired: 'Điểm là bắt buộc',
  updateBonusPointSuccess: 'Cập nhật điểm thành công!',
  updateBonusPointFail: 'Cập nhật điểm thất bại!',
  point: 'Điểm',
  status: 'Trạng thái',
  title: 'Tiêu đề',
  pointMustInteger: 'Điểm phải là số nguyên',
  pointRedeemTitle: 'Thiết lập điểm đổi thưởng',
  pointInviteUserTitle: 'Thiết lập điểm thưởng cho mời ngườii dùng mới',
  level: 'Cấp độ',
  userReferrer: 'Người giới thiệu (người dùng A)',
  referrerReceiver: 'Người nhận giới thiệu (người dùng B)',
  updateReferralPointSuccess: 'Cập nhật cài đặt điểm giới thiệu thành công!',
  updateReferralPointFail: 'Cập nhật cài đặt điểm giới thiệu thất bại!',
  pointUploadCVSuccessToMarketplace: 'Thiết lập điểm thưởng khi tải CV lên chợ',
  uploadCvPoint: 'Điểm thưởng tải lên CV',
  turnOnUploadCVPointSuccess:
    'Thiết lập bật điểm thưởng tải lên CV thành công!',
  turnOffUploadCVPointSuccess:
    'Thiết lập tắt điểm thưởng tải lên CV thành công!',
  turnOnUploadCVPointFailed: 'Thiết lập bật điểm thưởng tải lên CV thất bại!',
  turnOffUploadCVPointFailed: 'Thiết lập tắt điểm thưởng tải lên CV thất bại!',
  turnOnBonusPointForUploadCV: 'Bật điểm thưởng khi đưa CV lên chợ',
  turnOffBonusPointForUploadCV: 'Tắt điểm thưởng khi đưa CV lên chợ',
  updateUploadCVPointSuccess: 'Cập nhật điểm thưởng tải lên CV thành công!',
  updateUploadCVPointFailed: 'Cập nhật điểm thưởng tải lên CV thất bại!',
}

export default setting
