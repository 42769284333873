const users = {
  heading: 'List users',
  name: 'Name',
  user: 'User',
  team: 'Team',
  client: 'Client',
  leader: 'Leader',
  role: 'Role',
  salaryGross: 'Salary (Gross)',
  position: 'Position',
  actions: 'Actions',
  email: 'Email',
  password: 'Password',
  startDate: 'Start date',
  addNewUser: 'Add New User',
  editUser: 'Edit User',
  addNewTeam: 'Add New Team',
  editTeam: 'Edit Team',
  addNewClient: 'Add New Client',
  editClient: 'Edit Client',
  addMember: 'Add member',
  listMembers: 'List members',
  saveChange: 'Save change',
  clientRequired: 'Client is required',
  nameRequired: 'Name is required',
  leaderRequired: 'Leader is required',
  emailValid: 'Email must be a valid email address',
  emailRequired: 'Email is required',
  passwordRequired: 'Password is required',
  passwordMin: 'Password must be at least 8 characters',
  passwordMax: 'Password is limited 16 characters',
  salaryMin: 'Salary must not be less than 1',
  startDateRequired: 'Start date is required',
  startDateGreaterThanCreatedAt:
    'Start date must be greater than user created date ({{createdDate}})',
  roleRequired: 'Role is required',
  createSuccess: 'Create user success!',
  updateSuccess: 'Update user success!',
  toggleActiveUserSuccess: 'Toggle active user success!',
  toggleActiveUserFail: 'Toggle active user failed!',
  confirmDeleteUserMessage: 'Are you sure you want to delete user',
  createTeamSuccess: 'Create team success!',
  updateTeamSuccess: 'Update team success!',
  deleteTeamSuccess: 'Delete team success!',
  deleteTeamFail: 'Delete team failed!',
  confirmDeleteTeamMessage: 'Are you sure you want to delete team',
  listMemberOfTeam: 'List members of team',
  active: 'Active',
  confirmDeactiveUser:
    'Are you sure you want to deactive user <strong>{{name}}</strong>? You can only active this leader in the <strong>first 7 days</strong> of the period',
}

export default users
