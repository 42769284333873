const login = {
  welcome: 'Chào mừng bạn tới FETCH!',
  subWelcome: 'Welcome to Talent Acquisition Portal',
  loginSuccess: 'Đăng nhập thành công!',
  loginFail: 'Đăng nhập thất bại!',
  signin: 'Đăng nhập',
  signup: 'Đăng ký',
  email: 'Địa chỉ email',
  password: 'Mật khẩu',
  emailVerify: 'Email phải là địa chỉ email hợp lệ',
  emailRequired: 'Email là bắt buộc',
  passwordRequired: 'Mật khẩu là bắt buộc',
  captchaVerify: 'Hãy xác nhận bạn không phải là robot',
  rememberMe: 'Ghi nhớ tôi',
  forgotPassword: 'Quên mật khẩu?',
  login: 'Đăng nhập',
  orLoginWith: 'Hoặc đăng nhập với',
  oauth: {
    google: 'Đăng nhập với Google',
    facebook: 'Đăng nhập với Facebook',
    linkedin: 'Đăng nhập với Linkedin',
  },
  loginGoogleWarning:
    'Bạn đang mở trang web của chúng tôi từ chế độ xem web, Google có thể <span>chặn</span> trang web đó khi <span>đăng nhập bằng Google</span>. Nếu vậy, vui lòng mở nó trong trình duyệt web và tiếp tục đăng nhập.',
}

export default login
