import { configureStore } from '@reduxjs/toolkit'

// sections
import cvFormReducer from 'redux/cvFormSlice'
import feedbackFormReducer from 'redux/feedbackFormSlice'
import masterReducer from 'redux/masterDataSlice'

// sections
import blogReducer from 'sections/blog/blogSlice'
import candidateReducer from 'sections/candidate/candidateSlice'
import clientReducer from 'sections/client/clientSlice'
import dashboardReducer from 'sections/dashboard/dashboardSlice'
import newReducer from 'sections/fetch-news/newsSlice'
import interviewReducer from 'sections/interview/interviewSlice'
import jobDetailReducer from 'sections/job-detail/jobDetailSlice'
import jobReducer from 'sections/job/jobSlice'
import kanbanReducer from 'sections/kanban/kanbanSlice'
import kpiBonusReducer from 'sections/kpi-bonus/kpiBonusSlice'
import manageCVReducer from 'sections/manage-cv/manageCVSlice'
import manageRedeemReducer from 'sections/manage-redeem/manageRedeemSlice'
import marketCVReducer from 'sections/market-cv/marketCVSlice'
import marketMyCVReducer from 'sections/market-my-cv/marketMyCVSlice'
import marketMyPurchaseReducer from 'sections/market-my-purchase/marketMyPurchaseSlice'
import notificationReducer from 'sections/notification/notificationSlice'
import quizReducer from 'sections/quiz-game/quizSlice'
import recruiterReducer from 'sections/recruiter/recruiterSlice'
import reportReducer from 'sections/report/reportSlice'
import settingReducer from 'sections/setting/settingSlice'
import summaryHiringReducer from 'sections/summary-hiring/summaryHiringSlice'
import transferDataReducer from 'sections/transfer-data/transferDataSlice'
import accountReducer from 'sections/user/account/accountSlice'
import kpiBonusProfileReducer from 'sections/user/kpi-bonus/KpiBonusProfileSlice'
import teamReducer from 'sections/user/list/team-list/teamSlice'
import userReducer from 'sections/user/list/user-list/userSlice'
import withdrawReducer from 'sections/withdraw/withdrawSlice'

const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    account: accountReducer,
    kanban: kanbanReducer,
    blog: blogReducer,
    client: clientReducer,
    cvForm: cvFormReducer,
    feedbackForm: feedbackFormReducer,
    candidates: candidateReducer,
    job: jobReducer,
    jobDetail: jobDetailReducer,
    interview: interviewReducer,
    user: userReducer,
    team: teamReducer,
    transferData: transferDataReducer,
    setting: settingReducer,
    summaryHiring: summaryHiringReducer,
    notification: notificationReducer,
    recruiter: recruiterReducer,
    manageCV: manageCVReducer,
    manageRedeem: manageRedeemReducer,
    withdraw: withdrawReducer,
    marketCV: marketCVReducer,
    marketMyCV: marketMyCVReducer,
    marketMyPurchase: marketMyPurchaseReducer,
    master: masterReducer,
    kpiBonusProfile: kpiBonusProfileReducer,
    kpiBonus: kpiBonusReducer,
    report: reportReducer,
    quiz: quizReducer,
    new: newReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
})

export { store }
