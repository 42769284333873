import { PASSWORD_MIN_CHARACTER } from 'config'

const profile = {
  heading: 'Profile',
  general: 'General',
  name: 'Name',
  email: 'Email Address',
  role: 'Role',
  team: 'Team',
  salaryGross: 'Salary (Gross)',
  historySalary: 'History salary',
  show: 'Show',
  date: 'Date',
  edit: 'Edit',
  allow: 'Allowed',
  maxSizeOf: 'max size of',
  updateSuccess: 'Update success!',
  updateAvatarSuccess: 'Update avatar success!',
  updateAvatarFail: 'Update avatar fail!',
  fileSizeError: 'The photo you entered is over the allowed size!',
  totalFollowMessage: 'You are following',
  jobs: 'jobs',
  nameRequired: 'Name is required',
  change_password: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  oldPasswordRequired: 'Old password is required',
  verifyNewPassword: `Password must be at least ${PASSWORD_MIN_CHARACTER} characters`,
  newPasswordRequired: 'New password is required',
  samePasswordMessage: 'New password must be different from old password',
  passwordConfirmRequired: 'Password confirmation is required',
  confirmPasswordMessage: 'Password must be matched',
  changePasswordSuccess: 'Change password success!',
  changePasswordFail: 'Change password fail!',
  saveChange: 'Save change',
  billing: 'Billing Information',
  bankNumberInvalid: 'Bank number can only contain digital',
  nameInvalid: 'Name can only contain letters',
  updateBankInfoSuccess: 'Update bank info success!',
  updateBankInfoFail: 'Update bank info failed! Please try again!',
  bankNumberRequired: 'Bank number is required',
  bankNameRequired: 'Bank name is required',
  photoUrlBackRequired: 'Photo of the front of the ID card is required',
  photoUrlFrontRequired: 'Photo of the back of the ID card is required',
  uploadImageFail: 'Upload image failed! Please try again!',
  updatePhoto: 'Update Photo',
  uploadPhoto: 'Upload Photo',
  referral: 'Referral',
  referralCode: 'Referral code',
  referralLink: 'Referral link',
  referralBy: 'Referral by',
  referralInfo: `- You can refer the code to other users so that both receive a reward.
    - You can only enter another user's referral code once.
    - The referral code is only valid for 2 days.
    - If the person you recommend is added to someone else, you also get a reward. Maximum of 5 levels.
  `,
  inputCode: 'Input code',
  inputCodeRequired: 'Input code is required',
  referralName: 'Referral name',
  pointReferral: 'Point referral',
  referralNoticeMessage: `- The referral code for you will expire on {{dateExpire}} at {{timeExpire}}.
  - Once the code has expired, you will no longer be able to submit the code to receive a bonus.`,
  submitCodeFail: 'Submit code failed! Please try again!',
  userNotFound: 'User not found!',
  KPI: 'KPI',
  Bonus: 'Bonus',
  teamProcess: 'Team Process',
  teamKPI: 'Team KPI',
  process: 'Process',
  candidate: 'Candidate',
  jobSalary: 'Job Salary',
  salaryOffer: 'Salary Offer (VND)',
  status: 'Status',
  label: 'Label',
  point: 'Point',
  kpiPoint: 'KPI Point (VND)',
  kpiPeriod: 'KPI Period',
  year: 'Year',
  individualKPIAchievement: 'Individual KPI Achievement',
  bonusRate: 'Bonus Rate',
  personalBonus: 'Personal Bonus (VND)',
  teamBonus: 'Team Bonus (VND)',
  bonusFromTeamFund: 'Bonus From Team Fund (VND)',
  totalBonus: 'Total Bonus (VND)',
  teamKPIAchievement: 'Team KPI Achievement',
  coefficientBonus: 'Coefficient Bonus',
  bonusPoint: 'Bonus Point (VND)',
  coefficientKPI: 'Coefficient KPI',
  waiting: 'Waiting',
  referFriends: 'Refer friends',
  enterEmail: 'Enter email',
  referEmailIsRequired: 'Email is required',
  referEmailSuccess: 'Refer your friend email success!',
  referEmailFailed: 'Refer your friend email failed!',
}

export default profile
