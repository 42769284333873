const summaryHiring = {
  heading: 'Candidates job - Status',
  no: 'No',
  submitDate: 'Submit Date',
  location: 'Location',
  name: 'Full Name',
  cv: 'Curriculum Vitae',
  description: 'Short Description',
  status: 'Status',
  interviewTime: 'Interview Time',
  interviewDate: 'Interview Date',
  feedback: 'Feedback',
  negotiable: 'Negotiable',
  previewPdf: 'Preview pdf',
}

export default summaryHiring
