const forgotPassword = {
  forgotPasswordSuccess:
    'Please check the confirmation mailbox to retrieve your pasword',
  resetPassword: 'Reset password',
  emailVerify: 'Email must be a valid email address',
  emailRequired: 'Email is required',
  changePassword: 'Change password',
  emailNotFound: 'Email not found',
  serverError: 'Internal server error',
  enterEmailToResetPassword:
    'Please enter the email address associated with your account and We will email you a link to reset your password.',
  forgotYourPassword: 'Forgot your password?',
}

export default forgotPassword
