// api exchange currency rate
export const API_EXCHANGE_RATE_REQUEST_API_KEY =
  'https://vapi.vnappmob.com/api/request_api_key?scope=exchange_rate'
export const API_EXCHANGE_RATE_BANK =
  'https://vapi.vnappmob.com/api/v2/exchange_rate/vcb'

export const API_GOOGLE_USER_INFO =
  'https://www.googleapis.com/oauth2/v3/userinfo'
export const API_CANDIDATE_ADD_CARD = '/api/addCard/candidate'
export const API_LOGIN = '/api/login'
export const API_REFRESH_TOKEN = '/api/refreshToken'
export const API_ADMIN_USER_LIST = '/api/users/list'
export const API_ADMIN_ALL_NOTIFY = '/api/notifications/all'
export const API_UPLOAD_AVATAR_PROFILE = '/api/users/upload/avatar'
export const API_LIST_JOBS = '/api/jobs/admin'
export const API_LIST_JOBS_PROFILE = '/api/jobs/user/profile'
export const API_ADMIN_READ_ALL_NOTIFY = '/api/notifications/read/all'
export const API_CARD = '/api/cards'
export const API_SEARCH_CARD = '/api/cards/search'
export const API_LIST_LABEL = '/api/labels'
export const API_CARD_LABEL = '/api/cards/label'
export const API_LIST_CLIENT = '/api/all/clients'
export const API_ADMIN_SEARCH_LIST_JOB = '/api/jobs/all'
export const API_LIST_ACTIVE_JOB = '/api/jobs/trello/active'
export const API_LIST_USER = '/api/users/trello'
export const API_LIST_MEMBER = '/api/search/board/members'
export const API_SEARCH_PHONE = '/api/candidates/search/phone'
export const API_SEARCH_EMAIL = '/api/candidates/search/email'
export const API_REMOVE_ASSIGNMENT = '/api/cards/remove/assignment'
export const API_ASSIGNMENT = '/api/cards/assignment'
export const API_LIST_UPDATE_HISTORY = '/api/history/card'
export const API_LIST_COMMENT = '/api/comments/cards'
export const API_UPLOAD_LINK = '/api/cards/upload/cv'
export const API_ADMIN_CLIENTS_LIST = '/api/clients'
export const API_ADMIN_ACTIVE_CLIENT = '/api/clients/active'
export const API_DASHBOARD_JOBS_BY_STATUS = '/api/jobs/dashboard'
export const API_MEMBER_ACTIVITIES_USER_INFO = '/api/users'
export const API_ADMIN_CALENDAR_INTERVIEW = '/api/interviews/admin/calendar'
export const API_ALL_SKILL = '/api/all/skill'
export const API_ADMIN_SEARCH_CANDIDATE = '/api/search/candidates'
export const API_CANDIDATES = '/api/candidates'
export const API_ADMIN_DOWNLOAD_CV_PDF = '/api/candidates/download/pdf/private'
export const API_ADMIN_PREVIEW_PDF_CANDIDATEJOB =
  '/api/v1/admin/preview/pdf/candidateJob'
export const API_ASSIGNMENT_JOB = '/api/jobs/assignment'
export const API_ASSIGN_LIST_USER = '/api/users/assign/list'
export const API_CANDIDATE_JOB = '/api/candidates/job'
export const API_LANES = '/api/lanes'
export const API_SEARCH_SKILL = '/api/skill/search'
export const API_ADD_SKILL = '/api/skill'
export const API_LOCATIONS = '/api/locations/all'
export const API_DOWNLOAD_JOB = '/api/jobs/download'
export const API_HISTORY_JOB = '/api/history/job'
export const API_CREATE_JOB = '/api/jobs'
export const API_LIST_TAGS = '/api/tags'
export const API_JOB_REMOVE_ASSIGNMENT = '/api/jobs/remove/assignment'
export const API_SYSTEM = '/api/systems'
export const API_LIST_ROLES = '/api/roles'
export const API_LIST_TEAM = '/api/teams'
export const API_USER = '/api/users'
export const API_REGISTER = '/api/register'
export const API_CANDIDATE_INTERVIEW = '/api/candidates/interview'
export const API_ADMIN_INTERVIEW = '/api/interviews'
export const API_ADMIN_CARD_INTERVIEW = '/api/interviews/insert-from-card'
export const API_RECRUITER = '/api/recruiters'
export const API_COMMENT = '/api/comments'
export const API_FORGOT_PASSWORD = '/api/forgot/password'
export const API_CHANGE_PASSWORD_TOKEN = '/api/change/password/token'
export const API_SIGNIN_SOCIALS = '/api/social-signin'
export const API_REGISTER_RECRUITER = '/api/register-recruiter'
export const API_GENERATE_VERIFY_EMAIL = '/api/auth/generate/verify-email'
export const API_VERIFY_EMAIL_CODE = '/api/auth/verify-email'
export const API_CHANGE_PASSWORD = '/api/change/password'
export const API_BANK_RECRUITER = '/api/bank-recruiter'
export const API_BANK_RECRUITER_UPLOADS = 'api/bank-recruiter/uploads'
export const API_CARD_CREATE_BY_RECRUITER = '/api/cards/create-by-recruiter'
export const API_ADD_POSITION = '/api/positions'
export const API_SEARCH_POSITION = '/api/positions/search'
export const API_REDEEM_CONFIG_POINTS = '/api/configPoints'
export const API_LIST_BONUS_POINTS = '/api/recruiter-points'
export const API_CV_RECRUITER = '/api/candidates/cv-recruiter'
export const API_CV = '/api/cv'
export const API_RECRUITER_CANDIDATE_LIST = '/api/candidates/list'
export const API_LIST_RECRUITERS = '/api/users/recruiters'
export const API_LIST_REDEEM_BONUS = '/api/orders'
export const API_UPLOAD_CV = '/api/extractDataCandidate'
export const API_LIST_REDEEM_RECRUITER = '/api/recruiters/point'
export const API_REDEEM_POINT = '/api/orders/redeem-point'
export const API_LANGUAGES = '/api/v1/languages'
export const API_UPLOAD_AVATAR_CV = '/api/candidates/upload/avatar'
export const API_BLOG = '/api/blog'
export const API_BLOG_TAG = 'api/blog/tag'
export const API_BLOG_KEYWORD = 'api/blog/keyword'
export const API_LIST_REFER_CANDIDATE = 'api/candidates/recruiter'
export const API_HISTORY = 'api/history'
export const API_REFERRAL_SETTING = 'api/refer-setting'
export const API_REFFERAL_INPUT_CODE = 'api/users/code-refer'
export const API_LIST_REFERRAL_USER = 'api/refer-user'
export const API_CHECK_SHARE_CANDIDATE = 'api/candidates/check-share'
export const API_CARDS_LANE = '/api/cards/lane'
export const API_CLOSE_JOB = '/api/jobs/close'
export const API_USER_HISTORY_SALARY = '/api/history-kpi/salary'
export const API_CARD_MEMBER_IN_CHARGE = '/api/cards/charge'
export const API_KPI_PROFILE_USER = '/api/kpi'
export const API_BONUS_PROFILE_USER = '/api/bonus-card-user/profile-user'
export const API_BONUS_TEAM = '/api/kpi/bonus-team'
export const API_KPI_TEAM = '/api/kpi/team'
export const API_HISTORY_KPI_TEAM = '/api/history-kpi/team'
export const API_DASHBOARD_PERFORMANCE_MEMBER = 'api/cards/performance'
export const API_DASHBOARD_APPLICANT = 'api/candidates/new-applicant'
export const API_DASHBOARD_EX_RECRUITER = 'api/users/dashboard'
export const API_DASHBOARD_PERFORMANCE_DIRECTOR = 'api/cards/dashboard/cv'
export const API_LIST_RANKING = '/api/kpi/rank'
export const API_REFER_FRIEND = '/api/users/refer-friend'
export const API_REPORT = '/api/reports'
export const API_SEND_INVITATION_LETTER = '/api/candidates/invite'
export const API_TASK = '/api/task'
export const API_SYNC_TOTAL_CLICK = '/api/jobs/sync-total-click'
export const API_LIST_QUIZ = '/api/quiz-game'
export const API_SUBMIT_QUIZ = '/api/quiz-game/submitAnswer'
export const API_CHECK_QUIZ_DAILY = '/api/quiz-game/check-quiz-daily'
export const API_SKIP_QUIZ_DAILY = '/api/quiz-game/skip-quiz-daily'
export const API_EXTERNAL_RANKING = 'api/recruiters/top-point'

// transferData
export const API_REVIEW_TRANSFER_CANDIDATES = 'api/transfer-candidates/review'
export const API_SUBMIT_TRANSFER_CANDIDATES = 'api/transfer-candidates/submit'
