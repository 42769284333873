const header = {
  notification: {
    title: 'Thông báo',
    youHave: 'Bạn có',
    unreadMessages: 'tin nhắn chưa đọc',
    beforeThat: 'Trước đó',
    viewAll: 'Xem tất cả',
    markAsRead: 'Đánh dấu đã đọc',
  },
  account: {
    logoutFail: 'Không thể đăng xuất!',
  },
  languages: {
    en: 'Tiếng Anh',
    vn: 'Tiếng Việt',
  },
  subHeader: {
    overview: 'Tổng quan',
    hiring: 'Tuyển dụng',
    team: 'Đội',
    tool: 'Công cụ',
    setting: 'Cài đặt',
    other: 'Khác',
    management: 'Quản lý',
    refering: 'Tham khảo',
    marketplace: 'Chợ',
    transactions: 'Giao dịch',
    support: 'Hỗ trợ',
  },
}

export default header
