const setting = {
  bitlyConfig: 'Bitly Config',
  driverConfig: 'Driver Config',
  zoomConfig: 'Zoom Config',
  secretKey: 'Secret Key',
  refineCV: 'Refine CV',
  account: 'Account',
  folderCv: 'Folder CV',
  token: 'Token',
  id: 'ID',
  configSettings: 'Config Settings',
  configMessage: 'Setup Your Config Admin',
  updateSuccess: 'Update setting success!',
  updateFail: 'Update setting failed!',
  required: 'Required',
  redeem: 'Redeem Setting',
  bonus: 'Bonus Setting',
  system: 'Config Setting',
  minPointRequired: 'Min point is required',
  maxPointRequired: 'Max point is required',
  minPointMustNumber: 'Min point must be a number',
  maxPointMustNumber: 'Max point must be a number',
  minPointMustInteger: 'Min point must be an integer',
  maxPointMustInteger: 'Max point must be an integer',
  minPointNotNegative: 'Min point must be greater than or equal to 0',
  maxPointNotNegative: 'Max point must be greater than or equal to 0',
  minimumPointRedeem: 'Minimum Point Redeem',
  maximumPointRedeem: 'Maximum Point Redeem',
  updatePointSuccess: 'Update config redeem points success!',
  updatePointFail: 'Update config redeem points fail!',
  statusRequired: 'Status is required',
  pointMustNumber: 'Point must be a number',
  pointNotNegative: 'Point must be greater than or equal to 0',
  titleRequired: 'Title is required',
  pointRequired: 'Point is required',
  updateBonusPointSuccess: 'Update bonus point success!',
  updateBonusPointFail: 'Update bonus point fail!',
  point: 'Point',
  status: 'Status',
  title: 'Title',
  pointMustInteger: 'Point must be an integer',
  pointRedeemTitle: 'Setting point redeem',
  pointInviteUserTitle: 'Setting point for invite user',
  level: 'Level',
  userReferrer: 'User Referrer (User A)',
  referrerReceiver: 'Referrer Receiver (User B)',
  updateReferralPointSuccess: 'Update referral point setting success!',
  updateReferralPointFail: 'Update referral point setting fail!',
  pointUploadCVSuccessToMarketplace:
    'Setting point for Upload CV in Marketplace',
  uploadCvPoint: 'Upload CV Point',
  turnOnUploadCVPointSuccess: 'Turn on upload CV point success!',
  turnOffUploadCVPointSuccess: 'Turn off upload CV point success!',
  turnOnUploadCVPointFailed: 'Turn on upload CV point failed!',
  turnOffUploadCVPointFailed: 'Turn off upload CV point failed!',
  turnOnBonusPointForUploadCV: 'Turn on bonus points for uploading CV',
  turnOffBonusPointForUploadCV: 'Turn off bonus points for uploading CV',
  updateUploadCVPointSuccess: 'Update upload cv point success!',
  updateUploadCVPointFailed: 'Update upload cv point failed!',
}

export default setting
