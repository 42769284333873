import { createContext, useCallback } from 'react'

import PropTypes from 'prop-types'

// config
import { ROLE } from 'config'

// hooks
import useAuth from 'hooks/useAuth'

const RoleContext = createContext()

const RoleProvider = ({ children }) => {
  const { user } = useAuth()
  const currentRole = user?.role // Admin;

  const checkAccessPermission = useCallback(
    (roles = []) => [].concat(roles).includes(currentRole),
    [currentRole]
  )

  const isDirectorRole = ROLE.DIRECTOR === currentRole
  const isLeaderRole =
    ROLE.LEADER === currentRole ||
    ROLE.TA_LEADER === currentRole ||
    ROLE.HR_LEADER === currentRole
  const isAdminRole = ROLE.ADMIN === currentRole
  const isMemberRole = ROLE.MEMBER === currentRole
  const isRecruiterRole = ROLE.RECRUITER === currentRole
  const isBloggerRole = ROLE.BLOGGER === currentRole
  const isReviewerRole = ROLE.REVIEWER === currentRole
  const isSubmitterRole = ROLE.SUBMITTER === currentRole
  const isClientRole = ROLE.CLIENT === currentRole
  const isHRLeaderRole = ROLE.HR_LEADER === currentRole
  const isTALeaderRole = ROLE.TA_LEADER === currentRole
  const isRoleBelongToInternalTeam = [
    ROLE.DIRECTOR,
    ROLE.LEADER,
    ROLE.HR_LEADER,
    ROLE.TA_LEADER,
    ROLE.MEMBER,
  ].includes(currentRole)

  return (
    <RoleContext.Provider
      value={{
        checkAccessPermission,
        isAdminRole,
        isBloggerRole,
        isRecruiterRole,
        isDirectorRole,
        isLeaderRole,
        isMemberRole,
        isReviewerRole,
        isSubmitterRole,
        isClientRole,
        isRoleBelongToInternalTeam,
        isHRLeaderRole,
        isTALeaderRole,
        currentRole,
      }}
    >
      {children}
    </RoleContext.Provider>
  )
}

RoleProvider.propTypes = {
  children: PropTypes.node,
}

export { RoleContext, RoleProvider }
