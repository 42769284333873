const login = {
  welcome: 'Welcome to FETCH!',
  subWelcome: 'Welcome to Talent Acquisition Portal',
  loginSuccess: 'Login success!',
  loginFail: 'Login failed!',
  signin: 'SIGN-IN',
  signup: 'SIGN-UP',
  email: 'Email address',
  password: 'Password',
  emailVerify: 'Email must be a valid email address',
  emailRequired: 'Email is required',
  passwordRequired: 'Password is required',
  captchaVerify: 'Please verify that you are not a robot',
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot password?',
  login: 'Login',
  orLoginWith: 'Or login with',
  forgotPasswordSuccess:
    'Please check the confirmation mailbox to retrieve your pasword',
  oauth: {
    google: 'Login with Google',
    facebook: 'Login with Facebook',
    linkedin: 'Login with Linkedin',
  },
  loginGoogleWarning:
    'You are opening our website from a webview, Google may block it from Login with Google. If so, please open it in a web browser and continue signing in.',
}

export default login
