import qs from 'query-string'

import { removeEmptyKeysFromObject } from 'utils/removeEmptyKeysFromObject'

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
export const ROOTS_DASHBOARD = '/'

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: {
    root: path(ROOTS_AUTH, '/login'),
    view: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_AUTH, '/login')

      return path(ROOTS_AUTH, `/login?${qs.stringify(optional)}`)
    },
  },
  linkedin: (optional = {}) => {
    if (!Object.keys(optional).length) return path(ROOTS_AUTH, '/linkedin')

    return path(ROOTS_AUTH, `/linkedin?${qs.stringify(optional)}`)
  },
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  verifyEmail: path('', '/verify-email'),
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'dashboard')

      return path(
        ROOTS_DASHBOARD,
        `dashboard?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  profile: {
    root: path(ROOTS_DASHBOARD, 'profile/'),
    view: (id, optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, `profile/${id}`)

      return path(ROOTS_DASHBOARD, `profile/${id}?${qs.stringify(optional)}`)
    },
  },
  notification: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'notification')

      return path(
        ROOTS_DASHBOARD,
        `notification?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  task: {
    root: path(ROOTS_DASHBOARD, 'task'),
  },
  jobs: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_DASHBOARD, 'jobs')

      return path(
        ROOTS_DASHBOARD,
        `jobs?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    new: path(ROOTS_DASHBOARD, 'jobs/new'),
  },
  jobDetail: {
    root: path(ROOTS_DASHBOARD, 'job-detail'),
    view: (id) => path(ROOTS_DASHBOARD, `job-detail/${id}`),
  },
  clients: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_DASHBOARD, 'clients')

      return path(
        ROOTS_DASHBOARD,
        `clients?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  candidates: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'candidates')

      return path(
        ROOTS_DASHBOARD,
        `candidates?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  users: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_DASHBOARD, 'users')

      return path(
        ROOTS_DASHBOARD,
        `users?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    account: path(ROOTS_DASHBOARD, 'users/account'),
  },
  kpiBonus: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'kpi-bonus')

      return path(
        ROOTS_DASHBOARD,
        `kpi-bonus?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  interview: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'interview')

      return path(
        ROOTS_DASHBOARD,
        `interview?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  board: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_DASHBOARD, 'board')

      return path(
        ROOTS_DASHBOARD,
        `board?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    view: (cardId) => path(ROOTS_DASHBOARD, `board/opening?cardId=${cardId}`),
    opening: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'board/opening')

      return path(
        ROOTS_DASHBOARD,
        `board/opening?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    process: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'board/process')

      return path(
        ROOTS_DASHBOARD,
        `board/process?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    done: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'board/done')

      return path(
        ROOTS_DASHBOARD,
        `board/done?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    close: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'board/close')

      return path(
        ROOTS_DASHBOARD,
        `board/close?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  calculator: path(ROOTS_DASHBOARD, 'calculator'),
  recruiter: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'recruiter')

      return path(
        ROOTS_DASHBOARD,
        `recruiter?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    profile: (id, optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, `recruiter/${id}`)

      return path(ROOTS_DASHBOARD, `recruiter/${id}?${qs.stringify(optional)}`)
    },
  },
  blog: {
    root: path(ROOTS_DASHBOARD, 'blog'),
    list: {
      root: path(ROOTS_DASHBOARD, 'blog/list'),
      tags: (tag = '', slug = '') =>
        path(ROOTS_DASHBOARD, `blog/list?tag=${tag}&slug=${slug}`),
    },
    create: path(ROOTS_DASHBOARD, 'blog/create'),
    edit: (id) => path(ROOTS_DASHBOARD, `blog/${id}/edit`),
    view: (slug) => path(ROOTS_DASHBOARD, `blog/post/${slug}`),
  },
  installApp: {
    root: path(ROOTS_DASHBOARD, 'install'),
  },
  manage: {
    cv: {
      root: (optional = {}) => {
        if (!Object.keys(optional).length)
          return path(ROOTS_DASHBOARD, 'manage/cv')

        return path(
          ROOTS_DASHBOARD,
          `manage/cv?${qs.stringify(removeEmptyKeysFromObject(optional))}`
        )
      },
      detail: (id, optional = {}) => {
        if (!Object.keys(optional).length)
          return path(ROOTS_DASHBOARD, `manage/cv/${id}`)

        return path(
          ROOTS_DASHBOARD,
          `manage/cv/${id}?${qs.stringify(optional)}`
        )
      },
    },
    redeem: {
      root: (optional = {}) => {
        if (!Object.keys(optional).length)
          return path(ROOTS_DASHBOARD, 'manage/redeem')

        return path(
          ROOTS_DASHBOARD,
          `manage/redeem?${qs.stringify(removeEmptyKeysFromObject(optional))}`
        )
      },
    },
  },
  marketplace: {
    root: path(ROOTS_DASHBOARD, 'marketplace'),
    all: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'marketplace/all')

      return path(
        ROOTS_DASHBOARD,
        `marketplace/all?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    myListCV: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'marketplace/my-list-cv')

      return path(
        ROOTS_DASHBOARD,
        `marketplace/my-list-cv?${qs.stringify(
          removeEmptyKeysFromObject(optional)
        )}`
      )
    },
    myPurchaseCV: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'marketplace/my-purchase-cv')

      return path(
        ROOTS_DASHBOARD,
        `marketplace/my-purchase-cv?${qs.stringify(
          removeEmptyKeysFromObject(optional)
        )}`
      )
    },
  },
  transactions: {
    root: path(ROOTS_DASHBOARD, 'transactions'),
    withdraw: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'transactions/withdraw')

      return path(
        ROOTS_DASHBOARD,
        `transactions/withdraw?${qs.stringify(
          removeEmptyKeysFromObject(optional)
        )}`
      )
    },
  },
  transferData: {
    root: path(ROOTS_DASHBOARD, 'transfer-data'),
  },
  summaryHiring: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'summary-hiring')

      return path(
        ROOTS_DASHBOARD,
        `summary-hiring?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  report: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_DASHBOARD, 'report')

      return path(
        ROOTS_DASHBOARD,
        `report?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
  },
  news: {
    root: (optional = {}) => {
      if (!Object.keys(optional).length) return path(ROOTS_DASHBOARD, 'news')

      return path(
        ROOTS_DASHBOARD,
        `news?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    list: (optional = {}) => {
      if (!Object.keys(optional).length)
        return path(ROOTS_DASHBOARD, 'news/list')

      return path(
        ROOTS_DASHBOARD,
        `news/list?${qs.stringify(removeEmptyKeysFromObject(optional))}`
      )
    },
    create: path(ROOTS_DASHBOARD, 'news/create'),
    preview: path(ROOTS_DASHBOARD, 'news/preview'),
    edit: (id) => path(ROOTS_DASHBOARD, `news/${id}/edit`),
    view: (slug) => path(ROOTS_DASHBOARD, `news/${slug}`),
  },
}
