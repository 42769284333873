const clients = {
  heading: 'Danh sách khách hàng',
  newClient: 'Khách hàng mới',
  name: 'Tên',
  website: 'Website',
  time: 'Thời gian',
  email: 'Email',
  phone: 'Số điện thoại',
  action: 'Hành động',
  color: 'Màu sắc',
  about: 'Giới thiệu',
  active: 'Trạng thái',
  nameError: 'Tên là bắt buộc',
  aboutError: 'Giới thiệu là bắt buộc',
  deleteSuccess: 'Xóa khách hàng thành công!',
  deleteFail: 'Xóa khách hàng thất bại!',
  updateClient: 'Cập nhật khách hàng',
  updateSuccess: 'Cập nhật khách hàng thành công!',
  createSuccess: 'Tạo khách hàng thành công!',
  updateFail: 'Cập nhật khách hàng thất bại!',
  createFail: 'Tạo khách hàng thất bại!',
  confirmMessage: 'Bạn có chắc chắn xóa khách hàng',
  emailInvalid: 'Email không hợp lệ',
  phoneInvalid: 'Số điện thoại không hợp lệ',
  toggleClientStatusSuccess: 'Thay đổi trạng thái thành công!',
  toggleClientStatusFail: 'Thay đổi trạng thái thất bại!',
}

export default clients
