import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { API_CANDIDATES, API_CV_RECRUITER } from 'routes/api'

import { _deleteApi, _getApi, _postApi } from 'utils/axios'

export const getListCV = createAsyncThunk(
  'marketMyPurchase/getListCV',
  async (queries = {}) => {
    const response = await _getApi(API_CV_RECRUITER, { params: queries })
    return response?.data || {}
  }
)

export const getDetailCV = createAsyncThunk(
  'marketMyPurchase/getDetailCV',
  async (id) => {
    const response = await _getApi(`${API_CANDIDATES}/${id}/detail`)

    return response?.data?.cv || {}
  }
)

export const deleteCV = createAsyncThunk(
  'marketMyPurchase/deleteCV',
  async (id) => {
    const response = await _deleteApi(`${API_CANDIDATES}/${id}`)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const uploadCV = createAsyncThunk(
  'marketMyPurchase/uploadCV',
  async (data) => {
    const response = await _postApi(`${API_CANDIDATES}/create`, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

const initialState = {
  listCV: {
    isLoading: false,
    data: {},
  },
  detailCV: {
    data: {},
    isLoading: false,
    isError: false,
  },
}

const marketMyPurchaseSlice = createSlice({
  name: 'marketMyPurchase',
  initialState,
  reducers: {
    resetDetailCV: (state) => {
      state.detailCV = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListCV.pending, (state) => {
        state.listCV.isLoading = true
      })
      .addCase(getListCV.fulfilled, (state, action) => {
        state.listCV.isLoading = false
        state.listCV.data = action.payload
      })
      .addCase(getListCV.rejected, (state) => {
        state.listCV.isLoading = false
      })
      .addCase(getDetailCV.pending, (state) => {
        state.detailCV.isLoading = true
        state.detailCV.data = {}
        state.detailCV.isError = false
      })
      .addCase(getDetailCV.fulfilled, (state, action) => {
        state.detailCV.isLoading = false
        state.detailCV.data = action.payload
        state.detailCV.isError = false
      })
      .addCase(getDetailCV.rejected, (state) => {
        state.detailCV.isLoading = false
        state.detailCV.isError = true
      })
  },
})

export default marketMyPurchaseSlice.reducer

export const { resetDetailCV } = marketMyPurchaseSlice.actions

export const selectlistCV = createSelector(
  [(state) => state.marketMyPurchase.listCV],
  (listCV) => listCV
)

export const selectDetailCV = createSelector(
  [(state) => state.marketMyPurchase.detailCV],
  (detailCV) => detailCV
)
