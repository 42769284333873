import {
  MAX_LENGTH_PHONE_NUMBER,
  MIN_LENGTH_PHONE_NUMBER,
} from 'sections/job-detail/refer-now/config'
import { SEO_MAX_CHARACTERS } from 'sections/job/form/config'

const jobs = {
  heading: 'List jobs',
  yourJobs: 'Your Jobs',
  newJob: 'New Job',
  title: 'Title',
  client: 'Client',
  time: 'Time',
  salary: 'Salary (Gross)',
  minSalary: 'Min',
  maxSalary: 'Max',
  currency: 'Currency',
  type: 'Type',
  location: 'Location',
  status: 'Status',
  actions: 'Actions',
  editJob: 'Edit Job',
  addJob: 'Add New Job',
  jobInfo: 'Job Info',
  jobTitle: 'Job Title',
  tags: 'Tags',
  jobTitleHelper: `It is used to seo the web (max ${SEO_MAX_CHARACTERS} characters)`,
  allowShare: 'Allow Share External Recruiter',
  pageTitle: 'Page Title',
  chooseType: 'Choose Type',
  noteFromLeader: 'Note From Leader',
  keyword: 'Keyword',
  note: 'Note',
  descJob: 'Job Description',
  descMeta: 'Desc Meta',
  interviewProcess: 'Interview Process',
  extraBenefit: 'Extra Benefit',
  description: 'Description',
  aboutFetch: 'About Fetch',
  responsibilities: 'Responsibilities',
  requirement: 'Requirement',
  niceToHave: 'Nice To Have',
  benefit: "Why You'll Love Working Here",
  locationAndHours: 'Location and Hours',
  skillRequired: 'Skill Required',
  skill: 'Skill',
  addSkillText: 'You can add new skill',
  addNewSkill: 'Add New Skill',
  titleMessage: 'Job title is required',
  salaryMessage: 'Salary is required',
  minSalaryMessage: 'Min salary is required',
  maxSalaryMessage: 'Max salary is required',
  currencyMessage: 'Currency is required',
  locationMessage: 'Location is required',
  timeMessage: 'Time is required',
  typeMessage: 'Type is required',
  titlePagerMessage: 'Page title is required',
  metaJobMessage: 'Description meta is required',
  clientMessage: 'Client is required',
  downloadAsPdf: 'Download as PDF',
  followers: 'Followers',
  addToBoard: 'Add to board',
  name: 'Name',
  jobName: 'Job name',
  clientName: 'Client name',
  email: 'Email',
  lane: 'Lane',
  phone: 'Phone',
  approachDate: 'Approach date',
  linkCv: 'Link CV',
  position: 'Position',
  noteApproach: 'Note approach',
  rawCV: 'Raw CV',
  closeCV: 'Close Raw',
  changeTo: 'change to',
  activity: 'Activity',
  click: 'Click',
  candidate: 'Candidate',
  listCandidate: 'List Candidate',
  viewDetail: 'View detail',
  updateCandidateFail: 'Update candidate fail',
  updateCandidateSuccess: 'Update candidate success',
  selectStatus: 'Select status',
  noteApproachTitle: 'Note approach title is required',
  approachDateMessage: 'Approach date is required',
  laneMessage: 'Lane is required',
  positionMessage: 'Position is required',
  updateJobSuccess: 'Update job success',
  updateJobFailed: 'Update job failed',
  createJobFailed: 'Create job failed',
  createJobSuccess: 'Create job success',
  failedToGetSkill: 'Failed to get skill!',
  failedToAddSkill: 'Failed to add skill!',
  skillExist: 'Skill is exist!',
  addSkillSuccess: 'Add skill success!',
  enterLinkOrImportCv: 'Enter link or import CV',
  hasUpdateThisJob: 'Has update this job',
  active: 'Active',
  close: 'Close',
  pending: 'Pending',
  archive: 'Archive',
  process: 'Reviewing',
  descriptionMessage: 'Description is required',
  removeFromJob: 'Remove from job',
  removeFromCandidate: 'Remove from candidate',
  removeYourself: "You're not authorized to remove yourself",
  referNow: 'Refer Now',
  referCandidate: 'Refer Candidate',
  fullName: 'Full Name',
  yourMessage: 'Your Message',
  upload: 'Upload',
  uploadPortfolio: 'You can upload Portfolio',
  here: 'here',
  curriculumVitae: 'Curriculum Vitae (CV)',
  nameRequired: 'Name is required',
  emailRequired: 'Email is required',
  emailInvalid: 'Email is invalid',
  phoneRequired: 'Phone is required',
  messageRequired: 'Message is required',
  uploadCvRequired: 'Upload CV is required',
  captchaVerify: 'Please verify that you are not a robot',
  uploadCvInvalid: 'Upload CV is invalid',
  referSuccess: 'Refer success',
  referFailed: 'Refer failed',
  share: 'Share',
  unShare: 'Unshare',
  confirmShareMessage: 'Are you sure you want to share job',
  confirmUnshareMessage: 'Are you sure you want to unshare job',
  addNewPosition: 'Add New Position',
  addPositionText: 'You can add new position',
  positionExist: 'Position is exist!',
  addPositionSuccess: 'Add position success!',
  failedToAddPosition: 'Failed to add position!',
  shareJobSuccess: 'Share job success!',
  shareJobFail: 'Share job fail!',
  unShareJobSuccess: 'Unshare job success!',
  unShareJobFail: 'Unshare job fail!',
  removeAssigneeFailed: 'Remove assignee failed! Please try again.',
  addAssigneeFailed: 'Add assignee failed! Please try again.',
  removeAssigneeSuccess: 'Remove assignee success!',
  addAssigneeSuccess: 'Add assignee success!',
  bonusTitleLabel: 'Candidate pass',
  bonusPointLabel: 'Get bonus',
  pointOfJob: 'Point of job',
  points: 'points',
  minPhoneNumberLengthMessage: `Phone must be longer than or equal to ${MIN_LENGTH_PHONE_NUMBER} characters`,
  maxPhoneNumberLengthMessage: `Phone must be shorter than or equal to ${MAX_LENGTH_PHONE_NUMBER} characters`,
  cancelReferCV:
    'You will have to upload your CV again if you cancel this edition',
  submitReferral: 'Submit Referral',
  salaryExpectation: 'Salary Expectation',
  yearOfExperience: 'Year of experience',
  failMessageReferCV: `Something went wrong. Looks like you're having some problems. Please
  try again or contact us for assistance`,
  successMessageReferCV: 'Thanks for your reference.',
  inReferring: 'In-Referring',
  referralNotifySuccess: `Thank you for registering to use the portal FETCH. We are
  honored to have your trust. Congratulations, You have successfully entered the referred bonus.`,
  closeJob: 'Close Job',
  temporaryClosure:
    'Temporary closure <br /> (All cards will be pushed to the pending state)',
  permanentClosure: 'Permanent closure',
  closeJobFailed: 'Close job failed!',
  confirmEditJobMessage: `You are changing your job infomation. We need to check the infomation you have recently updated. Job will move to <b style="color: {{color}}">{{statusReview}}</b> status.`,
  confirmActiveMessage:
    'You are changing your job to <b style="color: {{color}}">{{statusActive}}</b>. We need to check the information you have recently updated. Job will move to <b style="color: {{color}}">{{statusReview}}</b> status first.',
  deactive: 'Deactive',
  cancelJobConfirmMessage: 'Are you sure you want to cancel job',
  cannotOpenCandidateCv: `Can't open this candidate's cv, please try again`,
  notification: 'Notification',
  availability: 'Availability',
  getBonus: 'Get Bonus (Points)',
  confirmArchiveMessage:
    'All cards will be pushed into <strong>storage</strong> if this job <strong>archived</strong>',
  shareJDViaCopyLink: 'Share JD via copy link',
  emailVerify: 'Email must be a valid email address',
  linkedinRequired: 'Link Linkedin is required',
  sendInvitationLetterSuccess:
    'Send invitation letter to your candidate successfully!',
  sendInvitationLetterFailed:
    'Send invitation letter to your candidate failed!',
  sendInvitationLetter: 'Send Invitation Letter for Your Candidate',
  importLinkedin: 'Import from Linkedin',
  enterEmail: 'Enter email',
  enterLinkedin: 'Enter link Linkedin',
}
export default jobs
