import { PASSWORD_MIN_CHARACTER } from 'config'

const profile = {
  heading: 'Hồ sơ',
  general: 'Chung',
  name: 'Tên',
  email: 'Địa chỉ email',
  role: 'Vị trí',
  team: 'Đội',
  salaryGross: 'Lương (Gross)',
  historySalary: 'Lịch sử lương',
  show: 'Hiển thị',
  date: 'Ngày',
  edit: 'Người chỉnh sửa',
  allow: 'Cho phép',
  maxSizeOf: 'kích thước tối đa',
  updateSuccess: 'Cập nhật thành công!',
  updateAvatarSuccess: 'Cập nhật ảnh đại diện thành công!',
  updateAvatarFail: 'Cập nhật ảnh đại diện thất bại!',
  fileSizeError: 'Ảnh bạn tải lên vượt quá kích thước cho phép!',
  totalFollowMessage: 'Bạn đang theo dõi',
  jobs: 'công việc',
  nameRequired: 'Tên là bắt buộc',
  change_password: 'Đổi mật khẩu',
  oldPassword: 'Mật khẩu cũ',
  newPassword: 'Mật khẩu mới',
  confirmNewPassword: 'Xác nhận mật khẩu mới',
  oldPasswordRequired: 'Mật khẩu cũ là bắt buộc',
  verifyNewPassword: `Mật khẩu phải có ít nhất ${PASSWORD_MIN_CHARACTER} ký tự`,
  newPasswordRequired: 'Mật khẩu mới là bắt buộc',
  samePasswordMessage: 'Mật khẩu mới phải khác mật khẩu cũ',
  passwordConfirmRequired: 'Xác nhận mật khẩu là bắt buộc',
  confirmPasswordMessage: 'Mật khẩu xác nhận phải khớp',
  changePasswordSuccess: 'Đổi mật khẩu thành công!',
  changePasswordFail: 'Đổi mật khẩu thất bại!',
  saveChange: 'Lưu thay đổi',
  billing: 'Thông tin thanh toán',
  bankNumberInvalid: 'Số tài khoản chỉ có thể chứa số',
  nameInvalid: 'Tên chỉ được chứa tiếng việt không dấu',
  updateBankInfoSuccess: 'Cập nhật thông tin ngân hàng thành công!',
  updateBankInfoFail:
    'Cập nhật thông tin ngân hàng thất bại! Vui lòng thử lại!',
  bankNumberRequired: 'Số tài khoản là bắt buộc',
  bankNameRequired: 'Tên ngân hàng là bắt buộc',
  photoUrlBackRequired: 'Ảnh mặt trước CMND là bắt buộc',
  photoUrlFrontRequired: 'Ảnh mặt sau CMND là bắt buộc',
  uploadImageFail: 'Tải ảnh thất bại! Vui lòng thử lại!',
  updatePhoto: 'Cập nhật ảnh',
  uploadPhoto: 'Tải ảnh',
  referral: 'Giới thiệu',
  referralCode: 'Mã giới thiệu',
  referralLink: 'Link giới thiệu',
  referralBy: 'Được giới thiệu bởi',
  referralInfo: `- Bạn có thể gửi mã giới thiệu cho người khác để cả 2 cùng nhận được thưởng.
    - Bạn chỉ được nhập mã giới thiệu của người dùng khác 1 lần.
    - Mã giới thiệu chỉ có giá trị trong 2 ngày
    - Nếu người bạn giới thiệu mà giới thiệu được thêm cho người khác thì bạn cũng nhận được thưởng. Tối đa lên đến 5 level.
  `,
  inputCode: 'Mã code',
  inputCodeRequired: 'Mã code là bắt buộc',
  referralName: 'Người giới thiệu',
  pointReferral: 'Điểm thưởng được giới thiệu',
  referralNoticeMessage: `- Mã giới thiệu của bạn sẽ hết hạn vào lúc {{timeExpire}} ngày {{dateExpire}}.
  - Khi mã hết hạn, bạn sẽ không thế nhập mã để nhận thưởng nữa.`,
  submitCodeFail: 'Gửi mã thất bại! Xin hãy thử lại!',
  userNotFound: 'Không tìm thấy người dùng!',
  KPI: 'KPI',
  Bonus: 'Bonus',
  teamProcess: 'Tiến độ Team',
  teamKPI: 'Team KPI',
  process: 'Tiến độ',
  candidate: 'Ứng viên',
  jobSalary: 'Lương job',
  salaryOffer: 'Lương Offer (VND)',
  status: 'Trạng thái',
  label: 'Nhãn',
  point: 'Điểm',
  kpiPoint: 'Điểm KPI (VND)',
  kpiPeriod: 'Kỳ KPI',
  year: 'Năm',
  individualKPIAchievement: 'Thành tích KPI cá nhân',
  bonusRate: 'Tỷ lệ Bonus',
  teamBonus: 'Team Bonus (VND)',
  personalBonus: 'Bonus cá nhân (VND)',
  bonusFromTeamFund: 'Bonus Từ Quỹ Team (VND)',
  totalBonus: 'Tổng Bonus (VND)',
  teamKPIAchievement: 'Thành tích KPI Team',
  coefficientBonus: 'Hệ số Bonus',
  bonusPoint: 'Điểm Bonus (VND)',
  coefficientKPI: 'Hệ số KPI',
  waiting: 'Đang chờ',
  referFriends: 'Giới thiệu bạn bè',
  enterEmail: 'Nhập email',
  referEmailIsRequired: 'Email là bắt buộc',
  referEmailSuccess: 'Gửi email giới thiệu tới bạn bè thành công!',
  referEmailFailed: 'Gửi email giới thiệu tới bạn bè thất bại!',
}

export default profile
