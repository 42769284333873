import { FORM_FIELDS } from 'components/cv-form/config'

import {
  DATE_DISABLE_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD,
  MIN_DATE_VALUE,
  ROLE,
} from 'config'

import { fIsBefore } from 'utils/formatTime'

export const SOCIAL_OPTIONS = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Linkedin', value: 'linkedin' },
  { label: 'Skype', value: 'skype' },
]
export const CARD_WIDTH = 284

export const KANBAN_STATUS_HEADER_HEIGHT = 84

export const ACTION_STATUS = {
  MOVE: 'move',
  LABEL: 'label',
  STORAGE: 'storage',
  COMPLETE: 'completeProcess',
  CHECK_ENGLISH: 'checkEnglish',
  DEFAULT: 'actions',
}

export const HISTORY_STATUS = {
  CREATE: 'create_card',
  UPDATE: 'update_card',
  DELETE: 'delete_card',
}

export const JOB_FORM_STICKY_BAR_COLOR = {
  LIGHT: {
    COLOR: '#fff',
    SHADOW: '#d8d8d8',
  },
  DARK: {
    COLOR: '#212b36',
    SHADOW: '#3e474f',
  },
}

export const SOCIAL_LIST = ['facebook', 'linkedin', 'skype']

export const INTERVIEW_FORM_FIELD = {
  JOB: 'jobName',
  CANDIDATE: 'candidate',
  LOCATION: 'location',
  TYPE: 'type',
  TIME_START: 'timeInterview',
  TIME_END: 'timeInterviewEnd',
  PASSWORD: 'password',
  EMAIL: 'email',
  PHONE: 'phone',
  LINK_ZOOM: 'linkZoom',
}

export const INTERVIEW_TYPE = {
  ONLINE: 'online',
  OFFLINE: 'offline',
}

export const INTERVIEW_TYPE_OPTIONS = [
  INTERVIEW_TYPE.ONLINE,
  INTERVIEW_TYPE.OFFLINE,
]

export const INTERVIEW_FORM_DEFAULT_VALUES = {
  [INTERVIEW_FORM_FIELD.JOB]: '',
  [INTERVIEW_FORM_FIELD.CANDIDATE]: '',
  [INTERVIEW_FORM_FIELD.PASSWORD]: '',
  [INTERVIEW_FORM_FIELD.LOCATION]: [],
  [INTERVIEW_FORM_FIELD.TYPE]: INTERVIEW_TYPE.OFFLINE,
  [INTERVIEW_FORM_FIELD.TIME_START]: new Date(),
  [INTERVIEW_FORM_FIELD.TIME_END]: '',
  [INTERVIEW_FORM_FIELD.EMAIL]: '',
  [INTERVIEW_FORM_FIELD.PHONE]: '',
  [INTERVIEW_FORM_FIELD.LINK_ZOOM]: '',
}

export const SEARCH_FORM_FIELDS = {
  TEXT_SEARCH: 'textSearch',
  SEARCH: 'search',
  LABEL: 'label',
  CLIENT_ID: 'clientId',
  USER_ID: 'userId',
  JOB_ID: 'jobId',
  START_DATE: 'timeStart',
  END_DATE: 'timeEnd',
  STORAGE: 'storage',
}

export const SEARCH_DEFAULT_VALUES = {
  [SEARCH_FORM_FIELDS.TEXT_SEARCH]: '',
  [SEARCH_FORM_FIELDS.SEARCH]: '',
  [SEARCH_FORM_FIELDS.LABEL]: '',
  [SEARCH_FORM_FIELDS.CLIENT_ID]: '',
  [SEARCH_FORM_FIELDS.USER_ID]: '',
  [SEARCH_FORM_FIELDS.JOB_ID]: '',
  [SEARCH_FORM_FIELDS.START_DATE]: null,
  [SEARCH_FORM_FIELDS.END_DATE]: null,
  [SEARCH_FORM_FIELDS.STORAGE]: false,
}

export const SEARCH_FIELD_TAG_PRIORITY = [
  SEARCH_FORM_FIELDS.TEXT_SEARCH,
  SEARCH_FORM_FIELDS.JOB_ID,
  SEARCH_FORM_FIELDS.CLIENT_ID,
  SEARCH_FORM_FIELDS.USER_ID,
  SEARCH_FORM_FIELDS.LABEL,
  SEARCH_FORM_FIELDS.START_DATE,
  SEARCH_FORM_FIELDS.END_DATE,
  SEARCH_FORM_FIELDS.STORAGE,
]

export const CARD_BACKGROUND_COLOR = {
  DARK: '#919EAB08',
  LIGHT: 'white',
  LIGHT_DONE: '#80808038',
  DARK_DONE: '#152027',
}

export const MARK_DONE_COLOR = '#37c339'

export const CHECKED_ENGLISH_COLOR = '#6048a1'

export const EXPECTED_DATE_COLOR = '#f14b66'

export const ONBOARD_DATE_COLOR = '#00a651'

export const EXTERNAL_RECRUITER_LABEL = 'External Recruiter'

export const COLUMN_LABEL = {
  PENDING: 'Pending',
  APPROACH: 'Approach',
  SENT_JD: 'Sent JD',
  CHECK_ENG: 'Check Eng',
  KEEP_IN_VIEW: 'Keep In View',
  SCREEN_CV: 'Screen CV',
  SCHEDULE: 'Schedule',
  INTERVIEW_ONLINE: 'Interview online',
  TESTING: 'Testing',
  INTERVIEW_F2F: 'Interview F2F',
  CONSIDERING: 'Considering',
  INFORM_FAILURE: 'Inform Failure',
  BENEFIT_DEAL: 'Benefit Deal',
  OFFER_CONFIRM: 'Offer confirmation waiting',
  ONBOARDING: 'Onboarding',
  PROBATION: 'Probation',
  PASSED_PROBATION: 'Passed Probation',
  FAILED_PROBATION: 'Failed Probation',
  FAILED_APPROACH: 'Failed Approach',
  FAILED_SCREEN_CV: 'Failed Screen CV',
  FAILED_INTERVIEW: 'Failed Interview',
  CANCELLED_INTERVIEW: 'Cancelled Interview',
  CANCELLED_OFFER_OR_FAILED_BENEFIT_DEAL:
    'Cancelled Offer Or Failed Benefit Deal',
  NOT_SELECTED: 'Not Selected',
  CANCELLED_TEST_OR_FAILED_TEST: 'Cancelled Test/Failed Test',
}

export const LIST_COLUMN_LABEL = Object.keys(COLUMN_LABEL).reduce(
  (acc, cur) => [...acc, COLUMN_LABEL[cur]],
  []
)

export const LIST_COLUMN_ADD = [
  COLUMN_LABEL.PENDING,
  COLUMN_LABEL.APPROACH,
  COLUMN_LABEL.SENT_JD,
  COLUMN_LABEL.CHECK_ENG,
]

export const LIST_COLUMN_RESET_CHECK_ENG = [
  COLUMN_LABEL.PENDING,
  COLUMN_LABEL.APPROACH,
  COLUMN_LABEL.SENT_JD,
]

export const LIST_COLUMN_MOVEABLE = ({
  listColumnName = [],
  isDirectorRole = false,
  isLeaderRole = false,
  isHRLeaderRole = false,
  isTALeaderRole = false,
  nameColumn = '',
  isCheckEng = false,
  hasMemberInCharge = false,
}) => {
  // Pending, Approach, SendJD
  if (
    [
      COLUMN_LABEL.APPROACH,
      COLUMN_LABEL.SENT_JD,
      COLUMN_LABEL.PENDING,
    ].includes(nameColumn)
  ) {
    const listColumnsMoveable = LIST_COLUMN_ADD.concat([
      COLUMN_LABEL.FAILED_APPROACH,
    ])

    return listColumnName.filter(({ label = '' }) =>
      listColumnsMoveable.includes(label)
    )
  }

  // Failed Approach
  if (nameColumn === COLUMN_LABEL.FAILED_APPROACH) {
    const listColumnsMoveable = LIST_COLUMN_ADD.concat([
      COLUMN_LABEL.KEEP_IN_VIEW,
      COLUMN_LABEL.FAILED_APPROACH,
    ])

    return listColumnName.filter(({ label = '' }) =>
      listColumnsMoveable.includes(label)
    )
  }

  // Check Eng
  if (nameColumn === COLUMN_LABEL.CHECK_ENG) {
    if (!isCheckEng)
      return listColumnName.filter(({ label = '' }) =>
        LIST_COLUMN_ADD.includes(label)
      )

    let listColumnsMoveable = [].concat([
      ...LIST_COLUMN_ADD,
      COLUMN_LABEL.KEEP_IN_VIEW,
    ])

    if (
      (isDirectorRole || isLeaderRole || isHRLeaderRole || isTALeaderRole) &&
      hasMemberInCharge
    ) {
      listColumnsMoveable = listColumnsMoveable.concat([COLUMN_LABEL.SCREEN_CV])
    }

    listColumnsMoveable = listColumnsMoveable.concat([
      COLUMN_LABEL.FAILED_APPROACH,
    ])

    return listColumnName.filter(({ label = '' }) =>
      listColumnsMoveable.includes(label)
    )
  }

  // Keep In View
  if (nameColumn === COLUMN_LABEL.KEEP_IN_VIEW) {
    const listColumnsMoveable = LIST_COLUMN_ADD.concat([
      COLUMN_LABEL.KEEP_IN_VIEW,
      COLUMN_LABEL.CHECK_ENG,
      COLUMN_LABEL.SCREEN_CV,
      COLUMN_LABEL.FAILED_APPROACH,
    ])

    return listColumnName.filter(({ label = '' }) =>
      listColumnsMoveable.includes(label)
    )
  }

  // Offer Confirm Waiting
  if (nameColumn === COLUMN_LABEL.OFFER_CONFIRM) {
    const listHiddenColumns = [
      COLUMN_LABEL.FAILED_APPROACH,
      COLUMN_LABEL.PROBATION,
      COLUMN_LABEL.PASSED_PROBATION,
      COLUMN_LABEL.FAILED_PROBATION,
    ]

    return listColumnName.filter(
      ({ label = '' }) => !listHiddenColumns.includes(label)
    )
  }

  // Onboarding
  if (nameColumn === COLUMN_LABEL.ONBOARDING) {
    const listColumnsMoveable = [
      COLUMN_LABEL.ONBOARDING,
      COLUMN_LABEL.PROBATION,
      COLUMN_LABEL.CANCELLED_OFFER_OR_FAILED_BENEFIT_DEAL,
    ]

    return listColumnName.filter(({ label = '' }) =>
      listColumnsMoveable.includes(label)
    )
  }

  // Probation
  if (nameColumn === COLUMN_LABEL.PROBATION) {
    const listColumnsMoveable = [
      COLUMN_LABEL.PROBATION,
      COLUMN_LABEL.PASSED_PROBATION,
      COLUMN_LABEL.FAILED_PROBATION,
    ]

    return listColumnName.filter(({ label = '' }) =>
      listColumnsMoveable.includes(label)
    )
  }

  // Passed/Failed Probation
  if (
    [COLUMN_LABEL.PASSED_PROBATION, COLUMN_LABEL.FAILED_PROBATION].includes(
      nameColumn
    )
  )
    return [].concat(
      listColumnName.find(({ label = '' }) => label === nameColumn)
    )

  // After ScreenCV
  const listHiddenColumns = [
    COLUMN_LABEL.ONBOARDING,
    COLUMN_LABEL.PROBATION,
    COLUMN_LABEL.PASSED_PROBATION,
    COLUMN_LABEL.FAILED_PROBATION,
    COLUMN_LABEL.FAILED_APPROACH,
  ]

  return listColumnName.filter(
    ({ label = '' }) => !listHiddenColumns.includes(label)
  )
}

export const OVERRIDE_VALIDATE_STATUS_LIST = [
  COLUMN_LABEL.APPROACH,
  COLUMN_LABEL.SENT_JD,
  COLUMN_LABEL.PENDING,
  COLUMN_LABEL.FAILED_APPROACH,
]

export const CHECK_INDEX_TYPE = {
  BEFORE: 'before',
  AFTER: 'after',
}

export const checkBeforeAfterColumn = (
  currentColumn,
  checkColumn,
  type = CHECK_INDEX_TYPE.BEFORE
) => {
  const currentColumnIndex = LIST_COLUMN_LABEL.indexOf(currentColumn)
  const checkColumnIndex = LIST_COLUMN_LABEL.indexOf(checkColumn)

  if (checkColumnIndex === -1 || currentColumnIndex === -1 || !type)
    return false

  if (type === CHECK_INDEX_TYPE.BEFORE)
    return currentColumnIndex < checkColumnIndex

  // type AFTER
  return currentColumnIndex > checkColumnIndex
}

export const PERMISSION_MOVE_TYPE = {
  ERROR: 'error',
  CONFIRM: 'confirm',
  ALLOW: 'allow',
}

export const checkPermissionMoveCard = ({
  columnStart = '',
  columnFinish = '',
  isLeaderRole = false,
  isDirectorRole = false,
  isMemberRole = false,
  columnOptions = [],
  hasMemberInCharge = false,
}) => {
  const hasMovePermission = columnOptions.some(
    ({ label = '' }) => label === columnFinish
  )

  if (!hasMovePermission) {
    if (
      [COLUMN_LABEL.PASSED_PROBATION, COLUMN_LABEL.FAILED_PROBATION].includes(
        columnStart
      )
    )
      return {
        type: PERMISSION_MOVE_TYPE.ERROR,
        message: 'pages.board.cardBlocked',
      }

    if (COLUMN_LABEL.ONBOARDING === columnFinish) {
      return {
        type: PERMISSION_MOVE_TYPE.ERROR,
        message: 'pages.board.needOfferConfirm',
      }
    }

    if (COLUMN_LABEL.PROBATION === columnFinish) {
      return {
        type: PERMISSION_MOVE_TYPE.ERROR,
        message: 'pages.board.needOnboarding',
      }
    }

    if (
      [COLUMN_LABEL.PASSED_PROBATION, COLUMN_LABEL.FAILED_PROBATION].includes(
        columnFinish
      )
    )
      return {
        type: PERMISSION_MOVE_TYPE.ERROR,
        message: 'pages.board.needProbation',
      }

    if (isLeaderRole || isDirectorRole) {
      if (
        COLUMN_LABEL.FAILED_APPROACH === columnStart &&
        COLUMN_LABEL.FAILED_SCREEN_CV === columnFinish
      )
        return {
          type: PERMISSION_MOVE_TYPE.ERROR,
          message: 'pages.board.cantChangeStatus',
        }

      if (!hasMemberInCharge) {
        return {
          type: PERMISSION_MOVE_TYPE.ERROR,
          message: 'pages.board.needMemberInCharge',
        }
      }

      if (LIST_COLUMN_ADD.includes(columnStart)) {
        if (
          [COLUMN_LABEL.SCREEN_CV, COLUMN_LABEL.KEEP_IN_VIEW].includes(
            columnFinish
          )
        )
          return {
            type: PERMISSION_MOVE_TYPE.ERROR,
            message: 'pages.board.needCheckEng',
          }

        const isAfterScreenCV = checkBeforeAfterColumn(
          columnFinish,
          COLUMN_LABEL.SCREEN_CV,
          CHECK_INDEX_TYPE.AFTER
        )

        if (isAfterScreenCV)
          return {
            type: PERMISSION_MOVE_TYPE.ERROR,
            message: 'pages.board.needScreenCV',
          }
      }

      return {
        type: PERMISSION_MOVE_TYPE.ERROR,
        message: 'pages.board.cantChangeStatus',
      }
    }

    if (isMemberRole)
      return {
        type: PERMISSION_MOVE_TYPE.ERROR,
        message: 'pages.board.dontHavePermissionChangeStatus',
      }

    return {
      type: PERMISSION_MOVE_TYPE.ERROR,
      message: 'common.somethingWentWrong',
    }
  }

  // Screen CV and after
  const isAfterCheckEng = checkBeforeAfterColumn(
    columnStart,
    COLUMN_LABEL.CHECK_ENG,
    CHECK_INDEX_TYPE.AFTER
  )

  // Check Eng and before
  const isBeforeScreenCV = LIST_COLUMN_ADD.includes(columnFinish)

  if (
    COLUMN_LABEL.KEEP_IN_VIEW === columnStart &&
    COLUMN_LABEL.SCREEN_CV === columnFinish &&
    !hasMemberInCharge
  )
    return {
      type: PERMISSION_MOVE_TYPE.ERROR,
      message: 'pages.board.needMemberInCharge',
    }

  if (
    isAfterCheckEng &&
    ![columnFinish, columnStart].includes(COLUMN_LABEL.FAILED_APPROACH) &&
    COLUMN_LABEL.KEEP_IN_VIEW !== columnStart &&
    !isBeforeScreenCV &&
    !hasMemberInCharge
  )
    return {
      type: PERMISSION_MOVE_TYPE.ERROR,
      message: 'pages.board.needMemberInCharge',
    }

  if (
    [COLUMN_LABEL.PASSED_PROBATION, COLUMN_LABEL.FAILED_PROBATION].includes(
      columnFinish
    )
  )
    return {
      type: PERMISSION_MOVE_TYPE.CONFIRM,
      message: 'pages.board.confirmMoveCardProbation',
    }

  if (
    isAfterCheckEng &&
    isBeforeScreenCV &&
    columnStart !== COLUMN_LABEL.FAILED_APPROACH
  )
    return {
      type: PERMISSION_MOVE_TYPE.CONFIRM,
      message: 'pages.board.confirmMoveCardBeforeScreenCV',
    }

  return {
    type: PERMISSION_MOVE_TYPE.ALLOW,
  }
}

export const FormSchema = ({
  Yup,
  translate,
  validateFields,
  isAddTaskNoColumn,
  isEditMode,
  cv,
  isShowCheckBoxCheckEng,
  watchIsCheckEng,
  isValidateSalaryOffer,
  watchIsExEmployee,
}) => ({
  [FORM_FIELDS.LANE_ID]:
    isAddTaskNoColumn &&
    Yup.object()
      .shape({
        value: Yup.string().required(translate('cvForm.columnNameRequired')),
        label: Yup.string().required(),
      })
      .nullable()
      .required(translate('cvForm.columnNameRequired')),
  [FORM_FIELDS.EMAIL]: Yup.string().required(translate('cvForm.emailRequired')),
  [FORM_FIELDS.PHONE]: Yup.string().required(translate('cvForm.phoneRequired')),
  [FORM_FIELDS.JOB_ID]: Yup.object()
    .shape({
      value: Yup.string().required(translate('cvForm.nameJobRequired')),
      label: Yup.string().required(),
    })
    .nullable()
    .required(translate('cvForm.nameJobRequired')),
  [FORM_FIELDS.NOTE_APPROACH]: Yup.string().required(
    translate('cvForm.approachPointRequired')
  ),
  ...(isValidateSalaryOffer && {
    [FORM_FIELDS.SALARY_OFFER]: Yup.string()
      .max(100)
      .required(translate('cvForm.salaryOfferRequired')),
  }),
  ...(isShowCheckBoxCheckEng &&
    watchIsCheckEng && {
      [FORM_FIELDS.ENGLISH_TAKE_NOTE]: Yup.string().required(
        translate('cvForm.englishTakeNoteRequired')
      ),
    }),
  ...(watchIsExEmployee && {
    [FORM_FIELDS.EX_EMPLOYEE_NOTE]: Yup.string().required(
      translate('cvForm.exEmployeeRequired')
    ),
  }),
  ...(isEditMode && cv
    ? {
        [FORM_FIELDS.UPLOAD_CV]: Yup.mixed().nullable(),
      }
    : {
        [FORM_FIELDS.UPLOAD_CV]: Yup.mixed().required(
          translate('cvForm.cvRequired')
        ),
      }),
  ...validateFields,
})

export const OVERRIDE_VALIDATE_FIELDS = ({ Yup }) => ({
  [FORM_FIELDS.UPLOAD_CV]: Yup.mixed().nullable(),
  [FORM_FIELDS.POSITION]: Yup.array().nullable().min(0),
  [FORM_FIELDS.EXPERIENCE]: Yup.array().nullable().min(0),
  [FORM_FIELDS.SKILL]: Yup.array().nullable().min(0),
  [FORM_FIELDS.LANGUAGES]: Yup.array().nullable().min(0),
  [FORM_FIELDS.NOTE_APPROACH]: Yup.string().nullable(),
})

export const EXPECTED_DATE_VALIDATE = ({ Yup, translate }) => ({
  [FORM_FIELDS.EXPECTED_DATE]: Yup.date()
    .nullable()
    .required(translate('cvForm.expectedDateRequired'))
    .min(MIN_DATE_VALUE, translate('common.minDateError'))
    .typeError(translate('common.invalidDate')),
})

export const ONBOARD_DATE_VALIDATE = ({ Yup, translate }) => ({
  [FORM_FIELDS.ONBOARD_DATE]: Yup.date()
    .nullable()
    .required(translate('cvForm.onboardDateRequired'))
    .min(MIN_DATE_VALUE, translate('common.minDateError'))
    .typeError(translate('common.invalidDate')),
})

export const INPUT_DATE_TYPE = {
  EXPECTED_DATE: 'EXPECTED_DATE',
  ONBOARD_DATE: 'ONBOARD_DATE',
  EXPECTED_TESTING_DATE: 'EXPECTED_TESTING_DATE',
}

export const CHECK_ENG_STATUS = {
  ALL: 'All',
  CHECKED: 'Checked',
  UNCHECKED: 'Unchecked',
}

export const CHECK_ENG_OPTIONS = Object.keys(CHECK_ENG_STATUS).reduce(
  (acc, cur) => [...acc, CHECK_ENG_STATUS[cur]],
  []
)

export const UNCHANGEABLE_LABEL_COLUMNS = [
  COLUMN_LABEL.ONBOARDING,
  COLUMN_LABEL.PROBATION,
  COLUMN_LABEL.PASSED_PROBATION,
  COLUMN_LABEL.FAILED_PROBATION,
]

export const REMOVE_USER_PERMISSION_ROLES = [
  ROLE.LEADER,
  ROLE.HR_LEADER,
  ROLE.TA_LEADER,
  ROLE.DIRECTOR,
]

export const DISABLE_EX_EMPLOYEE_FIELD_COLUMNS = [
  COLUMN_LABEL.PROBATION,
  COLUMN_LABEL.PASSED_PROBATION,
  COLUMN_LABEL.FAILED_PROBATION,
  COLUMN_LABEL.ONBOARDING,
]

export const COLUMN_CANNOT_CHANGE_MEMBER_IN_CHARGE = [
  COLUMN_LABEL.PROBATION,
  COLUMN_LABEL.PASSED_PROBATION,
  COLUMN_LABEL.FAILED_PROBATION,
  COLUMN_LABEL.ONBOARDING,
]

export const COLUMN_SHOW_SALARY_OFFER = [
  COLUMN_LABEL.OFFER_CONFIRM,
  COLUMN_LABEL.ONBOARDING,
  COLUMN_LABEL.PROBATION,
  COLUMN_LABEL.PASSED_PROBATION,
  COLUMN_LABEL.FAILED_PROBATION,
]

export const SALARY_OFFER_EDITABLE_COLUMN = [
  COLUMN_LABEL.OFFER_CONFIRM,
  COLUMN_LABEL.ONBOARDING,
]

export const checkCardUpdatedAtBeforeTime = (date) => {
  if (!DATE_DISABLE_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD) return false
  return fIsBefore(
    new Date(date),
    new Date(DATE_DISABLE_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD)
  )
}

export const VALIDATE_INPUT_DATE_FIELDS = (Yup, translate, isValidDate) => ({
  [FORM_FIELDS.ONBOARD_DATE]: Yup.date()
    .nullable()
    .required(translate('cvForm.onboardDateRequired'))
    .min(MIN_DATE_VALUE, translate('common.minDateError'))
    .typeError(translate('common.invalidDate')),
  [FORM_FIELDS.EXPECTED_DATE]: Yup.date()
    .nullable()
    .required(translate('cvForm.expectedDateRequired'))
    .min(MIN_DATE_VALUE, translate('common.minDateError'))
    .typeError(translate('common.invalidDate')),
  [FORM_FIELDS.EXPECTED_TESTING_DATE]: Yup.string()
    .nullable()
    .test('validator-expected-testing', (value, { createError }) => {
      if (!value || isValidDate(new Date(value))) return true

      return createError({
        path: FORM_FIELDS.EXPECTED_TESTING_DATE,
        message: translate('common.invalidDate'),
      })
    }),
})

export const INPUT_DATE_REQUIRED_FIELDS = [
  FORM_FIELDS.ONBOARD_DATE,
  FORM_FIELDS.EXPECTED_DATE,
]

export const LANES = {
  PENDING: {
    nameColumn: 'Pending',
    order: 1,
    disabled: false,
  },
  APPROACH: {
    nameColumn: 'Approach',
    order: 2,
    disabled: false,
  },
  SEND_JD: {
    nameColumn: 'Sent JD',
    order: 3,
    disabled: false,
  },
  CHECK_ENG: {
    nameColumn: 'Check Eng',
    order: 4,
    disabled: false,
  },
  KEEP_IN_VIEW: {
    nameColumn: 'Keep In View',
    order: 5,
    disabled: false,
  },
  SCREEN_CV: {
    nameColumn: 'Screen CV',
    order: 6,
    disabled: false,
  },
  SCHEDULE: {
    nameColumn: 'Schedule',
    order: 7,
    disabled: false,
  },
  INTERVIEW_ONLINE: {
    nameColumn: 'Interview online',
    order: 8,
    disabled: false,
  },
  TESTING: {
    nameColumn: 'Testing',
    order: 9,
    disabled: false,
  },
  INTERVIEW_F2F: {
    nameColumn: 'Interview F2F',
    order: 10,
    disabled: false,
  },
  CONSIDERING: {
    nameColumn: 'Considering',
    order: 11,
    disabled: false,
  },
  INFORM_FAILURE: {
    nameColumn: 'Inform Failure',
    order: 12,
    disabled: false,
  },
  BENEFIT_DEAL: {
    nameColumn: 'Benefit Deal',
    order: 13,
    disabled: false,
  },
  OFFER_CONFIRM: {
    nameColumn: 'Offer confirmation waiting',
    order: 14,
    disabled: false,
  },
  ONBOARDING: {
    nameColumn: 'Onboarding',
    order: 15,
    disabled: false,
  },
  PROBATION: {
    nameColumn: 'Probation',
    order: 16,
    disabled: false,
  },
  PASSED_PROBATION: {
    nameColumn: 'Passed Probation',
    order: 17,
    disabled: false,
  },
  FAILED_PROBATION: {
    nameColumn: 'Failed Probation',
    order: 18,
    disabled: false,
  },
  FAILED_APPROACH: {
    nameColumn: 'Failed Approach',
    order: 19,
    disabled: false,
  },
  FAILED_SCREEN_CV: {
    nameColumn: 'Failed Screen CV',
    order: 20,
    disabled: false,
  },
  FAILED_INTERVIEW: {
    nameColumn: 'Failed Interview',
    order: 21,
    disabled: false,
  },
  CANCELLED_INTERVIEW: {
    nameColumn: 'Cancelled Interview',
    order: 22,
    disabled: false,
  },
  CANCELLED_OFFER_OR_FAILED_BENEFIT_DEAL: {
    nameColumn: 'Cancelled Offer Or Failed Benefit Deal',
    order: 23,
    disabled: false,
  },
  NOT_SELECTED: {
    nameColumn: 'Not Selected',
    order: 24,
    disabled: false,
  },
  CONTRACT_PREPARE: {
    nameColumn: 'Contract Prepare',
    order: 25,
    disabled: true,
  },
  CONTRACT_DONE: {
    nameColumn: 'Contract Done',
    order: 26,
    disabled: true,
  },
  CANCELLED_TEST_OR_FAILED_TEST: {
    nameColumn: 'Cancelled Test/Failed Test',
    order: 27,
    disabled: false,
  },
}

export const KANBAN_TAB_VALUES = {
  OPENING: 'opening',
  PROCESS: 'process',
  DONE: 'done',
  CLOSE: 'close',
}

export const KANBAN_TAB_COLUMNS = {
  OPENING: [
    LANES.PENDING.order,
    LANES.APPROACH.order,
    LANES.SEND_JD.order,
    LANES.CHECK_ENG.order,
    LANES.KEEP_IN_VIEW.order,
  ],
  PROCESS: [
    LANES.CHECK_ENG.order,
    LANES.KEEP_IN_VIEW.order,
    LANES.SCREEN_CV.order,
    LANES.SCHEDULE.order,
    LANES.INTERVIEW_ONLINE.order,
    LANES.TESTING.order,
    LANES.INTERVIEW_F2F.order,
    LANES.CONSIDERING.order,
    LANES.INFORM_FAILURE.order,
    LANES.BENEFIT_DEAL.order,
    LANES.OFFER_CONFIRM.order,
  ],
  DONE: [
    LANES.OFFER_CONFIRM.order,
    LANES.ONBOARDING.order,
    LANES.PROBATION.order,
    LANES.PASSED_PROBATION.order,
  ],
  CLOSE: [
    LANES.CHECK_ENG.order,
    LANES.KEEP_IN_VIEW.order,
    LANES.INFORM_FAILURE.order,
    LANES.OFFER_CONFIRM.order,
    LANES.ONBOARDING.order,
    LANES.PROBATION.order,
    LANES.FAILED_PROBATION.order,
    LANES.FAILED_APPROACH.order,
    LANES.FAILED_SCREEN_CV.order,
    LANES.FAILED_INTERVIEW.order,
    LANES.CANCELLED_INTERVIEW.order,
    LANES.CANCELLED_OFFER_OR_FAILED_BENEFIT_DEAL.order,
    LANES.NOT_SELECTED.order,
    LANES.CANCELLED_TEST_OR_FAILED_TEST.order,
  ],
}

export const KANBAN_COLUMNS_PARAM = (pathname) => {
  const params =
    Object.entries(KANBAN_TAB_COLUMNS).find((item) =>
      String(pathname || '')
        .toUpperCase()
        .includes(String((item[0] || '').toUpperCase()))
    ) || []

  if (!params.length)
    return {
      value: '',
      label: 'opening',
    }

  return {
    label: String(params[0] || '').toLowerCase(),
    value: params[1].join(','),
  }
}

export const JOB_STATUS_COLORS = {
  active: 'success',
  close: 'error',
  pending: 'info',
  archive: 'primary',
  clientrequest: 'secondary',
}
