import { FORM_FIELDS } from 'components/cv-form/config'

import { fDateOfBirth } from './formatTime'

export function formatCVData(data) {
  if (!Object.keys({ ...data }).length) return null

  const {
    YearsExperience: yearOfExperience = 0,
    birthday = '',
    education = '',
    experience = [],
    languages = [],
    linkedin = '',
    facebook = '',
    skype = '',
    address = '',
    name = '',
    phone = '',
    skillCV = '',
    cvRaw = '',
  } = data || {}

  const birthdayFormat = fDateOfBirth(birthday)
  const languagesFormat = (languages || []).join(', ')
  const educationFormat = String(education).split(',').join('\n')
  const experienceFormat = (experience || [])
    .map((item) => {
      const {
        description = '',
        position = '',
        company = '',
        dates = {},
      } = item || {}
      const { from = null, to = null, currentJob = false } = dates || {}

      return {
        description,
        position,
        company,
        from,
        to,
        currentJob,
      }
    })
    .filter(Boolean)

  return {
    ...data,
    [FORM_FIELDS.YEAR_OF_EXPERIENCE]: yearOfExperience,
    [FORM_FIELDS.BIRTHDAY]: birthdayFormat,
    [FORM_FIELDS.EDUCATION]: educationFormat,
    [FORM_FIELDS.EXPERIENCE]: experienceFormat,
    [FORM_FIELDS.LANGUAGES]: languagesFormat,
    [FORM_FIELDS.LINKEDIN]: linkedin,
    [FORM_FIELDS.FACEBOOK]: facebook,
    [FORM_FIELDS.SKYPE]: skype,
    [FORM_FIELDS.ADDRESS]: address,
    [FORM_FIELDS.NAME]: name,
    [FORM_FIELDS.PHONE]: phone,
    [FORM_FIELDS.SKILL]: skillCV,
    [FORM_FIELDS.RAW_CV]: cvRaw,
  }
}
