import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_ADMIN_DOWNLOAD_CV_PDF,
  API_ASSIGNMENT,
  API_ASSIGNMENT_JOB,
  API_ASSIGN_LIST_USER,
  API_CANDIDATES,
  API_CANDIDATE_ADD_CARD,
  API_CANDIDATE_JOB,
  API_CARD,
  API_CARD_CREATE_BY_RECRUITER,
  API_CARD_MEMBER_IN_CHARGE,
  API_CREATE_JOB,
  API_HISTORY_JOB,
  API_JOB_REMOVE_ASSIGNMENT,
  API_LANES,
  API_LIST_REFER_CANDIDATE,
  API_LIST_USER,
  API_REMOVE_ASSIGNMENT,
  API_SEND_INVITATION_LETTER,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const updateCandidate = createAsyncThunk(
  'jobDetail/updateCandidate',
  async (data) => {
    try {
      const response = await _patchApi(`${API_CARD}/${data.id}`, data)

      if (!response?.data?.success) {
        throw new Error(response?.message || response?.data?.message)
      }
    } catch (error) {
      const { message } = error || {}

      if (Array.isArray(message)) {
        throw new Error(message.join(', '))
      }

      throw new Error(message)
    }
  }
)

export const getCandidateDetail = createAsyncThunk(
  'jobDetail/getCandidateDetail',
  async (data = {}) => {
    const { isAddCard = false, candidateId = '', ...rest } = data || {}
    const { idCandidateJob = '' } = rest

    if (isAddCard) {
      const response = await _getApi(`${API_CARD}/${idCandidateJob}`)

      return response?.data?.card || {}
    }

    const response = await _getApi(
      `${API_CANDIDATE_ADD_CARD}/${candidateId}?${qs.stringify(rest)}`
    )

    return response?.data || {}
  }
)

export const getListCandidateStatus = createAsyncThunk(
  'jobDetail/getListCandidateStatus',
  async (queries) => {
    const response = await _getApi(`${API_LANES}?${qs.stringify(queries)}`)
    return response.data?.lane || []
  }
)

export const getListCandidateJob = createAsyncThunk(
  'jobDetail/getListCandidateJob',
  async ({ jobId = '' }) => {
    const response = await _getApi(`${API_CANDIDATE_JOB}/${jobId}`)
    return response.data?.list || []
  }
)

export const getAssignListUser = createAsyncThunk(
  'jobDetail/getAssignListUser',
  async () => {
    const response = await _getApi(API_ASSIGN_LIST_USER)
    return response.data?.user || []
  }
)

export const getAssignUser = createAsyncThunk(
  'jobDetail/getAssignUser',
  async ({ jobId }) => {
    const url = `${API_ASSIGNMENT_JOB}/${jobId}`
    const response = await _getApi(url)
    return (response.data?.result || []).map((user) => ({
      ...user,
      id: user.userId,
    }))
  }
)

export const getJobDetail = createAsyncThunk(
  'jobDetail/getJobDetail',
  async ({ jobId }) => {
    const url = `${API_CREATE_JOB}/${jobId}`
    const response = await _getApi(url)

    if (response?.code === 403) return response

    return response?.data?.success ? response.data : {}
  }
)

export const updateJobDetail = createAsyncThunk(
  'jobDetail/updateJobDetail',
  async ({ jobId, data }) => {
    const url = `${API_CREATE_JOB}/${jobId}`
    const response = await _patchApi(url, data)

    if (!response?.data?.success) {
      throw new Error(response?.data?.message)
    }
  }
)

export const removeAssignUser = createAsyncThunk(
  'jobDetail/removeAssignUser',
  async ({ jobId, userId, assignUser }, { dispatch, rejectWithValue }) => {
    try {
      const newAssignUser = assignUser.filter((user) => user.id !== userId)
      dispatch(updateAssignUser(newAssignUser))
      const response = await _postApi(API_JOB_REMOVE_ASSIGNMENT, {
        jobId,
        userId,
      })
      if (!response?.data?.success) {
        throw new Error(response?.message || 'pages.job.removeAssigneeFailed')
      }
      return assignUser
    } catch (error) {
      return rejectWithValue({
        error,
        assignUser,
      })
    }
  }
)

export const addAssignUser = createAsyncThunk(
  'jobDetail/addAssignUser',
  async (
    { jobId, userId, assignUser, assign },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(updateAssignUser([...assignUser, assign]))

      const response = await _postApi(API_ASSIGNMENT_JOB, {
        jobId,
        userId,
      })

      if (!response?.data?.success) {
        throw new Error(response?.message || 'pages.job.addAssigneeFailed')
      }

      return assignUser
    } catch (error) {
      return rejectWithValue({
        error,
        assignUser,
      })
    }
  }
)

export const addCandidateAssignee = createAsyncThunk(
  'jobDetail/addCandidateAssignee',
  async ({ cardId, user, assignUser = [] }, { rejectWithValue }) => {
    try {
      const response = await _patchApi(`${API_ASSIGNMENT}/${cardId}`, {
        userId: user?.id,
      })

      if (!response?.data?.success) {
        throw new Error('pages.job.addAssigneeFailed')
      }

      return [...assignUser, user]
    } catch (error) {
      return rejectWithValue(assignUser)
    }
  }
)

export const removeCandidateAssignee = createAsyncThunk(
  'jobDetail/removeCandidateAssignee',
  async ({ cardId, assignUser, userId }, { rejectWithValue }) => {
    try {
      const response = await _patchApi(`${API_REMOVE_ASSIGNMENT}/${cardId}`, {
        userId,
      })

      if (!response?.data?.success) {
        throw new Error('pages.job.removeAssigneeFailed')
      }

      return assignUser
    } catch (error) {
      return rejectWithValue(assignUser)
    }
  }
)

export const getJobActivity = createAsyncThunk(
  'jobDetail/getJobActivity',
  async ({ idJob }) => {
    const response = await _getApi(`${API_HISTORY_JOB}/${idJob}`)
    return response.data?.historyJob || []
  }
)

export const getTrelloUsers = createAsyncThunk(
  'jobDetail/getTrelloUsers',
  async () => {
    const response = await _getApi(API_LIST_USER)

    return response?.data?.list || []
  }
)

export const createCardByRecruiter = createAsyncThunk(
  'jobDetail/createCardByRecruiter',
  async (data, { rejectWithValue }) => {
    try {
      const { captcha: recaptcha = '', ...rest } = data || {}
      const response = await _postApi(API_CARD_CREATE_BY_RECRUITER, rest, {
        headers: {
          recaptcha,
        },
      })

      if (!response?.data?.success) {
        return rejectWithValue(response?.data?.message)
      }

      return response?.data || []
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getListReferCandidate = createAsyncThunk(
  'jobDetail/getListReferCandidate',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_LIST_REFER_CANDIDATE}?${qs.stringify(queries)}`
    )

    return response?.data || {}
  }
)

export const getDetailCV = createAsyncThunk(
  'jobDetail/getDetailCV',
  async (id) => {
    const response = await _getApi(`${API_CANDIDATES}/${id}/detail`)

    return response?.data?.cv || {}
  }
)

export const updateMemberInCharge = createAsyncThunk(
  'jobDetail/updateMemberInCharge',
  async ({ cardId = '', data = {} }, { dispatch }) => {
    const response = await _patchApi(
      `${API_CARD_MEMBER_IN_CHARGE}/${cardId}`,
      data
    )

    if (response?.data?.success) {
      dispatch(
        updateMemberInChargeToCandidateDetail({
          memberInCharge: true,
          ...data,
        })
      )
    }

    return response || {}
  }
)

export const sendInvitationLetter = createAsyncThunk(
  'jobDetail/sendInvitationLetter',
  async (data) => {
    const response = await _postApi(API_SEND_INVITATION_LETTER, data)

    return response?.data || {}
  }
)

const initialState = {
  assignListUser: {
    isLoading: false,
    data: [],
  },
  assignUser: {
    isLoading: false,
    data: [],
  },
  jobDetail: {
    isLoading: false,
    data: {},
  },
  candidateJob: {
    candidateDetail: {},
    base64: '',
    isLoading: false,
    isLoadingBase64: false,
    data: [],
    candidateId: {},
    listAssignUser: [],
  },
  listReferCandidate: {
    isLoading: false,
    data: {},
  },
  jobActivity: {
    isLoading: false,
    data: [],
  },
  trelloUser: {
    isLoading: false,
    data: [],
  },
  listCandidateStatus: [],
  detailCV: {
    data: {},
    isLoading: false,
    isError: false,
  },
}

export const convertDriverToBase64 = createAsyncThunk(
  'convertBase64/download',
  async ({ linkDrive, candidateId }) => {
    const response = await _postApi(API_ADMIN_DOWNLOAD_CV_PDF, {
      linkDrive,
    })
    return {
      base64: response.data.base64,
      candidateId,
    }
  }
)

const jobDetailSlice = createSlice({
  name: 'jobDetail',
  initialState,
  reducers: {
    updateAssignUser(state, action) {
      state.assignUser.data = action.payload
    },
    resetCandidateDetail(state) {
      state.candidateJob.candidateId = {}
      state.candidateJob.candidateDetail = {}
    },
    setCandidateDetail(state, action) {
      state.candidateJob.candidateId = action.payload
    },
    setListAssignUser(state, action) {
      state.candidateJob.listAssignUser = action.payload
    },
    updateMemberInChargeToCandidateDetail(state, action) {
      const { userId = '', memberInCharge = false } = action.payload

      const memberInChargeIndex = state.candidateJob.listAssignUser.findIndex(
        (item) => item?.memberInCharge
      )

      const newMemberInCharIndex = state.candidateJob.listAssignUser.findIndex(
        (item) => item?.id === userId
      )

      if (memberInChargeIndex !== -1) {
        state.candidateJob.listAssignUser[
          memberInChargeIndex
        ].memberInCharge = false
      }

      if (newMemberInCharIndex !== -1) {
        state.candidateJob.listAssignUser[newMemberInCharIndex].memberInCharge =
          memberInCharge
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCandidateDetail.pending, (state) => {
        state.candidateJob.isLoading = true
        state.candidateJob.candidateDetail = {}
        state.candidateJob.listAssignUser = []
      })
      .addCase(getCandidateDetail.fulfilled, (state, action) => {
        state.candidateJob.isLoading = false
        state.candidateJob.candidateDetail = action.payload
        if (action.payload?.CardUsers)
          state.candidateJob.listAssignUser = action.payload.CardUsers.map(
            (item) => ({
              ...item?.User,
              memberInCharge: item?.isMemberInCharge,
            })
          )
      })
      .addCase(getCandidateDetail.rejected, (state) => {
        state.candidateJob.isLoading = false
      })
      .addCase(getListCandidateStatus.pending, (state) => {
        state.listCandidateStatus = []
      })
      .addCase(getListCandidateStatus.fulfilled, (state, action) => {
        state.listCandidateStatus = (action.payload || []).map(
          ({ id: value, nameColumn: label }) => ({
            value,
            label,
          })
        )
      })
      .addCase(getListCandidateStatus.rejected, (state) => {
        state.listCandidateStatus = []
      })
      .addCase(getListCandidateJob.pending, (state) => {
        state.candidateJob.isLoading = true
        state.candidateJob.data = []
      })
      .addCase(getListCandidateJob.fulfilled, (state, action) => {
        state.candidateJob.isLoading = false
        state.candidateJob.data = action.payload
      })
      .addCase(getListCandidateJob.rejected, (state) => {
        state.candidateJob.isLoading = false
      })
      .addCase(getAssignListUser.pending, (state) => {
        state.assignListUser.isLoading = true
        state.assignListUser.data = []
      })
      .addCase(getAssignListUser.fulfilled, (state, action) => {
        state.assignListUser.isLoading = false
        state.assignListUser.data = action.payload
      })
      .addCase(getAssignListUser.rejected, (state) => {
        state.assignListUser.isLoading = false
      })
      .addCase(getAssignUser.pending, (state) => {
        state.assignUser.isLoading = true
        state.assignUser.data = []
      })
      .addCase(getAssignUser.fulfilled, (state, action) => {
        state.assignUser.isLoading = false
        state.assignUser.data = action.payload
      })
      .addCase(getAssignUser.rejected, (state) => {
        state.assignUser.isLoading = false
      })
      .addCase(removeAssignUser.rejected, (state, action) => {
        state.candidateJob.candidateId = {}
        state.assignUser.data = action?.payload?.assignUser || []
      })
      .addCase(addAssignUser.rejected, (state, action) => {
        state.assignUser.data = action?.payload?.assignUser || []
      })
      .addCase(addCandidateAssignee.pending, (state, action) => {
        const { assignUser = [], user = {} } = action.meta.arg
        const listAssign = Array.isArray(assignUser) ? assignUser : []

        state.candidateJob.listAssignUser = [...listAssign, user]
      })
      .addCase(addCandidateAssignee.rejected, (state, action) => {
        state.candidateJob.listAssignUser = action.payload
      })
      .addCase(removeCandidateAssignee.pending, (state, action) => {
        const { assignUser = [], userId = '' } = action.meta.arg
        const listAssign = Array.isArray(assignUser) ? assignUser : []

        state.candidateJob.listAssignUser = listAssign.filter(
          (user) => user.id !== userId
        )
      })
      .addCase(removeCandidateAssignee.rejected, (state, action) => {
        state.candidateJob.listAssignUser = action.payload
      })
      .addCase(getJobDetail.pending, (state) => {
        state.jobDetail.isLoading = true
        state.jobDetail.data = {}
      })
      .addCase(getJobDetail.fulfilled, (state, action) => {
        state.jobDetail.isLoading = false
        state.jobDetail.data = action.payload
      })
      .addCase(getJobDetail.rejected, (state) => {
        state.jobDetail.isLoading = false
      })
      .addCase(convertDriverToBase64.pending, (state) => {
        state.candidateJob.isLoadingBase64 = true
      })
      .addCase(convertDriverToBase64.fulfilled, (state, action) => {
        const { base64 = '', candidateId: originalCandidateId = '' } =
          action.payload || {}
        const { id: candidateId = '' } = state.candidateJob.candidateId || ''
        if (originalCandidateId !== candidateId) return

        state.candidateJob.isLoadingBase64 = false
        state.candidateJob.base64 = base64
      })
      .addCase(convertDriverToBase64.rejected, (state) => {
        state.candidateJob.isLoadingBase64 = false
      })
      .addCase(getJobActivity.pending, (state) => {
        state.jobActivity.isLoading = true
        state.jobActivity.data = []
      })
      .addCase(getJobActivity.fulfilled, (state, action) => {
        state.jobActivity.isLoading = false
        state.jobActivity.data = action.payload || []
      })
      .addCase(getJobActivity.rejected, (state) => {
        state.jobActivity.isLoading = false
      })
      .addCase(getTrelloUsers.pending, (state) => {
        state.trelloUser.isLoading = true
        state.trelloUser.data = []
      })
      .addCase(getTrelloUsers.fulfilled, (state, action) => {
        state.trelloUser.isLoading = false
        state.trelloUser.data = action.payload
      })
      .addCase(getTrelloUsers.rejected, (state) => {
        state.trelloUser.isLoading = false
      })
      .addCase(getListReferCandidate.pending, (state) => {
        state.listReferCandidate.isLoading = true
        state.listReferCandidate.data = {}
      })
      .addCase(getListReferCandidate.fulfilled, (state, action) => {
        state.listReferCandidate.isLoading = false
        state.listReferCandidate.data = action.payload
      })
      .addCase(getListReferCandidate.rejected, (state) => {
        state.listReferCandidate.isLoading = false
      })
      .addCase(getDetailCV.pending, (state) => {
        state.detailCV.isLoading = true
        state.detailCV.data = {}
        state.detailCV.isError = false
      })
      .addCase(getDetailCV.fulfilled, (state, action) => {
        state.detailCV.isLoading = false
        state.detailCV.data = action.payload
        state.detailCV.isError = false
      })
      .addCase(getDetailCV.rejected, (state) => {
        state.detailCV.isLoading = false
        state.detailCV.isError = true
      })
  },
})

export const {
  updateAssignUser,
  resetCandidateDetail,
  setCandidateDetail,
  setListAssignUser,
  updateMemberInChargeToCandidateDetail,
} = jobDetailSlice.actions

export const selectJobDetail = createSelector(
  [(state) => state.jobDetail.jobDetail],
  (jobDetail) => jobDetail
)

export const selectAssignListUser = createSelector(
  [(state) => state.jobDetail.assignListUser],
  (assignListUser) => assignListUser
)

export const selectAssignUser = createSelector(
  [(state) => state.jobDetail.assignUser],
  (assignUser) => assignUser
)

export const selectCandidateJob = createSelector(
  [(state) => state.jobDetail.candidateJob],
  (candidateJob) => candidateJob
)

export const selectCandidateStatus = createSelector(
  [(state) => state.jobDetail.listCandidateStatus],
  (listCandidateStatus) => listCandidateStatus
)

export const selectJobActivity = createSelector(
  [(state) => state.jobDetail.jobActivity],
  (jobActivity) => jobActivity
)

export const selectTrelloUser = createSelector(
  [(state) => state.jobDetail.trelloUser],
  (trelloUser) => trelloUser
)

export const selectListReferCandidates = createSelector(
  [(state) => state.jobDetail.listReferCandidate],
  (listReferCandidate) => listReferCandidate
)

export const selectDetailCV = createSelector(
  [(state) => state.jobDetail.detailCV],
  (detailCV) => detailCV
)

export default jobDetailSlice.reducer
