const interview = {
  heading: 'List interview',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  timeInterview: 'Time interview',
  timeEnd: 'Time end',
  action: 'Actions',
  newInterview: 'New interview',
  editDetails: 'Edit details',
  reviewCandidate: 'Review Candidate',
  enterYourEmailSearch: 'Enter your email search',
  password: 'Zoom password',
  confirmMessage: 'Are you sure you want to delete interview',
  deleteSuccess: 'Delete interview success!',
  deleteFail: 'Delete interview fail!',
  updateSuccess: 'Update interview success!',
  updateFail: 'Update interview fail!',
  interviewer: 'Interviewer',
  nameJob: 'Name job',
  candidate: 'Candidate',
  location: 'Location',
  linkZoom: 'Link zoom',
  timeInterviewEnd: 'Time interview end',
  type: 'Type',
  editInterview: 'Edit interview',
  addInterview: 'Add interview',
  detailInterview: 'Interview detail',
  typeIsRequired: 'Type is required',
  jobIsRequired: 'Job is required',
  candidateIsRequired: 'Candidate is required',
  interviewAssessmentForm: 'Interview Assessment Form',
  interviewFeedbackForm: 'Feedback/Note',
  recommentedForNextRound: 'Recommended for next round',
  otherComment: 'Other comment',
  teamWork: 'Team work',
  motivation: 'Motivation to work',
  personalAttitude: 'Personal Attitude',
  languageAbility: 'Language Ability',
  workExperience: 'Work Experience',
  professionalKnowledge: 'Professional knowledge',
  abilityFactor: 'Ability Factors',
  positionApplied: 'Position applied',
  suggestionPosition: 'Suggestion position',
  interviewDate: 'Interview Date',
  candidateName: 'Name of candidate',
  placeholderFrom: 'Enter here',
  addSuccess: 'Add interview success!',
  addFail: 'Add interview fail!',
  linkZoomIsRequired: 'Link zoom is required',
  maxLengthPassword: 'Password not must be more than 10 characters',
  timeInterviewStartRequired: 'Time interview is requied',
  timeInterviewEndRequired: 'Time interview end is required',
}

export default interview
