import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { CREATE_REFINE_CV_TYPE } from 'components/cv-form/config'

import {
  API_CANDIDATES,
  API_CHECK_SHARE_CANDIDATE,
  API_COMMENT,
  API_LIST_COMMENT,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const checkShareCV = createAsyncThunk(
  'cvForm/checkShareCV',
  async (data) => {
    try {
      const response = await _postApi(API_CHECK_SHARE_CANDIDATE, data)

      if (!response?.data?.success) {
        throw new Error(response?.message || response?.data?.message)
      }

      return response?.data || {}
    } catch (error) {
      const { message } = error || {}

      if (Array.isArray(message)) {
        throw new Error(message.join(', '))
      }

      throw new Error(message)
    }
  }
)

export const getListComments = createAsyncThunk(
  'cvForm/getListComments',
  async (cardId) => {
    const response = await _getApi(`${API_LIST_COMMENT}/${cardId}`)

    return response?.data?.list || []
  }
)

export const addComment = createAsyncThunk(
  'cvForm/addComment',
  async (data) => {
    const response = await _postApi(`${API_LIST_COMMENT}/${data.cardId}`, {
      content: data.content,
    })

    return response?.data?.data?.cards || {}
  }
)

export const editComment = createAsyncThunk(
  'cvForm/editComment',
  async (data) => {
    const response = await _patchApi(`${API_COMMENT}/${data.id}`, {
      content: data.content,
    })
    return response?.data?.comment
  }
)

export const getLinkRefineCV = createAsyncThunk(
  'cvForm/getLinkRefineCV',
  async ({ candidateId }) => {
    const response = await _getApi(`${API_CANDIDATES}/${candidateId}/refine-cv`)

    return response?.data?.linkRefineCv || ''
  }
)

const cvFormSlice = createSlice({
  name: 'cvForm',
  initialState: {
    listComments: [],
    refineCV: {
      isLoadingDownload: false,
      isLoadingCopy: false,
      isLoading: false,
      data: '',
    },
  },
  reducers: {
    resetListComment(state) {
      state.listComments = []
    },
    resetNewCreatedRefineCv(state) {
      state.refineCV = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListComments.fulfilled, (state, action) => {
        state.listComments = action.payload
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.listComments.unshift(action.payload)
      })
      .addCase(editComment.fulfilled, (state, action) => {
        const commentIndex = state.listComments.findIndex(
          (comment) => comment.id === action.payload.id
        )
        state.listComments[commentIndex].content = action.payload.content
        state.listComments[commentIndex].updatedAt = action.payload.updatedAt
      })
      .addCase(getLinkRefineCV.pending, (state, action) => {
        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.DOWNLOAD) {
          state.refineCV.isLoadingDownload = true
        }

        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.COPY) {
          state.refineCV.isLoadingCopy = true
        }

        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.CREATE) {
          state.refineCV.isLoading = true
        }
      })
      .addCase(getLinkRefineCV.fulfilled, (state, action) => {
        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.DOWNLOAD) {
          state.refineCV.isLoadingDownload = false
        }

        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.COPY) {
          state.refineCV.isLoadingCopy = false
        }

        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.CREATE) {
          state.refineCV.isLoading = false
        }

        state.refineCV.data = action.payload
      })
      .addCase(getLinkRefineCV.rejected, (state, action) => {
        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.DOWNLOAD) {
          state.refineCV.isLoadingDownload = false
        }

        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.COPY) {
          state.refineCV.isLoadingCopy = false
        }

        if (action?.meta?.arg?.type === CREATE_REFINE_CV_TYPE.CREATE) {
          state.refineCV.isLoading = false
        }
      })
  },
})

export const { resetListComment, resetNewCreatedRefineCv } = cvFormSlice.actions

export const selectListComments = createSelector(
  [(state) => state.cvForm.listComments],
  (listComments) => listComments
)

export const selectLinkRefineCV = createSelector(
  [(state) => state.cvForm.refineCV],
  (refineCV) => refineCV
)

export default cvFormSlice.reducer
