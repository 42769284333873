const candidates = {
  heading: 'Danh sách ứng viên',
  email: 'Địa chỉ email',
  enterEmail: 'Nhập email',
  name: 'Tên',
  enterName: 'Nhập tên ',
  phone: 'Điện thoại',
  enterPhone: 'Nhập số điện thoại',
  text: 'Chữ',
  enterText: 'Nhập chữ',
  job: 'Công việc',
  selectJob: 'Chọn công việc',
  skill: 'Kĩ năng',
  selectKill: 'Chọn kỹ năng',
  search: 'Tìm kiếm',
  jobApply: 'Công việc ứng tuyển',
  timeApply: 'Thời gian ứng tuyển',
  source: 'Nguồn',
  column: 'Cột',
  follower: 'Người theo dõi',
  jobName: 'Công việc',
  location: 'Địa điểm',
  client: 'Tên khách hàng',
  approachDate: 'Ngày tiếp cận',
  linkCV: 'Liên kết CV',
  placeholderLink: 'Nhập link hoặc tải lên CV',
  position: 'Vị trí',
  notApproach: 'Lưu ý',
  rawCV: 'Xem CV',
  closeCV: 'Đóng CV',
  viewDetail: 'Xem chi tiết',
  status: 'Trạng thái',
  Bonus: 'Điểm thưởng',
  passed: 'đã pass',
  for: 'của job',
  youGot: 'Bạn nhận được',
  points: 'điểm',
  getListCandidatesError:
    'Tải danh sách ứng viên thất bải! Vui lòng thử lại sau',
  detailCandidate: 'Thông tin ứng viên',
  listComment: 'Danh sách bình luận',
}

export default candidates
