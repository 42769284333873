import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_BLOG, API_BLOG_KEYWORD, API_BLOG_TAG } from 'routes/api'

import { _deleteApi, _getApi, _patchApi, _postApi } from 'utils/axios'

export const getListBlogs = createAsyncThunk(
  'blog/getListBlogs',
  async (queries = {}) => {
    const url = `${API_BLOG}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const deletePost = createAsyncThunk(
  'blog/deletePost',
  async (postId) => {
    const response = await _deleteApi(`${API_BLOG}/${postId}`)

    return response?.data || {}
  }
)

export const getListTags = createAsyncThunk('blog/getListTags', async () => {
  const response = await _getApi(API_BLOG_TAG)

  return response?.data?.list || []
})

export const createNewTag = createAsyncThunk(
  'blog/createNewTag',
  async (data) => {
    const response = await _postApi(API_BLOG_TAG, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const getListKeywords = createAsyncThunk(
  'blog/getListKeywords',
  async () => {
    const response = await _getApi(API_BLOG_KEYWORD)

    return response?.data?.list || []
  }
)

export const createNewKeyword = createAsyncThunk(
  'blog/createNewKeyword',
  async (data) => {
    const response = await _postApi(API_BLOG_KEYWORD, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const getDetailBlog = createAsyncThunk(
  'blog/getDetailBlog',
  async (slug) => {
    const response = await _getApi(`${API_BLOG}/${slug}`)

    return response?.data?.blog || {}
  }
)

export const createBlog = createAsyncThunk('blog/createBlog', async (data) => {
  const response = await _postApi(API_BLOG, data)

  if (!response?.data?.success) {
    throw new Error(response?.message || response?.data?.message)
  }
})

export const updateBlog = createAsyncThunk(
  'blog/updateBlog',
  async ({ data = {}, id = '' }) => {
    const response = await _patchApi(`${API_BLOG}/${id}`, data)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const getDetailBlogPage = createAsyncThunk(
  'blog/getDetailBlogPage',
  async (slug) => {
    const response = await _getApi(`${API_BLOG}/${slug}`)

    return response?.data || {}
  }
)

export const getListRelatedPost = createAsyncThunk(
  'blog/getListRelatedPost',
  async ({ postId = '', queries = {} }) => {
    const url = `${API_BLOG}/${postId}/related?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data?.list || []
  }
)

const initialState = {
  listBlogs: {
    isLoading: false,
    data: {},
  },
  updatingBlog: {},
  listTags: [],
  listKeywords: [],
  detailBlog: {
    data: {},
    isLoading: false,
  },
  listRelatedPost: {
    data: [],
    isLoading: false,
  },
}

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    resetUpdatingBlog: (state) => {
      state.updatingBlog = {}
    },
    resetDetailBlog: (state) => {
      state.detailBlog.data = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListBlogs.pending, (state) => {
        state.listBlogs.isLoading = true
      })
      .addCase(getListBlogs.fulfilled, (state, action) => {
        state.listBlogs.data = action.payload
        state.listBlogs.isLoading = false
      })
      .addCase(getListBlogs.rejected, (state) => {
        state.listBlogs.isLoading = false
      })
      .addCase(getDetailBlog.fulfilled, (state, action) => {
        state.updatingBlog = action.payload
      })
      .addCase(getListTags.fulfilled, (state, action) => {
        state.listTags = action.payload?.map(({ id = '', title = '' }) => ({
          value: id,
          label: title,
        }))
      })
      .addCase(getListKeywords.fulfilled, (state, action) => {
        state.listKeywords = action.payload?.map(({ id = '', title = '' }) => ({
          value: id,
          label: title,
        }))
      })
      .addCase(getDetailBlogPage.pending, (state) => {
        state.detailBlog.isLoading = true
      })
      .addCase(getDetailBlogPage.fulfilled, (state, action) => {
        state.detailBlog.isLoading = false
        state.detailBlog.data = action.payload
      })
      .addCase(getDetailBlogPage.rejected, (state) => {
        state.detailBlog.isLoading = false
      })
      .addCase(getListRelatedPost.pending, (state) => {
        state.listRelatedPost.isLoading = true
      })
      .addCase(getListRelatedPost.fulfilled, (state, action) => {
        state.listRelatedPost.isLoading = false
        state.listRelatedPost.data = action.payload
      })
      .addCase(getListRelatedPost.rejected, (state) => {
        state.listRelatedPost.isLoading = false
      })
  },
})

export default blogSlice.reducer

export const { resetUpdatingBlog, resetDetailBlog } = blogSlice.actions

export const selectListBlogs = createSelector(
  [(state) => state.blog.listBlogs],
  (listBlogs) => listBlogs
)

export const selectListTags = createSelector(
  [(state) => state.blog.listTags],
  (listTags) => listTags
)

export const selectListKeywords = createSelector(
  [(state) => state.blog.listKeywords],
  (listKeywords) => listKeywords
)

export const selectDetailBlogPage = createSelector(
  [(state) => state.blog.detailBlog],
  (detailBlog) => detailBlog
)

export const selectListRelatedPost = createSelector(
  [(state) => state.blog.listRelatedPost],
  (listRelatedPost) => listRelatedPost
)

export const selectUpdatingBlog = createSelector(
  [(state) => state.blog.updatingBlog],
  (updatingBlog) => updatingBlog
)
