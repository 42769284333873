import {
  META_DESCRIPTION_MAX_CHARACTER,
  META_TITLE_MAX_CHARACTER,
} from 'components/post-form/config'

const blog = {
  addNewPost: 'Thêm bài viết mới',
  editPost: 'Sửa bài viết',
  deleteSuccess: 'Xóa bài viết thành công!',
  deleteFail: 'Xóa bài viết thất bại!',
  confirmDeleteMessage: 'Bạn có chắc muốn xóa bài viết',
  confirmShareMessage: 'Bạn có chắc chắn muốn chuyển đổi chia sẻ',
  toggleShareSuccess: 'Chuyển đổi chia sẻ Fetch New thành công',
  toggleShareFail: 'Chuyển đổi chia sẻ Fetch New thất bại',
  changePinStatusSuccess: 'Thay đổi trạng thái ghim thành công',
  changePinStatusFail: 'Thay đổi trạng thái ghim không thất bại',
  from: 'từ',
  post: 'Đăng bài',
  preview: 'Xem trước',
  coverImage: 'Ảnh bìa',
  postTitle: 'Tiêu đề bài viết',
  description: 'Mô tả',
  content: 'Nội dung',
  publish: 'Công khai',
  enableComments: 'Cho phép bình luận',
  tag: 'Thẻ',
  tags: 'Thẻ',
  metaTitle: 'Tiêu đề meta',
  metaDescription: 'Mô tả meta',
  metaKeywords: 'Từ khóa meta',
  characterRemainMessage: 'ký tự còn lại.',
  postSuccess: 'Đăng bài thành công!',
  postFail: 'Đăng bài thất bại!',
  updateBlogSuccess: 'Cập nhật bài viết thành công!',
  updateBlogFail: 'Cập nhật bài viết thất bại!',
  titleRequired: 'Tiêu đề bài viết là bắt buộc',
  descriptionRequired: 'Mô tả là bắt buộc',
  contentRequired: 'Nội dung là bắt buộc',
  coverImageRequired: 'Ảnh bìa là bắt buộc',
  tagsRequired: 'Thẻ là bắt buộc',
  metaTitleRequired: 'Tiêu đề meta là bắt buộc',
  metaTitleMaxCharacters: `Tiêu đề meta tối đa ${META_TITLE_MAX_CHARACTER} ký tự`,
  metaDescriptionRequired: 'Mô tả meta là bắt buộc',
  metaDescriptionMaxCharacters: `Mô tả meta tối đa ${META_DESCRIPTION_MAX_CHARACTER} ký tự`,
  metaKeywordsRequired: 'Từ khóa meta là bắt buộc',
  createTag: 'Tạo thẻ mới',
  newTag: 'Thẻ mới',
  tagExist: 'Thẻ đã tồn tại!',
  createTagSuccess: 'Tạo thẻ thành công!',
  createTagFailed: 'Tạo thẻ thất bại!',
  createKeyword: 'Tạo từ khóa mới',
  newKeyword: 'Từ khóa mới',
  keywordExist: 'Từ khóa đã tồn tại!',
  createKeywordSuccess: 'Tạo từ khóa thành công!',
  createKeywordFailed: 'Tạo từ khóa thất bại!',
  uploadImageFailed: 'Tải lên ảnh thất bại!',
  previewPost: 'Xem trước bài viết',
  dropSelectImageMessage: 'Thả hoặc lựa chọn ảnh bìa',
  dropOrClickMessage: 'Thả ảnh vào đây hoặc click',
  thoroughMachineMessage: 'qua máy của bạn',
  metaTitleMessage:
    'Đây là tiêu đề trang mà Google và hầu hết các công cụ tìm kiếm khác hiển thị trong kết quả tìm kiếm.',
  metaDescriptionMessage:
    'Mô tả meta tóm tắt nội dung của trang. Các công cụ tìm kiếm thường sử dụng nó cho đoạn trích trong kết quả tìm kiếm.',
  metaKeywordsDescription:
    'Từ khóa meta là thẻ meta mà bạn có thể sử dụng để cung cấp cho công cụ tìm kiếm thêm thông tin về nội dung trang',
  createdTime: 'Ngày tạo',
  lastestUpdate: 'Lần cập nhật gần nhất',
  imageCoverMessage:
    'Bạn nên sử dụng ảnh với tỷ lệ tương đương 1200 x 628px để có được hiển thị tốt nhất cho SEO',
  relatedPosts: 'Các bài viết liên quan',
}

export default blog
