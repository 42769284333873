export const TEXT_COLORS = [
  '#ee5253',
  '#27ae60',
  '#5f27cd',
  '#f368e0',
  '#ff6348',
]

export const TABLE_DESKTOP_HEAD = [
  {
    id: 'name',
    label: 'pages.dashboard.interviewSchedule.name',
    align: 'left',
  },
  {
    id: 'link_zoom',
    label: 'pages.dashboard.interviewSchedule.linkZoom',
    align: 'left',
  },
  {
    id: 'location',
    label: 'pages.dashboard.interviewSchedule.location',
    align: 'left',
  },
  {
    id: 'time_start',
    label: 'pages.dashboard.interviewSchedule.timeStart',
    align: 'left',
  },
  {
    id: 'time_end',
    label: 'pages.dashboard.interviewSchedule.timeEnd',
    align: 'left',
  },
]

export const TABLE_MOBILE_HEAD = [
  {
    id: 'name',
    label: 'pages.dashboard.interviewSchedule.name',
    align: 'left',
  },
]

export const CALENDAR_CONFIG = {
  initialDate: new Date(),
  rerenderDelay: 10,
  initialView: 'dayGridMonth',
  dayMaxEventRows: 3,
  headerToolbar: false,
  allDayMaintainDuration: true,
  eventResizableFromStart: true,
}
