const notification = {
  heading: 'Danh sách thông báo',
  message: 'Thông báo',
  time: 'Thời gian',
  viewDetail: 'Chi tiết',
  detail: 'Chi tiết',
  name: 'Tên',
  user: 'Thành viên',
  type: 'Loại thông báo',
  timeStartCard: 'Thời gian bắt đầu',
  timeEndCard: 'Thời gian kết thúc',
  assignCard: 'Assign Card',
  assignJob: 'Assign Job',
  assignTask: 'Assign Task',
  jobOverTime: 'Job Over Time',
  updatePoint: 'Update Point',
  updateStatusCandidate: 'Update Status Candidate',
  redeemPoint: 'Redeem Point',
  buyCV: 'Buy CV',
  sellCV: 'Sell CV',
  verifyCV: 'Verify CV',
  uploadCV: 'Upload CV',
  markDoneCard: 'Mark done card',
  markAsReadSuccess: 'Đánh dấu đã đọc thông báo thành công',
  markAsReadFail: 'Đánh dấu đã đọc thông báo thất bại',
  referUser: 'Giới thiệu người dùng',
  commentCard: 'Bình luận',
  clientRequestJob: 'Công việc từ khách hàng',
  reportProblem: 'Báo cáo vấn đề',
  quizGame: 'Quiz Game',
}

export default notification
