import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { ROLE } from 'config'

import {
  API_BANK_RECRUITER,
  API_BANK_RECRUITER_UPLOADS,
  API_CHANGE_PASSWORD,
  API_LIST_JOBS_PROFILE,
  API_LIST_REFERRAL_USER,
  API_MEMBER_ACTIVITIES_USER_INFO,
  API_REFER_FRIEND,
  API_REFFERAL_INPUT_CODE,
  API_UPLOAD_AVATAR_PROFILE,
  API_USER_HISTORY_SALARY,
} from 'routes/api'

import { _getApi, _patchApi, _postApi, _uploadApi } from 'utils/axios'

export const uploadProfileAvatar = createAsyncThunk(
  'account/uploadProfileAvatar',
  async (data = {}) => {
    const response = await _uploadApi(`${API_UPLOAD_AVATAR_PROFILE}`, data)
    return response?.data?.avatar || ''
  }
)

export const getUserProfile = createAsyncThunk(
  'account/getUserProfile',
  async (userId = '') => {
    const url = `${API_MEMBER_ACTIVITIES_USER_INFO}/${userId}`
    const response = await _getApi(url)

    if (!response?.data?.success)
      throw new Error(
        response?.message || response?.data?.message || response?.data?.error
      )

    return response?.data?.user || {}
  }
)

export const getListJobs = createAsyncThunk(
  'account/getListJobs',
  async (queries = {}) => {
    const url = `${API_LIST_JOBS_PROFILE}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const changePassword = createAsyncThunk(
  'account/changePassword',
  async (data) => {
    const response = await _patchApi(API_CHANGE_PASSWORD, data)

    if (!response?.data?.success) {
      throw new Error(response?.message)
    }
  }
)
export const getRecruiterProfile = createAsyncThunk(
  'account/getRecruiterProfile',
  async (recruiterId = '') => {
    const url = `${API_BANK_RECRUITER}/${recruiterId}`
    const response = await _getApi(url)

    return response?.data?.bank || {}
  }
)

export const updateRecruiterProfile = createAsyncThunk(
  'account/updateRecruiterProfile',
  async (data = '') => {
    const url = `${API_BANK_RECRUITER}`
    const response = await _postApi(url, data)

    return response?.data || {}
  }
)

export const upLoadImage = createAsyncThunk(
  'account/upLoadImage',
  async (data = {}) => {
    const response = await _uploadApi(`${API_BANK_RECRUITER_UPLOADS}`, data)
    return response?.data || ''
  }
)

export const updateReferralInputCode = createAsyncThunk(
  'account/updateReferralInputCode',
  async (data = {}) => {
    const response = await _postApi(`${API_REFFERAL_INPUT_CODE}`, data)

    return response?.data || {}
  }
)

export const getListReferralUser = createAsyncThunk(
  'account/getListReferralUser',
  async (userId = '') => {
    const response = await _getApi(`${API_LIST_REFERRAL_USER}/${userId}`)

    return response?.data?.listReferral || {}
  }
)

export const getUserHistorySalary = createAsyncThunk(
  'account/getUserHistorySalary',
  async (userId = '') => {
    const response = await _getApi(`${API_USER_HISTORY_SALARY}/${userId}`)

    return response?.data?.historySalaryUser?.HistoryKpi || []
  }
)

export const sendEmailReferFriend = createAsyncThunk(
  'account/sendEmailReferFriend',
  async (data) => {
    const response = await _postApi(API_REFER_FRIEND, data)

    return response?.data || {}
  }
)

const initialState = {
  userAvatar: {
    avatar: '',
  },
  userProfile: {
    isLoading: false,
    data: {},
  },
  listJobs: {
    isLoading: false,
    data: {},
  },
  recruiterProfile: {
    isLoading: false,
    data: {},
  },
  listReferralUsers: {
    isLoading: false,
    data: [],
  },
  userHistorySalary: {
    isLoading: false,
    data: [],
  },
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetHistorySalary(state) {
      state.userHistorySalary.isLoading = false
      state.userHistorySalary.data = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadProfileAvatar.fulfilled, (state, action) => {
        state.userAvatar.avatar = action.payload
      })
      .addCase(getUserProfile.pending, (state) => {
        state.userProfile.isLoading = true
        state.userProfile.data = {}
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const roleInfo = action.payload?.Role || {}

        state.userProfile.isLoading = false
        state.userProfile.data = {
          ...action.payload,
          isBloggerRole: ROLE.BLOGGER === roleInfo?.name,
        }
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.userProfile.isLoading = false
      })
      .addCase(getListJobs.pending, (state) => {
        state.listJobs.isLoading = true
        state.listJobs.data = {}
      })
      .addCase(getListJobs.fulfilled, (state, action) => {
        state.listJobs.isLoading = false
        state.listJobs.data = action.payload
      })
      .addCase(getListJobs.rejected, (state) => {
        state.listJobs.isLoading = false
      })
      .addCase(getRecruiterProfile.pending, (state) => {
        state.recruiterProfile.isLoading = true
        state.recruiterProfile.data = {}
      })
      .addCase(getRecruiterProfile.fulfilled, (state, action) => {
        state.recruiterProfile.isLoading = false
        state.recruiterProfile.data = action.payload
      })
      .addCase(getRecruiterProfile.rejected, (state) => {
        state.recruiterProfile.isLoading = false
        state.recruiterProfile.data = {}
      })
      .addCase(getListReferralUser.pending, (state) => {
        state.listReferralUsers.isLoading = true
        state.listReferralUsers.data = []
      })
      .addCase(getListReferralUser.fulfilled, (state, action) => {
        state.listReferralUsers.isLoading = false
        state.listReferralUsers.data = action.payload
      })
      .addCase(getListReferralUser.rejected, (state) => {
        state.listReferralUsers.isLoading = false
      })
      .addCase(getUserHistorySalary.pending, (state) => {
        state.userHistorySalary.isLoading = true
        state.userHistorySalary.data = []
      })
      .addCase(getUserHistorySalary.fulfilled, (state, action) => {
        state.userHistorySalary.isLoading = false
        state.userHistorySalary.data = action.payload
      })
      .addCase(getUserHistorySalary.rejected, (state) => {
        state.userHistorySalary.isLoading = false
      })
  },
})

export const { resetHistorySalary } = accountSlice.actions

export const selectUserProfile = createSelector(
  [(state) => state.account.userProfile],
  (userProfile) => userProfile
)

export const selectListJobs = createSelector(
  [(state) => state.account.listJobs],
  (listJobs) => listJobs
)

export const selectRecruiterProfile = createSelector(
  [(state) => state.account.recruiterProfile],
  (recruiterProfile) => recruiterProfile
)

export const selectListReferralUsers = createSelector(
  [(state) => state.account.listReferralUsers],
  (listReferralUsers) => listReferralUsers
)

export const selectUserHistorySalary = createSelector(
  [(state) => state.account.userHistorySalary],
  (userHistorySalary) => userHistorySalary
)

export default accountSlice.reducer
