import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_ADMIN_ACTIVE_CLIENT, API_ADMIN_CLIENTS_LIST } from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const getAdminClientList = createAsyncThunk(
  'client/getAdminClientList',
  async (queries = {}) => {
    const url = `${API_ADMIN_CLIENTS_LIST}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const createClient = createAsyncThunk(
  'client/createClient',
  async (data) => {
    const url = API_ADMIN_CLIENTS_LIST
    const response = await _postApi(url, data)

    return response?.data || {}
  }
)

export const updateClient = createAsyncThunk(
  'client/updateClient',
  async ({ clientId = '', data = {} }) => {
    const url = `${API_ADMIN_CLIENTS_LIST}/${clientId}`
    const response = await _patchApi(url, data)

    return response?.data || {}
  }
)

export const toggleClientStatus = createAsyncThunk(
  'client/toggleClientStatus',
  async (clientId) => {
    const url = `${API_ADMIN_ACTIVE_CLIENT}/${clientId}`
    const response = await _patchApi(url)

    return response?.data || {}
  }
)

const initialState = {
  listClient: {
    isLoading: false,
    data: {},
  },
}

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  // extra reducers set get column to state
  extraReducers: (builder) => {
    builder
      .addCase(getAdminClientList.pending, (state) => {
        state.listClient.isLoading = true
        state.listClient.data = {}
      })
      .addCase(getAdminClientList.fulfilled, (state, action) => {
        state.listClient.isLoading = false
        state.listClient.data = action.payload
      })
      .addCase(getAdminClientList.rejected, (state) => {
        state.listClient.isLoading = false
      })
      .addCase(toggleClientStatus.fulfilled, (state, action) => {
        const { arg: clientId = '' } = action.meta || {}
        const { success = false } = action.payload || {}
        const client = state.listClient?.data?.list?.find(
          (item) => item.id === clientId
        )

        if (!success || !client) return

        client.isActive = !client.isActive
      })
  },
})

export const selectListClients = createSelector(
  [(state) => state.client.listClient],
  (listClient) => listClient
)

export default clientSlice.reducer
