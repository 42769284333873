import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { API_LIST_REDEEM_BONUS } from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'

export const getListRedeemBonus = createAsyncThunk(
  'manageRedeem/getListRedeemBonus',
  async (queries = {}) => {
    const response = await _getApi(API_LIST_REDEEM_BONUS, { params: queries })
    return response?.data?.orders || {}
  }
)

export const updateStatusRedeemPoint = createAsyncThunk(
  'manageRedeem/updateStatusRedeemPoint',
  async ({ id, data = {}, headers = {} }) => {
    const url = `${API_LIST_REDEEM_BONUS}/${id}/status`
    const response = await _patchApi(url, data, headers)

    return response?.data || {}
  }
)

const manageRedeemSlice = createSlice({
  name: 'manageRedeem',
  initialState: {
    listRedeemBonus: {
      data: {},
      isLoading: false,
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListRedeemBonus.pending, (state) => {
        state.listRedeemBonus.isLoading = true
      })
      .addCase(getListRedeemBonus.fulfilled, (state, action) => {
        state.listRedeemBonus.isLoading = false
        state.listRedeemBonus.data = action.payload
      })
      .addCase(getListRedeemBonus.rejected, (state) => {
        state.listRedeemBonus.isLoading = false
      })
  },
})

export const selectListRedeemBonus = createSelector(
  [(state) => state.manageRedeem.listRedeemBonus],
  (listRedeemBonus) => listRedeemBonus
)

export default manageRedeemSlice.reducer
