const header = {
  notification: {
    title: 'Notifications',
    youHave: 'You have',
    unreadMessages: 'unread messages',
    beforeThat: 'Before that',
    viewAll: 'View All',
    markAsRead: 'Mark all as read',
  },
  account: {
    logoutFail: 'Unable to logout!',
  },
  languages: {
    en: 'English',
    vn: 'Vietnamese',
  },
  subHeader: {
    overview: 'Overview',
    hiring: 'Hiring',
    team: 'Team',
    tool: 'Tool',
    setting: 'Setting',
    other: 'Other',
    management: 'Management',
    refering: 'Referring',
    marketplace: 'Marketplace',
    transactions: 'Transactions',
    support: 'Support',
  },
}

export default header
