import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import {
  API_CANDIDATES,
  API_CHECK_SHARE_CANDIDATE,
  API_CV_RECRUITER,
  API_HISTORY,
} from 'routes/api'

import { _getApi, _patchApi, _postApi } from 'utils/axios'

export const getListCV = createAsyncThunk(
  'userManageCV/getListCV',
  async (queries = {}) => {
    const response = await _getApi(API_CV_RECRUITER, { params: queries })
    return response?.data || {}
  }
)

export const getDetailCV = createAsyncThunk(
  'userManageCv/getDetailCV',
  async (id) => {
    const response = await _getApi(`${API_CANDIDATES}/${id}/detail`)

    return response?.data?.cv || {}
  }
)

export const uploadCV = createAsyncThunk('marketCV/uploadCV', async (data) => {
  const {
    isPublic = false,
    email = '',
    phone = '',
    isAllowOverWrite = false,
    hasCheckShare = true,
  } = data || {}

  if (!isPublic && !isAllowOverWrite && hasCheckShare) {
    const response = await _postApi(API_CHECK_SHARE_CANDIDATE, { email, phone })

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }

    return response?.data || {}
  }

  const response = await _postApi(`${API_CANDIDATES}/create`, data)

  if (!response?.data?.success) {
    throw new Error(response?.message || response?.data?.message)
  }

  return response?.data || {}
})

export const unlockCV = createAsyncThunk(
  'userManageCV/unlockCV',
  async ({ id }) => {
    const response = await _patchApi(`${API_CANDIDATES}/${id}/buy`)

    if (!response?.data?.success) {
      throw new Error(response?.message || response?.data?.message)
    }
  }
)

export const getCVHistoryAction = createAsyncThunk(
  'userManageCV/getCVHistoryAction',
  async ({ id }) => {
    const response = await _getApi(`${API_HISTORY}/${id}/candidate`)

    return response?.data?.list || []
  }
)

const initialState = {
  listCV: {
    isLoading: false,
    data: {},
  },
  detailCV: {
    data: {},
    isLoading: false,
    isError: false,
  },
  cvHistoryAction: {
    isLoading: false,
    data: [],
  },
}

const marketCVSlice = createSlice({
  name: 'marketCV',
  initialState,
  reducers: {
    resetDetailCV: (state) => {
      state.detailCV = {}
    },
    resetHistoryAction: (state) => {
      state.cvHistoryAction = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListCV.pending, (state) => {
        state.listCV.isLoading = true
      })
      .addCase(getListCV.fulfilled, (state, action) => {
        state.listCV.isLoading = false
        state.listCV.data = action.payload
      })
      .addCase(getListCV.rejected, (state) => {
        state.listCV.isLoading = false
      })
      .addCase(getDetailCV.pending, (state) => {
        state.detailCV.isLoading = true
        state.detailCV.data = {}
        state.detailCV.isError = false
      })
      .addCase(getDetailCV.fulfilled, (state, action) => {
        state.detailCV.isLoading = false
        state.detailCV.data = action.payload
        state.detailCV.isError = false
      })
      .addCase(getDetailCV.rejected, (state) => {
        state.detailCV.isLoading = false
        state.detailCV.isError = true
      })
      .addCase(getCVHistoryAction.pending, (state) => {
        state.cvHistoryAction.isLoading = true
        state.cvHistoryAction.data = []
      })
      .addCase(getCVHistoryAction.fulfilled, (state, action) => {
        state.cvHistoryAction.isLoading = false
        state.cvHistoryAction.data = action.payload
      })
      .addCase(getCVHistoryAction.rejected, (state) => {
        state.cvHistoryAction.isLoading = false
      })
  },
})

export default marketCVSlice.reducer

export const { resetDetailCV, resetHistoryAction } = marketCVSlice.actions

export const selectListCV = createSelector(
  [(state) => state.marketCV.listCV],
  (listCV) => listCV
)

export const selectDetailCV = createSelector(
  [(state) => state.marketCV.detailCV],
  (detailCV) => detailCV
)

export const selectCVHistoryAction = createSelector(
  [(state) => state.marketCV.cvHistoryAction],
  (cvHistoryAction) => cvHistoryAction
)
