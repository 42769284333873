import { ROLE } from 'config'

import { fNumberWithDot } from 'utils/formatNumber'
import { replaceAll } from 'utils/replace'

export const JOB_ALL_STATUS = 'All'
export const JOB_ACTIVE_STATUS = 'Active'
export const JOB_CLOSE_STATUS = 'Close'
export const JOB_PENDING_STATUS = 'Pending'
export const JOB_ARCHIVE_STATUS = 'Archive'
export const JOB_PROCESS_STATUS = 'Process'
export const JOB_CLIENT_LABEL = 'Client'
export const JOB_CLIENT_STATUS = 'clientRequest'

export const JOB_STATUS_OPTIONS = (currentRole) => [
  {
    value: JOB_ACTIVE_STATUS,
    label: JOB_ACTIVE_STATUS,
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
      ROLE.CLIENT,
    ],
  },
  {
    value: JOB_CLOSE_STATUS,
    label: JOB_CLOSE_STATUS,
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
      ROLE.CLIENT,
    ],
  },
  {
    value: JOB_PENDING_STATUS,
    label: JOB_PENDING_STATUS,
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
      ROLE.CLIENT,
    ],
  },
  {
    value: JOB_ARCHIVE_STATUS,
    label: JOB_ARCHIVE_STATUS,
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
      ROLE.CLIENT,
    ],
  },
  {
    value: JOB_CLIENT_STATUS,
    label: currentRole === ROLE.CLIENT ? JOB_PROCESS_STATUS : JOB_CLIENT_LABEL,
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.CLIENT,
    ],
  },
]

export const JOB_STATUS_FOR_CLIENT = [
  JOB_ACTIVE_STATUS,
  JOB_ARCHIVE_STATUS,
  JOB_PENDING_STATUS,
]

export const JOB_STATUS_BY_CURRENT_STATUS = {
  [JOB_ACTIVE_STATUS]: [
    JOB_ACTIVE_STATUS,
    JOB_PENDING_STATUS,
    JOB_CLOSE_STATUS,
  ],
  [JOB_PENDING_STATUS]: [JOB_ACTIVE_STATUS, JOB_PENDING_STATUS],
  [JOB_CLOSE_STATUS]: [JOB_ACTIVE_STATUS, JOB_ARCHIVE_STATUS, JOB_CLOSE_STATUS],
  [JOB_ARCHIVE_STATUS]: [JOB_ACTIVE_STATUS, JOB_ARCHIVE_STATUS],
  [JOB_CLIENT_STATUS]: [JOB_ACTIVE_STATUS, JOB_CLIENT_STATUS],
}

export const SEARCH_FORM_FIELDS = {
  TITLE: 'title',
  STATUS: 'status',
  TYPE: 'type',
  CLIENT: 'client',
  POSITION: 'position',
  LOCATIONS: 'locations',
}

export const DEFAULT_STATUS = JOB_ACTIVE_STATUS

export const JOB_STATUS_COLORS = {
  active: 'success',
  close: 'error',
  pending: 'info',
  archive: 'primary',
  clientrequest: 'secondary',
}

export const JOB_AVAILABILITY_COLORS = {
  active: 'success',
  close: 'error',
  pending: 'info',
  archive: 'primary',
  client: 'secondary',
}

export const JOB_AVAILABILITY_STATUS = {
  NEW: 'New',
}

export const JOB_ALL_TYPE = 'All'
export const JOB_FULLTIME_TYPE = 'Full time'
export const JOB_PARTTIME_TYPE = 'Part time'
export const JOB_FREELANCE_TYPE = 'Freelance'

export const JOB_TYPE_OPTIONS = [
  JOB_FULLTIME_TYPE,
  JOB_PARTTIME_TYPE,
  JOB_FREELANCE_TYPE,
]

export const LIST_JOB_TYPE = [JOB_ALL_TYPE, ...JOB_TYPE_OPTIONS]

export const SEARCH_DEFAULT_VALUES = {
  [SEARCH_FORM_FIELDS.TITLE]: '',
  [SEARCH_FORM_FIELDS.STATUS]: JOB_ACTIVE_STATUS,
  [SEARCH_FORM_FIELDS.TYPE]: JOB_ALL_TYPE,
  [SEARCH_FORM_FIELDS.CLIENT]: '',
  [SEARCH_FORM_FIELDS.POSITION]: '',
  [SEARCH_FORM_FIELDS.LOCATIONS]: [],
}

export const formatSalary = (salary = {}) => {
  if (typeof salary === 'string') return salary

  const { min: minSalary = 0, max: maxSalary = 0, currency = '' } = salary || {}

  const fmtMinSalary = Number.parseInt(
    replaceAll(String(minSalary), '.', ''),
    10
  )
  const fmtMaxSalary = Number.parseInt(
    replaceAll(String(maxSalary), '.', ''),
    10
  )

  if (!fmtMaxSalary || !currency) return 'NaN'

  if (fmtMinSalary === 0 && fmtMaxSalary > fmtMinSalary)
    return `Up to ${fNumberWithDot(fmtMaxSalary)} ${currency}`

  return `${fNumberWithDot(minSalary)} - ${fNumberWithDot(
    fmtMaxSalary
  )} ${currency}`
}
