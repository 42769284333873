const board = {
  moveCard: 'Di Chuyển Thẻ',
  storageCard: 'Lưu Thẻ',
  undoStorage: 'Bỏ lưu trữ thẻ',
  addLabel: 'Thêm Nhãn',
  editComment: 'Chỉnh sửa bình luận',
  background: 'Màu nền',
  confirmStorage: 'Bạn có muốn lưu thẻ này ?',
  confirmUndoStorage: 'Bạn có muốn bỏ lưu trữ thẻ này ?',
  waiting: 'Đợi',
  fileTooBig: 'Tập tin quá lớn',
  warningFill: 'Hãy điền vào biểu mẫu trước khi upload',
  failedUpload: 'Tải thất bại! Xin thử lại',
  uploadCVSuccess: 'Tải lên CV thành công',
  chooseLabel: 'Chọn nhãn',
  chooseClient: 'Chọn khách hàng',
  chooseMember: 'Chọn thành viên',
  chooseJob: 'Chọn công việc',
  labelExist: 'Nhãn đã tồn tại',
  removeLabelSuccess: 'Xóa nhãn thành công',
  removeLabelFailed: 'Xóa nhãn thất bại',
  selectLabel: 'Bạn phải chọn nhãn',
  storageSuccess: 'Lưu trữ thành công!',
  storageFail: 'Lưu trữ thất bại!',
  undoStorageSuccess: 'Bỏ lưu trữ thành công!',
  undoStorageFail: 'Bỏ lưu trữ thất bại!',
  moveCardSuccess: 'Di chuyển thẻ thành công!',
  contacts: 'Liên hệ',
  somethingWentWrong: 'Có gì đó không đúng! Xin thử lại',
  updateCard: 'Cập nhật thẻ',
  addCard: 'Tạo mới thẻ',
  createCardSuccess: 'Tạo mới thẻ thành công!',
  updateCardSuccess: 'Cập nhật thẻ thành công!',
  name: 'Tên',
  columnName: 'Tên cột',
  nameJob: 'Tên công việc',
  location: 'Địa điểm',
  nameClient: 'Tên khách hàng',
  email: 'Email',
  phone: 'Điện thoại',
  approachDate: 'Ngày tiếp cận',
  expectedDate: 'Ngày dự kiến',
  position: 'Vị trí',
  uploadCV: 'Tải lên CV',
  uploadCVToLinkRefine: 'Tải lên CV tinh chỉnh',
  approachPoint: 'Lưu ý',
  createInterview: 'Tạo lịch phỏng vấn',
  downloadCV: 'Tải CV',
  addCommentFailed: 'Thêm bình luận thất bại! Xin thử lại.',
  checkCommentEmpty: 'Xin hãy nhập bình luận!',
  history: 'Lịch sử',
  historyCard: 'Lịch sử thẻ',
  hide: 'Ẩn',
  listComment: 'Danh sách bình luận',
  comment: 'Bình luận',
  newsUpdate: 'Cập nhật mới',
  show: 'Hiển thị',
  typeAMessage: 'Nhập tin nhắn',
  createInterviewFailed: 'Tạo phỏng vấn thất bại! Xin thử lại.',
  createInterviewSuccess: 'Tạo phỏng vấn thành công!',
  interviewDetail: 'Chi tiết phỏng vấn',
  actions: 'Hành động',
  move: 'Di chuyển',
  storage: 'Lưu trữ',
  label: 'Nhãn',
  filterCardFailed: 'Lọc thẻ thất bại! Xin thử lại.',
  removeAssigneeFailed: 'Xóa người tham gia thất bại! Xin thử lại.',
  removeFromCard: 'Xóa khỏi thẻ',
  nameRequired: 'Tên là bắt buộc',
  columnRequired: 'Tên cột là bắt buộc',
  nameJobRequired: 'Tên công việc là bắt buộc',
  noteApproachRequired: 'Lưu ý là bắt buộc',
  emailRequired: 'Email là bắt buộc',
  phoneRequired: 'Điện thoại là bắt buộc',
  typeInterviewRequired: 'Loại phỏng vấn là bắt buộc',
  detailCard: 'Chi tiết thẻ',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  skype: 'Skype',
  completeProcess: 'Hoàn thành quy trình',
  completeProcessWarning:
    'Sau khi hoàn tất, bạn sẽ không thể di chuyển, chỉnh sửa thẻ này nữa ?',
  moveCardFailed: 'Di chuyển thẻ thất bại! Xin thử lại.',
  addLabelFailed: 'Thêm nhãn thất bại! Xin thử lại.',
  inputDateMessage: 'Bạn cần nhập',
  forCandidate: 'cho ứng viên',
  checkedEnglish: 'Checked English',
  uncheckedEnglish: 'Unchecked English',
  updateCheckEnglishSuccess: 'Cập nhật kiểm tra tiếng Anh thành công',
  updateCheckEnglishFail: 'Cập nhật kiểm tra tiếng Anh thất bại',
  cantChangeStatus: 'Không thể thay đổi trạng thái thẻ',
  needCheckEng: 'Cần check English trước khi kéo vào cột này',
  needScreenCV: 'Cần kéo vào Screen CV trước khi kéo vào cột này',
  dontHavePermissionChangeStatus: 'Không có quyền thay đổi trạng thái thẻ',
  interviewFeedback: 'Đánh giá phỏng vấn',
  confirmMoveCardBeforeScreenCV:
    'Bạn có chắc muốn di chuyển về cột trước Screen CV card <strong>{{name}}</strong>',
  needProbation: 'Cần kéo vào Probation trước khi kéo card vào cột này',
  maximumLabelMessage: 'Bạn không thể thêm nhiều hơn 2 nhãn!',
  cannotAddLabelRecruiterCard:
    'Bạn không thể thêm nhãn vào thẻ của người tuyển dụng ngoài công ty!',
  memberInCharge: 'Thành viên phụ trách',
  confirmMessageChangeMIC:
    'Bạn có muốn thay đổi thành viên phụ trách của <strong>{{name}}</strong>',
  cannotChangeMemberInCharge: 'Không thể thay đổi thành viên phụ trách',
  salaryOffer: 'Lương đề nghị',
  salaryOfferCheck: 'Lương đề nghị phải lớn hơn 0',
  cardBlocked: 'Không thể di chuyển. Card đã bị chặn',
  needOfferConfirm:
    'Cần kéo vào Offer Confirmation Waiting trước khi kéo vào cột này',
  needOnboarding: 'Cần kéo vào Onboarding trước khi kéo vào cột này',
  needMemberInCharge: 'Thẻ cần có thành viên phụ trách để kéo vào cột này',
  confirmMoveCardProbation:
    'Card sẽ bị đóng băng. Bạn có chắc chắn muốn di chuyển card <strong>{{name}}</strong> đến cột <strong>{{columnName}}</strong>?',
}

export default board
