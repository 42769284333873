const board = {
  moveCard: 'Move Card',
  storageCard: 'Storage Card',
  undoStorage: 'Undo Storage Card',
  addLabel: 'Add Label',
  editComment: 'Edit Comment',
  background: 'Background',
  confirmStorage: 'Do you want to storage this card ?',
  confirmUndoStorage: 'Do you want to undo storage this card?',
  waiting: 'Waiting',
  fileTooBig: 'File is too big',
  warningFill: 'Please fill in the form before uploading',
  failedUpload: 'Failed to upload! Please try again.',
  uploadCVSuccess: 'Upload CV success',
  chooseLabel: 'Choose Label',
  chooseClient: 'Choose Client',
  chooseMember: 'Choose Member',
  chooseJob: 'Choose Job',
  labelExist: 'Label is exist',
  removeLabelSuccess: 'Remove label success',
  removeLabelFailed: 'Remove label failed',
  selectLabel: 'You must select label',
  storageSuccess: 'Storage success!',
  storageFail: 'Storage fail!',
  undoStorageSuccess: 'Undo storage success!',
  undoStorageFail: 'Undo storage fail!',
  moveCardSuccess: 'Move card success!',
  contacts: 'Contacts',
  somethingWentWrong: 'Something went wrong! Please try again',
  updateCard: 'Update Card',
  addCard: 'Add Card',
  createCardSuccess: 'Create card successfully!',
  updateCardSuccess: 'Update card successfully!',
  name: 'Name',
  columnName: 'Column Name',
  nameJob: 'Name Job',
  location: 'Location',
  nameClient: 'Client Name',
  email: 'Email',
  phone: 'Phone',
  approachDate: 'Approach Date',
  expectedDate: 'Expected Date',
  position: 'Position',
  uploadCV: 'Upload CV to link',
  uploadCVToLinkRefine: 'Upload CV to Link Refine',
  approachPoint: 'Approach Point',
  createInterview: 'Create Interview',
  downloadCV: 'Download CV',
  addCommentFailed: 'Add comment failed! Please try again.',
  checkCommentEmpty: 'Please type your comment!',
  history: 'History',
  historyCard: 'History card',
  hide: 'Hide',
  listComment: 'List Comment',
  comment: 'Comment',
  newsUpdate: 'News Update',
  show: 'Show',
  typeAMessage: 'Type a message',
  createInterviewFailed: 'Create Interview failed! Please try again.',
  createInterviewSuccess: 'Create interview successfully!',
  interviewDetail: 'Interview Detail',
  actions: 'Actions',
  move: 'Move',
  storage: 'Storage',
  label: 'Label',
  filterCardFailed: 'Fail to filter cards! Please try again',
  removeAssigneeFailed: 'Remove assignee failed! Please try again.',
  removeFromCard: 'Remove from card',
  nameRequired: 'Name is required',
  columnRequired: 'Column name is required',
  nameJobRequired: 'Name job is required',
  noteApproachRequired: 'Approach point is required',
  emailRequired: 'Email is required',
  phoneRequired: 'Phone is required',
  typeInterviewRequired: 'Type interview is required',
  detailCard: 'Detail Card',
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  skype: 'Skype',
  completeProcess: 'Complete Process',
  completeProcessDone: 'Complete process done',
  completeProcessFailed: 'Complete process failed',
  completeProcessWarning:
    'After completing the process, you will not be able to move, edit the card ?',
  moveCardFailed: 'Failed to move card! Please try again.',
  addLabelFailed: 'Add label failed! Please try again.',
  inputDateMessage: 'You need to input',
  forCandidate: 'for candidate',
  checkedEnglish: 'Checked English',
  uncheckedEnglish: 'Unchecked English',
  updateCheckEnglishSuccess: 'Update check English success!',
  updateCheckEnglishFail: 'Update check English failed!',
  cantChangeStatus: "Can't change status card",
  needCheckEng: 'Need check English before move card',
  needScreenCV: 'Need Screen CV before move card',
  dontHavePermissionChangeStatus: "Don't have permission change status card",
  interviewFeedback: 'Interview Feedback',
  confirmMoveCardBeforeScreenCV:
    'Are you sure you want to move back to the column before the Screen CV of card <strong>{{name}}</strong>',
  needProbation: 'Need Probation before move card',
  maximumLabelMessage: 'You cannot add more than 2 labels!',
  cannotAddLabelRecruiterCard:
    'You cannot add label to card from external recruiter!',
  memberInCharge: 'Member in Charge',
  confirmMessageChangeMIC:
    'Do you want to change Member in Charge of <strong>{{name}}</strong>',
  cannotChangeMemberInCharge: 'Cannot change Member in Charge',
  salaryOffer: 'Salary Offer (Gross)',
  salaryOfferCheck: 'Salary offer must be greater than 0',
  cardBlocked: "Can't move. Card is blocked",
  needOfferConfirm: 'Need Offer Confirmation Waiting before move card',
  needOnboarding: 'Need Onboarding before move card',
  needMemberInCharge: 'Card must have Member in Charge to move to this status',
  confirmMoveCardProbation:
    'Card will be blocked. Are you sure you want to move card <strong>{{name}}</strong> to the column <strong>{{columnName}}</strong>?',
}

export default board
