import { CALANDER_DATE_FORMAT, DASHBOARD_TABLE_HEIGHT, ROLE } from 'config'

import { fDate, fDateEndOfWeek, fDateStartOfWeek } from 'utils/formatTime'

export const DASHBOARD_TABLE_TOOLBAR_HEIGHT = 88
export const DASHBOARD_TABLE_HEADER_HEIGHT = 52
export const DASHBOARD_TABLE_PAGINATION_HEIGHT = 64

export const DASHBOARD_ITEMS_HEIGHT = {
  ACTIVE_JOBS: {
    headerHeight: 60,
    toolbarHeight: 0,
    paginationHeight: DASHBOARD_TABLE_PAGINATION_HEIGHT,
  },
  APPLICANTS: {
    headerHeight: DASHBOARD_TABLE_HEADER_HEIGHT,
    toolbarHeight: 0,
    paginationHeight: DASHBOARD_TABLE_PAGINATION_HEIGHT,
  },
  INTERVIEW_SCHEDULE: {
    headerHeight: DASHBOARD_TABLE_HEADER_HEIGHT,
    toolbarHeight: 0,
    paginationHeight: 0,
  },
  MEMBER_ACTIVITIES: {
    headerHeight: DASHBOARD_TABLE_HEADER_HEIGHT,
    toolbarHeight: 0,
    paginationHeight: 0,
  },
  PERFORMANCE: {
    headerHeight: DASHBOARD_TABLE_HEADER_HEIGHT,
    toolbarHeight: DASHBOARD_TABLE_TOOLBAR_HEIGHT,
    paginationHeight: 0,
  },
  RECRUITMENT_PROGRESS: {
    headerHeight: DASHBOARD_TABLE_HEADER_HEIGHT,
    toolbarHeight: 0,
    paginationHeight: DASHBOARD_TABLE_PAGINATION_HEIGHT,
  },
  WEEKLY_TASK: {
    headerHeight: DASHBOARD_TABLE_HEADER_HEIGHT,
    toolbarHeight: DASHBOARD_TABLE_TOOLBAR_HEIGHT,
    paginationHeight: 0,
  },
  JOB_LIST: {
    headerHeight: 60,
    toolbarHeight: 0,
    paginationHeight: DASHBOARD_TABLE_PAGINATION_HEIGHT,
  },
}

const calcHeight = (obj) => {
  const heightItems = Object.values(obj)

  const sumHeightItems = heightItems.reduce((sum, cur) => sum + cur, 0)
  return DASHBOARD_TABLE_HEIGHT - sumHeightItems
}

export const DASHBOARD_SECTIONS_HEIGHT = {
  ACTIVE_JOBS_HEIGHT: calcHeight(DASHBOARD_ITEMS_HEIGHT.ACTIVE_JOBS) - 80,
  APPLICANTS_HEIGHT: calcHeight(DASHBOARD_ITEMS_HEIGHT.APPLICANTS),
  INTERVIEW_SCHEDULE_HEIGHT: calcHeight(
    DASHBOARD_ITEMS_HEIGHT.INTERVIEW_SCHEDULE
  ),
  MEMBER_ACTIVITIES_HEIGHT: calcHeight(
    DASHBOARD_ITEMS_HEIGHT.MEMBER_ACTIVITIES
  ),
  PERFORMANCE_HEIGHT: calcHeight(DASHBOARD_ITEMS_HEIGHT.PERFORMANCE),
  RECRUITMENT_PROGRESS_HEIGHT: calcHeight(
    DASHBOARD_ITEMS_HEIGHT.RECRUITMENT_PROGRESS
  ),
  WEEKLY_TASK_HEIGHT: calcHeight(DASHBOARD_ITEMS_HEIGHT.WEEKLY_TASK),
  JOB_LIST_HEIGHT: calcHeight(DASHBOARD_ITEMS_HEIGHT.JOB_LIST) - 100,
}

export const DASHBOARD_CONFIG = [
  {
    component: 'performance',
    title: 'pages.dashboard.performance.title',
    order: (currentRole) => {
      if (ROLE.DIRECTOR === currentRole) return 1
      if (ROLE.MEMBER === currentRole) return 6
      return 0
    },
    sx: (currentRole) => {
      if (ROLE.DIRECTOR === currentRole)
        return {
          height: DASHBOARD_TABLE_HEIGHT + 100,
          mb: 2,
        }

      return {
        height: DASHBOARD_TABLE_HEIGHT,
        mb: 2,
      }
    },
    gridItem: () => ({ xs: 12 }),
  },
  {
    component: 'applicant',
    title: 'pages.dashboard.applicants.title',
    order: (currentRole) => {
      if (ROLE.DIRECTOR === currentRole) return 5
      return 0
    },
    sx: () => ({
      height: DASHBOARD_TABLE_HEIGHT,
      mb: 2,
    }),
    gridItem: () => ({ xs: 12 }),
  },
  {
    component: 'interview',
    title: 'pages.dashboard.interviewSchedule.title',
    order: (currentRole) => {
      if (
        [ROLE.LEADER, ROLE.HR_LEADER, ROLE.TA_LEADER, ROLE.MEMBER].includes(
          currentRole
        )
      )
        return 2
      return 3
    },
    sx: () => ({
      height: DASHBOARD_TABLE_HEIGHT,
      mb: 2,
    }),
    gridItem: (currentRole) => {
      if (
        [ROLE.DIRECTOR, ROLE.LEADER, ROLE.HR_LEADER, ROLE.TA_LEADER].includes(
          currentRole
        )
      )
        return { md: 6, xs: 12 }
      return { xl: 4, xs: 12 }
    },
  },
  {
    component: 'job',
    title: 'pages.dashboard.jobList.jobs',
    order: (currentRole) => {
      if ([ROLE.LEADER, ROLE.HR_LEADER, ROLE.TA_LEADER].includes(currentRole))
        return 3

      if ([ROLE.MEMBER].includes(currentRole)) return 4

      return 0
    },
    sx: () => ({
      height: { xs: '100%', md: DASHBOARD_TABLE_HEIGHT },
      mb: 2,
    }),
    gridItem: () => ({ xs: 12 }),
  },
  {
    component: 'kpi',
    title: 'pages.dashboard.kpi.title',
    order: (currentRole) => {
      if ([ROLE.DIRECTOR].includes(currentRole)) return 2
      if ([ROLE.HR_LEADER, ROLE.TA_LEADER, ROLE.LEADER].includes(currentRole))
        return 5
      return 3
    },
    sx: () => ({
      height: DASHBOARD_TABLE_HEIGHT,
      mb: 2,
    }),
    gridItem: (currentRole) => {
      if (currentRole === ROLE.DIRECTOR) return { xs: 12 }
      if ([ROLE.HR_LEADER, ROLE.TA_LEADER, ROLE.LEADER].includes(currentRole))
        return { xs: 12 }
      return { xl: 4, md: 6, xs: 12 }
    },
  },
  {
    component: 'kpiRanking',
    title: 'pages.dashboard.kpiRanking.title',
    order: (currentRole) => {
      if (
        [ROLE.HR_LEADER, ROLE.TA_LEADER, ROLE.LEADER, ROLE.MEMBER].includes(
          currentRole
        )
      )
        return 1

      return 4
    },
    sx: () => ({
      height: { xs: '100%', md: DASHBOARD_TABLE_HEIGHT },
      mb: 2,
    }),
    gridItem: (currentRole) => {
      if (
        [ROLE.DIRECTOR, ROLE.HR_LEADER, ROLE.TA_LEADER, ROLE.LEADER].includes(
          currentRole
        )
      )
        return { xs: 12, md: 6 }
      return { xl: 4, md: 6, xs: 12 }
    },
  },
  {
    component: 'weeklyTask',
    title: 'pages.dashboard.weeklyTask.title',
    order: (currentRole) => {
      if ([ROLE.LEADER, ROLE.HR_LEADER, ROLE.TA_LEADER].includes(currentRole))
        return 4

      if ([ROLE.MEMBER].includes(currentRole)) return 5

      return 0
    },
    sx: () => ({
      height: DASHBOARD_TABLE_HEIGHT,
      mb: 2,
    }),
    gridItem: () => ({ xs: 12 }),
  },
]

export const TEAM_COLORS = {
  HA_NOI: '#7E36AF',
  DA_NANG: '#D9534F',
  HO_CHI_MINH: '#E2A14F',
}

export const TEAM_COLORS_PERFORMANCE = [
  TEAM_COLORS.HA_NOI,
  TEAM_COLORS.DA_NANG,
  TEAM_COLORS.HO_CHI_MINH,
]

export const CANDIDATE_STATUS_COLORS = {
  APPROACH: '#2D99FF',
  IN_PROGRESS: '#7E36AF',
  DONE: '#2ECC71',
  FAILED: '#FF4842',
}

export const TIME_TAB_LABELS = {
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
}

export const DEFAULT_TIME_TAB = TIME_TAB_LABELS.WEEK

export const TIME_TAB_OPTIONS = [
  TIME_TAB_LABELS.WEEK,
  TIME_TAB_LABELS.MONTH,
  TIME_TAB_LABELS.YEAR,
]

export const DEFAULT_TIME_TAB_VALUES = {
  currentDate: new Date(),
  startDate: fDate(
    fDateStartOfWeek(new Date(), { weekStartsOn: 1 }),
    CALANDER_DATE_FORMAT
  ),
  endDate: fDate(
    fDateEndOfWeek(new Date(), { weekStartsOn: 1 }),
    CALANDER_DATE_FORMAT
  ),
}

export const APPLICANT_TAB_LABELS = {
  APPLICANT: 'applicants',
  EX_RECRUITER: 'recruiter',
}

export const DEFAULT_APPLICANTS_TAB = APPLICANT_TAB_LABELS.APPLICANT

export const APPLICANT_TAB_OPTIONS = [
  {
    value: APPLICANT_TAB_LABELS.EX_RECRUITER,
    label: 'pages.dashboard.applicants.exRecruiter',
  },
  {
    value: APPLICANT_TAB_LABELS.APPLICANT,
    label: 'pages.dashboard.applicants.applicants',
  },
]
