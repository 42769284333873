const manageRedeem = {
  system: {
    name: 'Tên',
    time: 'Thời gian',
    point: 'Điểm thưởng',
    status: 'Trạng thái',
    action: 'Hành động',
    reason: 'Lý do',
    approveAction: 'Chấp nhận',
    rejectAction: 'Từ chối',
    statusConfig: {
      processing: 'Đang tiến hành',
      done: 'Hoàn thành',
      reject: 'Từ chối',
    },
    approveConfirmMessage: 'Bạn có chắc muốn chập nhận yêu cầu từ',
    addMoneyTransferBill: 'Thêm hoá đơn chuyển tiền',
    rejectReason: 'Lý do từ chối yêu cầu từ',
    reasonRequired: 'Lý do là bắt buộc',
    approveSuccess: 'Chập nhận yêu cầu thành công!',
    approveFail: 'Chập nhận yêu cầu thất bại!',
    rejectSuccess: 'Từ chối yêu cầu thành công!',
    rejectFail: 'Từ chối yêu cầu thất bại!',
  },
  user: {
    message: 'Lời nhắn',
    amount: 'Số lượng',
    redeemBonus: 'Đổi điểm thưởng',
    redeemMessage: 'Nhập số điểm bạn muốn đổi',
    bankMessage:
      'Bạn cần cập nhật thông tin ngân hàng trước khi gửi đi yêu cầu đổi điểm',
    redeemSuccess: 'Yêu cầu đổi điểm thành công!',
    redeemFail: 'Yêu cầu đổi điểm thất bại!',
    cancelRedeemMessage: 'Bạn có chắc chắn muốn hủy yêu cầu đổi điểm này?',
    cancelRedeemSuccess: 'Hủy yêu cầu đổi điểm thành công!',
    cancelRedeemFail: 'Hủy yêu cầu đổi điểm thất bại!',
    pointCheck: 'Điểm đổi thưởng phải lớn hơn 0',
  },
}

export default manageRedeem
