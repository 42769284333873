const candidates = {
  heading: 'List candidate',
  email: 'Email Address',
  enterEmail: 'Enter email',
  name: 'Name',
  enterName: 'Enter name',
  phone: 'Phone',
  enterPhone: 'Enter phone',
  text: 'Text',
  enterText: 'Enter text',
  job: 'Job',
  selectJob: 'Select job',
  skill: 'Skill',
  selectKill: 'Select skill',
  search: 'Search',
  jobApply: 'Job apply',
  timeApply: 'Time apply',
  source: 'Source',
  column: 'Column',
  follower: 'Follower',
  jobName: 'Job Name',
  location: 'Location',
  client: 'Client Name',
  approachDate: 'Approach Date',
  linkCV: 'Link CV',
  placeholderLink: 'Enter link or import CV',
  position: 'Position',
  notApproach: 'Note Approach',
  rawCV: 'Raw CV',
  closeCV: 'Close Raw',
  viewDetail: 'View detail',
  status: 'Status',
  bonus: 'Bonus',
  passed: 'passed',
  for: 'for',
  youGot: 'You got',
  points: 'points',
  getListCandidatesError:
    'Failed to get list candidates! Please try again later',
  detailCandidate: 'Detail Candidate',
  listComment: 'List Comment',
}

export default candidates
