// @mui
import { enUS, viVN } from '@mui/material/locale'

import enUSLocale from 'date-fns/locale/en-US'
import viLocale from 'date-fns/locale/vi'

// routes
import { PATH_DASHBOARD } from 'routes/paths'

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard.root()

export const DASHBOARD_TABLE_HEIGHT = 610

// LAYOUT
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
}

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 220,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
}

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
}

// SETTINGS
// Please remove `localStorage` when you change settings.

export const defaultSettings = {
  themeMode: 'light',
  themeColorPresets: 'yellow',
}

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
]

export const defaultLang = allLangs[0] // English

export const DOMAIN_SERVER_API = process.env.REACT_APP_HOST_API || ''

export const CAPTCHA_SITE_KEY = process.env.REACT_APP_SITE_KEY || ''

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''

export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || ''

export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID || ''

export const isRunningAds = process.env.REACT_APP_IS_RUNNING_ADS || false

export const GOOGLE_SCOPE =
  'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'

export const GOOGLE_OAUTH2_ENDPOINT =
  'https://accounts.google.com/o/oauth2/v2/auth'

// OAUTH CONFIG
export const FACEBOOK_FIELDS = 'name,email,picture'
export const FACEBOOK_SCOPE = 'public_profile'
export const GOOGLE_COOKIE_POLICY = 'single_host_origin'
export const SOCIAL_TYPE = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
}

// PDF Viewer Toolbar
export const PDF_VIEWER_TOOLBAR = {
  ZOOM_OUT: 'ZoomOut',
  ZOOM: 'Zoom',
  ZOOM_IN: 'ZoomIn',
  GO_TO_PREVIOUS_PAGE: 'GoToPreviousPage',
  CURRENT_PAGE_INPUT: 'CurrentPageInput',
  NUMBER_OF_PAGES: 'NumberOfPages',
  GO_TO_NEXT_PAGE: 'GoToNextPage',
  ENTER_FULLSCREEN: 'EnterFullScreen',
  DOWNLOAD: 'Download',
  PRINT: 'Print',
}

export const PDF_VIEWER_TOOLBAR_STYLE = {
  GO_TO_PREVIOUS_PAGE: {
    marginLeft: 'auto',
  },
  CURRENT_PAGE_INPUT: {
    width: '4rem',
  },
  ENTER_FULLSCREEN: {
    marginLeft: 'auto',
  },
}

// ROLE AND PERMISSION
export const ROLE = {
  DIRECTOR: 'Director',
  LEADER: 'Leader',
  TA_LEADER: 'TA Leader',
  HR_LEADER: 'HR Leader',
  MEMBER: 'Member',
  ADMIN: 'Admin',
  RECRUITER: 'Recruiter',
  BLOGGER: 'Blogger',
  REVIEWER: 'Reviewer',
  SUBMITTER: 'Submiter',
  CLIENT: 'Client',
}

// User setting
export const PAGES = {
  Dashboard: 'Dashboard',
  Notification: 'Notification',
  Candidates: 'Candidates',
  Interview: 'Interview',
  Board: 'Board',
  Calculator: 'Calculator',
  Recruiter: 'External recruiter',
  Jobs: 'Jobs',
  Clients: 'Clients',
  Users: 'Users',
  Task: 'Task',
  Blog: 'Blog',
  Account: 'Account',
  Setting: 'Setting',
  Profile: 'Profile',
  InstallApp: 'InstallApp',
  ManageCV: 'Manage CV',
  ManageRedeem: 'Manage Redeem',
  MarketCV: 'Market CV',
  MyListCV: 'My List CV',
  MyPurchaseCV: 'My Purchase',
  Withdraw: 'Withdraw',
  TransferData: 'TransferData',
  SummaryHiring: 'Summary Hiring',
  Report: 'Report Problem',
  FetchNews: 'Fetch News',
}

export const PAGE_PERMISSION = [
  {
    pageNames: [
      PAGES.Notification,
      PAGES.Candidates,
      PAGES.Interview,
      PAGES.Board,
    ],
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
    ],
  },
  {
    pageNames: [PAGES.Jobs, PAGES.Calculator],
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
      ROLE.CLIENT,
    ],
  },
  {
    pageNames: [PAGES.Profile],
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
      ROLE.RECRUITER,
      ROLE.BLOGGER,
    ],
  },
  {
    pageNames: [PAGES.Dashboard, PAGES.InstallApp],
    roles: [
      ROLE.DIRECTOR,
      ROLE.LEADER,
      ROLE.HR_LEADER,
      ROLE.TA_LEADER,
      ROLE.MEMBER,
    ],
  },
  // {
  //   pageNames: [PAGES.Task],
  //   roles: [ROLE.DIRECTOR, ROLE.LEADER, ROLE.MEMBER, ROLE.ADMIN],
  // },
  {
    pageNames: [
      PAGES.Users,
      PAGES.Clients,
      PAGES.Recruiter,
      PAGES.ManageCV,
      PAGES.KPIBonus,
    ],
    roles: [ROLE.DIRECTOR, ROLE.LEADER, ROLE.HR_LEADER, ROLE.TA_LEADER],
  },
  {
    pageNames: [PAGES.Setting, PAGES.ManageRedeem],
    roles: [ROLE.DIRECTOR],
  },
  {
    pageNames: [PAGES.Blog],
    roles: [ROLE.BLOGGER],
  },
  {
    pageNames: [
      PAGES.MarketCV,
      PAGES.MyListCV,
      PAGES.MyPurchaseCV,
      PAGES.Withdraw,
      PAGES.Report,
      PAGES.FetchNews,
    ],
    roles: [ROLE.RECRUITER],
  },
  {
    pageNames: [PAGES.TransferData],
    roles: [ROLE.REVIEWER, ROLE.SUBMITTER],
  },
  {
    pageNames: [PAGES.SummaryHiring],
    roles: [ROLE.CLIENT],
  },
]

export const ROLE_BY_PAGE = PAGE_PERMISSION.reduce((prev, acc) => {
  const { pageNames = [], roles } = acc

  return {
    ...prev,
    ...pageNames.reduce(
      (subPrev, subAcc) => ({
        ...subPrev,
        [subAcc]: roles,
      }),
      {}
    ),
  }
}, {})

// PAGINATION
export const PAGINATION = [10, 20, 50, 100]
export const defaultPagination = PAGINATION[0] // 10
export const DEFAULT_PAGE = 1

// DATETIME FORMAT
export const DATE_FORMAT = 'dd/MM/yyyy'
export const DATE_YEAR_MONTH_DAY_FORMAT = 'yyyy-MM-dd'
export const DATETIME_FORMAT = 'dd/MM/yyyy HH:mm'
export const AMPM_DATETIME_FORMAT = 'hh:mma dd/MM/yyyy'
export const DATETIME_FORMAT_AMPM = 'dd/MM/yyyy hh:mm a'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_AMPM = 'hh:mm a'
export const DATE_FORMAT_DAY_MONTH = 'do MMM'
export const CALANDER_DATE_FORMAT = 'MM/dd/yyyy'
export const CALANDER_DATETIME_FORMAT = 'MM/dd/yyyy HH:mm:ss'
export const TIMEZONE = 'Asia/Ho_Chi_Minh'
export const MIN_DATE_VALUE = new Date('1970/01/01')
export const YEAR_FORMAT = 'yyyy'
export const MONTH_YEAR_FORMAT = 'MM/yyyy'
export const HUMAN_MONTH_YEAR_FORMAT = 'MMM yyyy'
export const HUMAN_MONTH_FORMAT = 'MMM'
export const HUMAN_DAY_FORMAT = 'E dd'

// LOCALE
export const DEFAULT_LOCALE = 'en'
export const dateFnsLocaleConfig = {
  en: enUSLocale,
  vn: viLocale,
}

// REGULAR EXPRESSION

// Regex detect special chars: ., *, +, ?, ^, $, {, }, (, ), |, [, ], \
export const REGEX_SPECIAL_CHARS = /[.*+?^${}()|[\]\\]/g

// Add '\' charater to the beginning of the string
// Ex: '.*' => '\.\*'
export const REGEX_WHOLE_MATCH = '\\$&'

// https://github.com/dandyraka/GDriveRegex/blob/master/regex.php
export const REGEX_GOOGLE_DRIVE_FILE = /drive\.google\.com\/file\/d\/(.*?)/

// https://stackoverflow.com/questions/16840038/easiest-way-to-get-file-id-from-url-on-google-apps-script
export const REGEX_GOOGLE_DRIVE_FILE_ID = /[-\w]{25,}/

export const REGEX_S3_DIGITAL_OCEAN = new RegExp(
  `^https?:\\/\\/${process.env.REACT_APP_AWS_BUCKET}\\.sgp1\\.digitaloceanspaces\\.com\\/.*$`
)

export const REGEX_EXTRACT_ACCESS_TOKEN_GOOGLE = /([^&=]+)=([^&]*)/g

export const MAX_SIZE_FILEIMAGE = 5 * 1024 * 1024 // 5 MB

export const FILE_CV_MAX_SIZE = 20 * 1024 * 1024 // 20 MB

export const UPLOAD_MULTI_CV = {
  MAX_FILES: 3,
  MAX_SIZE: 20 * 1024 * 1024, // 20MB
}

export const DRAWER_WIDTH = { xs: 1, sm: '80%', md: '60%', lg: '50%' }

export const IMAGES_WESERV_URL = 'https://images.weserv.nl/'

export const DEFAULT_IMAGES_WESERV_CONFIG = [
  {
    name: 'width',
    field: 'w',
    defaultValue: 0,
  },
  {
    name: 'height',
    field: 'h',
    defaultValue: 0,
  },
  {
    name: 'fit',
    field: 'fit',
    defaultValue: 'cover',
  },
]

export const EDITOR_RESET_CONFIG = {
  COLOR: [
    '#212529', // rgb(33, 37, 41)
    '#333333', // rgb(51, 51, 51)
    '#292929', // rgb(41, 41, 41
    '#091e42', // rgb(9, 30, 66)
    'black',
    '#000000', // rgb(0, 0, 0)
    '#222222', // rgb(34, 34, 34)
    '#2d2d2d', // rgb(45, 45, 45)
    '#1f2c37', // rgb(31, 44, 55)
  ],
  BACKGROUND: [
    '#ffffff', // rgb(255, 255, 255)
    'white',
  ],
}

export const EDITOR_EMPTY_CONTENT = [
  '',
  '<br/><p></p>',
  '<p style="margin: 0.5em 0"><br></p>',
  '<h1 style="margin: 0.75em 0"><br></h1>',
  '<h2 style="margin: 0.75em 0"><br></h2>',
  '<h3 style="margin: 0.75em 0"><br></h3>',
  '<h4 style="margin: 0.75em 0"><br></h4>',
  '<h5 style="margin: 0.75em 0"><br></h5>',
  '<h6 style="margin: 0.75em 0"><br></h6>',
  '<h1 style="margin: 0.75em 0"><br></h1><p style="margin: 0.5em 0"><br></p>',
  '<h2 style="margin: 0.75em 0"><br></h2><p style="margin: 0.5em 0"><br></p>',
  '<h3 style="margin: 0.75em 0"><br></h3><p style="margin: 0.5em 0"><br></p>',
  '<h4 style="margin: 0.75em 0"><br></h4><p style="margin: 0.5em 0"><br></p>',
  '<h5 style="margin: 0.75em 0"><br></h5><p style="margin: 0.5em 0"><br></p>',
  '<h6 style="margin: 0.75em 0"><br></h6><p style="margin: 0.5em 0"><br></p>',
]

export const LOGO_FETCH_NEW = '/assets/illustrations/fetch_logo2.png'

export const PASSWORD_MIN_CHARACTER = 8

export const URL_CMND = '/assets/images/defaultCmnd.png'

export const NOTIFICATION_POPOVER_MAX_SIZE = 10

export const BONUS_SETTING_DEFAULT_TYPE = 'default'

export const PDF_FILE_TYPE = 'application/pdf'
export const ACCEPTED_IMAGE_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/jpg',
]

export const SORT_OPTIONS = [
  { value: 'low-high', label: 'Low to High' },
  { value: 'high-low', label: 'High to Low' },
  { value: 'a-z', label: 'Name from A-Z' },
  { value: 'z-a', label: 'Name from Z-A' },
]

export const POINT_TO_VND_VALUE = 1000

export const URL_MESSENGER_CONTACT = 'https://m.me/fetchvietnam'

export const ANOTHER_USER_VIEW_PERMISSION = {
  [ROLE.DIRECTOR]: [ROLE.HR_LEADER, ROLE.TA_LEADER, ROLE.MEMBER, ROLE.LEADER],
  [ROLE.HR_LEADER]: [ROLE.TA_LEADER, ROLE.MEMBER],
  [ROLE.TA_LEADER]: [ROLE.MEMBER],
  [ROLE.LEADER]: [ROLE.MEMBER],
  [ROLE.MEMBER]: [],
  [ROLE.RECRUITER]: [],
  [ROLE.CLIENT]: [],
  [ROLE.BLOGGER]: [],
}

export const DATE_DISABLE_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD =
  process.env.REACT_APP_DATE_DISABLE_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD || ''

export const CAN_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD =
  process.env.REACT_APP_IS_ADD_MEMBER_IN_CHARGE_IN_ALL_CARD || false

export const DATE_DISABLE_EDIT_SALARY_OFFER_IN_PROBATION =
  process.env.REACT_APP_DATE_DISABLE_EDIT_SALARY_OFFER_IN_PROBATION || ''

export const DATE_CAN_EDIT_EX_EMPLOYEE_IN_ALL_CARD =
  process.env.REACT_APP_DATE_CAN_EDIT_EX_EMPLOYEE_IN_ALL_CARD || ''
