/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import { API_ADMIN_SEARCH_CANDIDATE, API_LIST_JOBS } from 'routes/api'

import { _getApi } from 'utils/axios'

export const getListActiveJobs = createAsyncThunk(
  'summaryHiring/getListActiveJobs',
  async (queries = {}) => {
    const response = await _getApi(API_LIST_JOBS, { params: queries })
    return response.data || {}
  }
)

export const getListCandidatesInJob = createAsyncThunk(
  'summaryHiring/getListCandidatesInJob',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_ADMIN_SEARCH_CANDIDATE}?${qs.stringify(queries)}`
    )

    if (!response?.data?.success)
      throw new Error(response?.message || response?.data?.message)

    return response?.data || {}
  }
)

const initialState = {
  listJobs: {
    isLoading: false,
    data: {},
  },
  listCandidates: {
    isLoading: true,
    data: {},
  },
}

const summaryHiringSlice = createSlice({
  name: 'summaryHiring',
  initialState,
  reducers: {
    resetListCandidates(state) {
      state.listCandidates = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getListActiveJobs.pending, (state) => {
        state.listJobs.isLoading = true
      })
      .addCase(getListActiveJobs.fulfilled, (state, action) => {
        state.listJobs.isLoading = false
        state.listJobs.data = action.payload
      })
      .addCase(getListActiveJobs.rejected, (state) => {
        state.listJobs.isLoading = false
      })
      .addCase(getListCandidatesInJob.pending, (state) => {
        state.listCandidates.data = {}
        state.listCandidates.isLoading = true
      })
      .addCase(getListCandidatesInJob.fulfilled, (state, action) => {
        state.listCandidates.data = action.payload
        state.listCandidates.isLoading = false
      })
      .addCase(getListCandidatesInJob.rejected, (state, action) => {
        if (action?.meta?.aborted) {
          state.listCandidates.data = {}
          return
        }

        state.listCandidates.isLoading = false
      })
  },
})

export const selectListActiveJobs = createSelector(
  [(state) => state.summaryHiring.listJobs],
  (listJobs) => listJobs
)

export const selectListCandidates = createSelector(
  [(state) => state.summaryHiring.listCandidates],
  (listCandidates) => listCandidates
)

export const { resetListCandidates } = summaryHiringSlice.actions

export default summaryHiringSlice.reducer
