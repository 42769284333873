import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import qs from 'query-string'

import {
  API_BONUS_TEAM,
  API_HISTORY_KPI_TEAM,
  API_KPI_PROFILE_USER,
  API_KPI_TEAM,
} from 'routes/api'

import { _getApi, _patchApi } from 'utils/axios'

export const getListKpiInfo = createAsyncThunk(
  'kpiBonus/getListKpiInfo',
  async ({ userId = '', queries = {} }) => {
    const url = `${API_KPI_PROFILE_USER}/${userId}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data?.kpiUser || {}
  }
)

export const getListBonusInfo = createAsyncThunk(
  'kpiBonus/getListBonusInfo',
  async (queries = {}) => {
    const url = `${API_BONUS_TEAM}?${qs.stringify(queries)}`
    const response = await _getApi(url)

    return response?.data || {}
  }
)

export const getListKpiTeams = createAsyncThunk(
  'kpiBonus/getListKpiTeams',
  async (queries = {}) => {
    const response = await _getApi(`${API_KPI_TEAM}?${qs.stringify(queries)}`)

    return response?.data?.list || []
  }
)

export const getListHistoryKpiTeam = createAsyncThunk(
  'kpiBonus/getListHistoryKpiTeam',
  async (queries = {}) => {
    const response = await _getApi(
      `${API_HISTORY_KPI_TEAM}?${qs.stringify(queries)}`
    )

    return response?.data?.history || []
  }
)

export const updateBonusTeam = createAsyncThunk(
  'kpiBonus/updateBonusTeam',
  async (data) => {
    try {
      await _patchApi(API_BONUS_TEAM, data)
    } catch (error) {
      throw new Error(error?.message)
    }
  }
)

const initialState = {
  kpiInfo: {
    isLoading: false,
    data: {},
  },
  bonusInfo: {
    isLoading: false,
    data: {},
  },
  listKpiTeams: {
    isLoading: false,
    data: [],
  },
  listHistoryKpiTeam: {
    isLoading: false,
    data: [],
  },
}

const kpiBonusSlice = createSlice({
  name: 'kpiBonus',
  initialState,
  reducers: {
    resetHistoryKpiTeam(state) {
      state.listHistoryKpiTeam.isLoading = false
      state.listHistoryKpiTeam.data = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListKpiInfo.pending, (state) => {
        state.kpiInfo.isLoading = true
        state.kpiInfo.data = {}
      })
      .addCase(getListKpiInfo.fulfilled, (state, action) => {
        state.kpiInfo.isLoading = false
        state.kpiInfo.data = action.payload
      })
      .addCase(getListKpiInfo.rejected, (state) => {
        state.kpiInfo.isLoading = false
        state.kpiInfo.data = {}
      })
      .addCase(getListBonusInfo.pending, (state) => {
        state.bonusInfo.isLoading = true
        state.bonusInfo.data = {}
      })
      .addCase(getListBonusInfo.fulfilled, (state, action) => {
        state.bonusInfo.isLoading = false
        state.bonusInfo.data = action.payload
      })
      .addCase(getListBonusInfo.rejected, (state) => {
        state.bonusInfo.isLoading = false
        state.bonusInfo.data = {}
      })
      .addCase(getListKpiTeams.pending, (state) => {
        state.listKpiTeams.isLoading = true
      })
      .addCase(getListKpiTeams.fulfilled, (state, action) => {
        state.listKpiTeams.isLoading = false
        state.listKpiTeams.data = action.payload
      })
      .addCase(getListKpiTeams.rejected, (state) => {
        state.listKpiTeams.isLoading = false
        state.listKpiTeams.data = []
      })
      .addCase(getListHistoryKpiTeam.pending, (state) => {
        state.listHistoryKpiTeam.isLoading = true
      })
      .addCase(getListHistoryKpiTeam.fulfilled, (state, action) => {
        state.listHistoryKpiTeam.isLoading = false
        state.listHistoryKpiTeam.data = action.payload
      })
      .addCase(getListHistoryKpiTeam.rejected, (state) => {
        state.listHistoryKpiTeam.isLoading = false
        state.listHistoryKpiTeam.data = []
      })
  },
})

export const { resetHistoryKpiTeam } = kpiBonusSlice.actions

export const selectListKpiInfo = createSelector(
  [(state) => state.kpiBonus.kpiInfo],
  (kpiInfo) => kpiInfo
)

export const selectListBonusInfo = createSelector(
  [(state) => state.kpiBonus.bonusInfo],
  (bonusInfo) => bonusInfo
)

export const selectListKpiTeams = createSelector(
  [(state) => state.kpiBonus.listKpiTeams],
  (listKpiTeams) => listKpiTeams
)

export const selectListHistoryKpiTeam = createSelector(
  [(state) => state.kpiBonus.listHistoryKpiTeam],
  (listHistoryKpiTeam) => listHistoryKpiTeam
)

export default kpiBonusSlice.reducer
