import { createContext, useEffect, useReducer } from 'react'

import jwtDecode from 'jwt-decode'
import PropTypes from 'prop-types'

import { ROLE } from 'config'

import {
  API_LOGIN,
  API_REFRESH_TOKEN,
  API_REGISTER_RECRUITER,
  API_SIGNIN_SOCIALS,
  API_USER,
} from 'routes/api'

// utils
import { _getApi, _postApi } from 'utils/axios'
import { handleSyncTotalClick } from 'utils/handleSyncTotalClick'
import {
  isValidToken,
  setIsFirstLogin,
  setLinkedInOauthState,
  setRefreshToken,
  setRememberMe,
  setSession,
} from 'utils/jwt'

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  loginMethod: null,
  user: null,
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      loginMethod: user?.social,
      isInitialized: true,
      user,
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      loginMethod: user?.social,
      user,
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
}

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  loginSocial: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
})

AuthProvider.propTypes = {
  children: PropTypes.node,
}

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken =
          typeof window !== 'undefined'
            ? localStorage.getItem('accessToken')
            : ''

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken)

          const user = await getUserInfo(accessToken)
          if (
            [ROLE.LEADER, ROLE.TA_LEADER, ROLE.HR_LEADER, ROLE.MEMBER].includes(
              user?.role
            )
          )
            handleSyncTotalClick()

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          })
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    }

    initialize()
  }, [])

  const getAccessToken = async (refreshToken = '', remember = false) => {
    if (!refreshToken) return

    const response = await _postApi(API_REFRESH_TOKEN, null, {
      headers: {
        'X-Refresh-Token': refreshToken,
      },
    })
    const accessToken = response?.data?.accessToken || ''

    setRememberMe(remember)
    setRefreshToken(refreshToken)
    setSession(accessToken)

    const user = await getUserInfo(accessToken)
    if (
      [ROLE.LEADER, ROLE.TA_LEADER, ROLE.HR_LEADER, ROLE.MEMBER].includes(
        user?.role
      )
    )
      handleSyncTotalClick()

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    })
  }

  const login = async (email, password, remember) => {
    const { data = {} } = await _postApi(API_LOGIN, {
      email,
      password,
    })

    const { refreshToken = '', isFirstLogin = false } = data?.user || {}

    setIsFirstLogin(isFirstLogin)
    await getAccessToken(refreshToken, remember)
  }

  const loginSocial = async (responseSocial) => {
    const response = await _postApi(API_SIGNIN_SOCIALS, responseSocial)

    const { refreshToken = '', isFirstLogin = false } =
      response?.data?.user || ''

    setIsFirstLogin(isFirstLogin)
    await getAccessToken(refreshToken, responseSocial)
  }

  const logout = async () => {
    setRememberMe(null)
    setRefreshToken(null)
    setSession(null)
    setIsFirstLogin(null)
    setLinkedInOauthState(null)
    dispatch({ type: 'LOGOUT' })
  }

  const register = async (name, email, password, rePassword, codeRefer) => {
    const response = await _postApi(API_REGISTER_RECRUITER, {
      name,
      email,
      password,
      rePassword,
      codeRefer,
    })

    if (!response?.data?.success) {
      throw new Error(response?.message)
    }
  }

  const getUserInfo = async (accessToken) => {
    const decoded = jwtDecode(accessToken)
    const response = await _getApi(`${API_USER}/${decoded.userId}`)
    const { referralReward = 0, user = {} } = response?.data || {}

    const {
      email,
      name: displayName = '',
      Role: { id: roleId, name: role } = {},
      Team = {},
      id: userId,
      linkAvatar,
      social,
      codeRefer = '',
      expireRefer = '',
      userRefer = '',
    } = user || {}
    const { id: teamId, name: team } = Team || {}

    return {
      email,
      displayName,
      role,
      roleId,
      userId,
      linkAvatar,
      team,
      teamId,
      social,
      codeRefer,
      expireRefer,
      userRefer,
      referralReward,
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        dispatch,
        login,
        loginSocial,
        logout,
        register,
        getUserInfo,
        getAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
